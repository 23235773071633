import React from 'react';
import Icon from '../Icon';
import Tooltip from '.';

interface Props {
  children: React.ReactNode;
}

export default function TooltipIcon({ children }: Props): JSX.Element {
  return (
    <Tooltip label={children}>
      <Icon glyph="help" color="gray.500" />
    </Tooltip>
  );
}
