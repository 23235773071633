import React from 'react';
import Icon from '../../Icon';

interface Props {
  isSorted?: boolean;
  isSortedDesc?: boolean;
}

export default function SortIcon({
  isSorted = false,
  isSortedDesc = false,
}: Props): JSX.Element | null {
  if (!isSorted) {
    return null;
  }

  if (isSortedDesc) {
    return <Icon glyph="chevron-down" aria-label="sorted descending" />;
  }

  return <Icon glyph="chevron-up" aria-label="sorted ascending" />;
}
