import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LogIn from './LogIn';

export default function LogInPage(): JSX.Element {
  return (
    <Routes>
      <Route index element={<LogIn />} />
    </Routes>
  );
}
