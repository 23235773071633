import React from 'react';
import { Box, Center, Spinner } from '..';
import CardContent from './CardContent';
import CardHeader, { Props as CardHeaderProps } from './CardHeader';
import CardFooter, { Props as CardFooterProps } from './CardFooter';
import CardShell from './CardShell';

export { CardContent, CardHeader, CardShell };
export type { CardHeaderProps };
export type { CardFooterProps };

export type CardProps = CardHeaderProps &
  CardFooterProps & {
    children: React.ReactNode;
    error?: string;
    loading?: boolean;
    noContentPadding?: boolean;
  };

export default function Card({
  children,
  loading = false,
  error,
  noContentPadding = false,
  ...otherProps
}: CardProps): JSX.Element {
  return (
    <CardShell>
      {otherProps.title ? <CardHeader {...otherProps} /> : null}
      <CardContent padding={noContentPadding ? 0 : 4}>
        {loading ? (
          <Center>
            <Spinner data-testid="loading" size="lg" color="gray.400" />
          </Center>
        ) : null}

        {error ? <Box color="red.700">{error}</Box> : null}

        {!loading && !error ? children : null}

        {otherProps.footnote || otherProps.footnoteRight ? (
          <CardFooter {...otherProps} />
        ) : null}
      </CardContent>
    </CardShell>
  );
}
