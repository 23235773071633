import React from 'react';
import { Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { AthleteApplicationPageMobilePadding } from './Athletes';
import { offerings } from '../../constants/copy';
import Offering from './Offering';
import { ApplicationPageActionButton } from './AthletesHero';

type Props = {
  handleOpen: () => void;
};

const AthletesSection4 = ({ handleOpen }: Props) => {
  return (
    <Flex
      direction="column"
      backgroundColor="#221F1D"
      padding={{
        sm: AthleteApplicationPageMobilePadding,
        lg: `4rem 2rem 4rem 2rem`,
      }}
      gap={{ sm: '2rem', md: '6rem' }}
    >
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontWeight={400}
        fontSize={{ sm: '16px', md: '25px' }}
        textAlign={{ sm: 'center', md: 'left' }}
      >
        WHAT WE OFFER
      </Text>
      <SimpleGrid columns={{ sm: 3, lg: 6 }} spacingY={3}>
        {offerings.map((offering, index) => (
          <Offering key={index} image={offering.image} text={offering.text} />
        ))}
      </SimpleGrid>
      <Flex justifyContent={{ sm: 'center', md: 'flex-start' }}>
        <ApplicationPageActionButton width="125px" onClick={handleOpen}>
          APPLY HERE
        </ApplicationPageActionButton>
      </Flex>
    </Flex>
  );
};

export default AthletesSection4;
