import React from 'react';
import { Box } from '../../../../ui';
import HoverListItem from './HoverListItem';
import { enviroFactors } from '../../../../constants';

type Props = {
  setHovered: (value: boolean) => void;
  setFactorCategory: (value: string) => void;
};

const EnvironmentalAssesmentItems = ({
  setHovered,
  setFactorCategory,
}: Props) => {

  return (
    <Box>
      {Object.keys(enviroFactors).map(category => (
        <HoverListItem
          key={category}
          factors={enviroFactors}
          factorCategory={category}
          setHovered={setHovered}
          setFactorCategory={setFactorCategory}
        />
      ))}
    </Box>
  );
};

export default EnvironmentalAssesmentItems;
