import React from 'react';
import { motion, Transition } from 'framer-motion';

const transition: Transition = {
  duration: 2,
  repeat: Infinity,
};

/**
 * Renders an animated ellipses (...) to indicate a relative time continues to
 * increase. Used next to the age value for open code reviews.
 */
export default function Ticker(): JSX.Element {
  return (
    <span>
      <motion.span
        animate={{
          opacity: [0, 1, 1, 1, 0, 0, 0],
        }}
        transition={transition}
      >
        .
      </motion.span>
      <motion.span
        animate={{
          opacity: [0, 0, 1, 1, 1, 0, 0],
        }}
        transition={transition}
      >
        .
      </motion.span>
      <motion.span
        animate={{
          opacity: [0, 0, 0, 1, 1, 1, 0],
        }}
        transition={transition}
      >
        .
      </motion.span>
    </span>
  );
}
