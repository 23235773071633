import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import { Box, Heading, HStack, VStack, SimpleGrid, Tooltip } from '..';
import Stat from './Stat';
import toPercent from '../util/toPercent';

export type Units = 'percent' | 'lines';

interface Metric {
  color: string;
  label: string;
  tooltip: string;
  value: number;
}

export interface MetricsBarProps {
  metrics: Metric[];
  onlyBar?: boolean;
  ratio: number;
  title?: string;
  tooltip?: string;
  units?: Units;
}

function toUnits(
  units: Units,
  value: number,
  total: number,
  digits = 1
): string {
  if (units === 'lines') {
    return value.toLocaleString();
  }

  return toPercent(value, total, digits);
}

const baseBarStyles: BoxProps = {
  _first: {
    borderLeftRadius: 'md',
  },
  _last: {
    borderRightRadius: 'md',
  },
  height: '100%',
};

export default function MetricsBar({
  metrics,
  onlyBar = false,
  ratio,
  title,
  tooltip,
  units = 'percent',
}: MetricsBarProps): JSX.Element {
  const total = metrics.reduce((count, metric) => {
    return count + metric.value;
  }, 0);

  return (
    <VStack align="stretch" spacing={1.5}>
      {!onlyBar && title ? (
        <HStack>
          <Heading as="h3" fontSize="lg">
            {title}
          </Heading>
          {tooltip ? <Tooltip label={tooltip} /> : null}
        </HStack>
      ) : null}

      <HStack
        align="flex-start"
        height="20px"
        spacing="1px"
        width={toPercent(ratio, 1)}
      >
        {metrics
          .map(({ color, label, value, tooltip: tip }) => {
            if (value === 0) {
              return null;
            }

            return (
              <Tooltip label={tip} key={`bar-${label}`}>
                <Box
                  {...baseBarStyles}
                  bg={color}
                  width={toPercent(value, total)}
                />
              </Tooltip>
            );
          })
          .filter(record => record !== null)}
        s
      </HStack>

      {!onlyBar ? (
        <SimpleGrid columns={[4, null, null, null, 2, 4]} spacing={0}>
          {metrics.map(({ color, label, value, tooltip: tip }) => (
            <Stat
              key={`stat-${label}`}
              color={color}
              label={label}
              tooltip={tip}
              value={toUnits(units, value, total)}
            />
          ))}
        </SimpleGrid>
      ) : null}
    </VStack>
  );
}
