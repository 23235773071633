import React from 'react';
import { Flex, Radio } from '..';

interface Props {
  selected: boolean;
}

export default function DataTableSelectControl({ selected }: Props) {
  return (
    <Flex width="5" marginRight="2.5">
      <Radio borderColor="gray.300" isChecked={selected} />
    </Flex>
  );
}
