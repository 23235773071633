import React from 'react';
import { IconGlyph } from '../Icon';

export interface NavigationEntry {
  enabled?: boolean;
  icon: IconGlyph;
  label: string;
  to: string;
}

export interface LinkEntry {
  enabled?: boolean;
  href: string;
  icon: IconGlyph;
  label: string;
}

interface PageContextState {
  customNavigation?: React.ReactNode;
  links: LinkEntry[];
  navigation: NavigationEntry[];
}

interface PageContextProviderProps {
  /** The routes and pages within the page context. */
  children: React.ReactNode;
  /** Replace the default navigation with a custom component. */
  customNavigation?: React.ReactNode;
  /** A list of external links to show in the navigation menu. */
  links?: LinkEntry[];
  /** A list of internal links to show in the navigation menu. */
  navigation?: NavigationEntry[];
}

const context = React.createContext<PageContextState>({
  links: [],
  navigation: [],
});

export const PageContext = ({
  children,
  customNavigation,
  links = [],
  navigation = [],
}: PageContextProviderProps) => {
  const { Provider } = context;

  return (
    <Provider value={{ customNavigation, links, navigation }}>
      {children}
    </Provider>
  );
};

export function usePageContext(): PageContextState {
  return React.useContext(context);
}
