import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import React from 'react';

import Icon from '../../Icon';

interface Props {
  defaultValue: { name: string; value: string };
  isDisabled?: boolean;
  onClick: (name: string) => void;
  options: { name: string; value: string }[];
}

const HeaderMenu = ({
  options,
  defaultValue,
  isDisabled,
  onClick,
}: Props): JSX.Element => (
  <Box>
    <Menu>
      <MenuButton
        verticalAlign="baseline"
        paddingLeft={1}
        paddingRight={1}
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="1px"
        backgroundColor="transparent"
        _expanded={{ bg: 'transparent' }}
        as={Button}
        fontSize="2xl"
        fontWeight="semibold"
        rightIcon={<Icon glyph="chevron-down" color="gray.400" />}
      >
        {defaultValue.value}
      </MenuButton>
      <Portal>
        <MenuList fontSize="xl" fontWeight="semibold">
          {options.map(option => (
            <MenuItem
              _hover={{
                textDecoration: 'underline',
              }}
              isDisabled={isDisabled}
              key={option.name}
              onClick={e => {
                onClick(e.currentTarget.innerHTML);
              }}
            >
              {option.value}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  </Box>
);
export default HeaderMenu;
