import React from 'react';
import { Grid, Text } from '@chakra-ui/react';
import Card, { CardProps } from '../Card';
import Link from '../Link';
import Tooltip from '../Tooltip';
import StatusSymbol, { StatusType } from './StatusSymbol';

export interface StatusCheck {
  label: string;
  status: StatusType;
  statusTooltip?: string;
  to: string;
  value: string;
  valueTooltip?: string;
}

interface StatusChecklistProps {
  action?: CardProps['action'];
  checks: StatusCheck[];
  error?: CardProps['error'];
  loading?: CardProps['loading'];
  title: CardProps['title'];
}

export default function StatusChecklist({
  action,
  checks,
  error,
  loading = false,
  title,
}: StatusChecklistProps): JSX.Element {
  return (
    <Card title={title} loading={loading} error={error} action={action}>
      <Grid
        templateColumns="auto 1fr auto"
        alignItems="center"
        gap={2}
        fontSize="16px"
      >
        {checks.map(check => {
          return (
            <React.Fragment key={check.label}>
              <StatusSymbol
                status={check.status}
                tooltip={check.statusTooltip}
              />
              <Link to={check.to ?? ''}>{check.label}</Link>

              <Text textAlign="right" color="gray.500">
                <Tooltip
                  label={check.valueTooltip}
                  shouldWrapChildren
                  isDisabled={check.valueTooltip === undefined}
                >
                  <Link to={check.to ?? ''}>{check.value}</Link>
                </Tooltip>
              </Text>
            </React.Fragment>
          );
        })}
      </Grid>
    </Card>
  );
}
