import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { useMediaQuery } from '@chakra-ui/react';
import './ForgotPassword.css'
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Copyright from '../../components/Copyright';
import Pool from '../../../server/cognito';
import PageBoundary from '../../ui/ErrorBoundary';
import {
    Box,
    Flex,
    Text,
  } from '../../ui';
import XButton from '../../components/XButton';
import ProductLogo from '../../ui/Shell/components/ProductLogo';




const ForgotPassword = () => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const [stage, setStage] = useState(1); // 1= email stage, 2= code stage
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();   
    



    const getUser = () => {
        return new CognitoUser({
            Username: email.toLowerCase(),
            Pool
        });
    };

    const sendCode = event => {
        event.preventDefault();

        getUser().forgotPassword({
            onSuccess: data => {
                console.log( "onSuccess:", data);
            },
            onFailure: err => {
                console.error("onFailure:", err);
                toast.error(err.message, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'dark',
                });
            },
            inputVerificationCode: data => {
                console.log("Input code:", data);
                setStage(2);
            }
        });
    };
    

    const resetPassword = event => {
      event.preventDefault();
    
      class PasswordMismatchError extends Error {
        constructor(message) {
          super(message);
          this.name = 'PasswordMismatchError';
          this.code = 'PASSWORD_MISMATCH_ERROR';
        }
      }
    
      try {
        if (password !== confirmPassword) {
          console.error("Passwords do not match");
          toast.error("Passwords do not match", {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          throw new PasswordMismatchError("Passwords do not match") 
        } else {
          getUser().confirmPassword(code, password, {
            onSuccess: data => {
              console.log("onSuccess:", data);
              navigate('../Login');
            },
            onFailure: err => {
              console.error("onFailure:", err);
              toast.error(err.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
            }
          });
        }
      } catch (error) {
        console.error("An error occurred: ", error);
      }
    };
    


    return(
       <PageBoundary>
       <>
        <Flex direction="row" h="100vh" overflowY="hidden">
          {!isMobile && (
            <Box
              bgImage={'/static/ads/earth.png'}
              bgRepeat="no-repeat"
              bgSize="cover"
              bgColor="current"
              flex="1"
              width="100%"
            />
          )}
          <Flex
            flex="1"
            bg="#141414"
            alignItems="center"
            justifyContent="space-between"
            direction="column"
            pt="6rem"
            style={{ position: 'relative' }}
          >
            <XButton style={{ position: 'absolute', top: '2%', right: '2%' }} />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              direction="column"
            >
              <Link to="/">
                <ProductLogo
                  boxSize="104px"
                  display="block"
                  name="MNLY"
                  position="relative"
                  top="-1.05rem"
                />
              </Link>

              <Text
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="32px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="150%"
                mt="3rem"
              >
                Forgot Password
              </Text>
              {stage === 1 && (
                    <form onSubmit ={sendCode}>
                        <input className='Forgot' value={email} placeholder='Email' 
                        onChange={event => setEmail(event.target.value)} />
                        <button className='Forgot' type='submit'>Send Verification Code</button>
                    </form>
                )}

                {stage === 2 && (
                    <form onSubmit={resetPassword}>
                        <input className='Forgot' value={code} placeholder='Code' 
                        onChange={event => setCode(event.target.value)} />
                        <input className='Forgot' type='password' value={password} placeholder='New Password' 
                        onChange={event => setPassword(event.target.value)} />
                        <input className='Forgot' type='password' value={confirmPassword} placeholder='Confirm Password' 
                        onChange={event => setConfirmPassword(event.target.value)} />
                        <button className='Forgot' type='submit'>Change Password</button>
                    </form>
                )}
            </Flex>
            <Copyright />
          </Flex>
       </Flex>
       <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
       </>
       </PageBoundary>             
    );
}
export default ForgotPassword
