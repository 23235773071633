import React from 'react';
import { Text } from '../../../../ui';
import ResultsScale from './ResultsScale';
import { Flex } from '@chakra-ui/react';
import { Factor, smallGap } from '../../../../constants';

type Props = {
  title: string;
  complete: boolean;
  completeText: string;
  incompleteText: string;
  factors: Factor;
};
const LockOrUnlockBox = ({
  title,
  complete,
  completeText,
  incompleteText,
  factors,
}: Props) => {
  return (
    <Flex flexDirection="column" gap={smallGap}>
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontSize="24px"
        fontWeight="700"
        textAlign={{ base: 'left', sm: 'center', md: 'left' }}
      >
        {title}
      </Text>

      <Flex
        flexDirection="column"
        justifyContent="space-around"
        border="solid .1rem #7D7C7A"
        color="#E3DBD3"
        maxWidth="375px"
        height="175px"
      >
        <Text
          color="#7D7C7A"
          fontFamily="DIN-Medium"
          fontSize="14px"
          fontWeight="700"
          lineHeight="150%"
          textAlign="center"
          paddingLeft="1rem"
          paddingRight="1rem"
        >
          {Object.keys(factors).map((factor, i) =>
            i === 0 ? factors[factor].title : ' - ' + factors[factor].title
          )}
        </Text>

        <ResultsScale />
        <Flex justifyContent="center" alignItems="center">
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="14px"
            fontStyle="normal"
            lineHeight="normal"
          >
            {complete ? completeText : incompleteText}
          </Text>
          <img
            src={`static/icons/${complete ? 'check_circle' : 'lock'}.png`}
            width="24px"
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LockOrUnlockBox;
