import React from 'react';
import { Flex } from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionSubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const Step4 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-4"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <StepLabel> Step 4</StepLabel>
      <InstructionTitle>
        Ensure your hands are warmed up as much as possible.
      </InstructionTitle>
      <InstructionSubtext>
        {' '}
        Warm water will help open up your capillaries and get your blood
        flowing. <br /> Run your hands under warm water for one minute.
      </InstructionSubtext>

      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step4;