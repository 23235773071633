import React from 'react';
import {
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

interface DetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const DetailsModal: React.FC<DetailsModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay bg="rgba(0, 0, 0, 0.6)" />
      <ModalContent bg="#141414" color="#E3DBD3">
        <ModalHeader
          fontFamily="DIN-Medium"
          fontSize="24px"
          fontWeight="700"
          textAlign="left"
        >
          Billing Details
        </ModalHeader>
        <Divider borderColor="#7D7C7A" mb="4" />
        <ModalCloseButton />
        <ModalBody>
          <Text
            as="p"
            fontFamily="DIN-Regular"
            fontSize="16px"
            lineHeight="1.6"
            mb="4"
          >
            This price does not include any discounts or offers added at check
            out. Once you have subscribed today, you will receive your test kit
            within 5-7 business days.
          </Text>
          <Text
            as="p"
            fontFamily="DIN-Regular"
            fontSize="16px"
            lineHeight="1.6"
            mb="4"
          >
            Your second billing date will occur in 30 days. Once you receive
            your test kit you must complete the sample and send it back to our
            lab in the provided return sleeve. Your results will populate in
            your portal within 48 hours of the sample receipt This will initiate
            the supplement formulation process. You can expect your supplements
            to ship 48 business hours once the process has initiated.
          </Text>
          <Text
            as="p"
            fontFamily="DIN-Regular"
            fontSize="16px"
            lineHeight="1.6"
            mb="4"
          >
            You can pause your subscription or change your order date in your
            account.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DetailsModal;
