import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import describeArc from '../helpers/describeArc';
import getColor from '../helpers/getColor';

interface Props extends BoxProps {
  negativeThreshold: number;
  positiveThreshold: number;
  value: number;
}

/**
 * Renders a score indicator with a ring indicating the value, and the value
 * itself in the center.
 */
export default function NormalScore({
  value,
  positiveThreshold,
  negativeThreshold,
  ...props
}: Props): JSX.Element {
  const color = getColor(value, positiveThreshold, negativeThreshold);

  return (
    <Box
      aria-label={`Score of ${value}%`}
      as="svg"
      display="inline-block"
      height="32px"
      role="img"
      viewBox="0 0 32 32"
      width="32px"
      {...props}
    >
      {/* Render a faded circle showing the path of the value ring. */}
      <Box
        as="circle"
        cx="16"
        cy="16"
        fill="none"
        opacity={0.1}
        r="14"
        stroke="black"
        strokeLinecap="round"
        strokeWidth="4"
      />

      {/* If the value is between 0 and 100 exclusively, render an arc. */}
      {value > 0 && value < 100 ? (
        <Box
          as="g"
          data-testid="score-value-arc"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="4"
        >
          <path d={describeArc(16, 16, 14, 0, (value / 100) * 360)} />
        </Box>
      ) : null}

      {/* If the value is 100, render a full circle.  */}
      {value >= 100 ? (
        <Box
          as="circle"
          cx="16"
          cy="16"
          data-testid="score-value-circle"
          fill="none"
          r="14"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="4"
        />
      ) : null}

      {/* Render the value as text in the center. */}
      <Box
        as="text"
        fill="black"
        fontSize="12px"
        fontWeight="bold"
        textAnchor="middle"
        x="16"
        y="20"
      >
        {value}
      </Box>
    </Box>
  );
}
