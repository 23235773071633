import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

/** The Slack logo rendered in full color. */
export default function SlackLogo(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 1610 1610" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M338.115 1017.459c0 93.115-76.066 169.18-169.18 169.18-93.115 0-169.18-76.065-169.18-169.18 0-93.115 76.065-169.18 169.18-169.18h169.18v169.18ZM423.36 1017.459c0-93.115 76.066-169.18 169.181-169.18 93.115 0 169.18 76.065 169.18 169.18v423.607c0 93.114-76.065 169.18-169.18 169.18-93.115 0-169.18-76.066-169.18-169.18v-423.607Z"
          fill="#E01E5A"
        />
        <path
          d="M592.541 338.115c-93.115 0-169.18-76.066-169.18-169.18 0-93.115 76.065-169.18 169.18-169.18 93.115 0 169.18 76.065 169.18 169.18v169.18h-169.18ZM592.541 423.36c93.115 0 169.18 76.066 169.18 169.181 0 93.115-76.065 169.18-169.18 169.18H168.934c-93.114 0-169.18-76.065-169.18-169.18 0-93.115 76.066-169.18 169.18-169.18h423.607Z"
          fill="#36C5F0"
        />
        <path
          d="M1271.885 592.541c0-93.115 76.066-169.18 169.18-169.18 93.115 0 169.18 76.065 169.18 169.18 0 93.115-76.065 169.18-169.18 169.18h-169.18v-169.18ZM1186.64 592.541c0 93.115-76.066 169.18-169.181 169.18-93.115 0-169.18-76.065-169.18-169.18V168.934c0-93.114 76.065-169.18 169.18-169.18 93.115 0 169.18 76.066 169.18 169.18v423.607Z"
          fill="#2EB67D"
        />
        <path
          d="M1017.459 1271.885c93.115 0 169.18 76.066 169.18 169.18 0 93.115-76.065 169.18-169.18 169.18-93.115 0-169.18-76.065-169.18-169.18v-169.18h169.18ZM1017.459 1186.64c-93.115 0-169.18-76.066-169.18-169.181 0-93.115 76.065-169.18 169.18-169.18h423.607c93.114 0 169.18 76.065 169.18 169.18 0 93.115-76.066 169.18-169.18 169.18h-423.607Z"
          fill="#ECB22E"
        />
      </g>
    </Icon>
  );
}
