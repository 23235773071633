import React from 'react';
import { Box, HStack, Text, Tooltip } from '..';
import Icon from '../Icon';

interface Props {
  color: string;
  label: string;
  tooltip: string;
  value: string;
}

const Stat = ({ color, label, tooltip, value }: Props): JSX.Element => {
  return (
    <Box>
      <Tooltip label={tooltip}>
        <HStack alignItems="flex-start">
          <Box pt={2} color={color}>
            <Icon glyph="circle-filled" />
          </Box>
          <Box>
            <Text as="div" fontSize="md">
              {label}
            </Text>
            <Text as="div" fontSize="md" fontWeight="bold">
              {value}
            </Text>
          </Box>
        </HStack>
      </Tooltip>
    </Box>
  );
};

export default Stat;
