import axios from 'axios';
import {
  QUIZ_FETCH_START,
  QUIZ_FETCH_FAILED,
  QUIZ_FETCH_SUCCEED,
  QUIZ_RESET,
} from './actionTypes';

const mock = process.env.MOCK_SOURCES === 'true' || false;
const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';

export function fetchQuizById(quizId: string | number) {
  return async (
    dispatch: (arg0: {
      type: string;
      quiz?: any;
      currentQuizQuestion?: any;
      error?: any;
    }) => void
  ) => {
    dispatch(fetchStart());

    try {
      const response = await axios.get(
        `${baseURL}${
          mock ? 'quiz.json' : 'survey/' + quizId + '?version=latest'
        }`
      );

      const questions = Object.entries(response.data.questions)
        // @ts-ignore
        //.filter(entry => entry[1].factorId !== 'NA')
        .sort((a, b) => {
          return (
            [
              'focus',
              'confidence',
              'stamina',
              'mood',
              'sleep',
              'libido',
              'recovery',
              // @ts-ignore
            ].indexOf(a[1].factorId) -
            [
              'focus',
              'confidence',
              'stamina',
              'mood',
              'sleep',
              'libido',
              'recovery',
              // @ts-ignore
            ].indexOf(b[1].factorId)
          );
        })
        .map(entity => {
          // @ts-ignore
          return { ...entity[1], id: entity[0] };
        });

      dispatch(fetchQuizSucceed({ ...response.data, questions }));
    } catch (error) {
      dispatch(fetchFailed(error));
    }
  };
}

export function fetchStart() {
  return {
    type: QUIZ_FETCH_START,
  };
}

export function fetchFailed(error: unknown) {
  return {
    type: QUIZ_FETCH_FAILED,
    error: error,
  };
}

export function fetchQuizSucceed(quiz: any[]) {
  return {
    type: QUIZ_FETCH_SUCCEED,
    quiz: quiz,
  };
}

export function quizReset() {
  return {
    type: QUIZ_RESET,
  };
}
