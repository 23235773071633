import React from 'react';
import { Box, Heading, HStack, Text } from '@chakra-ui/react';
import Indicator, { Props as IndicatorProps } from '../Indicator';

export interface StatIndicatorProps extends IndicatorProps {
  helpText?: React.ReactNode;
  indicator?: string;
}

export default function StatIndicator({
  children,
  indicator,
  helpText,
  ...indicatorProps
}: StatIndicatorProps): JSX.Element {
  return (
    <Box>
      <HStack>
        <Heading>{children}</Heading>
        {indicator ? (
          <Indicator {...indicatorProps}>{indicator}</Indicator>
        ) : null}
      </HStack>
      {helpText ? (
        <Text color="gray.600" mt={0}>
          {helpText}
        </Text>
      ) : null}
    </Box>
  );
}
