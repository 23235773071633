import React from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import Icon from '../Icon';

interface Props {
  canExpand: boolean;
  isExpanded: boolean;
  onClick: () => void;
}

export default function ExpandIcon({
  canExpand,
  isExpanded,
  onClick,
}: Props): JSX.Element | null {
  const color = useColorModeValue('gray.600', 'gray.300');

  if (canExpand) {
    return isExpanded ? (
      <Icon
        boxSize={6}
        p={1}
        my={-1}
        ms={-7}
        me={1}
        glyph="chevron-down"
        color={color}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        cursor="pointer"
      />
    ) : (
      <Icon
        boxSize={6}
        p={1}
        my={-1}
        ms={-7}
        me={1}
        glyph="chevron-right"
        color={color}
        onClick={e => {
          e.stopPropagation();
          onClick();
        }}
        cursor="pointer"
      />
    );
  }

  return null;
}
