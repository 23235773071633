import React from 'react';
import {
  Modal as ChakraModal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

interface Props extends ModalProps {
  footer?: React.ReactNode;
  title: React.ReactNode;
}

export default function Modal({
  children,
  title,
  footer,
  ...otherProps
}: Props): JSX.Element {
  return (
    <ChakraModal {...otherProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
