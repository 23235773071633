import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

export default function Card(props: BoxProps): JSX.Element {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.700')}
      rounded={{ md: 'lg' }}
      shadow="base"
      {...props}
    />
  );
}
