import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionSubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const StepCard = ({
  step,
  title,
  subtext,
}: {
  step: number;
  title: string;
  subtext: string;
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      gap="0.5rem"
      maxWidth="236px"
      border="2px solid #7D7C7A"
      padding="1.5rem"
    >
      <Text
        color="#E3DBD3"
        textAlign="center"
        fontFamily="DIN-Medium"
        fontSize="18px"
        fontWeight={550}
      >
        {step}
      </Text>
      <Text
        color="#E3DBD3"
        textAlign="center"
        fontFamily="DIN-Medium"
        fontSize="18px"
        fontWeight={550}
      >
        {title}
      </Text>
      <Text
        color="#E3DBD3"
        textAlign="center"
        fontFamily="DIN-Regular"
        fontSize="10px"
      >
        {subtext}
      </Text>
    </Flex>
  );
};

const Step7 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-7"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <StepLabel>Step 7</StepLabel>
      <InstructionTitle> Return your sample.</InstructionTitle>
      <InstructionSubtext>
        A few steps are required before you can ship your sample back to the
        lab.
      </InstructionSubtext>

      <Flex flexDirection={{ base: 'row', sm: 'column', md: 'row' }} gap="4rem">
        <StepCard
          step={1}
          title="Allow your sample to dry"
          subtext="Allow your sample to dry for 30 minutes."
        />
        <StepCard
          step={2}
          title="Secure your sample"
          subtext="Put your sample in the sample bag. Do not remove the contents of the bag. Then, place your sample back in the box and close it."
        />
        <StepCard
          step={3}
          title="Put the box in the return envelope"
          subtext="Once your sample is secured in the sample bag and box, place it in the return envelope."
        />
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="1rem"
        maxWidth="439px"
        mt="2rem"
      >
        <img alt="USPS" src="/static/icons/usps.png" width={'40px'} />
        <InstructionTitle>Drop it off for shipment today.</InstructionTitle>
        <Text
          color="#E3DBD3"
          textAlign="center"
          fontFamily="DIN-Regular"
          fontSize="14px"
        >
          Allow your sample to dry for two hours. Now that your sample is secure, it’s time to return it! Please drop
          your sample off today at the corresponding shipping company noted on your return label.
          Be sure to ship it before the last drop off time of the day - to
          ensure it is viable when the lab receives it. Shipping is already paid
          for.
        </Text>
      </Flex>

      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step7;