import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface ParamOptions {
  after?: string | null;
  before?: string | null;
  first?: string | number;
  last?: string | number;
}

type UseServerPagination = [
  {
    after: string | undefined;
    before: string | undefined;
    first: number | undefined;
    last: number | undefined;
  },
  (options: ParamOptions) => void
];

interface UseServerPaginationOptions {
  defaultPageSize?: number;
  paramPrefix?: string;
}

export default function useServerPagination({
  defaultPageSize = 10,
  paramPrefix = '',
}: UseServerPaginationOptions): UseServerPagination {
  const [params, setParams] = useSearchParams();
  const after = params.get(`${paramPrefix}after`) ?? undefined;
  const before = params.get(`${paramPrefix}before`) ?? undefined;
  const firstString = params.get(`${paramPrefix}first`) ?? undefined;
  const lastString = params.get(`${paramPrefix}last`) ?? undefined;
  let first = firstString ? parseInt(firstString, 10) : undefined;
  let last = lastString ? parseInt(lastString, 10) : undefined;

  if (!first && !last) {
    if (before) {
      last = defaultPageSize;
    } else {
      first = defaultPageSize;
    }
  }

  const updatePagination = useCallback(
    (options: ParamOptions) => {
      params.delete(`${paramPrefix}after`);
      params.delete(`${paramPrefix}before`);
      params.delete(`${paramPrefix}first`);
      params.delete(`${paramPrefix}last`);

      Object.entries(options).forEach(([key, value]) => {
        params.set(key, value);
      });

      setParams(params);
    },
    [params, setParams]
  );

  return [{ after, before, first, last }, updatePagination];
}
