import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  GridItem,
  Icon,
  Input,
  VStack,
} from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { LuMail, LuMapPin } from 'react-icons/lu';
import Copyright from '../../../components/Copyright';
import {
  defaultLargeVerticalGap,
  defaultVerticalGap,
  globalHorizontalMobilePadding,
  globalHorizontalPadding,
} from '../../../constants';
import { Box, Flex, Grid, Text } from '../../../ui';
import ProductLogo from '../../../ui/Shell/components/ProductLogo';

const klaviyoFormId = 'VnVVvb';
const klaviyoApiKey = process.env.KLAVIYO_API_KEY || 'Srdwct';
const Footer = () => {
  const navigate = useNavigate();
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [email, setEmail] = useState('');
  const emailFieldEmpty = email === '';
  const [toastStatusIsError, setToastStatusIsError] = useState(false);

  const klaviyoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (klaviyoRef!.current!.innerHTML === '') {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoApiKey}`
      );

      for (let i = 0; i < 10; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `klaviyo-form-${klaviyoFormId}`);

        klaviyoRef!.current!.appendChild(script);
        klaviyoRef!.current!.appendChild(div);
      }

      if (klaviyoRef?.current) {
        klaviyoRef.current.style.display = 'none';
      }
    }
  }, []);

  const validateForm = () => {
    const emailFieldWrongFormat: boolean =
      (!String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) as unknown as boolean) && email !== undefined;

    if (emailFieldWrongFormat) {
      setEmailIsInvalid(true);
      return false;
    }

    return true;
  };
  const onSubscribeClick = () => {
    if (!validateForm()) {
      return;
    }

    // invoke kavviyo action
    const emailInputField =
      klaviyoRef!.current!.querySelector('input[id^=email_]');
    const emailSubmitButton = document.querySelector('.klaviyo-form button');

    try {
      // @ts-ignore
      emailInputField.value = email;
      console.log('here2');
      // @ts-ignore
      emailInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      emailSubmitButton!.click();

      //notify user
      setToastStatusIsError(false);
      toast.success(
        "Thanks for subscribing, you'll get the first email in a couple of minutes.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    } catch (err) {
      setToastStatusIsError(true);
      toast.error('Internal server error.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    //reset input for subsecuent subscription requests
    setEmail('');
  };

  const footerColumns = [
    {
      header: 'Quick Nav',
      links: [
        { label: 'Registration', onClick: () => navigate('/product') },
        { label: 'Login', onClick: () => navigate('/login') },
      ],
    },
    {
      header: 'Follow Us',
      links: [
        { label: 'Instagram', href: 'https://www.instagram.com/getmnly/' },
        { label: 'LinkedIn', href: 'https://www.linkedin.com/company/mnly/' },
        { label: 'Facebook', href: 'https://www.facebook.com/getmnly/' },
      ],
    },
    {
      header: 'Contact Us',
      links: [
        {
          label: (
            <VStack alignItems="flex-start" spacing={2}>
              <Flex alignItems="center">
                <Icon as={LuMapPin} mr={2} />
                <Link
                  href="https://maps.app.goo.gl/gMrah6M9zpbx2rcf9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text>
                    832 Hermosa Avenue
                    <br /> Hermosa Beach, CA 90254
                  </Text>
                </Link>
              </Flex>
              <Flex alignItems="center">
                <Icon as={LuMail} mr={2} />
                <Link
                  href="mailto:support@getmnly.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text>support@getmnly.com</Text>
                </Link>
              </Flex>
            </VStack>
          ),
        },
      ],
    },
  ];

  return (
    <Flex
      id="footer"
      flexDirection="column"
      marginTop={defaultLargeVerticalGap}
    >
      <hr
        style={{
          border: '1.5px solid #7D7C7A',
          marginBottom: '1rem',
        }}
      />
      <Flex
        width="100%"
        justifyContent={'flex-start'}
        alignItems={{ base: 'flex-end', sm: 'center', lg: 'flex-end' }}
        flexDirection={{ base: 'row', sm: 'column', lg: 'row' }}
        gap={{ base: 0, sm: '4rem', lg: 0 }}
        padding={{
          base: globalHorizontalPadding,
          sm: `0 ${globalHorizontalMobilePadding} 0 ${globalHorizontalMobilePadding}`,
          md: globalHorizontalPadding,
        }}
      >
        <Flex
          id="email-footer-section"
          flexDirection="column"
          width={{ base: undefined, lg: '50%' }}
          gap={defaultVerticalGap}
        >
          <ProductLogo width="104.18px" height="22px" name="MNLY" />
          <Flex>
            <Box
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontStyle="normal"
              lineHeight="130%"
            >
              <Text
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
                pt="2rem"
                width="60%"
              >
                Subscribe and be the first to know about MNLY.
              </Text>

              <Box w="274px">
                <FormControl isInvalid={emailIsInvalid} textAlign="center">
                  <Grid
                    pt="1rem"
                    templateColumns="258px 16px"
                    borderBottomColor="rgba(125,124,122,0.5)"
                    borderBottomWidth="1px"
                  >
                    <Text
                      color="#7D7C7A"
                      display="inline"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="normal"
                      position="relative"
                      top="10px"
                    >
                      <Input
                        _active={{
                          border: '0',
                        }}
                        _selected={{
                          border: '0',
                        }}
                        _focus={{
                          border: '0',
                        }}
                        _placeholder={{ color: '#7D7C7A' }}
                        color="#7D7C7A"
                        fontFamily="DIN-Medium"
                        fontSize="14px"
                        fontStyle="normal"
                        fontWeight="550"
                        lineHeight="138%"
                        border="0"
                        pl="1rem"
                        position="relative"
                        top="-8px"
                        placeholder="Enter email"
                        value={email}
                        onChange={e => {
                          if (emailIsInvalid) {
                            setEmailIsInvalid(false);
                          }
                          setEmail(e.currentTarget.value);
                        }}
                      />
                    </Text>

                    <Button
                      top=".05rem"
                      textAlign="right"
                      width="116px"
                      _hover={{
                        textDecoration: 'none',
                        bg: '#E3DBD3',
                        // color: '#7D7C7A',
                      }}
                      bg="#7D7C7A"
                      borderRadius="0"
                      color="#141414"
                      onClick={onSubscribeClick}
                      fontFamily="DIN-Medium"
                      fontSize={{
                        md: '.85rem',
                        sm: '.65rem',
                        base: '.35rem',
                      }}
                      fontWeight="medium"
                      lineHeight="normal"
                      position="relative"
                      height={{
                        md: '2rem',
                        sm: '2.2rem',
                        base: '1.2rem',
                      }}
                      px="1.25rem"
                      py="1.25rem"
                      textTransform="uppercase"
                    >
                      SUBSCRIBE
                    </Button>
                  </Grid>
                  {!emailIsInvalid ? (
                    <FormHelperText />
                  ) : emailFieldEmpty ? (
                    <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                      Email is required.
                    </FormErrorMessage>
                  ) : (
                    <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                      Email format is not valid.
                    </FormErrorMessage>
                  )}
                </FormControl>
                <Text
                  color="#7D7C7A"
                  fontFamily="DIN-Regular"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="5"
                  lineHeight="normal"
                  pt="1rem"
                  w="22.5rem"
                >
                  By subscribing you agree to our Privacy Policy and provide
                  consent to receive updates from our company.
                </Text>
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {footerColumns.map(({ header, links }) => (
            <GridItem key={header}>
              <VStack alignItems="start" spacing={10}>
                <Text
                  color="#E3DBD3"
                  fontFamily="DIN-Medium"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="normal"
                >
                  {header}
                </Text>
                {links.map(({ label, href, onClick }) => (
                  <React.Fragment key={label}>
                    {href ? (
                      <Link
                        href={href}
                        isExternal
                        color="#7D7C7A"
                        fontFamily="DIN-Regular"
                        fontSize="12px"
                        fontStyle="normal"
                        fontWeight="5"
                        lineHeight="normal"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {label}
                      </Link>
                    ) : (
                      <Text
                        onClick={onClick}
                        color="#7D7C7A"
                        fontFamily="DIN-Regular"
                        fontSize="12px"
                        fontStyle="normal"
                        fontWeight="5"
                        lineHeight="normal"
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        {label}
                      </Text>
                    )}
                  </React.Fragment>
                ))}
              </VStack>
            </GridItem>
          ))}
        </Grid>
      </Flex>
      <Box
        width="100%"
        padding={{
          base: globalHorizontalPadding,
          sm: globalHorizontalMobilePadding,
          md: globalHorizontalPadding,
        }}
      >
        <hr
          color="#7D7C7A"
          style={{
            border: '1.5px solid #7D7C7A',
          }}
        />
        <Flex
          justifyContent="space-between"
          flexDirection={{ base: 'row', sm: 'column', lg: 'row' }}
          gap={{ base: 0, sm: '1rem', md: 0 }}
          paddingTop="0.5rem"
        >
          <Copyright />

          <Flex gap={6}>
            <Box
              _hover={{ cursor: 'pointer' }}
              color="#7D7C7A"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              onClick={() => window.open('https://www.shopify.com/legal/terms')}
            >
              Terms of Service
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              color="#7D7C7A"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              onClick={() =>
                window.open('https://www.shopify.com/legal/privacy')
              }
            >
              Privacy Policy
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              color="#7D7C7A"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              onClick={() =>
                window.open('https://www.shopify.com/legal/cookies')
              }
            >
              Cookie
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              color="#7D7C7A"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              onClick={() => window.open('https://www.shopify.com/legal/aup')}
            >
              Policy
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              color="#7D7C7A"
              fontFamily="Inter"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              onClick={() =>
                window.open('https://www.shopify.com/accessibility/policy')
              }
            >
              Accessibility
            </Box>
          </Flex>
        </Flex>
      </Box>
      <div
        ref={klaviyoRef}
        id={`klaviyo-form-${klaviyoFormId}-container`}
      ></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: toastStatusIsError ? 'initial' : '#303525',
        }}
        theme="dark"
      />
    </Flex>
  );
};

export default Footer;
