import { Button, Flex, Text } from '../../../../ui';
import { labResultsFriendlyStatus } from '../../../../constants/copy';
import React from 'react';
import { useSelector } from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';

type Props = {
  nextStage: any;
};
const StatusAlerts = ({ nextStage }: Props) => {
  const stages = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });

  const navigate = useNavigate();

  return (
    <Flex
      justifyContent={{ base: 'flex-start', sm: 'center', md: 'flex-start' }}
    >
      {nextStage?.kit_status && (
        <Flex
          alignItems="center"
          bg="#E3DBD3"
          gap="8px"
          p="14px 14px 18px 14px"
          position="relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14 15"
            fill="none"
            style={{ height: '15' }}
          >
            <mask
              id="mask0_1987_5653"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="14"
              height="15"
            >
              <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1987_5653)">
              <path
                d="M6.41699 10.4167H7.58366V6.91666H6.41699V10.4167ZM7.00033 5.75C7.1656 5.75 7.30415 5.6941 7.41595 5.58229C7.52776 5.47048 7.58366 5.33194 7.58366 5.16666C7.58366 5.00139 7.52776 4.86284 7.41595 4.75104C7.30415 4.63923 7.1656 4.58333 7.00033 4.58333C6.83505 4.58333 6.69651 4.63923 6.5847 4.75104C6.4729 4.86284 6.41699 5.00139 6.41699 5.16666C6.41699 5.33194 6.4729 5.47048 6.5847 5.58229C6.69651 5.6941 6.83505 5.75 7.00033 5.75ZM7.00033 13.3333C6.19338 13.3333 5.43505 13.1802 4.72533 12.874C4.0156 12.5677 3.39824 12.1521 2.87324 11.6271C2.34824 11.1021 1.93262 10.4847 1.62637 9.775C1.32012 9.06528 1.16699 8.30694 1.16699 7.5C1.16699 6.69305 1.32012 5.93472 1.62637 5.225C1.93262 4.51528 2.34824 3.89791 2.87324 3.37291C3.39824 2.84791 4.0156 2.43229 4.72533 2.12604C5.43505 1.81979 6.19338 1.66666 7.00033 1.66666C7.80727 1.66666 8.5656 1.81979 9.27533 2.12604C9.98505 2.43229 10.6024 2.84791 11.1274 3.37291C11.6524 3.89791 12.068 4.51528 12.3743 5.225C12.6805 5.93472 12.8337 6.69305 12.8337 7.5C12.8337 8.30694 12.6805 9.06528 12.3743 9.775C12.068 10.4847 11.6524 11.1021 11.1274 11.6271C10.6024 12.1521 9.98505 12.5677 9.27533 12.874C8.5656 13.1802 7.80727 13.3333 7.00033 13.3333Z"
                fill="#141414"
              />
            </g>
          </svg>
          <Text
            color="#141414"
            fontFamily="DIN-Regular"
            fontSize="14px"
            fontStyle="normal"
            lineHeight="150%"
          >
            <>
              {nextStage?.kit_status
                ? // @ts-ignore
                  labResultsFriendlyStatus
                    .get(nextStage.kit_status)!
                    //@ts-ignore
                    .format(
                      nextStage.to_patient_shipping?.carrier ?? '',
                      nextStage.to_patient_shipping?.trackingNumber ?? ''
                    )
                : labResultsFriendlyStatus.get('Order Created by MNLY')
                
                }
            </>
            <p>
            {nextStage?.kit_status === 'Shipped To Patient' ? (
              <Button onClick={() => window.open('https://tools.usps.com/go/TrackConfirmAction_input')}>Track Kit</Button>
            ) : (<></>)}
            </p>
            <p>
            {nextStage?.kit_status === "Delivered To Patient" ? (
              <Button onClick={() => {
                navigate('/instructions')
              }}>Show Instructions</Button>
            ) : (<></>)}
            </p>
            <p>
            {nextStage?.kit_status === 'Shipped To Lab' ? (
              <Button onClick={() => window.open('https://tools.usps.com/go/TrackConfirmAction_input')}>Track Kit</Button>
            ) : (<></>)}
            </p>
          </Text>

          <img
            style={{
              cursor: 'pointer',
              display: 'none',
              filter: 'invert(100%)',
              height: '15px',
              position: 'absolute',
              right: '0',
              top: '.1rem',
              width: '15px',
            }}
            onClick={e => {
              // @ts-ignore
              e.target.parentElement.style.display = 'none';
            }}
            src="/static/icons/close-modal.png"
          />
        </Flex>
      )}

      {!stages.stage1.achieved && (
        <Flex
          alignItems="center"
          bg="#E3DBD3"
          gap="8px"
          p="14px 14px 18px 14px"
          position="relative"
          maxW={{ base: 'fit-content' }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <mask
              id="mask0_1987_5653"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="14"
              height="15"
            >
              <rect y="0.5" width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1987_5653)">
              <path
                d="M6.41699 10.4167H7.58366V6.91666H6.41699V10.4167ZM7.00033 5.75C7.1656 5.75 7.30415 5.6941 7.41595 5.58229C7.52776 5.47048 7.58366 5.33194 7.58366 5.16666C7.58366 5.00139 7.52776 4.86284 7.41595 4.75104C7.30415 4.63923 7.1656 4.58333 7.00033 4.58333C6.83505 4.58333 6.69651 4.63923 6.5847 4.75104C6.4729 4.86284 6.41699 5.00139 6.41699 5.16666C6.41699 5.33194 6.4729 5.47048 6.5847 5.58229C6.69651 5.6941 6.83505 5.75 7.00033 5.75ZM7.00033 13.3333C6.19338 13.3333 5.43505 13.1802 4.72533 12.874C4.0156 12.5677 3.39824 12.1521 2.87324 11.6271C2.34824 11.1021 1.93262 10.4847 1.62637 9.775C1.32012 9.06528 1.16699 8.30694 1.16699 7.5C1.16699 6.69305 1.32012 5.93472 1.62637 5.225C1.93262 4.51528 2.34824 3.89791 2.87324 3.37291C3.39824 2.84791 4.0156 2.43229 4.72533 2.12604C5.43505 1.81979 6.19338 1.66666 7.00033 1.66666C7.80727 1.66666 8.5656 1.81979 9.27533 2.12604C9.98505 2.43229 10.6024 2.84791 11.1274 3.37291C11.6524 3.89791 12.068 4.51528 12.3743 5.225C12.6805 5.93472 12.8337 6.69305 12.8337 7.5C12.8337 8.30694 12.6805 9.06528 12.3743 9.775C12.068 10.4847 11.6524 11.1021 11.1274 11.6271C10.6024 12.1521 9.98505 12.5677 9.27533 12.874C8.5656 13.1802 7.80727 13.3333 7.00033 13.3333Z"
                fill="#141414"
              />
            </g>
          </svg>
          <Text
            color="#141414"
            fontFamily="DIN-Regular"
            fontSize="14px"
            fontStyle="normal"
            lineHeight="150%"
          >
            <>Complete Health Assessment to continue.</>
          </Text>
          <img
            style={{
              cursor: 'pointer',
              display: 'none',
              filter: 'invert(100%)',
              height: '15px',
              position: 'absolute',
              right: '0',
              top: '.1rem',
              width: '15px',
            }}
            onClick={e => {
              // @ts-ignore
              e.target.parentElement.style.display = 'none';
            }}
            src="/static/icons/close-modal.png"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default StatusAlerts;
