import React from 'react';
import { Box, Flex, Text } from '../../../../ui';
import { Factor } from '../../../../constants';

type Props = {
  hovered: boolean;
  factorCategory: string;
  imagePath: string;
  factors: Factor;
  factorsCopy: any;
};

const ImageWithPopover = ({
  hovered,
  factorCategory,
  imagePath,
  factors,
  factorsCopy,
}: Props) => {
  return (
    <Flex justifyContent="center" style={{ position: 'relative' }}>
      <img
        alt="MNLY Precision Men's Health"
        src={imagePath}
        width="100%"
        style={{ maxWidth: '630px', maxHeight: '675px' }}
      />
      {hovered ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            border: '2px solid #7D7C7A',
            backgroundColor: '#141414',
            color: '#E3DBD3',
            padding: '2%',
            width: '80%',
            maxWidth: '380px',
            maxHeight: '100%',
            zIndex: 10,
          }}
        >
          <Flex flexDirection="column" gap="1rem">
            <Flex
              id="card-header"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex flexDirection="row" alignItems="center" gap="1rem">
                <Box>
                  <img
                    alt="MNLY Precision Men's Health"
                    src={`/static/icons/${factorCategory}.png`}
                  />
                </Box>
                <Text
                  color="#E3DBD3"
                  fontFamily="DIN-Medium"
                  fontSize="14px"
                  fontWeight="5"
                >
                  {factors[factorCategory].title}
                </Text>
              </Flex>
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src={`/static/icons/expand-icon.png`}
                />
              </Box>
            </Flex>
            <Flex justifyContent="center">
              <img
                alt="MNLY Precision Men's Health"
                src={`/static/scale-bar.png`}
              />
            </Flex>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="10px"
              fontWeight="5"
              overflowY="hidden"
              textOverflow="ellipsis"
            >
              {factorsCopy.get(factors[factorCategory].title)}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default ImageWithPopover;
