import React from 'react';
import { Image, useBreakpointValue } from '@chakra-ui/react';

const Supplements = () => {
  const src = useBreakpointValue({
    base: '/static/ads/micronutrients.png',
    sm: '/static/ads/micronutrients_mobile.png',
    md: '/static/ads/micronutrients.png',
  });

  return <Image boxSize="100%" objectFit="cover" src={src} />;
};
export default Supplements;
