import React from 'react';
import { Box, Progress } from '..';

interface Props {
  isVisible: boolean;
}

/** Progress indicator bar for a page that is fixed to the top of the page and spans the whole width of the page */
export default function PageProgress({ isVisible }: Props): JSX.Element {
  return (
    <Box hidden={!isVisible} position="absolute" width="100%" top={0} ml={-6}>
      <Progress
        aria-label="The page is loading."
        size="sm"
        isIndeterminate
        position="absolute"
        left={0}
        right={0}
      />
    </Box>
  );
}
