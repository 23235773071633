/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { ErrorBoundary, Placeholder } from '../../ui';
import usePageTitle from '../../hooks/usePageTitle';

export default function NotFoundPage(): JSX.Element {
  usePageTitle('Not Found');

  return (
    <ErrorBoundary>
      <Placeholder
        src="/static/illustrations/404.svg"
        title="Not Found"
        description="We are working on it!"
      />
    </ErrorBoundary>
  );
}
