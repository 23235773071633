import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

/** The PayPal logo rendered in full color. */
export default function PayPalLogo(props: IconProps): JSX.Element {
  return (
    <Icon viewBox="0 0 155.57 183.56" {...props}>
      <path
        d="M154.21 46.64c2.24-14.28 0-24-7.74-32.8C138 4.15 122.6 0 103 0H46a8.15 8.15 0 0 0-8.06 6.89L14.08 157.54a4.9 4.9 0 0 0 4.84 5.66h35.22l-2.43 15.41a4.28 4.28 0 0 0 4.23 4.95h29.69a7.14 7.14 0 0 0 7.05-6L93 176l5.59-35.47.36-2a7.14 7.14 0 0 1 7.06-6h4.44c28.76 0 51.28-11.69 57.86-45.48 2.69-14.05 1.3-25.86-5.97-34.14a28.24 28.24 0 0 0-8.13-6.27"
        transform="translate(-14.02)"
        fill="#009cde"
      />
      <path
        d="M154.21 46.64c2.24-14.28 0-24-7.74-32.8C138 4.15 122.6 0 103 0H46a8.15 8.15 0 0 0-8.06 6.89L14.08 157.54a4.9 4.9 0 0 0 4.84 5.66h35.22L63 107.09l-.27 1.76a8.14 8.14 0 0 1 8-6.89h16.75c32.88 0 58.62-13.35 66.15-52 .22-1.15.41-2.25.58-3.34"
        transform="translate(-14.02)"
        fill="#012169"
      />
      <path
        d="M72.49 46.83a7.14 7.14 0 0 1 7.05-6h44.72A93.08 93.08 0 0 1 139 41.87c1.29.21 2.54.45 3.76.72s2.41.57 3.55.91c.58.17 1.14.34 1.7.53a39.68 39.68 0 0 1 6.18 2.61c2.24-14.28 0-24-7.74-32.8C138 4.15 122.6 0 103 0H46a8.15 8.15 0 0 0-8.06 6.89L14.08 157.54a4.9 4.9 0 0 0 4.84 5.66h35.22L63 107.09Z"
        transform="translate(-14.02)"
        fill="#003087"
      />
    </Icon>
  );
}
