import { Grid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';

type CardList = (JSX.Element | null)[];

interface Props {
  /**
   * The list of card/widget components to show in the dashboard.
   * They will be displayed in left-to-right order. If a different order of cards
   * is desired based on whether the screen width is small (max 1 column), medium (max 2 columns),
   * or large (max 3 columns), pass an object with the format:
   *   `{ base: CardList; md?: CardList; sm?: CardList; lg?: CardList }`
   * Where 'base' represents the default order of widgets, and 'sm', 'md', 'lg' are all optional
   * fields that can be provided to set a custom widget orders for each of those widths.
   */
  cards:
    | { base: CardList; lg?: CardList; md?: CardList; sm?: CardList }
    | CardList;
  /**
   * The maximum number of columns of cards to show, default is 3
   */
  maxColumns?: number;
}

function placeCardsInColumns(cards: CardList, numColumns: number): CardList[] {
  const columns: CardList[] = Array(numColumns).fill([]);
  cards.forEach((card, index) => {
    const column = index % numColumns;
    columns[column] = [...columns[column], card];
  });
  return columns;
}

export default function DashboardLayout({
  cards,
  maxColumns = 3,
}: Props): JSX.Element {
  // Determine the number of columns to organize the cards into.
  // A small screen width can have max 1 column, medium width is 2 columns, and
  // large width is 3 columns. If the maxColumns prop is set, that will be used to
  // override the max number of columns that is determined by screen width, i.e.
  // if maxColumns is 2 but the screen width is large and could support 3, the number
  // of columns shown will still be 2.
  const numColumns = Math.min(
    // TODO: customize the breakpoints in chakra theme, 2xl is more like lg and xl is more like md
    // https://venmoinc.atlassian.net/browse/ITLS-3128
    useBreakpointValue({ '2xl': 3, base: 1, xl: 2 }) ?? maxColumns,
    maxColumns
  );

  let cardsByColumn: CardList[];

  if (Array.isArray(cards)) {
    cardsByColumn = placeCardsInColumns(cards, numColumns);
  } else if (numColumns === 1 && cards.sm) {
    cardsByColumn = placeCardsInColumns(cards.sm, numColumns);
  } else if (numColumns === 2 && cards.md) {
    cardsByColumn = placeCardsInColumns(cards.md, numColumns);
  } else if (numColumns === 3 && cards.lg) {
    cardsByColumn = placeCardsInColumns(cards.lg, numColumns);
  } else {
    cardsByColumn = placeCardsInColumns(cards.base, numColumns);
  }

  return (
    <Grid templateColumns={`repeat(${numColumns}, 1fr)`} gap={4}>
      {cardsByColumn.map((column, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <VStack gap={2} key={`column-${i}`}>
            {column.map((card, j) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <GridItem width="100%" key={`column-${i}-row-${j}`}>
                  {card}
                </GridItem>
              );
            })}
          </VStack>
        );
      })}
    </Grid>
  );
}
