import React from 'react';
import { Text } from '../ui';
import { globalHorizontalPadding } from '../constants';

const Copyright = () => (
  <Text color="#7D7C7A" fontFamily="DIN-Regular" fontSize="12px" fontWeight="5">
    2024 MNLY, Inc. All rights reserved.
  </Text>
);

export default Copyright;
