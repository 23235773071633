import React from 'react';
import { Button, Td, Text } from '@chakra-ui/react';
import DataTableRow from './DataTableRow';

interface Props {
  cellCount: number;
  retry?: () => void;
}

export default function DataTableSubRowError({
  cellCount,
  retry,
}: Props): JSX.Element {
  return (
    <DataTableRow>
      <Td colSpan={cellCount} textAlign="center">
        <Text>
          An error occurred while loading data.{' '}
          {retry ? (
            <Button size="sm" my="-4" variant="ghost" onClick={retry}>
              Retry
            </Button>
          ) : null}
        </Text>
      </Td>
    </DataTableRow>
  );
}
