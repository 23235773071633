import React from 'react';
import { createIcon } from '..';

/**
 * The Venmo "V" logo.
 * https://chakra-ui.com/docs/media-and-icons/icon#creating-your-custom-icons
 */
const v = createIcon({
  displayName: 'VIcon',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8403 0.77002C18.5249 1.87837 18.8338 3.01963 18.8338 4.46186C18.8338 9.06061 14.8274 15.035 11.5754 19.23H4.14835L1.17 1.77725L7.67326 1.17211L9.24791 13.5911C10.7198 11.242 12.5352 7.55016 12.5352 5.03329C12.5352 3.65607 12.2945 2.71706 11.9181 1.94499L17.8403 0.77002Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 20 20',
});

export default v;
