import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { instructionStages } from '../Instructions';
import InstructionsStageStep from './InstructionsStageStep';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step0 from './Step0';

type Props = {
  activeStage: string;
  setActiveStage: (stage: string) => void;
};

const InstructionsStepper = ({ activeStage, setActiveStage }: Props) => {
  let instructions: React.ReactElement;

  switch (activeStage) {
    case '0':
      instructions = (
        <Step0 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '1':
      instructions = (
        <Step1 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '2':
      instructions = (
        <Step2 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '3':
      instructions = (
        <Step3 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '4':
      instructions = (
        <Step4 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '5':
      instructions = (
        <Step5 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '6':
      instructions = (
        <Step6 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '7':
      instructions = (
        <Step7 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
      break;
    case '8':
      instructions = <Step8 />;
      break;
    default:
      instructions = (
        <Step1 activeStage={activeStage} setActiveStage={setActiveStage} />
      );
  }

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      gap="3rem"
      padding={{ sm: '1rem', md: 0 }}
    >
      {activeStage !== '8' && (
        <Flex id="stage stepper" justifyContent="center">
          {instructionStages.map(stage =>
            stage === '0' || stage === '8' ? ( // stages 0 and 8 don't have boxes
              <></>
            ) : (
              <InstructionsStageStep
                stage={stage}
                active={activeStage === stage}
                achieved={Number(activeStage) > Number(stage)}
                onClick={() => {}}
                lastStage={stage === (instructionStages.length - 2).toString()}
              />
            )
          )}
        </Flex>
      )}
      <Flex id="stage instructions" justifyContent="center">
        {instructions}
      </Flex>
    </Flex>
  );
};

export default InstructionsStepper;
