import { Box, Button, Flex, Link, useBreakpointValue } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import variablePie from 'highcharts/modules/variable-pie';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { Grid, Input, Text } from '../../../ui';
import ProductLogo from '../../../ui/Shell/components/ProductLogo';
import Copyright from '../../Copyright';

const FinishedQuiz = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState('');

  const chartComponent1 = useRef();

  variablePie(Highcharts);

  const options = {
    plotOptions: {
      variablepie: {
        borderColor: '#7D7C7A',
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
      },
    },

    chart: {
      backgroundColor: '#141414',
      height: '555px',
      margin: '0',
      polar: true,
      type: 'variablepie',
      style: {
        fontFamily: 'DIN',
      },
    },
    credits: { enabled: false },
    legend: { enabled: false },
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: '#141414',
      borderColor: '#E3DBD3',
      style: { fontFamily: 'DIN', fontSize: '20px', width: '420px' },
      outside: true,
      headerFormat: '',
      pointFormat:
        '<div style="color:{point.color}">\u25A0</div> <p style="color:#E3DBD3">&nbsp;{point.name}</p><br/><br/>' +
        '<p style="color:#E3DBD3">Enhance your mental clarity and concentration with Focus protocols. Sharpen your attention for increased productivity.</p><br/><p>&nbsp;</p>',
    },
    series: [
      {
        minPointSize: 10,
        innerSize: '16%',
        zMin: 0,
        name: 'countries',
        dataLabels: {
          verticalAlign: 'top',
          enabled: true,
          color: '#FFFFFF',
          connectorWidth: 1,
          distance: -70,
          style: {
            fontFamily: 'DIN',
            fontSize: '18px',
          },
          //@ts-ignore
          formatter: function (a) {
            //@ts-ignore
            return Math.round(this.percentage) + '%';
          },
        },
        data: [
          {
            name: 'Focus',
            y: 505370,
            z: 92.9,
            color: '#434B30',
          },
          { name: 'Confidence', y: 551500, z: 118.7, color: '#696F59' },
          { name: 'Stamina', y: 312685, z: 124.6, color: '#A1A598' },
          { name: 'Mood', y: 78867, z: 137.5, color: '#C9B9AA' },
          { name: 'Sleep', y: 301340, z: 201.8, color: '#AD967F' },
          { name: 'Libido', y: 41277, z: 214.5, color: '#927355' },
          { name: 'Recovery', y: 357022, z: 235.6, color: '#77502A' },
        ],
      },
    ],
  };

  return (
    <>
      {/* Banner */}
      <Box id="Banner" mt="2rem" display="block" position="relative">
        <Box
          top={useBreakpointValue({
            '2xl': '4.55rem',
            xl: '3.30rem',
            lg: '2.5rem',
          })}
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontSize={useBreakpointValue({
            '2xl': '2.2858em',
            xl: '1.6em',
            lg: '1.4em',
          })}
          fontStyle="normal"
          left={useBreakpointValue({
            '2xl': '250.0px',
            xl: '220.0px',
            lg: '195.0px',
          })}
          lineHeight="130%"
          position="absolute"
          width={useBreakpointValue({
            '2xl': '373px',
            xl: '272px',
            lg: '222px',
          })}
        >
          Precision Supplement Formulation Based On Your Biology
        </Box>
        <Box
          top={useBreakpointValue({
            '2xl': '13.9rem',
            xl: '10.1rem',
            lg: '8.8rem',
          })}
          color="#E3DBD3"
          fontFamily="DIN-Regular"
          fontStyle="normal"
          left={useBreakpointValue({
            '2xl': '250px',
            xl: '220px',
            lg: '194.0px',
          })}
          lineHeight="150%"
          position="absolute"
          width={useBreakpointValue({
            '2xl': '213px',
            xl: '213px',
            lg: '213px',
          })}
        >
          <Button
            _hover={{
              textDecoration: 'none',
              color: '#7D7C7A',
            }}
            bg="#685848"
            borderRadius="0"
            border="2px solid #C6B6A6"
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize={useBreakpointValue({
              '2xl': '.85rem',
              xl: '.55rem',
              lg: '.55rem',
            })}
            fontWeight="medium"
            lineHeight={useBreakpointValue({
              '2xl': 'normal',
              xl: 'normal',
              lg: 'normal',
            })}
            height={useBreakpointValue({
              '2xl': '1rem',
              xl: '2rem',
              lg: '1.8rem',
            })}
            onClick={() => {
              navigate('/product');
            }}
            position="relative"
            px={useBreakpointValue({
              '2xl': '1.25rem',
              xl: '.6rem',
              lg: '.6rem',
            })}
            py={useBreakpointValue({
              '2xl': '1.25rem',
              xl: '.6rem',
              lg: '.6rem',
            })}
            textTransform="uppercase"
          >
            Get Started
          </Button>
        </Box>
        <Flex gap={6} px="50px" mx="10%" height="100%">
          <Box
            maxH={useBreakpointValue({
              '2xl': '680px',
              xl: 'none',
              lg: 'none',
            })}
            display="flex"
          >
            <img
              alt="MNLY Precision Men's Health"
              src="/static/ads/banner.jpg"
              width="100%"
            />
          </Box>
        </Flex>
      </Box>
      {/* Lifestyle Results */}
      <Flex gap={6} px="50px" mx="10%" height="100%" mt="2rem">
        <Text
          color="#E3DBD3"
          display="inline"
          fontFamily="DIN-Medium"
          fontSize="24px"
          fontStyle="normal"
          fontWeight="700"
          lineHeight="130%"
        >
          Your Lifestyle Results
        </Text>
      </Flex>
      <Flex
        justifyContent="space-between"
        border="2px solid #7D7C7A"
        mx="13%"
        mt="2rem"
        pt="2rem"
        pb="4rem"
      >
        {/* Chart */}
        <Flex alignItems="flex-start" maxH="10px">
          <Flex alignItems="center" justifyContent="center">
            <img
              style={{
                height: '32px',
                border: '1px solid #D7CBC0',
                color: '#D7CBC0',
                position: 'absolute',
                zIndex: 1,
              }}
              src="/static/root/m.png"
            />

            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              //@ts-ignore
              ref={chartComponent1}
            />
          </Flex>
        </Flex>
        {/* Reference Values */}
        <Box
          mt={useBreakpointValue({
            '2xl': '2.0rem',
            xl: '0rem',
            lg: '0rem',
          })}
          mx="3rem"
        >
          <Text
            color="#7D7C7A"
            fontFamily="DIN-Regular"
            fontSize=".9rem"
            fontStyle="normal"
            fontWeight="5"
            lineHeight="150%"
            width="23.8rem"
            mb="1.5rem"
          >
            Our technology analyzes crucial lifestyle factors that play a vital
            role in male performance.
          </Text>
          {/* Focus */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              lg: '3.5rem',
              xl: '3.5rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#434B30"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Focus
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Confidence */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#696F59"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Confidence
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Stamina */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#A1A598"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Stamina
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Mood */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#C9B9AA"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Mood
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Sleep */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#AD967F"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Sleep
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Libido */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1.3rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#927355"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Libido
              </Box>
              <Box />
            </Grid>
          </Box>
          {/* Recovery */}
          <Box
            h={useBreakpointValue({
              '2xl': '3.5rem',
              xl: '4rem',
              lg: '4.0rem',
            })}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="20px auto 20px"
              px="1rem"
              py={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '1rem',
                lg: '2rem',
              })}
            >
              <Box
                width="1.5rem"
                bg="#77502A"
                borderColor="#3E362E"
                height="1.5rem"
              />
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="550"
                lineHeight="normal"
              >
                Recovery
              </Box>
              <Box />
            </Grid>
          </Box>
        </Box>
      </Flex>
      {/* Unlock Biological Results */}
      <Box>
        <Flex gap={6} px="50px" mx="10%" height="100%" mt="6rem" mb="2rem">
          <Text
            color="#E3DBD3"
            display="inline"
            fontFamily="DIN-Medium"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="130%"
          >
            Your Biological Results
          </Text>
        </Flex>
        <Flex
          justifyContent="space-between"
          border="2px solid #7D7C7A"
          mx="13%"
        >
          <Box
            flex="1"
            maxH={useBreakpointValue({
              '2xl': '544px',
              xl: 'none',
              lg: 'none',
            })}
            display="flex"
          >
            <Box
              onClick={() => {
                navigate('/product');
              }}
              position="absolute"
              top="125.5rem"
              left="32rem"
              zIndex="555"
              cursor="pointer"
              display="inline-flex"
              color="#D7CBC0"
              fontFamily="DIN-Regular"
              fontWeight="700"
              fontSize={useBreakpointValue({
                '2xl': '1.0rem',
                xl: '0.9rem',
                lg: '0.8em',
              })}
              fontStyle="normal"
              lineHeight="normal"
              mt="40px"
              textTransform="uppercase"
              width={useBreakpointValue({
                '2xl': '513px',
                xl: '333px',
                lg: '273px',
              })}
            >
              <Text>UNLOCK YOUR BIOLOGIAL RESULTS</Text>
              <Text
                style={{
                  backgroundImage:
                    "url('" +
                    'static/illustrations/arrow-right-thin.svg' +
                    "')",
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  height: '24px',
                  width: '24px',
                  position: 'relative',
                  bottom: useBreakpointValue({
                    '2xl': '3px',
                    xl: '3px',
                    lg: '4px',
                  }),
                }}
              />
            </Box>
            <img
              alt="MNLY Precision Men's Health"
              src="/static/ads/mnly-box.png"
              width="100%"
            />
          </Box>
          <Box flex="1">
            {/* Reference Values */}
            <Box
              mt={useBreakpointValue({
                '2xl': '2.0rem',
                xl: '0rem',
                lg: '0rem',
              })}
              mx="3rem"
            >
              <Text
                color="#7D7C7A"
                fontFamily="DIN-Regular"
                fontSize=".9rem"
                fontStyle="normal"
                fontWeight="5"
                lineHeight="150%"
                width="23.8rem"
                mb="1.5rem"
              >
                Our technology analyzes crucial lifestyle factors that play a
                vital role in male performance.
              </Text>
              {/* Cardiometabolic Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '3.5rem',
                  lg: '3.5rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#434B30"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Cardiometabolic Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Cognitive Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#696F59"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Cognitive Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Hormonal Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#A1A598"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Hormonal Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Longevity */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#C9B9AA"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Longevity
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Adrenal Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#AD967F"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Adrenal Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Gastrointestinal Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1.3rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#927355"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Gastrointestinal Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>{' '}
                </Grid>
              </Box>
              {/* Immune Health */}
              <Box
                h={useBreakpointValue({
                  '2xl': '3.5rem',
                  xl: '4rem',
                  lg: '4.0rem',
                })}
                _hover={{
                  bg: '#685848',
                }}
                transition="background-color 200ms linear"
              >
                <hr
                  color="#3E362E"
                  style={{
                    borderColor: '#3E362E',
                  }}
                />
                <Grid
                  templateColumns="20px auto 20px"
                  px="1rem"
                  py={useBreakpointValue({
                    '2xl': '1.0rem',
                    xl: '1rem',
                    lg: '2rem',
                  })}
                >
                  <Box
                    width="1.5rem"
                    bg="#77502A"
                    borderColor="#3E362E"
                    height="1.5rem"
                  />
                  <Box
                    pl="1rem"
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="18px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                  >
                    Immune Health
                  </Box>
                  <Box height="130px">
                    <Text
                      style={{
                        backgroundImage:
                          "url('" + 'static/icons/lock.png' + "')",
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
      {/* HowItWorks */}
      <Box id="HowItWorks" mt="5.4rem">
        <Flex
          gap={6}
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
        >
          <Box flex="1" textAlign="left" pt="20px" pb="40px">
            <Text
              color="#685848"
              fontFamily="DIN-Medium"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="550"
              lineHeight="normal"
              textTransform="uppercase"
            >
              How It Works
            </Text>
          </Box>
        </Flex>
        <Flex
          gap={6}
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
          height="100%"
        >
          <Box
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize={useBreakpointValue({
              '2xl': '2.2858em',
              xl: '1.6em',
              lg: '1.4em',
            })}
            fontStyle="normal"
            lineHeight="130%"
            width={useBreakpointValue({
              '2xl': '443px',
              xl: '272px',
              lg: '222px',
            })}
          >
            Simple Steps to Supercharge Your Life
          </Box>
        </Flex>
        <Flex
          gap={5}
          mt="20px"
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
          height="100%"
        >
          <Flex
            _before={{
              position: 'absolute',
              top: '0',
              left: '-75%',
              zIndex: '2',
              display: 'block',
              content: '""',
              width: '50%',
              height: '100%',
              background:
                'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
              '-webkit-transform': 'skewX(-25deg)',
              transform: 'skewX(-25deg)',
            }}
            _hover={{
              _before: {
                '-webkit-animation': 'shine .75s',
                animation: 'shine .75s',
              },
            }}
            alignItems="center"
            bg="#221F1D"
            height={useBreakpointValue({
              '2xl': '29.4vh',
              xl: '250px',
              lg: '6%',
            })}
            overflow="hideen"
            width="25%"
            overflowX="hidden"
            overflowY="hidden"
            position="relative"
          >
            <Box
              style={{
                margin: 'auto',
              }}
            >
              <img
                alt="MNLY Precision Men's Health"
                src="/static/icons/assessment.png"
              />
            </Box>
          </Flex>
          <Flex
            _before={{
              position: 'absolute',
              top: '0',
              left: '-75%',
              zIndex: '2',
              display: 'block',
              content: '""',
              width: '50%',
              height: '100%',
              background:
                'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
              '-webkit-transform': 'skewX(-25deg)',
              transform: 'skewX(-25deg)',
            }}
            _hover={{
              _before: {
                '-webkit-animation': 'shine .75s',
                animation: 'shine .75s',
              },
            }}
            bg="#44241B"
            width="25%"
            height={useBreakpointValue({
              '2xl': '29.4vh',
              xl: '250px',
              lg: '6%',
            })}
            alignItems="center"
            justifyContent="center"
            overflowX="hidden"
            overflowY="hidden"
            position="relative"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="104"
              height="100"
              viewBox="0 0 104 100"
              fill="none"
            >
              <path
                d="M70.2094 63.8655C70.2094 82.7334 55.0471 98 36.3791 98C17.711 98 2.54877 82.7334 2.54877 63.8655C2.54877 44.9976 17.711 29.7311 36.3791 29.7311C55.0471 29.7311 70.2094 44.9976 70.2094 63.8655Z"
                stroke="#B44B2A"
                stroke-width="4"
              />
              <path
                d="M101.04 36.1345C101.04 55.0024 85.8776 70.2689 67.2096 70.2689C48.5415 70.2689 33.3793 55.0024 33.3793 36.1345C33.3793 17.2665 48.5415 2 67.2096 2C85.8776 2 101.04 17.2665 101.04 36.1345Z"
                stroke="#B44B2A"
                stroke-width="4"
              />
              <rect
                x="38.8788"
                y="55.4623"
                width="9.99915"
                height="10.084"
                fill="#B44B2A"
              />
            </svg>
          </Flex>
          <Flex
            _before={{
              position: 'absolute',
              top: '0',
              left: '-75%',
              zIndex: '2',
              display: 'block',
              content: '""',
              width: '50%',
              height: '100%',
              background:
                'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
              '-webkit-transform': 'skewX(-25deg)',
              transform: 'skewX(-25deg)',
            }}
            _hover={{
              _before: {
                '-webkit-animation': 'shine .75s',
                animation: 'shine .75s',
              },
            }}
            alignItems="center"
            bg="#221F1D"
            height={useBreakpointValue({
              '2xl': '29.4vh',
              xl: '250px',
              lg: '6%',
            })}
            overflow="hideen"
            width="25%"
            overflowX="hidden"
            overflowY="hidden"
            position="relative"
          >
            <Box
              style={{
                margin: 'auto',
              }}
            >
              <img
                alt="MNLY Precision Men's Health"
                src="/static/icons/suplements.png"
              />
            </Box>
          </Flex>
          <Flex
            _before={{
              position: 'absolute',
              top: '0',
              left: '-75%',
              zIndex: '2',
              display: 'block',
              content: '""',
              width: '50%',
              height: '100%',
              background:
                'linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%)',
              '-webkit-transform': 'skewX(-25deg)',
              transform: 'skewX(-25deg)',
            }}
            _hover={{
              _before: {
                '-webkit-animation': 'shine .75s',
                animation: 'shine .75s',
              },
            }}
            bg="#22241C"
            width="25%"
            height={useBreakpointValue({
              '2xl': '29.4vh',
              xl: '250px',
              lg: '6%',
            })}
            alignItems="center"
            justifyContent="center"
            overflowX="hidden"
            overflowY="hidden"
            position="relative"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="88"
              viewBox="0 0 100 88"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M84.8452 26.3514C83.2054 34.6174 75.8976 40.8591 67.1171 40.8591C58.0326 40.8591 50.5245 34.1777 49.2393 25.4857L84.9571 25.4857C84.9234 25.775 84.8861 26.0636 84.8452 26.3514ZM85.1394 21.4857H50.4504H49.0949C49.7862 12.1676 57.5836 4.80945 67.1171 4.80945C76.6506 4.80945 84.448 12.1676 85.1394 21.4857ZM89.1485 21.4857C88.4502 9.94977 78.8534 0.809448 67.1171 0.809448C55.3809 0.809448 45.784 9.94977 45.0857 21.4857L15.7117 21.4857L11.7117 21.4857V21.4858H4H0V25.4858V83.1107V87.1107H4H96H100V83.1107V25.4858V21.4858H96L89.1891 21.4857H89.1485ZM88.7876 27.0381C88.8867 26.5267 88.968 26.009 89.0309 25.4857L96 25.4858V83.1107H4V25.4858H11.9195C12.8349 34.268 16.7441 42.5192 23.058 48.8195C30.3229 56.0689 40.1763 60.1415 50.4504 60.1415C60.7246 60.1415 70.5779 56.0689 77.8428 48.8195C83.7833 42.8918 87.5952 35.2371 88.7876 27.0381ZM78.7881 41.5317C75.4023 43.6405 71.4023 44.8591 67.1171 44.8591C55.8263 44.8591 46.5157 36.3995 45.2034 25.4857L15.9437 25.4858C16.8423 33.202 20.3219 40.4384 25.8834 45.988C32.3975 52.4882 41.2344 56.1415 50.4504 56.1415C59.6665 56.1415 68.5033 52.4882 75.0174 45.988C76.4069 44.6016 77.6663 43.1099 78.7881 41.5317Z"
                fill="#434B30"
              />
            </svg>
          </Flex>
        </Flex>
        <Flex
          gap={5}
          mt="20px"
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
        >
          <Flex width="25%">
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
              textTransform="uppercase"
            >
              1.Take the assesstment
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
              textTransform="uppercase"
            >
              2.Take blood test
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
              textTransform="uppercase"
            >
              3.Get your suplements
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="700"
              lineHeight="normal"
              textTransform="uppercase"
            >
              4.Peak performance
            </Text>
          </Flex>
        </Flex>
        <Flex
          gap={5}
          mt="20px"
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
        >
          <Flex width="25%">
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="5"
              lineHeight="normal"
            >
              Health assessment to analyze your, Focus, Stamina, productivity,
              confidence, libido, mood and sleep.
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="5"
              lineHeight="normal"
            >
              Convenient at home blood analysis of 12 biomarkers for optimal
              mens health.
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="5"
              lineHeight="normal"
            >
              Precision supplement formula engineered to your biology using
              earth grown nutrients.
            </Text>
          </Flex>
          <Flex width="25%">
            <Text
              color="#7D7C7A"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              fontWeight="5"
              lineHeight="normal"
            >
              Personalized lifestyle and nutrition protocol for sustainable
              performance.
            </Text>
          </Flex>
        </Flex>
        <Flex
          gap={5}
          mt="20px"
          px="50px"
          mx={useBreakpointValue({
            '2xl': '10%',
            xl: '6%',
            lg: '6%',
          })}
        >
          <Flex
            width="25%"
            align-items="center"
            onClick={() => {
              navigate('/info');
            }}
            cursor="pointer"
          >
            <Text
              color="#D7CBC0"
              fontFamily="DIN-Regular"
              fontWeight="700"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
              textTransform="uppercase"
            >
              Learn More
            </Text>
            <Text
              style={{
                backgroundImage:
                  "url('" + 'static/illustrations/arrow-right-thin.svg' + "')",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '24px',
                width: '24px',
                position: 'relative',
                top: '1px',
              }}
            />
          </Flex>
          <Flex
            width="25%"
            align-items="center"
            onClick={() => {
              navigate('/info');
            }}
            cursor="pointer"
          >
            <Text
              color="#D7CBC0"
              fontFamily="DIN-Regular"
              fontWeight="700"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
              textTransform="uppercase"
            >
              Learn More
            </Text>{' '}
            <Text
              style={{
                backgroundImage:
                  "url('" + 'static/illustrations/arrow-right-thin.svg' + "')",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '24px',
                width: '24px',
                position: 'relative',
                top: '1px',
              }}
            />
          </Flex>
          <Flex
            width="25%"
            align-items="center"
            onClick={() => {
              navigate('/info');
            }}
            cursor="pointer"
          >
            <Text
              color="#D7CBC0"
              fontFamily="DIN-Regular"
              fontWeight="700"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
              textTransform="uppercase"
            >
              Learn More
            </Text>{' '}
            <Text
              style={{
                backgroundImage:
                  "url('" + 'static/illustrations/arrow-right-thin.svg' + "')",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '24px',
                width: '24px',
                position: 'relative',
                top: '1px',
              }}
            />
          </Flex>
          <Flex
            width="25%"
            align-items="center"
            onClick={() => {
              navigate('/info');
            }}
            cursor="pointer"
          >
            <Text
              color="#D7CBC0"
              fontFamily="DIN-Regular"
              fontWeight="700"
              fontSize="14px"
              fontStyle="normal"
              lineHeight="normal"
              textTransform="uppercase"
            >
              Learn More
            </Text>{' '}
            <Text
              style={{
                backgroundImage:
                  "url('" + 'static/illustrations/arrow-right-thin.svg' + "')",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: '24px',
                position: 'relative',
                top: '1px',
                width: '24px',
              }}
            />
          </Flex>
        </Flex>
      </Box>
      {/* Banner */}
      <Box id="Banner" my="6rem" display="block" position="relative">
        <Box
          top={useBreakpointValue({
            '2xl': '4.55rem',
            xl: '3.30rem',
            lg: '2.5rem',
          })}
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontSize={useBreakpointValue({
            '2xl': '2.2858em',
            xl: '1.6em',
            lg: '1.4em',
          })}
          fontStyle="normal"
          left={useBreakpointValue({
            '2xl': '250.0px',
            xl: '220.0px',
            lg: '195.0px',
          })}
          lineHeight="130%"
          position="absolute"
          width={useBreakpointValue({
            '2xl': '373px',
            xl: '272px',
            lg: '222px',
          })}
        >
          Precision Supplement Formulation Based On Your Biology
        </Box>
        <Box
          top={useBreakpointValue({
            '2xl': '13.9rem',
            xl: '10.1rem',
            lg: '8.8rem',
          })}
          color="#E3DBD3"
          fontFamily="DIN-Regular"
          fontStyle="normal"
          left={useBreakpointValue({
            '2xl': '250px',
            xl: '220px',
            lg: '194.0px',
          })}
          lineHeight="150%"
          position="absolute"
          width={useBreakpointValue({
            '2xl': '213px',
            xl: '213px',
            lg: '213px',
          })}
        >
          <Button
            _hover={{
              textDecoration: 'none',
              color: '#7D7C7A',
            }}
            bg="#685848"
            borderRadius="0"
            border="2px solid #C6B6A6"
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize={useBreakpointValue({
              '2xl': '.85rem',
              xl: '.55rem',
              lg: '.55rem',
            })}
            fontWeight="medium"
            lineHeight={useBreakpointValue({
              '2xl': 'normal',
              xl: 'normal',
              lg: 'normal',
            })}
            height={useBreakpointValue({
              '2xl': '1rem',
              xl: '2rem',
              lg: '1.8rem',
            })}
            onClick={() => {
              navigate('/waitlist');
            }}
            position="relative"
            px={useBreakpointValue({
              '2xl': '1.25rem',
              xl: '.6rem',
              lg: '.6rem',
            })}
            py={useBreakpointValue({
              '2xl': '1.25rem',
              xl: '.6rem',
              lg: '.6rem',
            })}
            textTransform="uppercase"
          >
            get started
          </Button>
        </Box>
        <Flex gap={6} px="50px" mx="10%" height="100%">
          <Box
            maxH={useBreakpointValue({
              '2xl': '680px',
              xl: 'none',
              lg: 'none',
            })}
            display="flex"
          >
            <img
              alt="MNLY Precision Men's Health"
              src="/static/ads/banner.jpg"
              width="100%"
            />
          </Box>
        </Flex>
      </Box>
      {/* Footer */}
      <Box width="100%" mt="2rem">
        <hr
          color="#7D7C7A"
          style={{
            borderColor: '#7D7C7A',
            height: '.1px',
          }}
        />
        <Box px="50px" mx="10%">
          <ProductLogo
            boxSize="104px"
            display="block"
            name="MNLY"
            position="relative"
            top="-0.05rem"
          />
          <Flex height="100%">
            <Box
              color="#E3DBD3"
              flex="1"
              fontFamily="DIN-Medium"
              fontSize={useBreakpointValue({
                '2xl': '2.2858em',
                xl: '1.6em',
                lg: '1.4em',
              })}
              fontStyle="normal"
              lineHeight="130%"
              width={useBreakpointValue({
                '2xl': '443px',
                xl: '272px',
                lg: '222px',
              })}
              ml={useBreakpointValue({
                '2xl': '0rem',
                xl: '0rem',
                lg: '0rem',
              })}
            >
              <Box
                maxH={useBreakpointValue({
                  '2xl': '544px',
                  xl: 'none',
                  lg: 'none',
                })}
                display="flex"
              >
                <Text
                  color="#E3DBD3"
                  fontFamily="DIN-Medium"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="normal"
                  pt="3rem"
                  width="17rem"
                >
                  Subscribe and be the first to know about MNLY.
                </Text>
              </Box>
              <Box w="274px">
                <Grid
                  pt="1rem"
                  templateColumns="258px 16px"
                  borderBottomColor="#7D7C7A"
                  borderBottomWidth="1px"
                >
                  <Text
                    color="#7D7C7A"
                    display="inline"
                    fontFamily="DIN-Medium"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                    position="relative"
                    top="10px"
                  >
                    <Input
                      _active={{
                        border: '0',
                      }}
                      _selected={{
                        border: '0',
                      }}
                      _focus={{
                        border: '0',
                      }}
                      _placeholder={{ color: '#7D7C7A' }}
                      color="#7D7C7A"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="138%"
                      border="0"
                      pl="0"
                      position="relative"
                      top="8px"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => {
                        setEmail(e.currentTarget.value);
                      }}
                    />
                    <input
                      type="text"
                      style={{
                        backgroundColor: 'transparent',
                        border: '0',
                      }}
                    ></input>
                  </Text>

                  <Button
                    top="18px"
                    textAlign="right"
                    width="116px"
                    _hover={{
                      textDecoration: 'none',
                      // color: '#7D7C7A',
                    }}
                    bg="#7D7C7A"
                    borderRadius="0"
                    color="#141414"
                    onClick={() => {
                      navigate('/info');
                    }}
                    fontFamily="DIN-Medium"
                    fontSize={useBreakpointValue({
                      md: '.85rem',
                      sm: '.65rem',
                      base: '.35rem',
                    })}
                    fontWeight="medium"
                    lineHeight="normal"
                    position="relative"
                    height={useBreakpointValue({
                      md: '2rem',
                      sm: '2.2rem',
                      base: '1.2rem',
                    })}
                    px="1.25rem"
                    py="1.25rem"
                    textTransform="uppercase"
                  >
                    SUBSCRIBE
                  </Button>
                </Grid>
                <Text
                  color="#7D7C7A"
                  fontFamily="DIN-Regular"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="5"
                  lineHeight="normal"
                  pt="1rem"
                  w="22.5rem"
                >
                  By subscribing you agree to our Privacy Policy and provide
                  consent to receive updates from our company.
                </Text>
              </Box>
            </Box>
            <Box flex="1" pt="6rem">
              <Flex>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                  >
                    Follow Us
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#E3DBD3"
                    fontFamily="DIN-Medium"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                  >
                    How It Works
                  </Text>
                </Box>
                <Box flex="1" />
              </Flex>
              <Flex pt="1.2rem">
                <Box flex="1">
                  <Link
                    href="https://www.instagram.com/mnlyhealth/"
                    isExternal
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Instagram
                  </Link>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    The Science
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Contact Us
                  </Text>
                </Box>
              </Flex>
              <Flex pt="1.2rem">
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    LinkedIn
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    What we do
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Registration
                  </Text>
                </Box>
              </Flex>
              <Flex pt="1.2rem">
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Facebook
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Company
                  </Text>
                </Box>
                <Box flex="1">
                  <Text
                    onClick={() => {
                      navigate('/product');
                    }}
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="12px"
                    fontStyle="normal"
                    fontWeight="5"
                    lineHeight="normal"
                  >
                    Login
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Box width="100%" mt="5rem">
            <hr
              color="#7D7C7A"
              style={{
                borderColor: '#7D7C7A',
                height: '.1px',
              }}
            />
            <Flex justifyContent="space-between" pt="1rem">
              <Copyright />

              <Flex gap={6}>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  color="#7D7C7A"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() =>
                    window.open('https://www.shopify.com/legal/terms')
                  }
                >
                  Terms of Service
                </Box>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  color="#7D7C7A"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() =>
                    window.open('https://www.shopify.com/legal/privacy')
                  }
                >
                  Privacy Policy
                </Box>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  color="#7D7C7A"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() =>
                    window.open('https://www.shopify.com/legal/cookies')
                  }
                >
                  Cookie
                </Box>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  color="#7D7C7A"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() =>
                    window.open('https://www.shopify.com/legal/aup')
                  }
                >
                  Policy
                </Box>
                <Box
                  _hover={{ cursor: 'pointer' }}
                  color="#7D7C7A"
                  fontFamily="Inter"
                  fontSize="12px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  onClick={() =>
                    window.open('https://www.shopify.com/accessibility/policy')
                  }
                >
                  Accessibility
                </Box>
              </Flex>
            </Flex>
          </Box>
          <br />
          <br />
        </Box>
      </Box>
    </>
  );
};

export default FinishedQuiz;
