import React, { useState, useEffect } from 'react';
import AnswerItem from '../AnswerItem/AnswerItem';
import { Grid } from '@chakra-ui/react';
import NextButton from '../NextButton/NextButton';
import { toast } from 'react-toastify';

const AnswersList = (props: {
  answers: string[];
  onAnswerClick: () => void;
  onSaveClick: () => void;
  result: any;
  answerState?: { [x: string]: any };
}) => {
  
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  console.log('selectedAnswer 1:', selectedAnswer)
  useEffect(() => {
    if (props.result !== null){
    setSelectedAnswer(props.result);
    } else{
      setSelectedAnswer(null);
    }
  }, [props.result]);

  // Handler for when an answer is clicked
  const handleAnswerClick = (answer) => {
    setSelectedAnswer(answer);
    console.log('selectedAnswer 2:', selectedAnswer)

  };

  const handleNextButtonClick = () => {
    if (selectedAnswer === null) {
      toast.error('Please select an answer before moving on.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } else {
      // Call the onSaveClick function if the value is not null
      console.log('selectedAnswer 3:', selectedAnswer);
      props.onSaveClick();
    }
  };
  return(
  <Grid gap="1rem" templateColumns="1fr" gridAutoRows="1fr" mr="1rem">
    {props.answers.map((answer, index) => {
      return (
        <AnswerItem
          key={index}
          answer={{ id: index, text: answer, selected: answer === selectedAnswer }}
          onAnswerClick={() => handleAnswerClick(answer)
            
          }
          
          // answerState={props.answerState ? props.answerState[answer.id] : null}
        />
      );
    })}
    <NextButton
            answer={{id:0,
                text:'Next >',
                value:selectedAnswer}}
            onAnswerClick={props.onAnswerClick}
            onSaveClick={handleNextButtonClick}
          />
  </Grid>
  )
  };

export default AnswersList;
