/* eslint-disable no-underscore-dangle */
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import {
  split,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { Provider as ReduxProvider } from 'react-redux';
import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './store/reducers/rootReducer';
import { CookiesProvider } from 'react-cookie';
import { setContext } from '@apollo/client/link/context';
import { ThemeProvider } from 'styled-components';
import BetaThemeProvider from './ui/ThemeProvider';
import getClientConfig from './helpers/getClientConfig';
import App from '.';
import theme from './theme';
import GlobalStyles from './styles';

async function start() {
  const {
    APOLLO_CLIENT_NAME,
    APOLLO_CLIENT_VERSION,
    GRAPH_ENDPOINT,
    WS_ENDPOINT,
  } = getClientConfig();

  const httpLink = new HttpLink({
    uri: GRAPH_ENDPOINT,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: WS_ENDPOINT,
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const authLink = setContext((_, { headers }) => {
    const token =
      document.querySelector('meta[name="session"]')?.getAttribute('content') ??
      '';

    const actor =
      document.querySelector('meta[name="actor"]')?.getAttribute('content') ??
      '';

    return {
      headers: {
        ...headers,
        'X-Portal-Actor': actor,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const clientName = APOLLO_CLIENT_NAME ?? 'localhost';
  const clientVersion = APOLLO_CLIENT_VERSION ?? '0.0.0';

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          persons: relayStylePagination(),
          teams: relayStylePagination(),
        },
      },
    },
  });

  const apolloClient = new ApolloClient({
    cache,
    link: authLink.concat(splitLink),
    name: clientName,
    version: clientVersion,
  });

  const composeEnhancers =
    //@ts-ignore
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? //@ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  ReactDOM.render(
    <StrictMode>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <CookiesProvider>
            <BetaThemeProvider>
              <ThemeProvider theme={theme}>
                <ApolloProvider client={apolloClient}>
                  <GlobalStyles />
                  <App />
                </ApolloProvider>
              </ThemeProvider>
            </BetaThemeProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ReduxProvider>
    </StrictMode>,
    document.getElementById('root')
  );
}

start();
