import React from 'react';
import { Button, HStack } from '..';

export type FilterActionButtonsProps = {
  isCancelDisabled?: boolean;
  onClickApply?: VoidFunction;
  onClickCancel?: VoidFunction;
};

export const FilterActionButtons = (props: FilterActionButtonsProps) => {
  const { onClickApply, onClickCancel, isCancelDisabled } = props;
  return (
    <HStack spacing="2" justify="space-between">
      <Button
        size="sm"
        variant="ghost"
        onClick={onClickCancel}
        isDisabled={isCancelDisabled}
      >
        Cancel
      </Button>
      <Button size="sm" colorScheme="blue" onClick={onClickApply}>
        Save
      </Button>
    </HStack>
  );
};

export default FilterActionButtons;
