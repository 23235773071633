import React from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Select,
  Spinner,
  Text,
  useServerPagination,
} from '..';
import Icon from '../Icon';

interface PageInfo {
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string | null;
}

interface ParamOptions {
  after?: string | null;
  before?: string | null;
  first?: string | number;
  last?: string | number;
}

export interface ServerPaginationProps {
  /** When loading is `true`, the buttons are disabled and a loading indicator is shown. */
  loading?: boolean;
  /** A callback to perform custom actions when any button is clicked. */
  onClick?: (params: ParamOptions, pageInfo: PageInfo) => void;
  /** A Relay style cursor pagination object for determining state and cursors. */
  pageInfo?: PageInfo | null;
  /** The size of each page of results. Should match the default `first` or `last` variable. */
  pageSize: number;
  /** If the user can change the page size, list the options. Should not go above 100. */
  pageSizeOptions?: number[];
  /** An optional prefix to append to the query string parameters storing pagination state. Should only be used if multiple pagination controls are on the same page. This is uncommon. */
  paramPrefix?: string;
  /** The total number of items in the result set, if known. */
  totalCount?: number | null;
}

/**
 * Communicates the results size and provides the user controls for navigating
 * through the result set using Relay style cursor pagination. Uses query string
 * parameters as the source of truth for state, avoiding the need to pass
 * state around to any components depending on pagination state.
 */
export function ServerPagination({
  loading = false,
  onClick,
  pageInfo,
  pageSize,
  pageSizeOptions: pageSizes = [],
  paramPrefix = '',
  totalCount,
}: ServerPaginationProps): JSX.Element | null {
  const [{ after, before }, updatePageParams] = useServerPagination({
    defaultPageSize: pageSize,
    paramPrefix,
  });

  if (!pageInfo) {
    return null;
  }

  return (
    <Flex justify="space-between" align="center" mt={4}>
      <HStack spacing={1}>
        <Text color="gray.500">Showing</Text>
        {pageSizes.length > 0 ? (
          <Select
            aria-label="Page Size"
            color="gray.500"
            size="xs"
            value={pageSize.toString()}
            width="70px"
            onChange={e => {
              const newSize = e.currentTarget.value;

              const params: ParamOptions = {};

              if (after) {
                params.after = after;
                params.first = newSize;
              } else if (before) {
                params.before = before;
                params.last = newSize;
              } else {
                params.first = newSize;
              }

              updatePageParams(params);
              onClick?.(params, pageInfo);
            }}
          >
            {!pageSizes.includes(pageSize) ? (
              <option value={pageSize}>{pageSize}</option>
            ) : null}
            {pageSizes.map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        ) : (
          <Text color="gray.500">
            {Math.min(pageSize, totalCount ?? Infinity).toLocaleString()}
          </Text>
        )}
        {typeof totalCount === 'number' ? (
          <Text color="gray.500">
            of {totalCount.toLocaleString()} results.
          </Text>
        ) : null}
      </HStack>

      <Flex align="center">
        {loading ? <Spinner size="sm" mr={2} /> : null}
        <ButtonGroup size="sm">
          {pageInfo.hasPreviousPage ? (
            <>
              <IconButton
                aria-label="First"
                disabled={loading}
                icon={<Icon glyph="chevrons-left" />}
                onClick={() => {
                  const params = {
                    first: pageSize,
                  };
                  onClick?.(params, pageInfo);
                  updatePageParams(params);
                }}
                title="Show the first page of results."
                variant="ghost"
              />
              <Button
                disabled={loading}
                leftIcon={<Icon glyph="chevron-left" />}
                onClick={() => {
                  const params = {
                    before: pageInfo.startCursor,
                    last: pageSize,
                  };
                  onClick?.(params, pageInfo);
                  updatePageParams(params);
                }}
                title="Show the previous page of results."
              >
                Previous
              </Button>
            </>
          ) : null}

          {pageInfo.hasNextPage ? (
            <>
              <Button
                disabled={loading}
                onClick={() => {
                  const params = {
                    after: pageInfo.endCursor,
                    first: pageSize,
                  };
                  updatePageParams(params);
                  onClick?.(params, pageInfo);
                }}
                rightIcon={<Icon glyph="chevron-right" />}
                title="Show the next page of results."
              >
                Next
              </Button>
              <IconButton
                aria-label="Last"
                disabled={loading}
                icon={<Icon glyph="chevrons-right" />}
                onClick={() => {
                  const params = {
                    last: pageSize,
                  };
                  updatePageParams(params);
                  onClick?.(params, pageInfo);
                }}
                title="Show the last page of results."
                variant="ghost"
              />
            </>
          ) : null}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}

export default ServerPagination;
