import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useState, useEffect, useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';
import { getBiomarkerScoresChartOptions } from './Scores/ChartOptions';
import { Text } from '../../../ui';

interface CardProps {
  description: string;
  factor: string;
  imgSrc?: string;
  philosofy: string;
  compare?: string;
  unit?: string;
  currentTest?: string;
  prevTest?: string;
  // origTest?: string;
  relatedCategoriesImgSrc?: string[];
  score: number;
  // chartComponent: React.MutableRefObject<HighchartsReact.RefObject | null>;
}

export default function BiomarkerCard({
  factor,
  imgSrc,
  description = '',
  philosofy = '',
  compare,
  unit,
  currentTest,
  prevTest,
  // origTest,
  relatedCategoriesImgSrc,
  score = 50,
}: CardProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const chartOptions = useMemo(
    () => getBiomarkerScoresChartOptions(currentTest, prevTest, unit),
    [currentTest, prevTest, unit]
  );

  return (
    <Box
      border="solid .1rem #7D7C7A"
      color="#E3DBD3"
      flex="25%"
      maxW={{sm:"348px", lg:"440px"}}
      pt="1.0rem"
      w={{sm:"348px", lg:"440px"}}
    >
      <Box marginLeft="1.0rem" display="inline">
        {imgSrc ? (
          <>
            <img
              src={imgSrc}
              style={{
                width: '24px',
                height: '24px',
                display: 'inline',
                position: 'relative',
                top: '.2rem',
              }}
            />
            <Text
              display="inline"
              paddingLeft=".3rem"
              position="relative"
              top="-.1rem"
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize={{sm:"18px", lg:"20px"}}
              fontStyle="normal"
              lineHeight="normal"
              textAlign="center"
            >
              {factor}
            </Text>
          </>
        ) : (
          <Text
            display="inline"
            paddingLeft=".3rem"
            position="relative"
            top=".3rem"
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="18px"
            fontStyle="normal"
            lineHeight="normal"
            textAlign="center"
          >
            {factor}
          </Text>
        )}
      </Box>
      <Flex
        cursor="pointer"
        float="right"
        h="24px"
        onClick={() => setIsExpanded(!isExpanded)}
        position="relative"
        right="0"
        textAlign="right"
      >
        {isExpanded ? (
          <img
            src={`/static/icons/minus.png`}
            style={{
              width: '22px',
              height: '1px',
              display: 'inline',
              position: 'relative',
              top: '0.95rem',
              marginRight: '1.015rem',
            }}
          />
        ) : (
          <img
            src={`/static/icons/plus.png`}
            style={{
              width: '24px',
              height: '24px',
              display: 'inline',
              position: 'relative',
              top: '.2rem',
              marginRight: '1.0rem',
            }}
          />
        )}
      </Flex>
      <Box display="flex" justifyContent="center" mx="1.0rem" mt="1rem">
        <Box bg="rgba(180,75,42,255)" border="solid .0rem #E3DBD3" boxSize={{sm:22, lg:29}}>
          {Math.round((score * 15) / 100) <= 1 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box bg="rgba(185,89,59,255)" border="solid .0rem #E3DBD3" boxSize={{sm:22, lg:29}}>
          {Math.round((score * 15) / 100) === 2 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(189,104,76,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 3 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(194,118,93,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 4 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(199,133,110,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 5 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(203,147,126,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 6 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(208,161,143,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 7 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(213,176,160,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 8 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box
          bg="rgba(163,161,146,255)"
          border="solid .0rem #E3DBD3"
          boxSize={{sm:22, lg:29}}
        >
          {Math.round((score * 15) / 100) === 9 && (
            <img
              src={`/static/icons/plus.png`}
              style={{
                width: '22px',
                height: '22px',
                display: 'inline',

              }}
            />
          )}
        </Box>
        <Box display="inline-flex">
          <Box
            bg="rgba(147,147,129,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {Math.round((score * 15) / 100) === 10 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
          <Box
            bg="rgba(131,133,113,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {' '}
            {Math.round((score * 15) / 100) === 11 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
          <Box
            bg="rgba(115,118,97,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {' '}
            {Math.round((score * 15) / 100) === 12 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
          <Box
            bg="rgba(99,104,81,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {' '}
            {Math.round((score * 15) / 100) === 13 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
          <Box
            bg="rgba(83,89,64,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {' '}
            {Math.round((score * 15) / 100) === 14 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
          <Box
            bg="rgba(67,75,48,255)"
            border="solid .0rem #E3DBD3"
            boxSize={{sm:22, lg:29}}
          >
            {' '}
            {Math.round((score * 15) / 100) === 15 && (
              <img
                src={`/static/icons/plus.png`}
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'inline',

                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Flex textAlign="center" mt=".5rem">
        <Text
          color="#7D7C7A"
          flex="1"
          fontFamily="DIN-Regular"
          fontSize={{sm:"8px", lg:"10px"}}
          fontStyle="normal"
          lineHeight="normal"
          textTransform="uppercase"
        >
          Not Optimal
        </Text>
        <Text
          color="#7D7C7A"
          flex="1"
          mx="3rem"
          fontFamily="DIN-Regular"
          fontSize={{sm:"8px", lg:"10px"}}
          fontStyle="normal"
          lineHeight="normal"
          textTransform="uppercase"
        >
          Average
        </Text>
        <Text
          color="#7D7C7A"
          flex="1"
          fontFamily="DIN-Regular"
          fontSize={{sm:"8px", lg:"10px"}}
          fontStyle="normal"
          lineHeight="normal"
          textTransform="uppercase"
        >
          Optimal
        </Text>
      </Flex>

      <Text
        p={4}
        w="97%"
        color="#E3DBD3"
        fontFamily="DIN-Regular"
        fontSize={{sm:"14px", lg:"16px"}}
        fontStyle="normal"
        lineHeight="132%"
        textAlign="left"
      >
        {isExpanded ? (
          <>
          <Text>{description} </Text>
          <br />
          <Text mb='5'>{philosofy}</Text>
          {prevTest && currentTest && (
            <>
              <Text mb='5'>
                You have a <span style={{ color: '#bf936b' }}>{compare}</span> change between your current and previous test.
              </Text>
              <HighchartsReact 
                highcharts={Highcharts}
                options={chartOptions}
                containerProps={{
                  style: {
                    maxWidth: isMobile ? '325px' : null,
                    margin: 10,
                  },
                }}
              />
            </>
          )}
        </>
        ) : description.length <= 130 ? (
          description
        ) : (
          description.substring(0, 130) + ' ...'
        )}
      </Text>

      {relatedCategoriesImgSrc && (
        <Flex mb="1rem"  justifyContent="center" gap={2}>
          {relatedCategoriesImgSrc.map(imgSrc => (
            <img
              src={imgSrc}
              style={{
                width: '24px',
                height: '24px',
                display: 'inline',
                opacity: '0.5',
              }}
            />
          ))}
        </Flex>
      )}
    </Box>
  );
}
