import React from 'react';
import { Box, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import {
  ApplicationPageLargeText,
  AthleteApplicationPageMobilePadding,
} from './Athletes';
import { athleteHighlights } from '../../constants/copy';
import AthleteHighlight from './AthleteHighlight';

const AthletesSection3 = () => {
  // @ts-ignore
  return (
    <Flex
      direction="column"
      padding={{
        sm: AthleteApplicationPageMobilePadding,
        lg: `4rem 2rem 4rem 2rem`,
      }}
      gap={{ sm: '2rem', md: '2rem' }}
      backgroundColor="#141414"
      textAlign="center"
    >
      <Box width="100%">
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontWeight={400}
          fontSize={{ sm: '18px', md: '25px' }}
        >
          MNLY X HYROX
        </Text>
      </Box>
      <ApplicationPageLargeText as="h1">
        We Value the Bold
        <br />
        and the Brave
      </ApplicationPageLargeText>
      <SimpleGrid columns={{ sm: 2, lg: 5 }} spacingX="0.5rem">
        {athleteHighlights.map((athlete, index) => (
          <AthleteHighlight
            key={index}
            image={athlete.image}
            title={athlete.title}
            text={athlete.text}
          />
        ))}
      </SimpleGrid>
    </Flex>
  );
};

export default AthletesSection3;
