import { Button, chakra } from '@chakra-ui/react';

export const ActionButton = chakra(Button, {
  baseStyle: {
    bg: '#685848',
    borderRadius: '0',
    color: '#E3DBD3',
    fontFamily: 'DIN-Medium',
    fontSize: '14px',
    fontWeight: 550,
    textTransform: 'uppercase',
    _hover: {
      bg: '#A0856A',
      textDecoration: 'none',
    },
  },
});
