import { Flex, Text } from '../../../../ui';
import { bioFactors, enviroFactors } from '../../../../constants';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import LockOrUnlockBox from './LockOrUnlockBox';
import { useMediaQuery } from '@chakra-ui/react';
import { useDispatch} from 'react-redux';
import { setContinue, setStart } from '../../../../store/actions/sessionActions';

type Props = {
  lifestyleSurveyComplete: boolean;
  biologicalComplete: boolean;
};

const gap = '2rem';
const largeGap = '6rem';

const UnlockLifestyleAndBiologicalResults = ({
  lifestyleSurveyComplete,
  biologicalComplete,
}: Props) => {
  const [isCompact] = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return ( 
    <Flex
      direction={isCompact ? 'column' : 'row'}
      justifyContent={{ base: 'center', sm: 'center', md: 'flex-start' }}
      gap={{ base: gap, sm: gap, md: largeGap }}
    >
      {!lifestyleSurveyComplete && (
        <Flex flexDirection="column" gap={gap}>
          <LockOrUnlockBox
            title="Health Assessment"
            complete={lifestyleSurveyComplete}
            completeText=""
            incompleteText="Complete health assessment to unlock results"
            factors={enviroFactors}
          />

        
          <Flex
            bg="#685848"
            width="250px"
            height="40px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mx="auto"
            _hover={{ cursor: 'hand' }}
            onClick={() => {
              dispatch(setContinue());
              navigate('/quiz');
            }}
          >
            <Text
              color="#E3DBD3"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="14px"
              textTransform="uppercase"
              cursor="pointer"
            >
              HEALTH ASSESSMENT
            </Text>
          </Flex>
         
         {/*}
          <Flex
            bg="#685848"
            width="250px"
            height="40px"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mx="auto"
            _hover={{ cursor: 'hand' }}
            onClick={() => {
              dispatch(setStart());
              navigate('/quiz');
            }}
          >
            <Text
              color="#E3DBD3"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="14px"
              textTransform="uppercase"
              cursor="pointer"
            >
              START HEALTH ASSESSMENT
            </Text>
          </Flex> 
          */}
       
        </Flex>
      )}

      <LockOrUnlockBox
        title="Biological Health Results"
        complete={biologicalComplete}
        completeText="Biological results in process"
        incompleteText="Submit blood sample to unlock results"
        factors={bioFactors}
      />
    </Flex>
  );
};

export default UnlockLifestyleAndBiologicalResults;
