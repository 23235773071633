import { Input, InputProps, forwardRef } from '@chakra-ui/react';
import React from 'react';

const MnlyFormInputField = forwardRef<InputProps, 'input'>((props, ref) => (
  <Input
    _placeholder={{ color: '#7D7C7A' }}
    _focus={{
      borderWidth: '1px',
    }}
    focusBorderColor="#E3DBD3"
    borderColor="#7D7C7A"
    color="#7D7C7A"
    variant="outline"
    fontSize="14px"
    fontWeight="550"
    lineHeight="138%"
    fontFamily="DIN-Medium"
    ref={ref}
    {...props}
  />
));

export default MnlyFormInputField;
