import React, {useState} from 'react';
import AnswerItem from '../AnswerItem/AnswerItem';
import { Grid } from '@chakra-ui/react';
import NextButton from '../NextButton/NextButton';
import { toast } from 'react-toastify';

const AnswerMultiple = (props: {
  answers: string[];
  onAnswerClick: () => void;
  onSaveClick:() => void;
  answerState?: { [x: string]: any };
  result: any;
  key?: number;
}) => {
  const emptyStringArray: string[] = [];
  const [multipleValue, setMultipleValue] = useState(props.result ? props.result.split(",") : emptyStringArray);
  //a counter is needed for proper rendering after an item is selected
  const [count, setCount] = useState(0);

  console.log(props.answers);
  function addOrRemoveAnswer(answer: string){
    if (multipleValue.indexOf(answer) > -1){
      let tempArray: string[] = multipleValue;
      tempArray.splice(tempArray.indexOf(answer),1);
      setMultipleValue(tempArray);
    }else{
      let tempArray: string[] = multipleValue;
      tempArray.push(answer)
      setMultipleValue(tempArray);
    }
    //this is what causes the re-render of components
    setCount(count + 1);
    console.log(count);
  }
  console.log('toString:', multipleValue.toString())
  const handleNextButtonClick = () => {
    if (multipleValue.toString() === '' || multipleValue.toString() === 'Next >') {
      toast.error('Please select an answer before moving on.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    } else {
      // Call the onSaveClick function if the value is not null
      props.onSaveClick();
    }
  };

  return (
    
  <Grid gap="1rem" templateColumns="1fr" gridAutoRows="1fr" mr="1rem">
    {props.answers.map((answer, index) => {
      var selected = multipleValue.includes(answer);
      if(multipleValue.indexOf(answer) > -1){
        selected = true;
      }
      return (
        <AnswerItem
          key={index}
          answer={{ id: index, text: answer, selected: selected }}
          onAnswerClick={(answer_string) => addOrRemoveAnswer(answer_string.text)}
          // answerState={props.answerState ? props.answerState[answer.id] : null}
        />
      );
    })}
    {/*<AnswerItem
          key={0}
          answer={{ id: 0, text: "Next >", value: multipleValue.toString() }}
          onAnswerClick={props.onAnswerClick}
          // answerState={props.answerState ? props.answerState[answer.id] : null}
  />*/}
    <NextButton
          key={0}
          answer={{ id: 0, text: "Next >", value: multipleValue.toString() }}
          onAnswerClick={props.onAnswerClick}
          onSaveClick={handleNextButtonClick}
        />

  </Grid>
  );
};

export default AnswerMultiple;
