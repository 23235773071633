/* eslint-disable no-param-reassign */
import React from 'react';
import sortBy from 'lodash/sortBy';
import { Box, Highcharts, HighchartsOptions, useTheme } from '..';
import getWeekendBands from '../../helpers/getWeekendBands';

export interface SeriesPoint {
  time: string;
  value: number | undefined;
}

type LineSeries = Omit<Highcharts.SeriesLineOptions, 'data' | 'type'> & {
  data: SeriesPoint[];
  type: 'line';
};

type AreaSeries = Omit<Highcharts.SeriesAreaOptions, 'data' | 'type'> & {
  data: SeriesPoint[];
  type: 'area';
};

export type Series = LineSeries | AreaSeries;

export interface LineDateGraphProps {
  extrapolate?: 'start' | 'end' | 'both';
  // stacked?: boolean;
  from?: string;
  height?: number;
  max?: number;
  min?: number;
  series: Series[];
  stepped?: boolean;
  to?: string;
}

function parseSeries(data: SeriesPoint[]) {
  const sorted = sortBy(
    data.map(({ time, value, ...other }) => ({
      x: Date.parse(time),
      y: value,
      ...other,
    })),
    'x'
  );

  return sorted;
}

export default function LineDateGraph({
  to,
  extrapolate,
  height = 200,
  max,
  min,
  series,
  from,
  // stacked = false,
  stepped = false,
}: LineDateGraphProps): JSX.Element {
  const theme = useTheme();

  const dates = series
    .map(instance => instance.data.map(point => point.time))
    .flat()
    .sort();

  // If start extrapolation is enabled, create a data point at the start.
  if (
    (extrapolate === 'start' || extrapolate === 'both') &&
    from !== undefined
  ) {
    series.forEach((dataset, i) => {
      const current = dataset.data;
      const first = current[0];

      series[i].data = [
        {
          time: from,
          value: first.value,
        },
        ...current,
      ];
    });
  }

  // If end extrapolation is enabled, create a data point at the end.
  if ((extrapolate === 'end' || extrapolate === 'both') && to !== undefined) {
    series.forEach((dataset, i) => {
      const current = dataset.data;
      const last = current[current.length - 1];

      series[i].data = [
        ...current,
        {
          time: to,
          value: last.value,
        },
      ];
    });
  }

  const startDate = new Date(Date.parse(from ?? dates[0]));
  const endDate = new Date(Date.parse(to ?? dates[dates.length - 1]));

  const options: HighchartsOptions = {
    chart: {
      animation: false,
      backgroundColor: 'transparent',
      height,
      style: { fontFamily: theme.fonts.body },
    },
    credits: { enabled: false },
    legend: { enabled: false },
    plotOptions: {
      area: {
        fillOpacity: 0.3,
        lineWidth: 2,
        marker: {
          enabled: false,
          radius: 0,
          states: { hover: { radius: 6 } },
        },
        step: stepped ? 'left' : undefined,
      },
      line: {
        lineWidth: 2,
        marker: {
          enabled: false,
          radius: 0,
          states: { hover: { radius: 6 } },
        },
        step: stepped ? 'left' : undefined,
      },
    },
    series: series.map(({ data, ...other }) => ({
      data: parseSeries(data),
      ...other,
    })),
    title: {
      text: '',
    },
    tooltip: {
      backgroundColor: '#334150',
      borderRadius: 5,
      borderWidth: 0,
      headerFormat: '<strong style="opacity: 0.7">{point.key}</strong><br/>',
      padding: 10,
      shared: true,
      style: { color: '#fff' },
      xDateFormat: '%b %e',
    },
    xAxis: {
      dateTimeLabelFormats: { day: '%b %e' },
      max: endDate.getTime(),
      min: startDate.getTime(),
      plotBands: startDate ? getWeekendBands(startDate, endDate) : [],
      type: 'datetime',
    },
    yAxis: {
      gridLineColor: 'rgba(0, 0, 0, 0.05)',
      labels: {
        format: '{value:.2f}',
      },
      max,
      min,
      title: { text: null },
    },
  };

  /**
   * The relative/absolute positioned wrappers are necessary for Highcharts
   * to handle responsive size changes correctly. See:
   * https://stackoverflow.com/questions/25934907/
   */

  return (
    <Box position="relative" width="100%" height={`${height}px`}>
      <Box position="absolute" width="100%">
        <Highcharts options={options} />
      </Box>
    </Box>
  );
}
