import React from 'react';
import { Box, BoxProps, Flex } from '@chakra-ui/react';
import Icon, { IconGlyph } from '../Icon';

type Unit = 'literal' | 'percent';
type Sentiment = 'favorable' | 'unfavorable';

interface Props extends BoxProps {
  deltaPrecision?: number;
  deltaUnits?: Unit;
  inline?: boolean;
  positiveSentiment?: Sentiment;
  previousValue?: number;
  value?: number;
  valuePrecision?: number;
  valueUnits?: Unit;
}

function processData(
  data: Omit<Props, 'deltaPrecision' | 'deltaUnits' | 'value'> & {
    deltaPrecision: number;
    deltaUnits: Unit;
    value: number;
  }
) {
  let delta = 0;

  if (data.previousValue) {
    if (data.valueUnits === 'literal' && data.deltaUnits === 'literal') {
      // If both value and delta are literals... show a literal change
      delta = data.value - data.previousValue;
    } else if (data.valueUnits === 'literal' && data.deltaUnits === 'percent') {
      // If value is a literal, and delta is a percent... show a percent change
      delta = data.value / data.previousValue;
    } else if (data.valueUnits === 'percent') {
      // If both value and delta are percents... show a literal change
      delta = data.value - data.previousValue;
    }
  } else if (data.valueUnits === 'literal' && data.deltaUnits === 'literal') {
    // If both value and delta are literals... show a literal change
    delta = data.value;
  }

  const formattedDelta = format(
    Math.abs(delta),
    data.deltaPrecision,
    data.deltaUnits
  );

  const formattedZero = format(0, data.deltaPrecision, data.deltaUnits);

  let glyph: IconGlyph = 'circle-filled';
  let sentiment = 'favorable';

  if (formattedDelta === formattedZero) {
    sentiment = 'neutral';
  } else {
    if (delta > 0 && data.positiveSentiment === 'unfavorable') {
      sentiment = 'unfavorable';
    } else if (delta === 0) {
      sentiment = 'neutral';
    } else if (delta < 0 && data.positiveSentiment === 'favorable') {
      sentiment = 'unfavorable';
    }

    if (delta > 0) {
      glyph = 'arrow-up';
    } else if (delta < 0) {
      glyph = 'arrow-down';
    }
  }

  let color;

  switch (sentiment) {
    case 'favorable':
      color = 'status.ok';
      break;
    case 'unfavorable':
      color = 'status.error';
      break;
    default:
      color = 'gray.400';
  }

  return { color, formattedDelta, glyph };
}

function format(value: number, precision: number, units: Unit) {
  if (units === 'percent') {
    return value.toLocaleString('en-US', {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
      style: 'percent',
    });
  }

  return value.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });
}

export default function ChangeIndicator({
  deltaPrecision = 1,
  deltaUnits = 'percent',
  inline = false,
  positiveSentiment = 'favorable',
  previousValue,
  value,
  valuePrecision = 1,
  valueUnits = 'literal',
  ...props
}: Props): JSX.Element {
  if (value === undefined) {
    return (
      <Flex alignItems="center" display={inline ? 'inline-flex' : 'flex'}>
        <Box flex="1" textAlign="right">
          -
        </Box>
        <Box
          color="gray.400"
          flex=" 0 0 auto"
          fontSize="16px"
          padding="2.5px 10px"
          textAlign="center"
        >
          <Icon glyph="circle-filled" />
        </Box>
        <Box flex="1" textAlign="left">
          0
        </Box>
      </Flex>
    );
  }

  const { color, formattedDelta, glyph } = processData({
    deltaPrecision,
    deltaUnits,
    inline,
    positiveSentiment,
    previousValue,
    value,
    valuePrecision,
    valueUnits,
  });

  return (
    <Flex alignItems="center" display={inline ? 'inline-flex' : 'flex'}>
      <Box {...props}>{format(value, valuePrecision, valueUnits)}</Box>
      <Box
        color={color}
        flex=" 0 0 auto"
        fontSize="16px"
        padding="0px 10px"
        textAlign="center"
      >
        <Icon glyph={glyph} verticalAlign="upper" />
      </Box>
      <Box color={color} flex="1" textAlign="left">
        {formattedDelta}
      </Box>
    </Flex>
  );
}
