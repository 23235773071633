.NextButton {
    margin-bottom: 20px;
    /* padding: 0px 10px; */


    background: #7D7C7A;
    
    line-height: 56px;
    cursor: pointer;
    color: #141414;
    text-align: center;
    font-family: DIN-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 550;



}

.NextButton:hover {
    background: #434B30;
    color: #E3DBD3;
    transition: background .3s ease-in-out;
    transition: color .4s ease-in-out;
}