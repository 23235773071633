import React from 'react';
import { createPortal } from 'react-dom';
import generateId from './helpers/generateId';

export interface Props {
  children: React.ReactNode;
  id?: string;
  onCreated?: () => void;
}

export default function Portal({
  children,
  id,
  onCreated: onPortalCreated,
}: Props): React.ReactPortal | null {
  const [isMounted, setIsMounted] = React.useState(false);
  const portalNode = React.useRef<HTMLDivElement>();
  const portalId = generateId(id);

  React.useEffect(() => {
    const node = document.createElement('div');

    node.setAttribute('id', portalId);

    portalNode.current = node;

    document.body.appendChild(node);
    setIsMounted(true);

    return function cleanup() {
      if (portalNode.current) {
        document.body.removeChild(portalNode.current);
      }
    };
  }, []);

  /**
   * We only want to call onPortalCreated once, following the first render
   * after the portal is created.
   */

  React.useEffect(() => {
    if (onPortalCreated && isMounted) {
      onPortalCreated();
    }
  }, [isMounted]);

  /**
   * Don't render anything if the node has not been created or hasn't been
   * mounted yet.
   */

  if (!portalNode || !isMounted) {
    return null;
  }

  return portalNode.current && isMounted
    ? createPortal(children, portalNode.current)
    : null;
}
