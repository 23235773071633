import { Flex, Text } from '../../../ui';
import React from 'react';

type Props = {
  iconPath: string;
  text: string;
  mobile?: boolean;
};

const MethodologySection = ({ iconPath, text, mobile = false }: Props) => {
  const textAsArray = text.split(' ');

  return (
    <Flex
      flexDirection={mobile ? 'column' : 'row'}
      alignItems="center"
      gap="2rem"
    >
      <img alt="MNLY Precision Men's Health" src={iconPath} />
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Regular"
        fontSize="1rem"
        fontStyle="normal"
        fontWeight="5"
        lineHeight="150%"
        textAlign={mobile ? 'center' : undefined}
      >
        {/*Bold first two words*/}
        <span style={{ fontWeight: 'bold' }}>
          {textAsArray.slice(0, 2).join(' ')}
        </span>
        <span> {textAsArray.slice(2).join(' ')}</span>
      </Text>
    </Flex>
  );
};

export default MethodologySection;
