import { Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function ContainedText({ children }: Props): JSX.Element {
  return (
    <Text
      as="span"
      color="gray.500"
      borderRadius="md"
      borderWidth="1px"
      borderColor="gray.200"
      backgroundColor="white"
      p="2"
      px="4"
    >
      {children}
    </Text>
  );
}
