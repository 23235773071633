import { chakra, ListItem, Text } from '@chakra-ui/react';

export const InstructionTitle = chakra(Text, {
  baseStyle: {
    color: '#E3DBD3',
    textAlign: 'center',
    fontFamily: 'DIN-Medium',
    fontSize: '24px',
    fontWeight: 700,
  },
});

export const InstructionSubtext = chakra(Text, {
  baseStyle: {
    color: '#E3DBD3',
    textAlign: 'center',
    fontFamily: 'DIN-Regular',
    fontSize: '18px',
  },
});

export const InstructionTinySubtext = chakra(Text, {
  baseStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontFamily: 'DIN-Regular',
    fontSize: '12px',
  },
});

export const InstructionListItem = chakra(ListItem, {
  baseStyle: {
    color: '#E3DBD3',
    fontFamily: 'DIN-Regular',
    fontSize: '18px',
  },
});

export const StepLabel = chakra(Text, {
  baseStyle: {
    color: '#685848',
    textAlign: 'center',
    fontFamily: 'DIN-Medium',
    fontSize: '14px',
    fontWeight: 550,
    textTransform: 'uppercase',
  },
});

