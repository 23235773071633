import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Tabs, { TabItem } from '../Tabs';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Grid } from '..';

interface Crumb {
  id?: string;
  to?: string;
  value: string | JSX.Element;
}

interface Props {
  breadcrumbs: Crumb[];
  children?: React.ReactNode;
  initialTab?: string;
  onTabChange?: (newId: string) => void;
  tabs?: TabItem[];
}

export default function Header({
  breadcrumbs,
  children,
  initialTab,
  onTabChange,
  tabs = [],
}: Props): JSX.Element {
  const defaultTabSelection = initialTab ?? tabs[0]?.id;
  const [searchParams, updateSearchParams] = useSearchParams();

  const tab = searchParams.get('tab') ?? defaultTabSelection;

  return (
    <Grid gap={4} alignItems="center" templateColumns="1fr auto">
      <Breadcrumb color="gray.500" fontWeight="semibold" fontSize="2xl">
        {breadcrumbs.map((crumb, index) => {
          const key = crumb.id ?? crumb.to ?? crumb.value.toString();

          if (crumb.to && typeof crumb.value === 'string') {
            const isLastCrumb = index === breadcrumbs.length - 1;
            return (
              <BreadcrumbItem
                color={isLastCrumb ? 'gray.800' : undefined}
                key={key}
              >
                <BreadcrumbLink as={Link} to={crumb.to}>
                  {crumb.value}
                </BreadcrumbLink>
              </BreadcrumbItem>
            );
          }

          return (
            <BreadcrumbItem color="gray.800" height={2} isCurrentPage key={key}>
              <BreadcrumbLink>{crumb.value}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
      <Box>{children}</Box>
      <Tabs
        activeTab={tab}
        tabs={tabs}
        onChange={newTab => {
          updateSearchParams({ tab: newTab });
          onTabChange?.(newTab);
        }}
      />
    </Grid>
  );
}
