import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

export default function Disclaimer(props: BoxProps): JSX.Element {
  return (
    <Box
      color="gray.500"
      fontSize="14px"
      margin="0 auto"
      textAlign="center"
      {...props}
    />
  );
}
