import React from 'react';
import { Flex } from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionSubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const Step3 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-3"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <StepLabel> Step 3</StepLabel>
      <InstructionTitle> Time to get your blood flowing.</InstructionTitle>
      <InstructionSubtext>
        We recommend doing at least 20 push ups followed by a couple minutes <br />of continuous jumping jacks, however do whatever exercise{' '} you are comfortable<br/>with. If you have a smart watch or a heart
        monitor, we recommend getting<br /> your heart rate above 120 beats per
        minute.
      </InstructionSubtext>

      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step3;