import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import defaultTheme from './theme';

type Props = React.ComponentProps<typeof ChakraProvider>;

export default function ThemeProvider(props: Props): JSX.Element {
  const { theme, ...otherProps } = props;

  const themeProps = {
    theme: theme ?? defaultTheme,
    ...otherProps,
  };

  return <ChakraProvider {...themeProps} />;
}
