import React from 'react';
import Card from '../Card';
import ChangeIndicator from '../ChangeIndicator';

type CardProps = React.ComponentPropsWithoutRef<typeof Card>;

type Props = CardProps & {
  indicator: React.ComponentPropsWithoutRef<typeof ChangeIndicator>;
};

export default function DeltaCard({
  children,
  indicator,
  ...otherProps
}: Props): JSX.Element {
  return (
    <Card {...otherProps}>
      <ChangeIndicator inline {...indicator} />
      <div>{children}</div>
    </Card>
  );
}
