import React, { useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import { useMediaQuery } from '@chakra-ui/react';
import { Box, Flex, Text } from '../../../../ui';
import HighchartsReact from 'highcharts-react-official';
import ScoreCategoryAndPercentage from './ScoreCategoryAndPercentage';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { getEnvironmentalHealthChartOptions } from './ChartOptions';
import EnviroHealthCard from '../EnviroHealthCard';
import {
  enviroFactors,
  defaultLargeVerticalGap,
  smallGap,
} from '../../../../constants';

type Props = {
  chartComponent: React.MutableRefObject<HighchartsReact.RefObject | null>;
};
const EnvironmentalHealthResults = ({ chartComponent }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const { stage1 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });
  const [results, setResults] = useState(null);
  const [prevResults, setPrevResults] = useState(null);
  const [origResults, setOrigResults] = useState(null);


  const mock = process.env.MOCK_SOURCES === 'true' || false;
  const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';

  const { user } = useSelector(state => {
    //@ts-ignore
    return state.session;
  })
  useEffect(() => {
    // Define the async function inside useEffect
    const fetchLifestyleResults = async () => {
      try {
        const { data } = await axios.get(`${baseURL}plan${mock ? '.json' : ''}`, {
          headers: { Authorization: user?.token },
        });
        // Set state with results from data
        setResults(data[0]?.results ?? null);
        setPrevResults(data[1]?.results ?? null);
        setOrigResults(null);
        if (data.length > 2) {
          setOrigResults(data[data.length - 1]?.results ?? null);
        }
      } catch (error) {
        console.error('Error fetching lifestyle results:', error);
      }
    };

    // Call the async function
    fetchLifestyleResults();
  }, []);

  const chartOptions = useMemo(
    () => getEnvironmentalHealthChartOptions(results, prevResults, origResults),
    [results, prevResults, origResults]
  );
  /**
   * Fix bug where sometimes the chart width is messed up. On first render, wait
   * 10 milliseconds then reflow the chart.
   */
  useEffect(() => {
    setTimeout(() => {
      chartComponent.current?.chart.reflow();
    }, 10);
  }, [chartOptions]);


  return (
    <Flex flexDirection="column" gap={defaultLargeVerticalGap}>
      <Flex
        id={'environmental-health-results'}
        flexDirection="column"
        gap={smallGap}
      >
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontSize="24px"
          textTransform="capitalize"
        >
          Environmental Health Results
        </Text>

        <Flex
          gap={3}
          overflow="hidden"
          flexWrap="wrap"
          justifyContent={{
            base: 'flex-start',
            sm: 'center',
            lg: 'flex-start',
          }}
        >
          <EnviroHealthCard
            factor="Focus"
            imgSrc="/static/icons/focus.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.focus
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.focus.score
            }
          />
          <EnviroHealthCard
            factor="Confidence"
            imgSrc="/static/icons/confidence.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.confidence
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.confidence
                .score
            }
          />
          <EnviroHealthCard
            factor="Stamina"
            imgSrc="/static/icons/stamina.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.stamina
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.stamina.score
            }
          />
          <EnviroHealthCard
            factor="Libido"
            imgSrc="/static/icons/libido.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.libido
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.libido.score
            }
          />
          <EnviroHealthCard
            factor="Sleep"
            imgSrc="/static/icons/sleep.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.sleep
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.sleep.score
            }
          />
          <EnviroHealthCard
            factor="Mood"
            imgSrc="/static/icons/mood.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.mood
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.mood.score
            }
          />
          <EnviroHealthCard
            factor="Recovery"
            imgSrc="/static/icons/recovery.png"
            description={
              stage1.lifestyle_output?.lifestyle_categories_output.recovery
                .recommendation
            }
            score={
              stage1.lifestyle_output?.lifestyle_categories_output.recovery
                .score
            }
          />
        </Flex>
      </Flex>

      

      {/* Environmental Health Scores */}
      <Flex flexDirection="column" gap={smallGap}>
        <Text
          color="#E3DBD3"
          display="inline"
          fontFamily="DIN-Medium"
          fontSize="24px"
          lineHeight="130%"
        >
          Environmental Health Scores
        </Text>

        <Flex
          id="enviro-health-scores-box"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="1rem"
          border={{
            base: '2px solid #7D7C7A',
            sm: 'none',
            md: '2px solid #7D7C7A',
          }}
          gap={smallGap}
        >
          <Flex
            id="enviro-viz-box"
            justifyContent="center"
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems="center"
            width="100%"
          >
            {/*Chart*/}
            <Flex id="enviro-pie" alignItems="flex-start">
              <Flex alignItems="center" justifyContent="center">
                <img
                  style={{
                    height: '32px',
                    border: '1px solid #D7CBC0',
                    color: '#D7CBC0',
                    position: 'absolute',
                    zIndex: 1,
                  }}
                  src="/static/root/m.png"
                />

                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                  containerProps={{
                    style: {
                      maxWidth: isMobile ? '325px' : null,
                    },
                  }}
                  ref={chartComponent}
                />
              </Flex>
            </Flex>

            <Box
              id="enviro-percentage-viz"
              width={{ base: '33%', sm: '100%', md: '33%' }}
              maxWidth="325px"
            >
              {Object.keys(enviroFactors).map(category => {

                const item = enviroFactors[category];
                return (
                  <ScoreCategoryAndPercentage
                    key={item.title}
                    category={item.title}
                    description={stage1.lifestyle_output?.lifestyle_categories_output[
                      category
                      ].recommendation}
                    score={
                      stage1.lifestyle_output?.lifestyle_categories_output[
                        category
                      ].score
                    }
                    color={item.color}
                  />
                );
              })}
            </Box>
          </Flex>
          <Text
            color="#7D7C7A"
            fontFamily="DIN-Regular"
            fontSize="14px"
            lineHeight="130%"
            textAlign="left"
            maxWidth={{ base: '75%', sm: '100%', md: '75%' }}
          >
            The lifestyle category scores are calculated by assigning categories
            and scores to individual question-answer pairs in the health
            assessment. The scores are combined for all questions within a
            category using a weighted average, which accounts for the relative
            importance of each question within that category. The category
            scores range from 0 (not optimal) to 100 (optimal).
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EnvironmentalHealthResults;
