/* eslint-disable no-underscore-dangle */

import React from 'react';
import { Tr, TableRowProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

interface Props extends TableRowProps {
  to?: string;
}

/**
 * Adds support for clicking on a row to navigate. Supports
 * keyboard interactions, a tab stop to receive focus, and
 * pressing space or enter will activate the navigation.
 */
export default function LinkRow({ to, ...trProps }: Props): JSX.Element | null {
  const navigate = useNavigate();
  const linkProps: Partial<TableRowProps> = {};

  if (to) {
    // Add visual feedback on mouse hover.
    linkProps._hover = {
      background: 'blue.50',
      cursor: 'pointer',
    };

    // Trigger the navigation on click.
    linkProps.onClick = () => {
      navigate(to);
    };

    // Allow the row to receive focus.
    linkProps.tabIndex = 0;

    // Trigger the navigation on space or enter when in focus.
    linkProps.onKeyDown = e => {
      if (e.key === ' ' || e.key === 'Enter') {
        navigate(to);
      }
    };
  }

  return <Tr {...trProps} {...linkProps} />;
}
