export const environmentalFactorsCopy = new Map([
  [
    'Focus',
    'Your focus is around average. This has both positive and negative impacts on your life. On one hand, it allows you to stay focused on tasks for a reasonable amount of time and to get things done. On the other hand, distractions may still be a challenge and you may struggle to concentrate on a task for an extended period of time. Maintaining average focus can still lead to reduced productivity and make it difficult to achieve your goals in some cases.',
  ],
  [
    'Confidence',
    'Based on your results from the confidence assessment, you demonstrate a balanced perspective on your recovery health. Your confidence levels are in line with the average, suggesting that while you have a foundational understanding and trust in your abilities, there may be areas where you seek further clarity or assurance. This moderate confidence can serve as a solid starting point, allowing us to identify specific areas for growth and enhancement.',
  ],
  [
    'Stamina',
    'Based on the stamina health assessment, your performance indicates a moderate level of endurance and sustained energy capability. Scoring in the average range suggests that you have a foundational level of stamina that can support many of your daily activities. However, there may still be opportunities for growth and further development in this area. Stamina, influenced by factors such as physical conditioning, diet, and mental resilience, can be nurtured and enhanced with the right strategies',
  ],
  [
    'Mood',
    'Your mood assessment states an average rating. Try to doing things to elevate your mood, like sleeping well, exercising, and protecting your gut health, and having deep conversations with close friends. As well as stress reduction techniques like mindfulness.',
  ],
  [
    'Sleep',
    'You have an average sleep score. For example, while you may be able to perform decently, be somewhat productive, and regulate your emotions, your lack optimal quality and quantity of sleep is defintiely affecting all these things. Start to implement good sleep hygeine habits, and a nightly routine. These may include, having a cool, very dark bedroom, without distractions. Prepare yourself beforehand, with a power down routine. Additionally, morning sunlight 10 minutes after wakening can reset circadian rhythms optimizing sleep patterns.',
  ],
  [
    'Libido',
    "From the outcomes of your libido health assessment, your score aligns with an average range, suggesting a balanced level of sexual desire that falls within typical expectations. It's worth noting that libido is a nuanced aspect of overall well-being, with natural ebbs and flows influenced by various factors like emotional health, physical condition, stress, and life circumstances. An average score means you experience typical variations and are in alignment with many others.",
  ],
  [
    'Recovery',
    "Based on the results of your recovery health assessment, your practices and experiences fall within the average range. This suggests that you have a foundational understanding of and engagement with recuperative strategies, whether they pertain to physical rest, mental downtime, or emotional rejuvenation. While you may have effective recovery routines in place, there's always room to refine and optimize these strategies to align more closely with your individual needs.",
  ],
]);

export const biologicalFactorsCopy = new Map([
  [
    'Cardiometabolic Health',
    'Your cardiometabolic health score is 53.5 out of 100. Your cardiometabolic health score is calculated using the scores from the following biomarkers: Vitamin D, Vitamin B12, Ferritin, ALT, AST, Free Testosterone, Total Testosterone, SHBG, Prolactin, TSH, T4, T3, Total Cholesterol, HDL, LDL, Triglycerides, Creatinine.',
  ],
  [
    'Immune Health',
    'Your immune health score is 100.0 out of 100. Your immune health score is calculated using the scores from the following biomarkers: Vitamin D.',
  ],
  [
    'Cognitive Health',
    'Your cognitive health score is 59.1 out of 100. Your cognitive health score is calculated using the scores from the following biomarkers: Vitamin D, Vitamin B12, Free Testosterone, Total Testosterone, SHBG, Prolactin, TSH, T4, T3, Total Cholesterol, HDL, LDL, Triglycerides.',
  ],
  [
    'Hormonal Health',
    'Your hormonal health score is 55.7 out of 100. Your hormonal health score is calculated using the scores from the following biomarkers: Vitamin D, Free Testosterone, Total Testosterone, SHBG, Prolactin, TSH, T4, T3, Total Cholesterol, HDL, LDL, Triglycerides.',
  ],
  [
    'Longevity',
    'Your longevity health score is 54.1 out of 100. Your longevity health score is calculated using the scores from the following biomarkers: Vitamin D, Ferritin, ALT, AST, eGFR, Free Testosterone, Total Testosterone, SHBG, Albumin, Prolactin, TSH, T4, T3, Total Cholesterol, HDL, LDL, Triglycerides.',
  ],
  [
    'Adrenal Health',
    'Your adrenal health score is 68.5 out of 100. Your adrenal health score is calculated using the scores from the following biomarkers: Vitamin D, Ferritin, eGFR, Albumin.',
  ],
  [
    'Gastrointestinal Health',
    'Your gastrointestinal health score is 5.0 out of 100. Your gastrointestinal health score is calculated using the scores from the following biomarkers: Ferritin.',
  ],
]);

export const microNutrientsRow1 = [
  ['Vitamin D3', '/static/ads/supplements/Vitamin D3.png'],
  ['Vitamin B12', '/static/ads/supplements/Vitamin B12.png'],
  ['Vitamin C', '/static/ads/supplements/Vitamin C.png'],
  ['NAC', '/static/ads/supplements/NAC.png'],
  ['Fadogia Agrestis', '/static/ads/supplements/Fadogia Agrestis.png'],
  ['Glutathione', '/static/ads/supplements/Glutathione.png'],
];

export const microNutrientsRow2 = [
  ['Cinnulin PF', '/static/ads/supplements/Cinnulin PF.png'],
  ['Berberine', '/static/ads/supplements/Berberine.png'],
  ['Bergamot Extract', '/static/ads/supplements/Bergamot Extract.png'],
  ['Tongat Ali', '/static/ads/supplements/Tongkat Ali.png'],
  ['Taurine', '/static/ads/supplements/Taurine.png'],
  [
    'Cordyceps Mushroom Extract',
    '/static/ads/supplements/Cordyceps Mushroom Extract.png',
  ],
];

export const microNutrientsRow3 = [
  ['L-Phenylalanine', '/static/ads/supplements/L-Phenylalanine.png'],
  ['L-Tyrosine', '/static/ads/supplements/L-Tyrosine.png'],
  ['Forskolin Extract', '/static/ads/supplements/Forskolin Extract.png'],
  ['Boron Citrate', '/static/ads/supplements/Boron Citrate.png'],
  ['Bladderwrack Extract', '/static/ads/supplements/Bladderwrack Extract.png'],
  ['Artichoke Extract', '/static/ads/supplements/Artichoke Extract.png'],
  ['Coenzyme Q10', '/static/ads/supplements/Coenzyme Q10.png'],
];

export const ourMethodologySteps = new Map([
  [
    'lifestyle-analysis',
    [
      'Lifestyle Analysis',
      "offers essential insights into an individual's distinct physiology and predispositions, enabling a comprehensive understanding of various biomarkers, such as hormonal levels, micronutrient status, metabolic markers, inflammatory indicators, and genetic variations.",
      '',
    ],
  ],
  [
    'clinical-analysis',
    [
      'Clinical Analysis',
      'studies is a foundational pillar in formulating precision health solutions and allows for the evaluation of different interventions to determine their effectiveness and suitability as the solution.',
      '',
    ],
  ],
  [
    'biological-analysis',
    [
      'Biological Analysis',
      "offers essential insights into an individual's distinct physiology and predispositions, enabling a comprehensive understanding of various biomarkers, such as hormonal levels, micronutrient status, metabolic markers, inflammatory indicators, and genetic variations.",
      '',
    ],
  ],
  [
    'nutritional-analysis',
    [
      'Nutritional Analysis',
      "involves an overview of the individual's diet, considering their unique genetic, metabolic, and lifestyle factors. It aims to identify scientific nutritional needs and address deficiencies or intolerances.",
      '',
    ],
  ],
  [
    'precision-solution',
    [
      'Precision Personal Performance',
      'is an approach underpinned by a comprehensive four-step process, custom-tailored to provide you with deeply personalized, evidence-based biological & environmental protocols in combination with unique supplement formulations all fortified by clinical research.',
      '',
    ],
  ],
]);

export const labResultsFriendlyStatus = new Map([
  ['Rejected By Lab', 'The order has been rejected by the lab.'],
  [
    'Order Created by MNLY',
    'Your blood test kit has been ordered. Shipping details will be updated as soon as they are available.',
  ],
  [
    'Shipped To Patient',
    'Your blood test kit has been shipped with {0} (Tracking Number: {1}) and is on its way to you.',
  ],
  [
    'Delivered To Patient',
    'The blood test kit has been successfully delivered to you. Submit your blood samples to continue.',
  ],
  [
    'Shipped To Lab',
    'The  blood test kit has been dispatched with {0} (Tracking Number: {1}) and is now on its way to the lab for processing.',
  ],
  [
    'Delivered To Lab',
    'Your blood test kit has been delivered by the carrier and will be sent to processing soon.',
  ],
  [
    'Received By Lab',
    'Your blood test kit has been received by the lab and is now in the processing stage.',
  ],
  [
    'Partially Resulted By Lab',
    'Partial results from your blood test are now available. Our lab has begun processing, and further details will follow once all tests are finalized.',
  ],
  [
    'Fully Resulted By Lab',
    'Your complete blood test results are now available. Health report and plan in preparation.',
  ],
]);

export const ourScienceTeamCopy = [
  {
    id: '8c31cd23-58ea-45e2-aba6-272857dcf2ba',
    name: 'Aaron Burciaga',
    role: 'Chief Technology Officer',
    src: 'static/ads/testimonial.jpg',
    description:
      'Aaron Burciaga is a renowned Data Scientist, AI Engineer, author and Advisor, Founder and president of Prime.ai. As a seasoned technology and business leader in both startup and enterprise settings, he has focused his career on delivering efficiency and value through automation, data science, machine learning, Artificial Intelligence, blockchain, quantum computing, and emerging concepts and innovative technologies.',
    index: 0,
  },
  {
    id: 'ca9863d3-e2be-48dc-8729-255a82749ad7',
    name: 'Lucas Aoun',
    role: 'Science Board Advisor',
    src: 'static/ads/testimonial2.jpg',
    description:
      'Lucas is the founder Of Ergogenic Health. He Integrates modern scientific evidence, anecdotal/personal reports and traditional wisdom to develop client solutions, education and consulting for optimal human performance. Lucas Holds a Dux of Bachelor of Health Science.',
    index: 1,
  },
  {
    id: '3c0ed261-c949-4241-9c63-5dc2593dc4bf',
    name: 'Michael Mannino',
    role: 'Science Board Advisor',
    src: 'static/ads/testimonial3.jpg',
    description:
      'Neuroscientist and peak performance expert. Currently Director, Artificial Intelligence center at Miami Dade College & Chief Science Officer of the Flow Research Collective, founded by Steven Kotler.  He holds a PhD in neuroscience and complex systems, having researched networks of the human brain involved in attention, and perception, with an emphasis on the framework of embodied cognition.',
    index: 2,
  },
];

export const athleteHighlights = [
  {
    image: '/static/ads/athletes/athlete_1.png',
    title: 'Masters of Their Game',
    text:
      'We seek individuals who embody the HYROX ethos of all-around athletic prowess. These athletes demonstrate ' +
      'an infectious zeal and commitment ' +
      'to their sport, mirroring the multidisciplinary challenge that ' +
      'HYROX represents.',
  },
  {
    image: '/static/ads/athletes/athlete_2.png',
    title: 'Unbreakable Spirit',
    text:
      'Our ambassadors are the ones who rise stronger with every fall, turning challenges into stepping stones ' +
      'with unwavering grit.',
  },
  {
    image: '/static/ads/athletes/athlete_3.png',
    title: 'Seekers of Potential',
    text: 'Never settling, always hungry for more – we admire athletes who relentlessly chase their peak, both in and out of the arena.',
  },
  {
    image: '/static/ads/athletes/athlete_4.png',
    title: 'Leaders in their community',
    text:
      'More than personal glory, we value men who inspire and elevate those around them, leading by example and igniting ' +
      'a fire in their community.',
  },
  {
    image: '/static/ads/athletes/athlete_5.png',
    title: 'Masters of Their Game',
    text: 'In alliance with HYROX, our ambassadors are the vanguards of optimal health and performance, embodying excellence in every aspect of their lives.',
  },
];

export const offerings = [
  {
    image: '/static/ads/offerings/blood_analysis_icon.png',
    text: 'Blood Analysis',
  },
  {
    image: '/static/ads/offerings/supplements_icon.png',
    text: 'Precision Supplement Packs',
  },
  {
    image: '/static/ads/offerings/health_plan_icon.png',
    text: 'Personalized Health Plan',
  },
  {
    image: '/static/ads/offerings/docuseries_icon.png',
    text: 'Athlete Docuseries',
  },
  {
    image: '/static/ads/offerings/community_icon.png',
    text: 'Community',
  },
  {
    image: '/static/ads/offerings/race_icon.png',
    text: '1 Year HYROX Race Entry',
  },
];
