import React from 'react';
import { Alert as ChakraAlert, AlertIcon, AlertProps } from '@chakra-ui/react';

export default function Alert({
  children,
  status = 'warning',
  ...otherProps
}: AlertProps): JSX.Element {
  return (
    <ChakraAlert mb={4} status={status} {...otherProps}>
      <AlertIcon />
      {children}
    </ChakraAlert>
  );
}
