import React, {useState} from 'react';
import { Box, Flex, Grid, GridItem, SimpleGrid, Button, Image,
  Modal, ModalContent, ModalHeader, ModalBody, ModalCloseButton
 } from '@chakra-ui/react';
import { Text } from '../../../ui';
import { connect, useSelector } from 'react-redux';


function formatFood(food?: string) {
    if (!food) {
      return '';
    }
  
    food = food.trimEnd().toLowerCase();
    if (food === 'uncooked cruciferous vegetables') {
      return 'brocoli';
    }
  
    return food;
  }

  function filterItems(items) {
    const rank1Items = items.filter((v) => v.Rank === 1);
    const rank2Items = items.filter((v) => v.Rank === 2);
    const rank3Items = items.filter((v) => v.Rank === 3);

    const filteredItems = [...rank1Items, ...rank2Items, ...rank3Items].slice(0, 12);

    return filteredItems;
}

  const PersonalizedPlanPage = (): JSX.Element => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedFood, setSelectedFood] = useState(null);

    const handleToggleModal = (food) => {
        setSelectedFood(food);
        setIsOpen(!isOpen);
    };

    const { stage4 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
    });


    const [biologicalFactorIsExpanded, setBiologicalFactorIsExpanded] =
    React.useState(
      new Map([
        ['Cardiometabolic Health', false],
        ['Immune Health', false],
        ['Cognitive Health', false],
        ['Hormonal Health', false],
        ['Longevity', false],
        ['Adrenal Health', false],
        ['Gastrointestinal Health', false],
        ['More', false],
      ])
    );

    const [lifestyleFactorIsExpanded, setLifestyleFactorIsExpanded] =
    React.useState(
      new Map([
        ['Confidence', false],
        ['Stamina', false],
        ['Mood', false],
        ['Sleep', false],
        ['Libido', false],
        ['Recovery', false],
        ['Focus', false],
        ['More', false],
      ])
    );

  {/* Nutrion Protocols */}
  {/* Immune Health */}
    const immune =
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Immune Health',
                    !biologicalFactorIsExpanded.get('Immune Health')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/immune.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Immune Health
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Immune Health') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Immune Health') ? 'block' : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .immune[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols.immune[2]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4.biomarker_output.biomarkers_nutrition_protocols
                          .immune[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>
                {stage4.biomarker_output.biomarkers_nutrition_protocols.immune[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.immune[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.immune[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
              </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols.immune[4]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4.biomarker_output.biomarkers_nutrition_protocols
                          .immune[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.immune[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>

{/* Cardiometabolic Health */}
    const cardiometabolic = 
    <>
    <Box
    h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
    onClick={() => {
      setBiologicalFactorIsExpanded(
        new Map(
          biologicalFactorIsExpanded.set(
            'Cardiometabolic Health',
            !biologicalFactorIsExpanded.get('Cardiometabolic Health')
          )
        )
      );
    }}
    _hover={{
      bg: '#685848',
    }}
    transition="background-color 200ms linear"
  >
    <hr
      color="#3E362E"
      style={{
        borderColor: '#3E362E',
      }}
    />
    <Grid
      templateColumns="30px auto 20px"
      px="1rem"
      py={{
        MobileS: '1.0rem',
        Tablet: '1.0rem',
        Laptop: '1.4rem',
        '4k': '1.4rem',
      }}
    >
      <Box>
        <img
          alt="MNLY Precision Men's Health"
          src={`/static/icons/cardiometabolic.png`}
          width="100%"
        />
      </Box>
      <Box
        pl="1rem"
        color="#E3DBD3"
        fontFamily="DIN-Regular"
        fontSize="18px"
        fontStyle="normal"
        lineHeight="normal"
      >
        Cardiometabolic Health
      </Box>
      <Box>
        {biologicalFactorIsExpanded.get('Cardiometabolic Health') ? (
          <img
            style={{ position: 'relative', top: '.5rem' }}
            alt="MNLY Precision Men's Health"
            src="/static/icons/minus.png"
            width="100%"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
              fill="#E3DBD3"
            />
          </svg>
        )}
      </Box>
    </Grid>
  </Box>
  <Box
    display={
      biologicalFactorIsExpanded.get('Cardiometabolic Health')
        ? 'block'
        : 'none'
    }
    mt="0rem"
    mr="4em"
    ml="1rem"
    maxW={{
      MobileS: '30.5rem',
      Tablet: '30.5rem',
      Laptop: '40.5rem',
      LaptopL: 'none',
    }}
    overflow="hidden"
  >
    <Text
      color="#E3DBD3"
      fontFamily="DIN-Regular"
      fontSize="18px"
      fontStyle="normal"
      lineHeight="130%"
      w="100%"
      mt=".5rem"
      mb="2rem"
    >
      {
        stage4?.biomarker_output.biomarkers_nutrition_protocols
          .cardiometabolic[0]
      }
    </Text>

    {stage4.biomarker_output.biomarkers_nutrition_protocols
      .cardiometabolic[2].length > 0 && (
      <>
        <Grid templateColumns="2rem 1fr" mt="2rem">
          <GridItem>
            <img
              style={{
                maxWidth: '100%',
                display: 'block',
              }}
              alt="MNLY Precision Men's Health"
              src={`/static/icons/check_circle.png`}
            />
          </GridItem>
          <GridItem>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
            >
              {
                stage4.biomarker_output.biomarkers_nutrition_protocols
                  .cardiometabolic[1]
              }
            </Text>
          </GridItem>
        </Grid>

        {stage4.biomarker_output.biomarkers_nutrition_protocols.cardiometabolic[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.cardiometabolic[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.cardiometabolic[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )} 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
      </>
    )}

    {stage4.biomarker_output.biomarkers_nutrition_protocols
      .cardiometabolic[4].length > 0 && (
      <>
        <Grid templateColumns="2rem 1fr" mt="2rem">
          <GridItem>
            <img
              style={{
                maxWidth: '100%',
                display: 'block',
              }}
              alt="MNLY Precision Men's Health"
              src={`/static/icons/do_not_disturb_on.png`}
            />
          </GridItem>
          <GridItem>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
            >
              {
                stage4.biomarker_output.biomarkers_nutrition_protocols
                  .cardiometabolic[3]
              }
            </Text>
          </GridItem>
        </Grid>

        <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.cardiometabolic[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
      </>
    )}
  </Box>
  </>

{/* Cognitive Health */}
    const cognitive = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Cognitive Health',
                    !biologicalFactorIsExpanded.get('Cognitive Health')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/cognitive.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Cognitive Health
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Cognitive Health') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Cognitive Health')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .cognitive[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[2]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .cognitive[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                {stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
        </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[4]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .cognitive[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.cognitive[4]       
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>

{/* Hormonal Health */}
    const hormonal = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Hormonal Health',
                    !biologicalFactorIsExpanded.get('Hormonal Health')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/hormonal.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Hormonal Health
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Hormonal Health') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Hormonal Health')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .hormonal[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[2]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .hormonal[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                {stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}

        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[4]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .hormonal[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.hormonal[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>
    
  {/* Longevity */}
    const longevity = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Longevity',
                    !biologicalFactorIsExpanded.get('Longevity')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/longevity.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Longevity
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Longevity') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Longevity') ? 'block' : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .longevity[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols
              .longevity[2].length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .longevity[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                {stage4.biomarker_output.biomarkers_nutrition_protocols.longevity[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.longevity[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.longevity[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}

        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols.longevity[4]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .longevity[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.longevity[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>

{/* Adrenal Health */}
    const adrenal = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Adrenal Health',
                    !biologicalFactorIsExpanded.get('Adrenal Health')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/adrenal.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Adrenal Health
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Adrenal Health') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Adrenal Health')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .adrenal[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols
              .adrenal[2].length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .adrenal[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                {stage4.biomarker_output.biomarkers_nutrition_protocols.adrenal[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.adrenal[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.adrenal[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}

        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols.adrenal[4]
              .length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .adrenal[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.adrenal[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>

{/* Gastrointestinal Health */}
    const gastrointestinal = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'Gastrointestinal Health',
                    !biologicalFactorIsExpanded.get('Gastrointestinal Health')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/gastrointestinal.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Gastrointestinal Health
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('Gastrointestinal Health') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('Gastrointestinal Health')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                stage4?.biomarker_output.biomarkers_nutrition_protocols
                  .gastrointestinal[0]
              }
            </Text>

            {stage4.biomarker_output.biomarkers_nutrition_protocols
              .gastrointestinal[2].length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/check_circle.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                      w="100%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .gastrointestinal[1]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                {stage4.biomarker_output.biomarkers_nutrition_protocols.gastrointestinal[2].every(food => food.Rank) ? (
         <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {filterItems(stage4.biomarker_output.biomarkers_nutrition_protocols.gastrointestinal[2])
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid>
        ):(
          <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.gastrointestinal[2]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        )}

        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
            {stage4.biomarker_output.biomarkers_nutrition_protocols
              .gastrointestinal[4].length > 0 && (
              <>
                <Grid templateColumns="2rem 1fr" mt="2rem">
                  <GridItem>
                    <img
                      style={{
                        maxWidth: '100%',
                        display: 'block',
                      }}
                      alt="MNLY Precision Men's Health"
                      src={`/static/icons/do_not_disturb_on.png`}
                    />
                  </GridItem>
                  <GridItem>
                    <Text
                      color="#E3DBD3"
                      fontFamily="DIN-Medium"
                      fontSize="18px"
                      fontStyle="normal"
                      lineHeight="130%"
                    >
                      {
                        stage4?.biomarker_output.biomarkers_nutrition_protocols
                          .gastrointestinal[3]
                      }
                    </Text>
                  </GridItem>
                </Grid>

                <SimpleGrid p='15px' mt='2rem' spacing={5} minChildWidth='348px'>
            {stage4.biomarker_output.biomarkers_nutrition_protocols.gastrointestinal[4]
                .slice(0,12)
                .map((food) => (
                    <Box 
                     key={food.Food} 
                     border="solid .1rem #7D7C7A" 
                     maxW='430px'
                     >
                        <Button
                            onClick={() => handleToggleModal(food)}
                            display ='flex'
                            alignItems='center'
                            justifyContent='space-between'
                            w='100%'
                            backgroundColor='transparent'
                            height='75px'
                            _hover={{bg: '#685848'}}
                        >
                            <Flex>
                            <Image
                                alt="MNLY Precision Men's Health"
                                src={`/static/ads/foods/${formatFood(food.Food)}.png`}
                                height="64px"
                                width="64px"
                                
                            />
                            </Flex>
                            <Flex>
                            <Box>
                                <Box 
                                 fontSize='20px' 
                                 fontWeight='bold' 
                                 textTransform='capitalize'
                                 textColor="#E3DBD3">
                                    {formatFood(food.Food)}</Box>
                            </Box>
                            </Flex>
                            <Flex>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                                fill="#E3DBD3"
                              />
                            </svg>
                            </Flex>
                        </Button>
                    </Box>
                ))}
        </SimpleGrid> 
        {selectedFood && (
                <Modal
                  isCentered 
                  isOpen={isOpen} 
                  onClose={() => setIsOpen(false)}
                  closeOnOverlayClick='true'>
                    
                    <ModalContent bg='#685848' color='white'>
                        <ModalHeader>{selectedFood.Food}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedFood.Description}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}   
              </>
            )}
          </Box>
    </>

{/* Lifestyle Protocols*/}
{/* Focus */}
    const focus =
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Focus',
                    !lifestyleFactorIsExpanded.get('Focus')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/focus.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Focus
              </Box>
              <Box>
                {lifestyleFactorIsExpanded.get('Focus') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={lifestyleFactorIsExpanded.get('Focus') ? 'block' : 'none'}
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.focus
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.focus[0]
                : ''}
            </Text>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.focus
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.focus[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.focus &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.focus[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your focus score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.focus
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.focus[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5erm' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.focus
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.focus[2]
                : ''}
            </Text>
          </Box>
    </>

{/* Confidence */}
    const confidence =
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Confidence',
                    !lifestyleFactorIsExpanded.get('Confidence')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/confidence.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Confidence
              </Box>
              <Box>
                {lifestyleFactorIsExpanded.get('Confidence') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              lifestyleFactorIsExpanded.get('Confidence') ? 'block' : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols
                .confidence
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .confidence[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols
                ?.confidence
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.confidence[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence
                ? stage4?.lifestyle_output?.lifestyle_category_hacks
                    ?.confidence[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your confidence score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.confidence[2]
                : ''}
            </Text>
          </Box>
    </>
    
{/* Stamina */}
    const stamina =
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Stamina',
                    !lifestyleFactorIsExpanded.get('Stamina')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/stamina.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Stamina
              </Box>
              <Box>
                {lifestyleFactorIsExpanded.get('Stamina') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              lifestyleFactorIsExpanded.get('Stamina') ? 'block' : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols.stamina
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .stamina[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.stamina
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.stamina[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your stamina score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.stamina[2]
                : ''}
            </Text>
          </Box>
    </>

{/* Mood */}
    const mood = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Mood',
                    !lifestyleFactorIsExpanded.get('Mood')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/mood.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Mood
              </Box>{' '}
              <Box>
                {lifestyleFactorIsExpanded.get('Mood') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={lifestyleFactorIsExpanded.get('Mood') ? 'block' : 'none'}
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols.mood
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .mood[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.mood
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.mood[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.mood
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.mood[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.mood &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.mood[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your mood score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.mood
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.mood[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.mood
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.mood[2]
                : ''}
            </Text>
          </Box>
    </>

 {/* Sleep */}
    const sleep =
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Sleep',
                    !lifestyleFactorIsExpanded.get('Sleep')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/sleep.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Sleep
              </Box>{' '}
              <Box>
                {lifestyleFactorIsExpanded.get('Sleep') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>

          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={lifestyleFactorIsExpanded.get('Sleep') ? 'block' : 'none'}
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols.sleep
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .sleep[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.sleep
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.sleep[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your sleep score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.sleep[2]
                : ''}
            </Text>
          </Box>
    </>

  {/* Libido */}
    const libido = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Libido',
                    !lifestyleFactorIsExpanded.get('Libido')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/libido.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Libido
              </Box>{' '}
              <Box>
                {lifestyleFactorIsExpanded.get('Libido') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={lifestyleFactorIsExpanded.get('Libido') ? 'block' : 'none'}
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols.libido
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .libido[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.libido
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.libido[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.libido
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.libido[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.libido &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.libido[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  w="100%"
                  my="1rem"
                >
                  In order to improve your libido score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.libido
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.libido[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.libido
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.libido[2]
                : ''}
            </Text>
          </Box>
    </>

  {/* Recovery */}
    const recovery = 
    <>
    <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'Recovery',
                    !lifestyleFactorIsExpanded.get('Recovery')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
                <img
                  alt="MNLY Precision Men's Health"
                  src="/static/icons/recovery.png"
                  width="100%"
                />
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                Recovery
              </Box>{' '}
              <Box>
                {lifestyleFactorIsExpanded.get('Recovery') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              lifestyleFactorIsExpanded.get('Recovery') ? 'block' : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols.recovery
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    .recovery[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
            >
              {stage4?.biomarker_output.biomarkers_nutrition_protocols?.recovery
                ? stage4?.biomarker_output.biomarkers_nutrition_protocols
                    ?.recovery[0]
                : ''}
            </Text>

            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery
                ? stage4?.lifestyle_output?.lifestyle_category_hacks
                    ?.recovery[0]
                : ''}
            </Text>
            {stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery &&
              stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery[1]
                .length > 0 && (
                <Text
                  color="#bf936b"
                  fontFamily="DIN-Regular"
                  fontSize="18px"
                  fontStyle="normal"
                  lineHeight="130%"
                  my="1rem"
                  w="100%"
                >
                  In order to improve your recovery score, we suggest to the
                  following:
                </Text>
              )}
            <ul
              style={{
                marginTop: '1rem',
                marginLeft: '2rem',
                color: '#E3DBD3',
                fontFamily: 'DIN-Regular',
                fontSize: '16px',
                fontStyle: 'normal',
                lineHeight: '130%',
                width: '100%',
              }}
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery[1].map(
                    (lifeHack: string) => (
                      <li key={lifeHack} style={{ marginBottom: '.5rem' }}>
                        <Text w="90%">{lifeHack}</Text>
                      </li>
                    )
                  )
                : ''}
            </ul>
            <Text
              color="#E3DBD3"
              fontFamily="DIN-Medium"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              ml="0rem"
              w="100%"
              mt='1rem'
            >
              {stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery
                ? stage4?.lifestyle_output?.lifestyle_category_hacks?.recovery[2]
                : ''}
            </Text>
          </Box>
    </>




{/* Finding which category and assigning it a const from above */}
    const getCategory = (value) => {
      switch (value) {
        case 'immune':
          return immune;
          break;
        case 'cardiometabolic':
          return cardiometabolic;
          break;
        case 'cognitive':
          return cognitive;
          break;
        case 'hormonal':
          return hormonal;
          break;
        case 'longevity':
          return longevity;
          break;
        case 'adrenal':
          return adrenal;
          break;
        case 'gastrointestinal':
          return gastrointestinal;
          break;
        case 'recovery':
          return recovery;
          break;
        case 'stamina':
          return stamina;
          break;
        case 'focus':
          return focus;
          break;
        case 'libido':
          return libido;
          break;
        case 'sleep':
          return sleep;
          break;
        case 'mood':
          return mood;
          break;
        case 'confidence':
          return confidence;
          break;
      }
    }

{/* Page starts here */}
    return (
      <>
      <Flex mt="2rem">
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Regular"
          fontSize="32px"
          fontStyle="normal"
          lineHeight="130%"
          textTransform="capitalize"
        >
          Nutrition Protocols
        </Text>
      </Flex>
      {stage4.biomarker_output.biomarkers_categories_ranking ? (
      <Flex>
      <Text
              color="#bf936b"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="100%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                "You scored lowest in these three categories and should focus on improvements here first."
              }
            </Text>
      </Flex>
      ):(
        <Flex>

        </Flex>
      )}
      {stage4.biomarker_output.biomarkers_categories_ranking ? (
      <Flex>
        <Box flex="1">
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[0])}
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[1])}
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[2])}
        
        <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setBiologicalFactorIsExpanded(
                new Map(
                  biologicalFactorIsExpanded.set(
                    'More',
                    !biologicalFactorIsExpanded.get('More')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                More
              </Box>
              <Box>
                {biologicalFactorIsExpanded.get('More') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              biologicalFactorIsExpanded.get('More')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#bf936b"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                "You scored highest in these categories and should focus on them after making adjustments above."
              }
            </Text>
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[3])}
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[4])}
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[5])}
        {getCategory(stage4.biomarker_output.biomarkers_categories_ranking[6])}

          </Box>


        </Box>
      </Flex>
      ):(
        <Flex>
        <Box flex="1">
          {getCategory('immune')}
          {getCategory('cardiometabolic')}
          {getCategory('cognitive')}
          {getCategory('hormonal')}
          {getCategory('longevity')}
          {getCategory('adrenal')}
          {getCategory('gastrointestinal')}
        </Box>
        </Flex>
    )}
      <Flex mt="2rem">
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Regular"
        fontSize="32px"
        fontStyle="normal"
        lineHeight="130%"
        textTransform="capitalize"
      >
        Lifestyle Protocols
      </Text>
    </Flex>
    {stage4.lifestyle_output.lifestyle_categories_ranking ? (
    <Flex>
      <Text
              color="#bf936b"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="100%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                "You scored lowest in these three categories and should focus on improvements here first."
              }
            </Text>
      </Flex>
    ):(
      <Flex>

      </Flex>
    )}
      {stage4.lifestyle_output.lifestyle_categories_ranking ? (
    <Flex>
      <Box flex="1">
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[0])}
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[1])}
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[2])}

        <Box
            h={{ MobileS: '3.5rem', Tablet: '3.5rem', Laptop: '4.0rem' }}
            onClick={() => {
              setLifestyleFactorIsExpanded(
                new Map(
                  lifestyleFactorIsExpanded.set(
                    'More',
                    !lifestyleFactorIsExpanded.get('More')
                  )
                )
              );
            }}
            _hover={{
              bg: '#685848',
            }}
            transition="background-color 200ms linear"
          >
            <hr
              color="#3E362E"
              style={{
                borderColor: '#3E362E',
              }}
            />
            <Grid
              templateColumns="30px auto 20px"
              px="1rem"
              py={{
                MobileS: '1.0rem',
                Tablet: '1.0rem',
                Laptop: '1.4rem',
                '4k': '1.4rem',
              }}
            >
              <Box>
              <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
              </Box>
              <Box
                pl="1rem"
                color="#E3DBD3"
                fontFamily="DIN-Regular"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
              >
                More
              </Box>
              <Box>
                {lifestyleFactorIsExpanded.get('More') ? (
                  <img
                    style={{ position: 'relative', top: '.5rem' }}
                    alt="MNLY Precision Men's Health"
                    src="/static/icons/minus.png"
                    width="100%"
                  />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                      fill="#E3DBD3"
                    />
                  </svg>
                )}
              </Box>
            </Grid>
          </Box>
          <Box
            mt="0rem"
            mr="4em"
            ml="1rem"
            display={
              lifestyleFactorIsExpanded.get('More')
                ? 'block'
                : 'none'
            }
            maxW={{
              MobileS: '30.5rem',
              Tablet: '30.5rem',
              Laptop: '40.5rem',
              LaptopL: 'none',
            }}
            overflow="hidden"
          >
            <Text
              color="#bf936b"
              fontFamily="DIN-Regular"
              fontSize="18px"
              fontStyle="normal"
              lineHeight="130%"
              w="100%"
              mt=".5rem"
              mb="2rem"
            >
              {
                "You scored highest in these categories and should focus on them after making adjustments above."
              }
            </Text>
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[3])}
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[4])}
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[5])}
        {getCategory(stage4.lifestyle_output.lifestyle_categories_ranking[6])}
          </Box>

      </Box>
    </Flex>
      ):(
      <Flex>
      <Box flex="1">
        {getCategory('recovery')}
        {getCategory('stamina')}
        {getCategory('focus')}
        {getCategory('libido')}
        {getCategory('sleep')}
        {getCategory('mood')}
        {getCategory('confidence')}
      </Box>
      </Flex>
      )}
    </>
    )
  }

  function mapStateToProps(state: { session: any }) {
    return {
      stages: state.session.stages,
    };
  }
  
  export default connect(mapStateToProps, {})(PersonalizedPlanPage);