import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  image: string;
  text: string;
};
const Offering = (props: Props) => {
  return (
    <Flex direction="column" alignItems="center">
      <Box width={{ sm: '6rem', md: '11.5rem' }}>
        <Box
          backgroundColor="#000000"
          padding={{ sm: '1.75rem', md: '3.5rem' }}
          width={{ sm: '6rem', md: '11.5rem' }}
          height={{ sm: '6rem', md: '11.5rem' }}
        >
          <img alt="MNLY Precision Men's Health" src={props.image} />
        </Box>
        <Box
          marginTop={{ sm: '0.5rem', md: '1rem' }}
          width={{ sm: '100%', md: '75%' }}
        >
          <Text
            color="#ffffff"
            fontSize={{ sm: '10px', md: '16px' }}
            fontFamily="DIN-Bold"
            textAlign={{ sm: 'center', md: 'left' }}
          >
            {props.text}
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};

export default Offering;
