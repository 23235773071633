import React, { useState } from 'react';
import AnswerItem from '../AnswerItem/AnswerItem';
import { Grid } from '@chakra-ui/react';
import { Select, Box } from '@chakra-ui/react';
import NextButton from '../NextButton/NextButton';

const AnswerDropdown = (props: {
  answers: any;
  onAnswerClick: () => void;
  onSaveClick: () => void;
  result: any;
  answerState?: { [x: string]: any };
}) => {
  const [dropdownValue, setDropdownValue] = useState(props.result ? props.result : props.answers[0]);

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  };

  return (
    <Grid gap="1rem" templateColumns="1fr" gridAutoRows="1fr 1fr">
      <Select
        color="white"
        fontFamily="DIN-Regular" height="3rem"
        _active={{
          borderColor: 'white',
        }}
        _selected={{
          borderColor: 'white',
        }}
        _focus={{
          borderColor: 'white',
        }}
        sx={{
          '> option': {
            background: '#141414',
            color: 'white',
            height: '20rem',
          },
        }}
        value={dropdownValue}
        onChange={val => setDropdownValue(val.target.value)}
      >
        {props.answers.map(
          (
            answer: {} | null | undefined,
            index: React.Key | null | undefined
          ) => (
            <option key={index}>{answer}</option>
          )
        )}
      </Select>
      {/*<AnswerItem
        answer={{ id: dropdownValue, text: dropdownValue }}
        onAnswerClick={props.onAnswerClick}
      />*/}
      <NextButton
        answer={{ id: dropdownValue, text: 'Next >', value: dropdownValue}}
        onAnswerClick={props.onAnswerClick}
        onSaveClick={props.onSaveClick}
      />
    </Grid>
  );
};

export default AnswerDropdown;
