/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Product from './Product';

export default function ProductsPage(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Product />} />
    </Routes>
  );
}
