import React from 'react';
import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionListItem,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const Step5 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-5"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
      maxWidth="615px"
    >
      <StepLabel> Step 5</StepLabel>
      <InstructionTitle>
        Before you prick your finger you will need to do the <br />
        <br /> following:
      </InstructionTitle>

      <UnorderedList spacing="0.5rem">
        <InstructionListItem>
          We recommend using your "ring" finger as this finger is usually the
          least calloused
        </InstructionListItem>
        <InstructionListItem>
          Wipe your finger with the provided alcohol swab.
        </InstructionListItem>
        <InstructionListItem>
          Without touching the collection material, remove the collection device
          and place it face up on the table.
        </InstructionListItem>
        <InstructionListItem>
          Twist and remove the cap on your lancet. It is now ready to use.
        </InstructionListItem>
      </UnorderedList>

      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step5;
