import React from 'react';
import {
  Tooltip,
  Box,
  Text,
  getColorBySentiment,
  getIconBySentiment,
  Sentiment,
} from '..';

interface ValueSentimentProps {
  sentiment?: Sentiment;
  tooltip?: React.ReactNode;
  value: string;
}

export default function ValueSentiment({
  sentiment = 'neutral',
  tooltip,
  value,
}: ValueSentimentProps): JSX.Element {
  return (
    <Tooltip isDisabled={!tooltip} label={tooltip}>
      <Box display="inline-block" color={getColorBySentiment(sentiment)}>
        <Text as="span" fontWeight="bold" verticalAlign="middle">
          {value}
        </Text>
        <Box verticalAlign="middle" display="inline-block" m={1}>
          {getIconBySentiment(sentiment)}
        </Box>
      </Box>
    </Tooltip>
  );
}
