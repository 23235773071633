import { background, extendTheme } from '@chakra-ui/react';
import colors, { tokens as colorTokens } from './foundations/colors';
import { modalTheme } from './modalTheme';


const standardBreakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  '2xl': '1536px',
};

const breakpoints = {
  MobileS: '320px',
  MobileM: '375px',
  MobileL: '425px',
  Tablet: '768px',
  Laptop: '1024px',
  LaptopL: '1440px',
  '4k': '2560px',
  ...standardBreakpoints,
};

export default extendTheme({
  breakpoints,
  colors,
  fonts: {
    body: 'lato, Helvetica, Arial, sans-serif',
    heading: 'lato, Helvetica, Arial, sans-serif',
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },
  semanticTokens: {
    colors: colorTokens,
  },
  components: {
    Input: {
      variants: {
        outline: {
          field: {
            borderColor: '#7D7C7A',
          },
        },
      },
    },
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: '#434B30',
        },
      },
    },
  },
});

