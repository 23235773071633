import React from 'react';
import {
  Box,
  Center,
  Spinner,
  getColorBySentiment,
  Sentiment,
  Tooltip,
} from '..';
import { fontFamily } from '../../theme';
import { CardContent, CardShell } from '../Card';
import ValueCardHeader, {
  Props as ValueCardHeaderProps,
} from './ValueCardHeader';

export { CardContent, ValueCardHeader, CardShell };
export type { ValueCardHeaderProps };

export type CardProps = ValueCardHeaderProps & {
  children?: React.ReactNode;
  error?: string;
  loading?: boolean;
  sentiment?: Sentiment;
  subtitle?: string;
  tooltip?: string;
  value: string | number;
};

export default function Card({
  children,
  error,
  loading = false,
  sentiment = 'neutral',
  tooltip,
  value,
  ...otherProps
}: CardProps): JSX.Element {
  let content = <h2>{value}</h2>;

  if (tooltip) {
    content = (
      <Tooltip label={tooltip}>
        <h2 className={sentiment}>{value}</h2>
      </Tooltip>
    );
  }

  return (
    <CardShell>
      {otherProps.title ? <ValueCardHeader {...otherProps} /> : null}
      <CardContent {...otherProps}>
        {loading ? (
          <Center>
            <Spinner data-testid="loading" size="lg" color="gray.400" />
          </Center>
        ) : null}

        {error ? <Box color="red.700">{error}</Box> : null}

        {!loading && !error ? (
          <Box
            color={getColorBySentiment(sentiment)}
            fontFamily={fontFamily}
            fontSize="26px"
            fontWeight={700}
            lineHeight="32px"
          >
            {content}
            <div>{children}</div>
          </Box>
        ) : null}
      </CardContent>
    </CardShell>
  );
}
