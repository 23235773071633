import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Text } from '@chakra-ui/react';
import StageStep from './StageStep';
import {
  emulateDeliveredToLab,
  emulateDeliveredToPatient,
  emulateFullyReultedByLab,
  emulatePartiallyResultedByLab,
  emulatePlanDeveloped,
  emulateQuizCompleted,
  emulateReceivedByLab,
  emulateShippedToLab,
  emulateShippedToPatient,
  fetchCustomer,
  stageAchieved,
} from '../../../../store/actions/sessionActions';
import results from '../../../../../server/static/results.json';
import React from 'react';

type Props = {
  stage2State: string;
  setStage2State: (val: string) => void;
  stage3State: string;
  setStage3State: (val: string) => void;
};

const StageStepText = ({ line1, line2 }: { line1: string; line2: string }) => {
  return (
    <>
      <Box
        color="#E3DBD3"
        textAlign="center"
        w={{
          base: '12rem',
          sm: '4.1rem',
          md: '8rem',
          lg: '12rem',
        }}
      >
        <>
          <Text
            fontFamily="DIN-medium"
            lineHeight={{ base: 1, sm: '10px', md: 1 }}
            fontSize={{
              base: '17px',
              sm: '10px',
              md: '17px',
            }}
          >
            {line1}
          </Text>
          <Text
            fontFamily="DIN-medium"
            lineHeight={{ base: 1, sm: '10px', md: 1 }}
            fontSize={{
              base: '17px',
              sm: '10px',
              md: '17px',
            }}
          >
            {line2}
          </Text>
        </>
      </Box>
    </>
  );
};

const StageStepper = ({
  stage2State,
  setStage2State,
  stage3State,
  setStage3State,
}: Props) => {
  const dispatch = useDispatch();
  const { stage1, stage2, stage3, stage4, stage5 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });
  const { customer } = useSelector(state => {
    //@ts-ignore
    return state.session;
  });

  return (
    <Flex flexDirection="column" alignItems="center" gap="1rem">
      <Flex justifyContent="center">
        <Flex>
          <StageStep
            stage="1"
            onClick={() => {
              if (process.env.REACT_ENV !== 'production') {
                dispatch(emulateQuizCompleted());
                dispatch(stageAchieved(1));
              }
            }}
            achieved={stage1?.achieved}
          />

          <StageStep
            stage="2"
            onClick={() => {
              if (process.env.REACT_ENV !== 'production') {
                if (
                  stage2State === 'Order Created by MNLY' &&
                  !customer?.birthdate
                ) {
                  let customerUpdated = { ...customer };
                  customerUpdated.birthdate = '2023-10-25';
                  dispatch(fetchCustomer(customerUpdated));
                } else if (
                  stage2State === 'Order Created by MNLY' &&
                  customer?.birthdate
                ) {
                  dispatch(emulateShippedToPatient());
                  setStage2State('Shipped To Patient');
                } else if (stage2State === 'Shipped To Patient') {
                  dispatch(emulateDeliveredToPatient());
                  setStage2State('Delivered To Patient');
                } else if (stage2State === 'Delivered To Patient') {
                  dispatch(emulateShippedToLab());
                  setStage3State('Shipped To Lab');
                  dispatch(stageAchieved(2));
                }
              }
            }}
            achieved={stage2?.achieved}
          />

          <StageStep
            stage="3"
            onClick={() => {
              if (process.env.REACT_ENV !== 'production') {
                if (stage3State === 'Shipped To Lab') {
                  dispatch(emulateDeliveredToLab());
                  setStage3State('Delivered To Lab');
                } else if (stage3State === 'Delivered To Lab') {
                  dispatch(emulateReceivedByLab());
                  setStage3State('Received By Lab');
                } else if (stage3State === 'Received By Lab') {
                  dispatch(emulatePartiallyResultedByLab());
                  setStage3State('Partially Resulted By Lab');
                } else if (stage3State === 'Partially Resulted By Lab') {
                  dispatch(emulateFullyReultedByLab());
                  dispatch(stageAchieved(3));
                }
              }
            }}
            achieved={stage3?.achieved}
          />

          <StageStep
            stage="4"
            onClick={() => {
              if (process.env.REACT_ENV !== 'production') {
                dispatch(emulatePlanDeveloped(results));
                dispatch(stageAchieved(4));
                dispatch(stageAchieved(5));
              }
            }}
            achieved={stage4?.achieved}
          />

          <StageStep
            stage="5"
            onClick={() => {}}
            achieved={stage5?.achieved}
            lastStage
          />
        </Flex>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <StageStepText line1="Complete Health" line2="Assessment" />

        <Box position="relative" top="1.3rem" h=".05rem" w=".5rem">
          &nbsp;
        </Box>

        <StageStepText line1="Submit Blood" line2="Samples" />

        <Box position="relative" top="1.3rem" h=".05rem" w=".5rem">
          &nbsp;
        </Box>

        <StageStepText line1="Blood Analysis" line2="In Process" />

        <Box position="relative" top="1.3rem" h=".05rem" w=".5rem">
          &nbsp;
        </Box>

        <StageStepText line1="Health Report And" line2="Plan Developed" />

        <Box position="relative" top="1.3rem" h=".05rem" w=".5rem">
          &nbsp;
        </Box>

        <StageStepText line1="Precision Supplements" line2="In Formulation" />
      </Flex>
    </Flex>
  );
};

export default StageStepper;
