/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { motion } from 'framer-motion';

import QuizForm from './QuizForm';

export default function QuizPage(): JSX.Element {
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
    >
      <Routes>
        <Route index element={<QuizForm />} />
      </Routes>
    </motion.div>
  );
}
