import { CSSObject } from 'styled-components';

export const fontFamily = 'lato, Helvetica, Arial, sans-serif';

const theme = {
  colors: {
    base: {
      bg: '#f5f6f7',
      black: '#000',
      dark: '#334050',
      darkgray: '#7f8fa4',
      gray: '#bfc7d1',
      lightgray: '#e1e8ee',
      white: '#fff',
    },
    category: {
      blue: { base: '#008cef', dark: '#0064C2', light: '#A8D1FF' },
      cactus: { base: '#00D791', dark: '#008558', light: '#AAEDCD' },
      candy: { base: '#FF5FA0', dark: '#D91261', light: '#FFC1DA' },
      denim: { base: '#28328C', dark: '#1F2451', light: '#A4AACF' },
      ramen: { base: '#ffaa0f', dark: '#A36A00', light: '#FFDBA6' },
      salsa: { base: '#FA414B', dark: '#A11219', light: '#FFC0C2' },
      silver: { base: '#888c94', dark: '#55585E', light: '#D8DBDF' },
      yoga: { base: '#AF50DC', dark: '#772F98', light: '#E0BAF2' },
    },
    light: {
      dark: '#EAECED',
      darkgray: '#F0F3F5',
      gray: '#f6f8f9',
      lightgray: '#F8FAFB',
      primary: '#e8f4fd',
    },
    primary: {
      hover: '#E3DBD3',
      normal: '#E3DBD3',
    },
    ratings: {
      five: '#15A94F',
      four: '#92C946',
      one: '#DD4228',
      three: '#F8A816',
      two: '#EE6B00',
    },
    status: {
      aborted: '#888C94',
      error: '#DD4228',
      ok: '#15A94F',
      pending: '#F2C94C',
      running: '#0064C2',
      unknown: '#D8DBDF',
      warning: '#F8A816',
    },
  },
  shadows: {
    popup: {
      boxShadow: '0 4px 6px 0 rgba(51, 65, 80, 0.2)',
    },
  },
  typography: {
    bodyLarge: {
      fontFamily,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    bodyLargeBold: {
      fontFamily,
      fontSize: '16px',
      fontWeight: 900,
      lineHeight: '22px',
    },
    bodyRegular: {
      fontFamily,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    bodyRegularBold: {
      fontFamily,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '20px',
    },
    bodySmall: {
      fontFamily,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
    },
    header1: {
      fontFamily,
      fontSize: '26px',
      fontWeight: 900,
      lineHeight: '32px',
    },
    header1Light: {
      fontFamily,
      fontSize: '26px',
      fontWeight: 700,
      lineHeight: '32px',
    },
    header2: {
      fontFamily,
      fontSize: '21px',
      fontWeight: 700,
      lineHeight: '30px',
    },
    header3: {
      fontFamily,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '26px',
    },
    header3Light: {
      fontFamily,
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '26px',
    },
  },
};

/**
 * Makes content visually invisible but available to screen readers.
 * https://accessibility.18f.gov/hidden-content/
 */
const scr: CSSObject = {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: 1,
};

/**
 * @deprecated - Use our custom Chakra color tokens instead, or if using the color token strings isn't possible then get the colors from Chakra's useTheme
 */
export default {
  ...theme,
  fontFamily,
  scr,
};
