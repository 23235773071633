import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import Icon, { IconGlyph } from '../Icon';

interface Option<T> {
  ariaLabel: string;
  glyph?: IconGlyph;
  label: string;
  value: T;
}

interface Props<T> {
  onChange: (value: T) => void;
  options: Option<T>[];
  selected: T;
}

export default function ToggleGroup<T>({
  selected,
  options,
  onChange,
}: Props<T>): JSX.Element {
  return (
    <ButtonGroup
      spacing={0}
      alignItems="center"
      border="1px solid lightgray"
      borderRadius="5px"
      boxSizing="border-box"
      data-testid="toggle-group"
    >
      {options.map((option, index) => {
        const isLastChild = index === options.length - 1;

        return (
          <Button
            _hover={{ background: 'none' }}
            background="none"
            border="none"
            borderRight={isLastChild ? 'none' : '1px solid lightgray'}
            color={selected === option.value ? 'venmo.blue' : 'gray.400'}
            size="sm"
            fontWeight="normal"
            aria-pressed={selected === option.value}
            key={option.value as unknown as string}
            onClick={() => {
              onChange(option.value);
            }}
            title={option.ariaLabel}
          >
            {option.glyph ? (
              <Icon
                display="inline-block"
                glyph={option.glyph}
                boxSize="12px"
                aria-label={option.label}
              />
            ) : (
              <span>{option.label}</span>
            )}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
