import React, { useState } from 'react';
import PageBoundary from '../../ui/ErrorBoundary';
import {
  chakra,
  Flex,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import AthletesHero from './AthletesHero';
import AthletesSection1 from './AthletesSection1';
import ApplicationSection2 from './AthletesSection2';
import AthletesSection3 from './AthletesSection3';
import AthletesSection4 from './AthletesSection4';
import AthleteApplicationForm from './AthleteApplicationForm';
import KlaviyoForm from './KlaviyoAthleteApplicationForm';

export const AthleteApplicationPageHorizontalPadding = '4rem';
export const AthleteApplicationPageVerticalPadding = '6rem';
export const AthleteApplicationPagePadding = `${AthleteApplicationPageVerticalPadding} ${AthleteApplicationPageHorizontalPadding} ${AthleteApplicationPageVerticalPadding} ${AthleteApplicationPageHorizontalPadding}`;
export const AthleteApplicationPageMobilePadding = '2rem';

export const ApplicationPageLargeText = chakra(Heading, {
  baseStyle: {
    color: '#FFFFFF',
    fontFamily: 'DIN-Bold',
    fontWeight: 400,

  },
});

const Athletes = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  return (
    <PageBoundary>
      <Flex id="AthleteApplication" flexDirection="column" width="100%">
        <AthletesHero handleOpen={handleOpen} />
        <AthletesSection1 handleOpen={handleOpen} />
        <ApplicationSection2 />
        <AthletesSection3 />
        <AthletesSection4 handleOpen={handleOpen} />
        <Modal isOpen={modalOpen} onClose={handleClose}>
          <ModalOverlay />
          <ModalContent>
            <KlaviyoForm />
          </ModalContent>
        </Modal>
      </Flex>
    </PageBoundary>
  );
};

export default Athletes;
