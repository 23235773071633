import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { Box, Grid, GridItem } from '..';
import usePageTitle from '../../hooks/usePageTitle';
import Navigation from './Navigation';
import NavigationPopover from './NavigationPopover';
import { usePageContext } from './PageContext';

export interface PageProps {
  /** The contents of the page. */
  children: React.ReactNode;
  /** A list of filters to present on the page. */
  filters?: never[]; // Not yet implemented.
  /** The label to use in the navigation popup for the page. */
  label?: string;
  /** The title to show in the browser for the page. */
  title: string;
}

/**
 * Handles rendering common UI elements for a page, including sub navigation
 * and filters. Also manages the page title shown in the browser.
 */
export const Page = ({ children, filters = [], label, title }: PageProps) => {
  const { customNavigation, links, navigation } = usePageContext();

  /**
   * If we are on the smallest breakpoint, show popovers for navigation
   * and filters.
   */
  const showPopovers = useBreakpointValue({
    base: true,
    md: false,
  });

  /**
   * Show the top bar if we have both filters and navigation items, or if
   * or if we are showing the navigation popover instead of the menu.
   */
  const hasTopBar =
    (filters.length > 0 && navigation.length > 0) || showPopovers;

  /**
   * Show the sidebar if we either filters or navigation items.
   */
  const hasSidebar = filters.length > 0 || navigation.length > 0;

  usePageTitle(title);

  return (
    <Grid
      gap={6}
      templateColumns={hasSidebar ? { base: '1fr', md: '240px 1fr' } : '1fr'}
    >
      {hasSidebar && !showPopovers ? (
        <GridItem>
          {customNavigation ?? (
            <Navigation navigation={navigation} links={links} />
          )}
        </GridItem>
      ) : null}

      <GridItem>
        {hasTopBar ? (
          <Box mb={4}>
            {showPopovers ? (
              <NavigationPopover
                label={label}
                navigation={navigation}
                links={links}
              />
            ) : null}

            {/** TODO: Conditionally show filter popovers. */}
          </Box>
        ) : null}
        {children}
      </GridItem>
    </Grid>
  );
};

export { PageContext } from './PageContext';
export default Page;
