import React from 'react';
import { chakra, Flex, Heading } from '@chakra-ui/react';
import { ActionButton } from '../../components/Buttons';
import { ApplicationPageLargeText } from './Athletes';
import { Text } from '../../ui';

export const ApplicationPageActionButton = chakra(ActionButton, {
  baseStyle: {
    color: '#000000',
    fontFamily: 'DIN-Regular',
    width: '225px',
    fontSize: '12.75px',
    fontWeight: 400,
  },
});

type Props = {
  handleOpen: () => void;
};

const AthletesHero = ({ handleOpen }: Props) => {
  return (
    <Flex
      width="100%"
      overflowX="hidden"
      backgroundImage="url(/static/ads/application_hero.png)"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition="center"
      height={{ sm: '20rem', md: '496.24px' }}
      padding={{ sm: '3rem 2rem 3rem 2rem', lg: '9rem 4rem 9rem 4rem' }}
    >
      <Flex
        direction="column"
        gap={{ sm: '1rem', lg: '2rem' }}
        alignItems="flex-start"
      >
        <Flex direction="column">
          <Heading as="h3" size="xl" color="#E3DBD3" fontFamily="DIN-Medium">
            Peak performance.
          </Heading>
          <Heading as="h3" size="xl" color="#E3DBD3" fontFamily="DIN-Medium">
            Powered by science.
          </Heading>
        </Flex>
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Regular"
          fontSize={{ sm: '12px', lg: '18px' }}
        >
          Become a founding MNLY HYROX Athlete
        </Text>

        <ApplicationPageActionButton
          onClick={handleOpen}
          width={{ sm: '136px', lg: '225px' }}
          fontSize={{ sm: '8px', lg: '12.75px' }}
          height={{ sm: '20px', lg: '40px' }}
        >
          APPLY HERE - LIMITED SPACES -
        </ApplicationPageActionButton>
      </Flex>
    </Flex>
  );
};

export default AthletesHero;
