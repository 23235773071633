import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import {
  ApplicationPageLargeText,
  AthleteApplicationPageMobilePadding,
  AthleteApplicationPagePadding
} from "./Athletes";

const ApplicationSection1 = () => {
  return (
    <Flex
      direction={{ sm: 'column', lg: 'row' }}
      padding={{ sm: AthleteApplicationPageMobilePadding, lg: AthleteApplicationPagePadding }}
      gap={{ sm: '2rem', md: '0' }}
      backgroundColor="#221F1D"
    >
      <Flex
        direction="column"
        alignItems='flex-start'
        gap="2rem"
        width={{ sm: '100%', lg: '50%' }}
      >
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontWeight={400}
          fontSize={{sm: "18px", md: "25px"}}
        >
          MNLY X HYROX
        </Text>
        <ApplicationPageLargeText textAlign='left' as='h1'>
          The Ultimate Peak
          <br />
          Performance Partnership
        </ApplicationPageLargeText>
        <Text color="#7D7C7A" fontSize="16px" fontWeight={400}>
          Our collaboration with HYROX is a powerful statement of our
          commitment. It's about empowering men to break barriers, not just in
          competitions but in life. This is a call to action for those who want
          to lead, inspire, and thrive at the pinnacle of their potential.
        </Text>
      </Flex>
      <Box
        width={{ sm: '75%', lg: '50%' }}
        paddingLeft={{ sm: '0', lg: '5.3rem' }}
        paddingRight={{ sm: '0', lg: '5.3rem' }}
      >
        <img
          alt="MNLY Precision Men's Health"
          src={`/static/ads/gym_male.png`}
        />
      </Box>
    </Flex>
  );
};

export default ApplicationSection1;
