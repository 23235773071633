import { Box, Flex, HStack } from '@chakra-ui/react';
import React from 'react';

export interface Props {
  footnote?: React.ReactNode;
  footnoteRight?: React.ReactNode;
}

export default function CardFooter({
  footnote,
  footnoteRight,
}: Props): JSX.Element {
  return (
    <Flex align="center" justify="space-between" p={2} borderBottomWidth="1px">
      <HStack fontSize="sm" color="gray.600">
        <Box>{footnote}</Box>
        <Box textAlign="right">{footnoteRight}</Box>
      </HStack>
    </Flex>
  );
}
