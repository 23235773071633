import React from 'react';
import {
  ButtonGroup,
  Button,
  Modal as ChakraModal,
  ModalProps,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

interface Props extends ModalProps {
  error?: string;
  isSubmitting: boolean;
  onSubmit: () => void;
  submitButtonLabel?: string;
  title: React.ReactNode;
}

export default function FormModal({
  children,
  title,
  error,
  isSubmitting,
  onSubmit,
  submitButtonLabel = 'Submit',
  ...otherProps
}: Props): JSX.Element {
  return (
    <ChakraModal {...otherProps}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            {error ? (
              <Text color="red.600" fontSize="md" me={4}>
                {error}
              </Text>
            ) : null}
            <ButtonGroup>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting}>
                {submitButtonLabel}
              </Button>
              <Button onClick={otherProps.onClose} disabled={isSubmitting}>
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </ChakraModal>
  );
}
