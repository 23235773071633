import React from 'react';
import { Box, Popover, Radio, RadioGroup, Stack } from '..';
import { FilterPopoverContent } from './FilterPopoverContent';
import { FilterPopoverButton } from './FilterPopoverButton';
import { useFilterState } from './useFilterState';

export interface RadioFilterProps {
  defaultValue: string;
  label: string;
  onSubmit?: (values: string) => void;
  options: {
    count?: number;
    label: string;
    value: string;
  }[];
  queryParam: string;
}

export const RadioFilter = ({
  defaultValue,
  label,
  options,
  onSubmit,
  queryParam,
}: RadioFilterProps) => {
  const state = useFilterState({
    defaultValue,
    onSubmit,
    parseValue: v => v,
    queryParam,
    serializeValue: v => v,
  });

  const selected = options.find(
    option => option.value === state.submittedValue
  );

  return (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={label} value={selected?.label} />
      <FilterPopoverContent
        isCancelDisabled={!state.canCancel}
        onClickApply={state.onSubmit}
        onClickCancel={state.onReset}
      >
        <Stack as="fieldset" spacing={2}>
          <RadioGroup
            onChange={v => state.onChange(v)}
            defaultValue={defaultValue}
          >
            <Stack>
              {options.map(option => (
                <Radio
                  key={option.value}
                  value={option.value}
                  colorScheme="blue"
                >
                  <span>{option.label}</span>
                  {option.count != null && (
                    <Box as="span" color="gray.500" fontSize="sm">
                      {' '}
                      ({option.count})
                    </Box>
                  )}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </Stack>
      </FilterPopoverContent>
    </Popover>
  );
};

export default RadioFilter;
