import { Box, Text } from '../../../../ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';

const TotalHealthScore = () => {
  const { stage4 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });

  return (
    <Flex flexDirection="column" bg="#303525" p="1rem" justifyContent="center" gap="1rem">
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontSize="24px"
        lineHeight="90%"
        textTransform="capitalize"
      >
        Total health score
      </Text>
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontSize="80px"
        lineHeight="90%"
        textTransform="capitalize"
      >
        {stage4?.total_output?.total.value}
      </Text>
      <hr
        color="#E3DBD3"
        style={{
          height: '2px',
          borderColor: '#E3DBD3',
        }}
      />
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Regular"
        fontSize="14px"
        lineHeight="130%"
      >
        The total health score is calculated using the lifestyle health results,
        accounting for 25% of the total, and biological health results,
        accounting for 75% of the total, with the overall score ranging from 0
        (not optimal) to 100 (optimal). The lifestyle health results consist of
        eight category scores computed from the health assessment answers.
        Individual answers are scored and then weighted to reflect their
        relative importance within the survey. The biological health results are
        computed similarly using seven category scores. Each biomarker is
        categorized, with influential biomarkers like total testosterone
        assigned to multiple categories to reflect their significant impact on
        overall health, and the category scores are the average of these
        individual inputs.
      </Text>
    </Flex>
  );
};

export default TotalHealthScore;
