import React from 'react';
import { Icon, IconProps } from '../..';

/**
 * The Product logo.
 */
export default function ProductLogo(props: IconProps): JSX.Element {
  return (
    <Icon
      viewBox="0 0 104.18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{props.name}</title>
      <path
        d="M32.2385 21.9736H35.9599V10.9158L32.2385 7.16463V21.9736Z"
        fill="#E3DBD3"
      />
      <path
        d="M34.1524 0.0357208H32.2385V2.72618L51.8024 21.9736H53.7154V19.5409L34.1524 0.0357208Z"
        fill="#E3DBD3"
      />
      <path
        d="M49.9595 11.3156L53.7157 15.1025V0H49.9595V11.3156Z"
        fill="#E3DBD3"
      />
      <path d="M66.751 0H63.1785V15.4104H66.751V0Z" fill="#E3DBD3" />
      <path
        d="M76.5755 18.5355H63.1785V21.9736H76.5755V18.5355Z"
        fill="#E3DBD3"
      />
      <path
        d="M81.9972 0H77.4958L86.8525 12.0148V21.9736H90.6087V11.979L90.6581 11.9169L84.2564 2.99752L81.9972 0Z"
        fill="#E3DBD3"
      />
      <path
        d="M95.6062 0L90.6106 6.48925L92.6742 9.36514L100.072 0H95.6062Z"
        fill="#E3DBD3"
      />
      <path
        d="M104.183 18.0039H99.9297V21.9379H104.183V18.0039Z"
        fill="#E3DBD3"
      />
      <path
        d="M12.9504 7.71752V12.52L19.0978 6.54369C19.0978 7.07446 19.062 8.09944 19.062 9.09039V22H22.7758V0.0722953L20.8662 0.00169373C20.8662 0.00169373 13.141 7.53549 12.9504 7.71922V7.71752Z"
        fill="#E3DBD3"
      />
      <path
        d="M1.9096 0.0705948H0V21.9983H3.71375V9.23074C3.71375 8.13431 3.67801 7.00216 3.67801 6.47224L9.82532 12.3958V7.73879L1.9096 0.0705948Z"
        fill="#E3DBD3"
      />
    </Icon>
  );
}
