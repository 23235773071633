import React from 'react';
import { GridItem, useMediaQuery } from '@chakra-ui/react';
import { Flex, Grid, Text } from '../../../ui';
import { defaultHorizontalGap, defaultVerticalGap } from '../../../constants';
import BuyNowBtn from '../../../components/Buttons/BuyNowBtn';

const title = (
  <Text
    color="#E3DBD3"
    fontFamily="DIN-Bold"
    fontSize="2xl"
    fontStyle="normal"
    lineHeight="130%"
  >
    Personalized Health Plan
  </Text>
);

const description = (
  <Text
    color="#7D7C7A"
    fontFamily="DIN-Regular"
    fontSize="18px"
    fontStyle="normal"
    fontWeight="5"
    lineHeight="150%"
  >
    MNLY analyzes over 100 data points from your health assessment & biological
    analysis. Using machine learning and AI we produce a comprehensive overview
    of your biological and environmental health via our digital health platform.
    Helping you decode, measure and optimize your vitality through clinically
    proven protocols personalized for you based on your results.
  </Text>
);

const image = (
  <Flex justifyContent="center">
    <img
      alt="MNLY Precision Men's Health"
      src="/static/ads/personalized-plan.png"
      width="100%"
      style={{ position: 'relative', maxWidth: '630px', maxHeight: '675px' }}
    />
  </Flex>
);

const orderAndSubscribeBtn = (
  // <Button
  //   _hover={{
  //     textDecoration: 'none',
  //     bg: '#A0856A',
  //   }}
  //   width="15rem"
  //   bg="#685848"
  //   borderRadius="0"
  //   color="#E3DBD3"
  //   fontFamily="DIN-Medium"
  //   fontSize=".85rem"
  //   fontWeight="medium"
  //   lineHeight="normal"
  //   onClick={() => {
  //     window.location.href = baseURL;
  //   }}
  //   position="relative"
  //   px="1.25rem"
  //   py="1.25rem"
  //   textTransform="uppercase"
  // >
  //   ORDER AND SUBSCRIBE
  // </Button>
  <BuyNowBtn />
);

const PersonalizedHealthPlan = () => {
  const [isMobile] = useMediaQuery('(max-width: 992px)');
  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      width="100%"
      gap={defaultHorizontalGap}
    >
      {isMobile ? (
        <GridItem colSpan={{ base: 2 }}>
          <Flex flexDirection="column" gap={defaultVerticalGap}>
            {title}
            {image}
            {description}
            {orderAndSubscribeBtn}
          </Flex>
        </GridItem>
      ) : (
        <>
          <GridItem>{image}</GridItem>
          <GridItem>
            <Flex flexDirection="column" gap={defaultVerticalGap}>
              {title}
              {description}
              {orderAndSubscribeBtn}
            </Flex>
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default PersonalizedHealthPlan;
