import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import Tooltip from '../../Tooltip';

interface Props {
  children: React.ReactNode;
  tooltip?: string;
}

export default function Header({ children, tooltip }: Props): JSX.Element {
  return (
    <>
      <Text as="span">{children}</Text>
      {tooltip ? (
        <Box>
          <Tooltip display="block" label={tooltip} />
        </Box>
      ) : null}
    </>
  );
}
