/* eslint-disable no-nested-ternary */

import React from 'react';
import { useTable, useSortBy, Column, SortingRule } from 'react-table';
import { BoxProps } from '@chakra-ui/react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '..';
import SortIcon from './components/SortIcon';
import Header from './components/Header';
import LinkRow from './components/LinkRow';

// https://chakra-ui.com/guides/integrations/with-react-table

interface BaseColumn {
  align?: BoxProps['textAlign'];
  tooltip?: string;
}

type ExtendedColumn<TData extends Record<string, unknown>> = Column<TData> &
  BaseColumn;

interface Props<TData extends Record<string, unknown>> {
  columns: ExtendedColumn<TData>[];
  data: TData[];
  hideHeaders?: boolean;
  initialSort?: SortingRule<TData>;
  nouns?: [string, string];
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  rowLink?: (data: TData) => string | undefined;
  totalCount?: number;
}

/**
 * A common table design that supports sortable columns.
 */
export default function SortedTable<TData extends Record<string, unknown>>({
  columns,
  data,
  hideHeaders = false,
  initialSort,
  nouns = ['record', 'records'],
  onNextPage,
  onPreviousPage,
  rowLink,
  totalCount,
}: Props<TData>): JSX.Element {
  const dataMemo = React.useMemo(() => data, [data]);
  const columnsMemo = React.useMemo(() => columns, [columns]);
  const bg = useColorModeValue('gray.50', 'gray.800');

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<TData>(
      {
        columns: columnsMemo,
        data: dataMemo,
        initialState: {
          sortBy: initialSort ? [initialSort] : [],
        },
      },
      useSortBy
    );

  const count = dataMemo.length;

  return (
    <>
      <Table borderWidth="1px" {...getTableProps()}>
        {!hideHeaders ? (
          <Thead bg={bg}>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const extendedColumn = column as ExtendedColumn<TData>;
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      whiteSpace="nowrap"
                      scope="col"
                      px={4}
                    >
                      <HStack align="center" spacing={1}>
                        <Header tooltip={extendedColumn.tooltip}>
                          {column.render('Header')}
                        </Header>

                        {column.isSorted ? (
                          <Box color="gray.400" fontSize="16px">
                            <SortIcon
                              isSorted={column.isSorted}
                              isSortedDesc={column.isSortedDesc}
                            />
                          </Box>
                        ) : null}
                      </HStack>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
        ) : null}

        <Tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <LinkRow {...row.getRowProps()} to={rowLink?.(row.original)}>
                {row.cells.map(cell => (
                  <Td {...cell.getCellProps()} px={4}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </LinkRow>
            );
          })}
        </Tbody>
      </Table>

      <Flex align="center" justify="space-between" mt={2}>
        <Text
          color={useColorModeValue('gray.600', 'gray.400')}
          fontSize="16px"
          pl={4}
        >
          {totalCount ? (
            <span>
              Showing {count.toLocaleString()} of {totalCount.toLocaleString()}
            </span>
          ) : (
            <span>{count.toLocaleString()}</span>
          )}{' '}
          {count === 1 ? nouns[0] : nouns[1]}
        </Text>
        <ButtonGroup variant="outline" size="sm">
          {onPreviousPage ? (
            <Button as="a" rel="prev" onClick={onPreviousPage}>
              Previous
            </Button>
          ) : null}
          {onNextPage ? (
            <Button as="a" rel="next" onClick={onNextPage}>
              Next
            </Button>
          ) : null}
        </ButtonGroup>
      </Flex>
    </>
  );
}
