import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps } from '@chakra-ui/react';

const XButton: FC<BoxProps> = ({ ...props }) => {
  const navigate = useNavigate();

  return (
    <Box
      {...props}
      style={{ ...props.style, cursor: 'pointer' }}
      _hover={{ opacity: '75%' }}
      onClick={() => navigate(-1)}
    >
      <img
        alt="MNLY Precision Men's Health"
        src={`/static/icons/close-icon.png`}
        width="100%"
        onClick={() => navigate(-1)}
      />
    </Box>
  );
};

export default XButton;
