import React from 'react';
import {
  Tabs as TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

export interface TabItem {
  content?: React.ReactNode;
  disabled?: boolean;
  id: string;
  label: string;
}

interface Props {
  activeTab?: string;
  isLazy?: boolean;
  onChange?: (id: string) => void;
  tabs: TabItem[];
}

type KeyIndex = { [id: string]: number };

/** A convenience layer around Chakra UI's tab components. */
export default function Tabs({
  activeTab,
  isLazy = false,
  onChange,
  tabs,
}: Props): JSX.Element {
  const keyIndex = React.useMemo<KeyIndex>(() => {
    const results: KeyIndex = {};

    tabs.forEach((tab, i) => {
      results[tab.id] = i;
    });

    return results;
  }, [tabs]);

  const activeIndex = activeTab ? keyIndex[activeTab] : 0;

  return (
    <TabGroup
      defaultIndex={activeIndex}
      isLazy={isLazy}
      onChange={index => {
        onChange?.(tabs[index].id);
      }}
    >
      <TabList borderBottomColor="#7D7C7A" mb={6}>
        {tabs.map(item => {
          return (
            <Tab
              color="#7c7b79"
              fontFamily="DIN-Medium"
              fontSize={{
                MobileS: '0.88rem',
                Tablet: '1.0rem',
                Laptop: '1.0rem',
              }}
              
              isDisabled={item.disabled}
              _selected={{ color: '#e3dbd3', borderBottomColor: '#E3DBD3' }}
              key={item.id}
            >
              {item.label}
            </Tab>
          );
        })}
      </TabList>
      <TabPanels>
        {tabs.map(item => {
          return (
            <TabPanel key={item.id} p={0}>
              {item.content}
            </TabPanel>
          );
        })}
      </TabPanels>
    </TabGroup>
  );
}
