import React from 'react';
import { Box, Checkbox, CheckboxGroup, Popover, Stack } from '..';
import { FilterPopoverButton } from './FilterPopoverButton';
import { FilterPopoverContent } from './FilterPopoverContent';
import { useFilterState } from './useFilterState';

export interface CheckboxFilterProps {
  defaultValue: string[];
  label: string;
  onSubmit?: (values: string[]) => void;
  options: {
    count?: number;
    label: string;
    value: string;
  }[];
  queryParam: string;
}

/**
 * Determines how to show the selected value on the filter button. If only
 * one value is selected, we show the label of that option. Otherwise, we
 * show how many options are selected.
 */
function getButtonValueLabel(
  options: CheckboxFilterProps['options'],
  values: string[]
): string | undefined {
  if (values.length === 0) {
    return undefined;
  }

  const selected = options
    .filter(option => values.includes(option.value))
    .map(option => option.label);

  if (selected.length === 1) {
    return selected[0];
  }

  return `(${values.length.toLocaleString()})`;
}

/**
 * Allows users to select 0 or more filter options as checkboxes.
 */
export const CheckboxFilter = ({
  defaultValue,
  label,
  options,
  onSubmit,
  queryParam,
}: CheckboxFilterProps) => {
  const state = useFilterState<string[]>({
    defaultValue,
    onSubmit,
    parseValue: v => v.split(','),
    queryParam,
    serializeValue: v => v.join(','),
  });

  return (
    <Popover placement="bottom-start">
      <FilterPopoverButton
        label={label}
        value={getButtonValueLabel(options, state.submittedValue)}
      />
      <FilterPopoverContent
        isCancelDisabled={!state.canCancel}
        onClickApply={state.onSubmit}
        onClickCancel={state.onReset}
      >
        <Stack as="fieldset" spacing={2}>
          <CheckboxGroup
            defaultValue={defaultValue}
            onChange={value => {
              state.onChange(value.map(v => `${v}`));
            }}
            value={state.value}
          >
            {options.map(option => (
              <Checkbox
                key={option.value}
                value={option.value}
                colorScheme="blue"
              >
                <span>{option.label}</span>
                {option.count !== undefined && (
                  <Box as="span" color="gray.500" fontSize="sm">
                    {' '}
                    ({option.count})
                  </Box>
                )}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Stack>
      </FilterPopoverContent>
    </Popover>
  );
};

export default CheckboxFilter;
