import React from 'react';
import { Box, Tooltip as BaseTooltip, TooltipProps } from '@chakra-ui/react';

/**
 * Extends the Chakra UI tooltip component to render the standard tooltip icon
 * if no children are otherwise passed to the component. The icon is based on
 * the "help-circle" icon from the Feather Icons libary:
 * https://feathericons.com/?query=help-circle
 */

const defaultIcon = (
  <Box
    as="svg"
    aria-label="tooltip"
    color="gray.400"
    display="inline-block"
    fill="none"
    height="12px"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2.5}
    viewBox="0 0 24 24"
    verticalAlign="middle"
    width="12px"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
    <line x1="12" y1="17" x2="12.01" y2="17" />
  </Box>
);

// Make the children prop optional so we can add the default behavior.
type Props = Omit<TooltipProps, 'children'> & {
  children?: TooltipProps['children'];
};

export default function Tooltip({
  children = defaultIcon,
  ...otherProps
}: Props): JSX.Element {
  return (
    <BaseTooltip {...otherProps} hasArrow fontSize="16px" py={2} px={4}>
      {children}
    </BaseTooltip>
  );
}
