import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import Huge from './components/Huge';
import Normal from './components/Normal';
import Tiny from './components/Tiny';

interface Props extends BoxProps {
  negativeThreshold?: number;
  positiveThreshold?: number;
  size?: 'huge' | 'normal' | 'tiny';
  value: number;
}

/**
 * Ensures the value is rounded and between 0 and 100.
 */
function normalizeValue(value: number): number {
  if (value < 0) {
    return 0;
  }

  if (value > 100) {
    return 100;
  }

  return Math.round(value);
}

export default function Score({
  positiveThreshold = 80,
  negativeThreshold = 50,
  size = 'normal',
  value,
  ...other
}: Props): JSX.Element {
  const normalizedValue = normalizeValue(value);

  if (size === 'tiny') {
    return (
      <Tiny
        positiveThreshold={positiveThreshold}
        negativeThreshold={negativeThreshold}
        value={normalizedValue}
        {...other}
      />
    );
  }

  if (size === 'normal') {
    return (
      <Normal
        positiveThreshold={positiveThreshold}
        negativeThreshold={negativeThreshold}
        value={normalizedValue}
        {...other}
      />
    );
  }

  return (
    <Huge
      positiveThreshold={positiveThreshold}
      negativeThreshold={negativeThreshold}
      value={normalizedValue}
      {...other}
    />
  );
}
