export const QUIZ_FETCH_START = 'QUIZ_FETCH_START';
export const QUIZ_FETCH_FAILED = 'QUIZ_FETCH_FAILED';
export const QUIZ_FETCH_SUCCEED = 'QUIZ_FETCH_SUCCEED';
export const QUIZ_RESET = 'QUIZ_RESET';

export const SESSION_CUSTOMER_FETCH_START = 'SESSION_CUSTOMER_FETCH_START';
export const SESSION_CUSTOMER_SET_STATE = 'SESSION_CUSTOMER_SET_STATE';

export const SESSION_USER_EMULATE_PURCHASE = 'SESSION_USER_EMULATE_PURCHASE';
export const SESSION_USER_LOGGED_IN = 'SESSION_USER_LOGGED_IN';
export const SESSION_USER_LOGGED_OUT = 'SESSION_USER_LOGGED_OUT';
export const SESSION_USER_TOKEN_SET_STATE = 'SESSION_USER_TOKEN_SET_STATE';
export const SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED =
  'SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED';
export const SESSION_QUIZ_SET_ID = 'SESSION_QUIZ_SET_ID';
export const SESSION_QUIZ_SET_CURRENT_QUESTION =
  'SESSION_QUIZ_SET_CURRENT_QUESTION';
export const SESSION_QUIZ_SET_STATE = 'SESSION_QUIZ_SET_STATE';
export const SESSION_QUIZ_SET_NEXT_QUESTION = 'SESSION_QUIZ_SET_NEXT_QUESTION';
export const SESSION_QUIZ_SET_QUIZ_FINISHED = 'SESSION_QUIZ_SET_QUIZ_FINISHED';
export const SESSION_QUIZ_SET_QUIZ_SUBMITTED = 'SESSION_QUIZ_SET_QUIZ_SUBMITTED';
export const SESSION_QUIZ_SET_QUIZ_UNSUBMITTED = 'SESSION_QUIZ_SET_QUIZ_UNSUBMITTED';

export const STAGES_STAGE_1_ACHIEVED = 'STAGES_STAGE_1_ACHIEVED';
export const STAGES_STAGE_1_SET_STATE = 'STAGES_STAGE_1_SET_STATE';
export const STAGES_STAGE_2_ACHIEVED = 'STAGES_STAGE_2_ACHIEVED';
export const STAGES_STAGE_2_SET_STATE = 'STAGES_STAGE_2_SET_STATE';
export const STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB =
  'STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB';
export const STAGES_STAGE_2_EMULATE_SET_REJECTED_BY_LAB =
  'STAGES_STAGE_2_EMULATE_SET_REJECTED_BY_LAB';
export const STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT =
  'STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT';
export const STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT =
  'STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT';
export const STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB =
  'STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB';
export const STAGES_STAGE_3_ACHIEVED = 'STAGES_STAGE_3_ACHIEVED';
export const STAGES_STAGE_3_SET_STATE = 'STAGES_STAGE_3_SET_STATE';
export const STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB =
  'STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB';
export const STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB =
  'STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB';
export const STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB =
  'STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB';

export const STAGES_STAGE_4_ACHIEVED = 'STAGES_STAGE_4_ACHIEVED';
export const STAGES_STAGE_4_SET_RESULTS = 'STAGES_STAGE_4_SET_RESULTS';
export const STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED =
  'STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED';
export const STAGES_STAGE_5_ACHIEVED = 'STAGES_STAGE_5_ACHIEVED';
export const STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION =
  'STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION';
  export const SESSION_SET_CONTINUE = 'SESSION_SET_CONTINUE';
  export const SESSION_UNSET_CONTINUE = 'SESSION_UNSET_CONTINUE';