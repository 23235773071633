import React from 'react';
import { BadgeProps } from '@chakra-ui/react';
import { Box, Badge, VisuallyHidden } from '..';

/**
 * Displays a visual and color indication if a value is trending up or down.
 * Based on the "Stat with Indicator" pattern in the Chakra Pro library.
 * https://pro.chakra-ui.com/components/application-ui/stats
 */

type Type = 'positive' | 'negative' | 'neutral';
type Trend = 'up' | 'down' | 'neutral';

interface CombinedProps extends BadgeProps {
  trend?: Trend;
  type?: Type;
}

// Same props as the Badge except we control the variant and color scheme props.
export type Props = Omit<CombinedProps, 'variant' | 'colorScheme'>;

const typeToColorMap: { [key: string]: string } = {
  negative: 'red',
  neutral: 'gray',
  positive: 'green',
};

// We use the same svg for up and down, just rotated.
const trendToTransform: { [key: string]: string } = {
  down: 'rotate(0deg)',
  neutral: 'rotate(0deg)',
  up: 'rotate(180deg)',
};

export default function StatIndicator({
  children,
  type = 'neutral',
  trend = 'neutral',
  ...otherProps
}: Props): JSX.Element {
  const color = typeToColorMap[type] ?? typeToColorMap.neutral;
  const transform = trendToTransform[trend] ?? trendToTransform.neutral;

  return (
    <Badge
      colorScheme={color}
      fontWeight="bold"
      variant="solid"
      whiteSpace="nowrap"
      {...otherProps}
    >
      <VisuallyHidden>
        Value is {trend} by {children}
      </VisuallyHidden>
      {trend !== 'neutral' ? (
        <Box
          as="svg"
          aria-hidden="true"
          display="inline-block"
          fill="currentColor"
          height="1em"
          mr={1}
          stroke="currentColor"
          strokeWidth="0"
          transform={transform}
          transformOrigin="center"
          viewBox="0 0 16 16"
          verticalAlign="sub"
          width="1em"
        >
          <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
        </Box>
      ) : null}
      {children}
    </Badge>
  );
}
