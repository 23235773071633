import axios from 'axios';
import {
  SESSION_CUSTOMER_FETCH_START,
  SESSION_CUSTOMER_SET_STATE,
  SESSION_USER_EMULATE_PURCHASE,
  SESSION_USER_LOGGED_IN,
  SESSION_USER_LOGGED_OUT,
  SESSION_USER_TOKEN_SET_STATE,
  SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED,
  SESSION_QUIZ_SET_CURRENT_QUESTION,
  SESSION_QUIZ_SET_ID,
  SESSION_QUIZ_SET_STATE,
  SESSION_QUIZ_SET_NEXT_QUESTION,
  SESSION_QUIZ_SET_QUIZ_FINISHED,
  STAGES_STAGE_1_ACHIEVED,
  STAGES_STAGE_1_SET_STATE,
  STAGES_STAGE_2_ACHIEVED,
  STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB,
  STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT,
  STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT,
  STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB,
  STAGES_STAGE_2_SET_STATE,
  STAGES_STAGE_3_ACHIEVED,
  STAGES_STAGE_3_SET_STATE,
  STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB,
  STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB,
  STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB,
  STAGES_STAGE_4_ACHIEVED,
  STAGES_STAGE_4_SET_RESULTS,
  STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED,
  STAGES_STAGE_5_ACHIEVED,
  STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION,
  SESSION_QUIZ_SET_QUIZ_SUBMITTED,
  SESSION_QUIZ_SET_QUIZ_UNSUBMITTED,
  SESSION_SET_CONTINUE,
  SESSION_UNSET_CONTINUE,
} from './actionTypes';
import { toast } from 'react-toastify';

const mock = process.env.MOCK_SOURCES === 'true' || false;
const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';

//User actions
export function updateSessionUser(user: any) {
  return {
    type: SESSION_USER_LOGGED_IN,
    user,
  };
}

export function updateSessionUserToken(token: string) {
  return {
    type: SESSION_USER_TOKEN_SET_STATE,
    token,
  };
}

export function updateSessionQuiz(quiz: any) {
  return {
    type: SESSION_QUIZ_SET_ID,
    quiz,
  };
}

export function userLogOut() {
  return {
    type: SESSION_USER_LOGGED_OUT,
  };
}

//Stages actions
export function stageAchieved(number: number): {
  number: number;
  type: string;
} {
  switch (number) {
    case 1:
      return {
        number: 1,
        type: STAGES_STAGE_1_ACHIEVED,
      };
    case 2:
      return {
        number: 2,
        type: STAGES_STAGE_2_ACHIEVED,
      };
    case 3:
      return {
        number: 3,
        type: STAGES_STAGE_3_ACHIEVED,
      };
    case 4:
      return {
        number: 4,
        type: STAGES_STAGE_4_ACHIEVED,
      };
    case 5:
      return {
        number: 5,
        type: STAGES_STAGE_5_ACHIEVED,
      };
    default:
      throw Error('Not Implemented');
  }
}

export function setContinue() {
  return {
    type: SESSION_SET_CONTINUE,
  };
}

export function setStart() {
  return {
    type: SESSION_UNSET_CONTINUE,
  };
}

export function prevAnswerClick() {
  return (
    dispatch: (arg0: {
      type: string;
      answerState?: any;
      results?: any;
      activeQuestionNumber?: any;
      currentQuizQuestion?: any;
    }) => void,
    getState: () => {
      session: any;
      quiz: any;
    }
  ) => {
    const state = getState();

    let activeQuestionNumber = state.session.quiz.activeQuestionNumber;

    const timeout = window.setTimeout(async () => {
      let prevQuestionNumber = activeQuestionNumber - 1;
      dispatch(
        quizPrevNextQuestion(
          prevQuestionNumber,
          Object.entries(state.quiz.quiz.questions)
          // @ts-ignore
          //.filter(key => key[1].factorId !== 'NA')
          .map(entry => {
            return entry[1];
          })[prevQuestionNumber]
        )
      );

      window.clearTimeout(timeout);
    }, 500);
  };
}

//Stages -> Stage1 actions
export function quizAnswerClick(answer: any) {
  return (
    dispatch: (arg0: {
      type: string;
      answerState?: any;
      results?: any;
      activeQuestionNumber?: any;
      currentQuizQuestion?: any;
    }) => void,
    getState: () => {
      session: any;
      quiz: any;
    }
  ) => {
    const state = getState();

    // TODO:
    // let currentState = state.answerState;
    // if (currentState) {
    //   const key = Object.keys(currentState)[0];
    //   if (currentState[key] === 'answered') return;
    // }

    let activeQuestionNumber = state.session.quiz.activeQuestionNumber;
    let isFinalQuestion =
      activeQuestionNumber + 1 ===
      Object.entries(state.quiz.quiz.questions).length;

    let results = state.session.quiz.results;

    // if (!results[state.session.quiz.currentQuizQuestion.id])
      results[state.session.quiz.currentQuizQuestion.id] = answer.text;

    dispatch(
      quizSetState(
        { [state.session.quiz.currentQuizQuestion.id]: answer.text },
        results
      )
    );
  };
}

export function saveAnswerClick() {
  return (
    dispatch: (arg0: {
      type: string;
      answerState?: any;
      results?: any;
      activeQuestionNumber?: any;
      currentQuizQuestion?: any;
    }) => void,
    getState: () => {
      session: any;
      quiz: any;
    }
  ) => {
    const state = getState();

    let activeQuestionNumber = state.session.quiz.activeQuestionNumber;
    let isFinalQuestion =
      activeQuestionNumber + 1 ===
      Object.entries(state.quiz.quiz.questions).length;

    const timeout = window.setTimeout(async () => {

      if (isFinalQuestion) {
          
        dispatch(quizSubmitted());
        try {
          const putResult = await axios.put(
            `${baseURL}${
              mock ? 'response.json' : 'response/' + state.session.quiz.id
            }`,
            {
              answers: state.session.quiz.results,
              type: 'all',
            },
            { headers: { Authorization: state.session.user.token } }
          );

          if (putResult.status != 200) {
            throw Error("Response was not 200")
          } else {
            dispatch(stageAchieved(1));
            dispatch(finishQuiz());
          }
        } catch (error) {
          console.error("Error submitting final question:", error);
          toast.error(
            'There was an error submitting the survey response.  Please try again.',
            {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          );
          dispatch(quizUnSubmitted());
          dispatch(
            quizSetCurrentQuestion(
              Object.entries(state.quiz.quiz.questions).map(entry => {
                  return entry[1];
                })[0]
            )
          );
        }
      } else {
        if(state.session.quiz.results) {
          try {
            const putResult = await axios.put(
              `${baseURL}${
                mock ? 'response.json' : 'response/' + state.session.quiz.id
              }`,
              {
                answers: JSON.stringify(state.session.quiz.results),
                type: 'multiple',
              },
              { headers: { Authorization: state.session.user.token } }
            );
  
            if (putResult.status != 200) {
              throw Error("Response was not 200")
            } else {
              dispatch(quizUnSubmitted());
            }
          } catch (error) {
            console.error("Error saving intermediate question:", error);
            toast.error(
              'There was an error saving the survey response.  Please try again.',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              }
            );
          }
        }
        let nextQuestionNumber = activeQuestionNumber + 1;
        dispatch(
          quizPrevNextQuestion(
            nextQuestionNumber,
            Object.entries(state.quiz.quiz.questions)
              // @ts-ignore
              //.filter(key => key[1].factorId !== 'NA')
              .map(entry => {
                return entry[1];
              })[nextQuestionNumber]
          )
        );
      }

      window.clearTimeout(timeout);
    }, 500);
  }
}

export function quizSetCurrentQuestion(question: string) {
  return {
    type: SESSION_QUIZ_SET_CURRENT_QUESTION,
    question,
  };
}
export function quizSetState(
  answerState: { [x: number]: string },
  results: any
) {
  return {
    type: SESSION_QUIZ_SET_STATE,
    answerState,
    results,
  };
}
export function quizPrevNextQuestion(
  nextQuestionNumber: any,
  nextQuizQuestion: any
) {
  return {
    type: SESSION_QUIZ_SET_NEXT_QUESTION,
    activeQuestionNumber: nextQuestionNumber,
    currentQuizQuestion: nextQuizQuestion,
  };
}
export function quizSubmitted() {
  return {
    type: SESSION_QUIZ_SET_QUIZ_SUBMITTED,
  };
}
export function quizUnSubmitted() {
  return {
    type: SESSION_QUIZ_SET_QUIZ_UNSUBMITTED,
  };
}
export function finishQuiz() {
  return {
    type: SESSION_QUIZ_SET_QUIZ_FINISHED,
  };
}
export function emulateQuizCompleted() {
  return {
    type: SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED,
  };
}
export function updateStage1Results(results: {}) {
  return {
    results,
    type: STAGES_STAGE_1_SET_STATE,
  };
}
//Stages -> Stage2 actions
export function emulateReceivedByLab() {
  return {
    type: STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB,
  };
}
export function emulateShippedToPatient() {
  return {
    type: STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT,
  };
}
export function emulateDeliveredToPatient() {
  return {
    type: STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT,
  };
}
export function emulateShippedToLab() {
  return {
    type: STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB,
  };
}
export function updateStage2Results(results: {}) {
  return {
    results,
    type: STAGES_STAGE_2_SET_STATE,
  };
}
//Stages -> Stage3 actions
export function emulateDeliveredToLab() {
  return {
    type: STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB,
  };
}
export function emulatePartiallyResultedByLab() {
  return {
    type: STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB,
  };
}
export function emulateFullyReultedByLab() {
  return {
    type: STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB,
  };
}
export function updateStage3Results(results: {}) {
  return {
    results,
    type: STAGES_STAGE_3_SET_STATE,
  };
}
//Stages -> Stage4 actions
export function emulatePlanDeveloped(results: {}) {
  return {
    results,
    type: STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED,
  };
}
export function updateStage4Results(results: {}) {
  return {
    results,
    type: STAGES_STAGE_4_SET_RESULTS,
  };
}
//Stages -> Stage5 actions
export function emulateSupplementsInFormulation() {
  return {
    type: STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION,
  };
}

export function emulatePurchase() {
  return {
    type: SESSION_USER_EMULATE_PURCHASE,
  };
}

//Customer Actions
export function fetchCustomer(customer: {}) {
  return {
    type: SESSION_CUSTOMER_FETCH_START,
    customer,
  };
}

export function updateCustomer(customer: {}) {
  return {
    type: SESSION_CUSTOMER_SET_STATE,
    customer,
  };
}
