import React, { useState, useEffect } from 'react';
import { ContainedText, Flex, IconButton } from '..';
import Icon from '../Icon';
import dayjs from '../../helpers/dayjs';

interface Props {
  onClick: () => void;
  timestamp?: string | null;
}

export default function RefreshControl({
  timestamp: ts,
  onClick,
}: Props): JSX.Element {
  const [fromNow, setFromNow] = useState<string | undefined>();
  let interval: NodeJS.Timeout;

  const onClickHook = () => {
    clearInterval(interval);
    setFromNow(undefined);
    onClick();
  };

  useEffect(() => {
    if (ts) {
      setFromNow(dayjs(ts).fromNow());
      interval = setInterval(
        () => ts && setFromNow(dayjs(ts).fromNow()),
        5 * 1000
      );

      return () => {
        clearInterval(interval);
      };
    }

    return undefined;
  }, [ts]);
  return (
    <Flex display="inline-flex" flexWrap="wrap" gap="2">
      {fromNow && <ContainedText>Last refreshed {fromNow}.</ContainedText>}
      <IconButton
        isLoading={!fromNow}
        aria-label="Refresh records."
        icon={<Icon glyph="refresh" />}
        onClick={onClickHook}
        variant="outline"
      />
    </Flex>
  );
}
