import React from 'react';
import { Flex } from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionSubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const Step2 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-2"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <StepLabel>Step 2</StepLabel>
      <InstructionTitle>Get Hydrated</InstructionTitle>
      <InstructionSubtext> Drink at least 16oz of water.</InstructionSubtext>

      <Flex justifyContent="center" alignItems="center" width="100px">
        <img alt={'hydrate'} src={'/static/icons/hydrate.png'} width={'100%'} />
      </Flex>
      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step2;
