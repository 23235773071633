export const defaultHorizontalGap = '2rem';
export const defaultVerticalGap = '2rem';
export const defaultLargeVerticalGap = '4rem';

export const globalHorizontalPadding = '4rem';
export const globalHorizontalMobilePadding = '1rem';
export const smallGap = '1rem';

export type Factor = {
  [key: string]: {
    title: string;
    color: string;
    pieCoords: { y: number; z: number };
  };
};

export const bioFactors: Factor = {
  cardiometabolic: {
    title: 'Cardiometabolic Health',
    color: '#434B30',
    pieCoords: {
      y: 100,
      z: 100,
    },
  },

  cognitive: {
    title: 'Cognitive Health',
    color: '#696F59',
    pieCoords: {
      y: 100,
      z: 120,
    },
  },

  hormonal: {
    title: 'Hormonal Health',
    color: '#A1A598',
    pieCoords: {
      y: 100,
      z: 140,
    },
  },

  longevity: {
    title: 'Longevity',
    color: '#C9B9AA',
    pieCoords: {
      y: 100,
      z: 160,
    },
  },

  adrenal: {
    title: 'Adrenal Health',
    color: '#AD967F',
    pieCoords: {
      y: 100,
      z: 180,
    },
  },

  gastrointestinal: {
    title: 'Gastrointestinal Health',
    color: '#927355',
    pieCoords: {
      y: 100,
      z: 200,
    },
  },

  immune: {
    title: 'Immune Health',
    color: '#77502A',
    pieCoords: {
      y: 100,
      z: 220,
    },
  },
};

export const enviroFactors: Factor = {
  focus: {
    title: 'Focus',
    color: '#434B30',
    pieCoords: {
      y: 100,
      z: 100,
    },
  },

  confidence: {
    title: 'Confidence',
    color: '#696F59',
    pieCoords: {
      y: 100,
      z: 120,
    },
  },

  stamina: {
    title: 'Stamina',
    color: '#A1A598',
    pieCoords: {
      y: 100,
      z: 140,
    },
  },

  mood: {
    title: 'Mood',
    color: '#C9B9AA',
    pieCoords: {
      y: 100,
      z: 160,
    },
  },

  sleep: {
    title: 'Sleep',
    color: '#AD967F',
    pieCoords: {
      y: 100,
      z: 180,
    },
  },

  libido: {
    title: 'Libido',
    color: '#927355',
    pieCoords: {
      y: 100,
      z: 200,
    },
  },

  recovery: {
    title: 'Recovery',
    color: '#77502A',
    pieCoords: {
      y: 100,
      z: 220,
    },
  },
};
