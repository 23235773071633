import {
  QUIZ_FETCH_START,
  QUIZ_FETCH_FAILED,
  QUIZ_FETCH_SUCCEED,
  QUIZ_RESET,
} from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  error: null,
  quiz: {},
};

export default function quizReducer(
  state = initialState,
  action: {
    error: any;
    quiz: any;
    type: any;
  }
) {
  switch (action.type) {
    case QUIZ_FETCH_START:
      return {
        ...state,
        isLoading: true,
      };
    case QUIZ_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case QUIZ_FETCH_SUCCEED:
      return {
        ...state,
        isLoading: false,
        quiz: action.quiz,
      };
    case QUIZ_RESET:
      return {
        ...state,
        quiz: {},
      };

    default:
      return state;
  }
}
