import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Instructions from './Instructions';

export default function InstructionsPage(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Instructions />} />
    </Routes>
  );
}
