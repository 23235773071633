import React from 'react';
import { Flex, Grid, GridItem } from '@chakra-ui/react';
import { defaultVerticalGap } from '../../../constants';
import { InstructionStageProps } from './Step0';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionTinySubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const ImageWithText = ({
  text,
  iconName,
}: {
  text: string;
  iconName: string;
}) => (
  <GridItem width="113px" height="100%">
    <Flex flexDirection="column" justifyContent="space-around" gap="0.5rem">
      <Flex justifyContent="center" alignItems="center" height="113px">
        <img
          alt={iconName}
          src={`/static/icons/${iconName}.png`}
          width={'100%'}
        />
      </Flex>
      <InstructionTinySubtext> {text}</InstructionTinySubtext>
    </Flex>
  </GridItem>
);

const items = [
  { text: 'Lancet (x3)', iconName: 'lancet' },
  { text: 'Collection device (x1)', iconName: 'collection_device' },
  { text: 'Alcohol Pad (x3)', iconName: 'alcohol_pad' },
  { text: 'Bandage (x3)', iconName: 'bandage' },
  { text: 'Sample Bag (x1)', iconName: 'sample_bag' },
  { text: 'Return envelope (x1)', iconName: 'return_envelope' },
];
const Step1 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-1"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
      maxWidth="450px"
    >
      <StepLabel>Step 1</StepLabel>

      <InstructionTitle> Review what's in your kit</InstructionTitle>

      <Grid
        templateColumns={{
          base: 'repeat(3, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        border="2px solid #7D7C7A"
        padding={'1.5rem'}
        columnGap={'3rem'}
        rowGap={'2rem'}
      >
        {items.map(item => (
          <ImageWithText
            key={item.iconName}
            text={item.text}
            iconName={item.iconName}
          />
        ))}
      </Grid>
      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step1;
