/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from '..';
import Icon from '../Icon';
import type { LinkEntry, NavigationEntry } from './PageContext';

interface NavigationPopoverProps {
  /** The label to show in the navigation menu. Typically the page name. */
  label?: string;
  /** A list of external links to show below the navigation menu. */
  links: LinkEntry[];
  /** A list of internal links to show in the navigation menu. */
  navigation: NavigationEntry[];
}

/**
 * Renders the navigation menu and external links as a popover menu for smaller
 * screens who don't have room to render the expanded menu.
 */
export function NavigationPopover({
  navigation,
  label,
  links,
}: NavigationPopoverProps) {
  const navigate = useNavigate();

  if (navigation.length === 0) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        background="white"
        borderRadius="md"
        borderWidth="1px"
        px={4}
        py={2}
        transition="all 200ms"
      >
        <HStack spacing={2}>
          <Icon glyph="menu" boxSize="4" />
          <Text>{label ?? 'Navigate'}</Text>
          <Icon glyph="chevron-down" boxSize="4" />
        </HStack>
      </MenuButton>
      <MenuList>
        {navigation.map(item => {
          return (
            <MenuItem
              onClick={() => {
                navigate(item.to);
              }}
              key={item.to}
            >
              <HStack spacing={2}>
                <Icon glyph={item.icon} boxSize="4" />
                <Text>{item.label}</Text>
              </HStack>
            </MenuItem>
          );
        })}

        {navigation.length > 0 && links.length > 0 ? <MenuDivider /> : null}

        {links.map(item => {
          return (
            <MenuItem
              onClick={() => {
                window.open(item.href, '_blank');
              }}
              key={item.href}
            >
              <HStack spacing={2}>
                <Icon glyph={item.icon} boxSize="4" />
                <Text>{item.label}</Text>
                <Icon glyph="external-link" boxSize="3" />
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

export default NavigationPopover;
