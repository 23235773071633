import React from 'react';
import AnswersList from '../AnswersList/AnswersList';
import AnswerRange from '../AnswerRange/AnswerRange';
import AnswerDropdown from '../AnswerDropdown/AnswerDropdown';
import AnswerMultiple from '../AnswerMultiple/AnswerMultiple';
import { Box, Button, IconButton , Progress, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons'

const ActiveQuiz = (props: {
  questionNumber: {} | null | undefined;
  question:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  quizLength:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  questionFactor: string;
  answers: any;
  questionType: string;
  questionResult: any;
  onPrevAnswerClick: any;
  onAnswerClick: any;
  onSaveClick: any;
  answerState?: any;
}) => {
  const progress =
    (((props.questionNumber as unknown as number) - 1) /
      (!props.quizLength ? 0 : (props.quizLength as unknown as number))) *
    100;

  return (
    <>
      <Progress value={progress} size="sm" mr="1rem" bg="#E3DBD3" mt=".5rem" />
      <Text
        style={{
          color: '#E3DBD3',
          fontFamily: 'DIN-Bold',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: 'normal',
          paddingTop: '.5rem',
          marginRight: '1rem',
        }}
      >
        {(props.questionNumber as unknown as number) }/{props.quizLength}
      </Text>
      
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={4} mr={4}>
        <Box display="flex" gap={4} alignItems="center">
          {props.questionNumber > 1 ? <IconButton variant="outline" colorScheme="whiteAlpha" icon={<ArrowBackIcon />} onClick={props.onPrevAnswerClick}>Prev</IconButton > : null}
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Bold"
            fontSize="32px"
            fontStyle="normal"
            lineHeight="normal"
            my="2rem"
          >
            {props.question}
          </Text>
        </Box>
       {/* {props.questionNumber > 0 ? <Button colorScheme="whiteAlpha" borderRadius={0} onClick={props.onSaveClick}>Next</Button> : null} */}
      </Box>
      
      {props.questionType == 'scroll' && 
      <AnswerRange
        answers={{
          "min":props.answers.min,
          "max":props.answers.max
        }}
        result={props.questionResult}
        onAnswerClick={props.onAnswerClick}
        onSaveClick={props.onSaveClick}
      />
      }
      {props.questionType == 'single' && 
      <AnswersList
        answers={props.answers}
        result={props.questionResult}
        onAnswerClick={props.onAnswerClick}
        onSaveClick={props.onSaveClick}
        // answerState={props.answerState}
      />}

      {props.questionType == 'dropdown' && 
      <AnswerDropdown
      answers={props.answers}
      result={props.questionResult}
      onAnswerClick={props.onAnswerClick}
      onSaveClick={props.onSaveClick}
    />}
    {props.questionType == 'multiple' && 
      <AnswerMultiple
      answers={props.answers}
      onAnswerClick={props.onAnswerClick}
      result={props.questionResult}
      // Key is needed to reset state if multiple questions
      // of the same type are next to each other
      // This mostly matters only here since the state is set
      // based on an array that is added to or removed from...
      // state isn't being set to a specific value like with other questions
      key={props.questionNumber as unknown as number}
      onSaveClick={props.onSaveClick}
    />}
    </>
  );
};

export default ActiveQuiz;