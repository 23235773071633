import React, { useEffect } from 'react';
import NativeHighcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsHistogram from 'highcharts/modules/histogram-bellcurve';
import HighchartsReact from 'highcharts-react-official';
import { Box } from '..';

// Load optional Highcart modules.
HighchartsMore(NativeHighcharts);
HighchartsHistogram(NativeHighcharts);

export type HighchartsOptions = NativeHighcharts.Options;

export interface HighchartsProps {
  defaultHeight?: number;
  options: HighchartsOptions;
}

/**
 * The relative/absolute positioned wrappers are necessary for Highcharts
 * to handle responsive size changes correctly. See:
 * https://stackoverflow.com/questions/25934907/
 */

export default function Highcharts({
  defaultHeight = 400,
  options,
}: HighchartsProps): JSX.Element {
  const chartRef = React.useRef<HighchartsReact.RefObject>(null);

  /**
   * Fix bug where sometimes the chart appears squashed. On first render, wait
   * 10 milliseconds then reflow the chart.
   */
  useEffect(() => {
    setTimeout(() => {
      chartRef.current?.chart.reflow();
    }, 10);
  }, [options]);

  return (
    <Box
      position="relative"
      width="100%"
      height={options?.chart?.height ?? `${defaultHeight}px`}
    >
      <Box position="absolute" width="100%">
        <HighchartsReact
          highcharts={NativeHighcharts}
          options={options}
          ref={chartRef}
        />
      </Box>
    </Box>
  );
}
