import React from 'react';
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
} from 'react-router-dom';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

export type Props = ChakraLinkProps & RouterLinkProps;

export default function Link(props: Props): JSX.Element {
  return <ChakraLink as={RouterLink} {...props} />;
}
