import { Box, Text } from '../../../ui';
import React from 'react';
import {
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  useMediaQuery,
} from '@chakra-ui/react';
import MethodologySection from './MethodologySection';
import { defaultVerticalGap } from '../../../constants';

const content = {
  bio: {
    text: "Biological analysis offers essential insights into an individual's distinct physiology and predispositions, enabling a comprehensive understanding of various biomarkers, such as hormonal levels, micronutrient status, metabolic markers, inflammatory indicators, and genetic variations.",
    icon: '/static/icons/bio-methodology-icon.svg',
  },
  nutrition: {
    text: "Nutrition analysis involves an overview of teh individual's diet, considering their unique genetic, metabolic, and lifestyle factors. It aims to identify scientific nutritional needs and address deficiencies or intolerances.",
    icon: '/static/icons/nutritional-methodology-icon.svg',
  },
  lifestyle: {
    text: "Lifestyle analysis offers essential insights into an individual's distinct physiology and predispositions, enabling a comprehensive understanding of various biomarkers, such as hormonal levels, micronutrient status, metabolic markers, inflammatory indicators, and genetic variations.",
    icon: '/static/icons/lifestyle-methodology-icon.svg',
  },
  clinical: {
    text: 'Clinical research studies is a foundational pillar in formulating precision health solutions and allows for the evaluation of different interventions to determine their effectiveness and suitability as the solution.',
    icon: '/static/icons/clinical-methodology-icon.svg',
  },
};

const OurMethodology = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex id="OurMethodology" flexDirection="column" gap={defaultVerticalGap}>
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Bold"
        fontSize="2xl"
        fontStyle="normal"
        lineHeight="130%"
        textAlign={isMobile ? 'center' : undefined}
      >
        Our Methodology
      </Text>
      <Box>
        {isMobile ? (
          <Flex flexDirection="column" justifyContent="center" gap="2rem">
            {Object.keys(content).map((item, index) => (
              <>
                <MethodologySection
                  key={`methodology-section-${index}`}
                  iconPath={content[item as keyof typeof content].icon}
                  text={content[item as keyof typeof content].text}
                  mobile
                />
                {index < 3 && (
                  <Center height="75px">
                    <Divider
                      orientation="vertical"
                      borderColor="#3E362E"
                      borderWidth="2px"
                    />
                  </Center>
                )}
              </>
            ))}
          </Flex>
        ) : (
          <Grid
            templateColumns="repeat(2, 2fr)"
            bgColor="#3E362E"
            gridGap="2px"
          >
            {Object.keys(content).map((item, index) => (
              <GridItem
                key={`methodology-section-${index}`}
                padding={{ base: '4rem', md: '1rem', lg: '3rem' }}
                bgColor="#141414"
                style={{ display: 'flex' }}
              >
                <MethodologySection
                  iconPath={content[item as keyof typeof content].icon}
                  text={content[item as keyof typeof content].text}
                />
              </GridItem>
            ))}
          </Grid>
        )}
      </Box>
    </Flex>
  );
};

export default OurMethodology;
