import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { Text } from '../../../ui';
import SupplementCard from '../components/SupplementCard';

const SupplementsPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { stage4 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });

  const [email, setEmail] = React.useState('');

  return (
    <>
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontSize="24px"
        fontStyle="normal"
        lineHeight="111%"
        textTransform="capitalize"
      >
        Your custom Nutrient formula{' '}
      </Text>

      <Flex
        gap={4}
        mt="2rem"
        overflow="hidden"
        flexWrap="wrap"
        display="flex"
        ml={{ MobileS: '2rem', MobileM: '0rem' }}
        justifyContent={{
          MobileS: 'center',
          MobileL: 'center',
          Tablet: 'center',
          Laptop: 'flex-start',
        }}
      >
        {stage4.biomarker_output?.supplement_info['Artichoke Extract'] &&
          stage4.biomarker_output.supplement_info['Artichoke Extract'].time.map(
            (time: string) => (
              <SupplementCard
                name="Artichoke Extract"
                philosophy={
                  stage4.biomarker_output.supplement_info['Artichoke Extract']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Artichoke Extract']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'Artichoke Extract'
                  ].units.toUpperCase()
                }
              />
            )
          )}

        {stage4.biomarker_output?.supplement_info['Berberine'] &&
          stage4.biomarker_output.supplement_info['Berberine'].time.map(
            (time: string) => (
              <SupplementCard
                name="Berberine"
                philosophy={
                  stage4.biomarker_output.supplement_info['Berberine']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Berberine'].dosage +
                  stage4.biomarker_output.supplement_info[
                    'Berberine'
                  ].units.toUpperCase()
                }
              />
            )
          )}

        {stage4.biomarker_output?.supplement_info['Bergamot Extract'] &&
          stage4.biomarker_output.supplement_info['Bergamot Extract'].time.map(
            (time: string) => (
              <SupplementCard
                name="Bergamot Extract"
                philosophy={
                  stage4.biomarker_output.supplement_info['Bergamot Extract']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Bergamot Extract']
                    ?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'Bergamot Extract'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info[
          'Bladderwrack Extract (Fucus Vesiculosus)'
        ] &&
          stage4.biomarker_output.supplement_info[
            'Bladderwrack Extract (Fucus Vesiculosus)'
          ].time.map((time: string) => (
            <SupplementCard
              name="Bladderwrack Extract"
              philosophy={
                stage4.biomarker_output.supplement_info[
                  'Bladderwrack Extract (Fucus Vesiculosus)'
                ]?.philosophy
              }
              timeOfDay={time.toUpperCase()}
              weightInMilligrams={
                stage4.biomarker_output.supplement_info[
                  'Bladderwrack Extract (Fucus Vesiculosus)'
                ]?.dosage +
                stage4.biomarker_output.supplement_info[
                  'Bladderwrack Extract (Fucus Vesiculosus)'
                ]?.units.toUpperCase()
              }
            />
          ))}
        {stage4.biomarker_output?.supplement_info['Boron Citrate'] &&
          stage4.biomarker_output.supplement_info['Boron Citrate'].time.map(
            (time: string) => (
              <SupplementCard
                name="Boron Citrate"
                philosophy={
                  stage4.biomarker_output.supplement_info['Boron Citrate']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Boron Citrate']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'Boron Citrate'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Cinnulin'] &&
          stage4.biomarker_output.supplement_info['Cinnulin'].time.map(
            (time: string) => (
              <SupplementCard
                name="Cinnulin"
                philosophy={
                  stage4.biomarker_output.supplement_info['Cinnulin']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Cinnulin']?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'Cinnulin'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Coenzyme Q10'] &&
          stage4.biomarker_output.supplement_info['Coenzyme Q10'].time.map(
            (time: string) => (
              <SupplementCard
                name="Coenzyme Q10"
                philosophy={
                  stage4.biomarker_output.supplement_info['Coenzyme Q10']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Coenzyme Q10']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'Coenzyme Q10'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info &&
          stage4.biomarker_output.supplement_info[
            'Cordyceps Mushroom Extract'
          ] &&
          stage4.biomarker_output.supplement_info[
            'Cordyceps Mushroom Extract'
          ].time.map((time: string) => (
            <SupplementCard
              name="Cordyceps Mushroom Extract"
              philosophy={
                stage4.biomarker_output.supplement_info[
                  'Cordyceps Mushroom Extract'
                ]?.philosophy
              }
              timeOfDay={time.toUpperCase()}
              weightInMilligrams={
                stage4.biomarker_output.supplement_info[
                  'Cordyceps Mushroom Extract'
                ]?.dosage +
                stage4.biomarker_output.supplement_info[
                  'Cordyceps Mushroom Extract'
                ]?.units.toUpperCase()
              }
            />
          ))}
        {stage4.biomarker_output?.supplement_info['Fadogia Agrestis'] &&
          stage4.biomarker_output.supplement_info['Fadogia Agrestis'].time.map(
            (time: string) => (
              <SupplementCard
                name="Fadogia Agrestis"
                philosophy={
                  stage4.biomarker_output.supplement_info['Fadogia Agrestis']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Fadogia Agrestis']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'Fadogia Agrestis'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Forskolin Extract'] &&
          stage4.biomarker_output.supplement_info['Forskolin Extract'].time.map(
            (time: string) => (
              <SupplementCard
                name="Forskolin Extract"
                philosophy={
                  stage4.biomarker_output.supplement_info['Forskolin Extract']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Forskolin Extract']
                    ?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'Forskolin Extract'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Glutathione'] &&
          stage4.biomarker_output.supplement_info['Glutathione'].time.map(
            (time: string) => (
              <SupplementCard
                name="Glutathione"
                philosophy={
                  stage4.biomarker_output.supplement_info['Glutathione']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Glutathione']
                    ?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'Glutathione'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['L-Phenylalanine'] &&
          stage4.biomarker_output.supplement_info['L-Phenylalanine'].time.map(
            (time: string) => (
              <SupplementCard
                name="L-Phenylalanine"
                philosophy={
                  stage4.biomarker_output.supplement_info['L-Phenylalanine']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['L-Phenylalanine']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'L-Phenylalanine'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['L-Tyrosine'] &&
          stage4.biomarker_output.supplement_info['L-Tyrosine'].time.map(
            (time: string) => (
              <SupplementCard
                name="L-Tyrosine"
                philosophy={
                  stage4.biomarker_output.supplement_info['L-Tyrosine']
                    ?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['L-Tyrosine']
                    ?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'L-Tyrosine'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['NAC'] &&
          stage4.biomarker_output.supplement_info['NAC'].time.map(
            (time: string) => (
              <SupplementCard
                name="NAC"
                philosophy={
                  stage4.biomarker_output.supplement_info['NAC'].philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['NAC'].dosage +
                  stage4.biomarker_output.supplement_info[
                    'NAC'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Taurine'] &&
          stage4.biomarker_output.supplement_info['Taurine'].time.map(
            (time: string) => (
              <SupplementCard
                name="Taurine"
                philosophy={
                  stage4.biomarker_output.supplement_info['Taurine']?.philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Taurine']?.dosage +
                  stage4.biomarker_output.supplement_info[
                    'Taurine'
                  ]?.units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Tongkat Ali'] &&
          stage4.biomarker_output.supplement_info['Tongkat Ali'].time.map(
            (time: string) => (
              <SupplementCard
                name="Tongkat Ali"
                philosophy={
                  stage4.biomarker_output.supplement_info['Tongkat Ali']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Tongkat Ali']
                    .dosage +
                  stage4.biomarker_output.supplement_info[
                    'Tongkat Ali'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info[
          'Vitamin B12 (Adenosylcobalamin)'
        ] &&
          stage4.biomarker_output.supplement_info[
            'Vitamin B12 (Adenosylcobalamin)'
          ].time.map((time: string) => (
            <SupplementCard
              name="Vitamin B12"
              philosophy={
                stage4.biomarker_output.supplement_info[
                  'Vitamin B12 (Adenosylcobalamin)'
                ]?.philosophy
              }
              timeOfDay={time.toUpperCase()}
              weightInMilligrams={
                stage4.biomarker_output.supplement_info[
                  'Vitamin B12 (Adenosylcobalamin)'
                ]?.dosage +
                stage4.biomarker_output.supplement_info[
                  'Vitamin B12 (Adenosylcobalamin)'
                ]?.units.toUpperCase()
              }
            />
          ))}
        {stage4.biomarker_output?.supplement_info['Vitamin C'] &&
          stage4.biomarker_output.supplement_info['Vitamin C'].time.map(
            (time: string) => (
              <SupplementCard
                name="Vitamin C"
                philosophy={
                  stage4.biomarker_output.supplement_info['Vitamin C']
                    .philosophy
                }
                timeOfDay={time.toUpperCase()}
                weightInMilligrams={
                  stage4.biomarker_output.supplement_info['Vitamin C'].dosage +
                  stage4.biomarker_output.supplement_info[
                    'Vitamin C'
                  ].units.toUpperCase()
                }
              />
            )
          )}
        {stage4.biomarker_output?.supplement_info['Vitamin D3 5000 IU'] &&
          stage4.biomarker_output.supplement_info[
            'Vitamin D3 5000 IU'
          ].time.map((time: string) => (
            <SupplementCard
              name="Vitamin D3"
              philosophy={
                stage4.biomarker_output.supplement_info['Vitamin D3 5000 IU']
                  ?.philosophy
              }
              timeOfDay={time.toUpperCase()}
              weightInMilligrams={
                stage4.biomarker_output.supplement_info['Vitamin D3 5000 IU']
                  ?.dosage +
                stage4.biomarker_output.supplement_info[
                  'Vitamin D3 5000 IU'
                ]?.units.toUpperCase()
              }
            />
          ))}
      </Flex>
    </>
  );
};

function mapStateToProps(state: { session: any }) {
  return {
    stages: state.session.stages,
  };
}

export default connect(mapStateToProps, {})(SupplementsPage);
