import {Alert, useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Flex, Text} from '../../ui';
import PageBoundary from '../../ui/ErrorBoundary';
import ProductLogo from '../../ui/Shell/components/ProductLogo';
import RightArrow from "../../components/RightArrow";
import XButton from "../../components/XButton";
import Copyright from "../../components/Copyright";

const LoginInstructions = () => {
    const [isMobile] = useMediaQuery("(max-width: 768px)")
    const navigate = useNavigate();
    const [arrowIsHover, setArrowIsHover] = React.useState(false);
    return (
        <PageBoundary>
            <Flex direction="row" h="100vh" overflowY="hidden">
                {!isMobile && <Box
                    bgImage={'/static/ads/earth.png'}
                    bgRepeat="no-repeat"
                    bgSize="cover"
                    bgColor="current"
                    flex="1"
                    width="100%"
                />}
                <Flex
                    flex="1"
                    bg="#141414"
                    alignItems="center"
                    justifyContent="space-between"
                    direction="column"
                    pt="6rem"
                    paddingX="2%"
                    style={{position: "relative"}}
                >
                    <XButton style={{position: "absolute", top: "2%", right: "2%"}}/>
                    <Flex alignItems="center"
                          justifyContent="space-between"
                          direction="column">
                        <Link to="/">
                            <ProductLogo
                                boxSize="104px"
                                display="block"
                                name="MNLY"
                                position="relative"
                                top="-1.05rem"
                            />
                        </Link>
                        <Text
                            color="#E3DBD3"
                            fontFamily="DIN-Medium"
                            fontSize="32px"
                            fontStyle="normal"
                            fontWeight="700"
                            lineHeight="150%"
                            mt="3rem"
                        >
                            Thank you for signing up.
                        </Text>
                        <Text
                            color="#E3DBD3"
                            display="inline"
                            fontFamily="DIN-Regular"
                            fontSize="1.5rem"
                            mt="3rem"
                            textAlign="center"
                        >
                            You should receive an email from us shortly.
                            <br/>
                            <br/>
                            Please click the link below to set your password and sign in to the user
                            portal.
                        </Text>

                        <Alert
                            status="info"
                            bg="rgba(227,219,211,255)"
                            mb="2rem"
                            mt="3rem"
                            mx="10%"
                            maxW="25rem"
                            p="1rem"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="45"
                                height="45"
                                viewBox="0 0 14 15"
                                fill="none"
                            >
                                <mask
                                    id="mask0_2327_4736"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="14"
                                    height="15"
                                >
                                    <rect y="0.5" width="14" height="14" fill="#D9D9D9"/>
                                </mask>
                                <g mask="url(#mask0_2327_4736)">
                                    <path
                                        d="M6.4165 10.4165H7.58317V6.9165H6.4165V10.4165ZM6.99984 5.74984C7.16512 5.74984 7.30366 5.69393 7.41546 5.58213C7.52727 5.47032 7.58317 5.33178 7.58317 5.1665C7.58317 5.00123 7.52727 4.86268 7.41546 4.75088C7.30366 4.63907 7.16512 4.58317 6.99984 4.58317C6.83456 4.58317 6.69602 4.63907 6.58421 4.75088C6.47241 4.86268 6.4165 5.00123 6.4165 5.1665C6.4165 5.33178 6.47241 5.47032 6.58421 5.58213C6.69602 5.69393 6.83456 5.74984 6.99984 5.74984ZM6.99984 13.3332C6.19289 13.3332 5.43456 13.18 4.72484 12.8738C4.01512 12.5675 3.39775 12.1519 2.87275 11.6269C2.34775 11.1019 1.93213 10.4846 1.62588 9.77484C1.31963 9.06512 1.1665 8.30678 1.1665 7.49984C1.1665 6.69289 1.31963 5.93456 1.62588 5.22484C1.93213 4.51512 2.34775 3.89775 2.87275 3.37275C3.39775 2.84775 4.01512 2.43213 4.72484 2.12588C5.43456 1.81963 6.19289 1.6665 6.99984 1.6665C7.80678 1.6665 8.56512 1.81963 9.27484 2.12588C9.98456 2.43213 10.6019 2.84775 11.1269 3.37275C11.6519 3.89775 12.0675 4.51512 12.3738 5.22484C12.68 5.93456 12.8332 6.69289 12.8332 7.49984C12.8332 8.30678 12.68 9.06512 12.3738 9.77484C12.0675 10.4846 11.6519 11.1019 11.1269 11.6269C10.6019 12.1519 9.98456 12.5675 9.27484 12.8738C8.56512 13.18 7.80678 13.3332 6.99984 13.3332Z"
                                        fill="#141414"
                                    />
                                </g>
                            </svg>
                            <Text
                                ml="1rem"
                                color="#141414"
                                fontFamily="DIN-Regular"
                                fontSize="14px"
                                fontStyle="normal"
                                fontWeight="bold"
                                lineHeight="150%"
                            >
                                YOUR TEMPORARY PASSWORD IS YOUR EMAIL ADDRESS YOU USED FOR PURCHASING. PLEASE ENTER YOUR EMAIL AS YOUR USERNAME AND PASSWORD HERE. YOU WILL BE PROMPTED TO CREATE A NEW PASSWORD ON THE NEXT PAGE.
                            </Text>
                        </Alert>
                        <Flex
                            alignItems="center"
                            onClick={() => {
                                navigate('/login');
                            }}
                            onMouseOver={() => setArrowIsHover(true)}
                            onMouseOut={() => setArrowIsHover(false)}
                            cursor="pointer"
                        >
                            <Text
                                color={arrowIsHover ? '#7D7C7A' : '#D7CBC0'}
                                fontFamily="DIN-Regular"
                                fontWeight="700"
                                fontSize="14px"
                                textTransform="uppercase"
                            >
                                GO TO LOGIN
                            </Text>
                            <RightArrow/>
                        </Flex>
                    </Flex>
                    <Copyright/>
                </Flex>
            </Flex>
        </PageBoundary>
    );
};

export default LoginInstructions;
