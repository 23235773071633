import React from 'react';
import { Tr, TableRowProps, useColorModeValue } from '@chakra-ui/react';

interface Props extends TableRowProps {
  isTotal?: boolean;
}

export default function DataTableRow({
  children,
  onClick,
  isTotal = false,
  ...rest
}: Props): JSX.Element {
  // Different colors based on whether dark mode or not
  const rowBgColor = useColorModeValue('gray.50', 'gray.700');
  const totalRowBgColor = useColorModeValue('gray.200', 'gray.600');
  const rowHighlightBgColor = useColorModeValue('blue.50', 'blue.800');
  const rowTextColor = useColorModeValue('black', 'white');

  return (
    <Tr
      color={rowTextColor}
      background={isTotal ? totalRowBgColor : undefined}
      _odd={
        !isTotal
          ? {
              background: rowBgColor,
            }
          : undefined
      }
      _hover={
        onClick
          ? {
              background: rowHighlightBgColor,
              cursor: 'pointer',
            }
          : undefined
      }
      fontWeight={isTotal ? 'bold' : undefined}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Tr>
  );
}
