export default {
  category: {
    // Based on Tableau's "Tableau 10" color palette.
    // https://www.tableau.com/about/blog/2016/7/colors-upgrade-tableau-10-56782
    1: '#4d79a6',
    10: '#bab0ac',
    2: '#f18e2a',
    3: '#e05758',
    4: '#76b7b2',
    5: '#59a24e',
    6: '#ecc949',
    7: '#b07aa1',
    8: '#fe9da6',
    9: '#9c755f',
  },
  heat: {
    // Based on D3's "RdYlGn" color interpolation:
    // https://github.com/d3/d3-scale-chromatic#schemeRdYlGn
    100: '#d73027',
    200: '#fc8d59',
    300: '#fee08b',
    400: '#ffffbf',
    500: '#d9ef8b',
    600: '#91cf60',
    700: '#1a9850',
  },
  paypal: {
    blue: '#009cde', // deprecated
    leftBlue: '#003087',
    middleBlue: '#001c64',
    offWhite: '#faf8f5',
    orange: '#ff9600', // deprecated
    pink: '#de0063', // deprecated
    purple: '#640487', // deprecated
    rightBlue: '#0070e0',
    slate: '#001435',
    yellow: '#ffd140',
  },
  ratings: {
    five: '#15A94F',
    four: '#92C946',
    one: '#DD4228',
    three: '#F8A816',
    two: '#EE6B00',
  },
  sentiment: {
    bad: '#EE6B00',
    good: '#92C946',
    great: '#15A94F',
    neutral: '#F8A816',
    poor: '#DD4228',
  },
  status: {
    aborted: '#888C94',
    error: '#DD4228',
    ok: '#15A94F',
    pending: '#F2C94C',
    running: '#0064C2',
    unknown: '#D8DBDF',
    warning: '#F8A816',
  },
  venmo: {
    blue: { base: '#008cef', dark: '#0064C2', light: '#A8D1FF' },
    cactus: { base: '#00D791', dark: '#008558', light: '#AAEDCD' },
    candy: { base: '#FF5FA0', dark: '#D91261', light: '#FFC1DA' },
    denim: { base: '#28328C', dark: '#1F2451', light: '#A4AACF' },
    ramen: { base: '#ffaa0f', dark: '#A36A00', light: '#FFDBA6' },
    salsa: { base: '#FA414B', dark: '#A11219', light: '#FFC0C2' },
    silver: { base: '#888c94', dark: '#55585E', light: '#D8DBDF' },
    yoga: { base: '#AF50DC', dark: '#772F98', light: '#E0BAF2' },
  },
};

export const tokens = {
  'canvas-accent': 'blue.500',
  'canvas-bg': {
    _dark: 'gray.700',
    default: '#f5f6f7',
  },
  'canvas-text': 'black',
  'shell-accent': 'blue.300',
  'shell-bg': {
    _dark: 'gray.800',
    default: 'gray.700',
  },
  'shell-text': 'gray.300',
  'shell-text-bright': 'white',
  'shell-text-dim': 'gray.500',
};
