/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Td, Skeleton } from '@chakra-ui/react';
import DataTableRow from './DataTableRow';

interface Props {
  cellCount: number;
  rowCount?: number;
}

export default function DataTableLoading({
  rowCount = 5,
  cellCount,
}: Props): JSX.Element {
  return (
    <>
      {[...Array(rowCount)].map((row, i) => (
        <DataTableRow key={i}>
          {[...Array(cellCount)].map((cell, j) => (
            <Td style={{ background: 'none' }} key={j}>
              <Skeleton height={5} />
            </Td>
          ))}
        </DataTableRow>
      ))}
    </>
  );
}
