import React from 'react';
import { Box, Flex } from '../../../../ui';

const boxColors = [
  '#B44B2A',
  '#B9593B',
  '#BD684C',
  '#C2765E',
  '#C7856E',
  '#CB937F',
  '#D0A18F',
  '#D5B0A0',
  '#A3A192',
  '#939381',
  '#838571',
  '#737661',
  '#636851',
  '#535940',
  '#434B30',
];
const ResultsScale = () => {
  return (
    <Flex flexDirection="row" justifyContent="center">
      {boxColors.map(color => (
        <Box key={color} bg={color} boxSize={22} />
      ))}
    </Flex>
  );
};
export default ResultsScale;
