import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  TableState,
  OnChangeFn,
  SortingState,
  PaginationState,
  RowSelectionState,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
  getSortedRowModel,
  getExpandedRowModel,
  Row,
} from '@tanstack/react-table';
import {
  HStack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
  ButtonGroup,
  Button,
  Flex,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import type { IconGlyph } from '../Icon';
import Alert from '../Alert';
import Tooltip from '../Tooltip';
import DataTableLoading from './DataTableLoading';
import DataTableRow from './DataTableRow';
import DataTableExpandIcon from './DataTableExpandIcon';
import DataTableSortIcon from './DataTableSortIcon';
import DataTableSubRowError from './DataTableSubRowError';
import DataTableSelectControl from './DataTableSelectControl';
import Icon from '../Icon';
import { IconButton } from '..';
import DataTableCell from './DataTableCell';

export { createColumnHelper } from '@tanstack/react-table';

export interface RowData<TData> {
  hasUnresolvedSubRows?: boolean;
  id: string;
  // Either parent or subRows (or even both at same time) can be used for organizing rows into hierarchy
  parent?: string | undefined;
  subRows?: TData[];
}

interface ReactTableProps<TData> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[]; // 2nd param needs to be any, for now. Known issue  https://github.com/TanStack/table/issues/4241
  data: TData[];
  initialState?: Pick<
    Partial<TableState>,
    'pagination' | 'rowSelection' | 'sorting'
  >;
  onPaginationChange?: OnChangeFn<PaginationState>;

  onSortingChange?: OnChangeFn<SortingState>;

  pageCount?: number;
  state?: Pick<
    Partial<TableState>,
    'pagination' | 'rowSelection' | 'sorting' | 'columnVisibility'
  >;
}

export interface DataTableProps<TData extends RowData<TData>>
  extends ReactTableProps<TData> {
  /**
   * If bulkSelectActions is passed (doesn't need onSelect also passed), multi-select with checkboxes is enabled and bulk action buttons are rendered in the header bar if at least one thing selected.
   */
  bulkSelectActions?: {
    icon: IconGlyph;
    label: string;
    onClick: (selectedRecords: TData[]) => void;
  }[];
  /**
   * Default false. When true, table shows a toggle button for toggling between hierarchy and flat views. Sometimes user might want to turn off the hierarchy view to be able to sort by individual rows instead of grouped by something. If the table isn't expected to have a hierarchy, this should be set to false.
   */
  canFlattenHierarchy?: boolean;
  dataTestId?: string;
  /**
   * Optional custom empty state message.
   */
  emptyStateMessage?: string;
  /**
   * If an error message is present, and isLoading is false, message will display in the error view. If the data array is empty, the error will show in the main table area. If data array is not empty, that data will show in the main area and the error message will show in a toast.
   */
  error?: string;
  /**
   * If any row has the hasUnresolvedSubRows set to true, this fetchMore function is required. It should call the query to get children of the given record, and on success, the additional data should be included in the data array passed to the Data Table.
   */
  fetchMore?: (record: TData) => Promise<void>;
  /**
   * When true, a loading skeleton shows in the main table area.
   */
  isLoading?: boolean;
  /**
   * Optional event handler that is called when any row of the table is clicked.
   */
  onClick?: (record?: TData) => void;
  /**
   * If onSelect event handler is passed, but not bulkSelectActions, radio button will appear next to each row. Will be ignored if bulkSelectActions is also passed, since the table will become multi-select instead of single-select.
   */
  onSelect?: (record?: TData) => void;
  /**
   * Default is 10. A shorthand prop for setting the pagination page size in initialState.
   */
  pageSize?: number;
  /**
   * Default false. Configures whether to pagination is enabled or not. Default page size is 10. To configure pagination state (including page size), use state.pagination
   */
  paginated?: boolean;
  /**
   * If rowActions are passed, each row will render icon buttons at the end of the row with the chosen glyph, name (shown in hover tooltip), and onClick event handler.
   */
  rowActions?: {
    /**
     * Note: in the future when we need the icons to change based on state (i.e. a favorited item), we can change this prop to also accept a function
     */
    icon: IconGlyph;
    label: string;
    onClick: (record: TData) => void;
    visible?: (record: TData) => boolean;
  }[];
  /**
   * Default false. When true, table will show button to expand/collapse all of the hierarchy. If the table has unresolved subrows that need to be lazy loaded, the toggle button will automatically hide the expand all option (only collapse all is an option), to avoid sending too many requests at once.
   */
  showCollapseAll?: boolean;
  /**
   * The number of levels of the hierarchy that should start pre-expanded when the table loads. Set to -1 to start everything expanded.
   */
  startDepthExpanded?: number;

  /**
   * An array of IDs of rows that should be visible when the table loads, i.e. if the table has subrows in a hierarchy and they may be initially collapsed, including those row IDs in this array will have those rows pre-expanded
   */
  startRowsVisible?: string[];

  /**
   * Displays a static total row at the top of the table with unique styling to differentiate from the other rows.
   */
  total?: TData;
}

export default function DataTable<TData extends RowData<TData>>({
  columns,
  data,
  state,
  initialState,
  paginated,
  pageSize,
  pageCount,
  onPaginationChange,
  total,
  isLoading,
  emptyStateMessage,
  error,
  startDepthExpanded,
  onClick,
  onSelect,
  fetchMore,
  dataTestId,
  rowActions,
  // Remove the eslint disable on unused variables once we implement all the functionality
  /* eslint-disable */
  bulkSelectActions,
  showCollapseAll,
  canFlattenHierarchy,
  startRowsVisible,
  onSortingChange,
}: /* eslint-enable */
DataTableProps<TData>): JSX.Element {
  const errorTextColor = useColorModeValue('red.700', 'red.300');

  const [sorting, setSorting] = useState<SortingState>(
    initialState?.sorting ?? []
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    initialState?.rowSelection ?? {}
  );

  const [loadingSubRows, setLoadingSubRows] = useState<Record<string, boolean>>(
    {}
  );
  const [errorSubRows, setErrorSubRows] = useState<Record<string, boolean>>({});

  // In order to avoid showing subrows duplicated in the table, we need an array of data excluding rows that have a parent
  const dataWithoutChildren = useMemo(
    () => data.filter(d => !d.parent),
    [data]
  );
  const hasHierarchy =
    data.length !== dataWithoutChildren.length || !!fetchMore;

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    getCanPreviousPage,
    getCanNextPage,
    previousPage,
    nextPage,
    toggleAllRowsExpanded,
    setExpanded,
    toggleAllRowsSelected,
  } = useReactTable({
    columns,
    data: total ? [total, ...dataWithoutChildren] : dataWithoutChildren,
    enableSubRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: paginated ? getPaginationRowModel() : undefined,
    getSortedRowModel: getSortedRowModel(),
    getSubRows: row => [
      ...data.filter(d => d.parent === row.id),
      ...(row.subRows ?? []),
    ],
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: pageSize ?? 10,
        ...initialState?.pagination,
      },
      ...initialState,
    },
    manualPagination: !!onPaginationChange,
    onRowSelectionChange: setRowSelection,
    // TODO: server-side sorting https://venmoinc.atlassian.net/browse/ITLS-3339
    onSortingChange: setSorting,
    pageCount,
    state: {
      rowSelection: state?.rowSelection ?? rowSelection,
      sorting: state?.sorting ?? sorting,
      ...state,
    },
    // For some reason, if we just add onPaginationChange field to this object normally, react-table thinks these functions are
    // defined even when the function is undefined. (and breaking client-side pagination) So, need to conditionally add the
    // field based on whether the function is defined or not.
    ...(!!onPaginationChange && { onPaginationChange }),
  });

  const { rows } = getRowModel();

  const totalRow = rows.find(r => r.original.id === total?.id);
  const columnCount = columns.length + (rowActions ? 1 : 0);

  const hasCompletedInitialExpansion = useRef(false);

  useEffect(() => {
    if (hasCompletedInitialExpansion.current) {
      return;
    }

    if (startDepthExpanded === -1) {
      toggleAllRowsExpanded(true);
      hasCompletedInitialExpansion.current = true;
    } else if (startDepthExpanded && startDepthExpanded > 0 && !isLoading) {
      rows.forEach(row => {
        if (row.getCanExpand() && !row.getIsExpanded()) {
          row.toggleExpanded(true);
        }

        const idParts = row.id.split('.');

        if (idParts.length === startDepthExpanded) {
          hasCompletedInitialExpansion.current = true;
        }
      });

      if (rows.length === 0) {
        hasCompletedInitialExpansion.current = true;
      }
    }
  }, [toggleAllRowsExpanded, startDepthExpanded, rows, isLoading]);

  const toggleRowExpansion = (row: Row<TData>) => {
    if (loadingSubRows[row.id]) {
      return;
    }

    if (row.original.hasUnresolvedSubRows && !row.subRows.length) {
      if (fetchMore) {
        // Set this row to a loading state
        setErrorSubRows(currentErrorSubRows => ({
          ...currentErrorSubRows,
          [row.id]: false,
        }));
        setLoadingSubRows(currentLoadingSubRows => ({
          ...currentLoadingSubRows,
          [row.id]: true,
        }));

        fetchMore(row.original)
          .then(() => {
            // Set this row to be expanded after subRows are fetched, preserving existing expansion state
            setExpanded(expanded => {
              return {
                ...(typeof expanded === 'object' ? expanded : {}),
                [row.id]: true,
              };
            });
          })
          .catch(() => {
            // Set this row to an error state
            setErrorSubRows(currentErrorSubRows => ({
              ...currentErrorSubRows,
              [row.id]: true,
            }));
          })
          .finally(() => {
            // After fetch is complete (whether successful or not), set loading to false for row
            setLoadingSubRows(currentLoadingSubRows => ({
              ...currentLoadingSubRows,
              [row.id]: false,
            }));
          });
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'DataTable fetchMore function must be provided in order to expand subRows that are null (unresolved subRows)'
        );
      }
    } else {
      const toggleRowExpanded = row.getToggleExpandedHandler();
      toggleRowExpanded();
    }
  };

  const toggleSelected = (row: Row<TData>) => {
    if (!row.getIsSelected()) {
      toggleAllRowsSelected(false);
      row.toggleSelected();
    }
  };

  return (
    <>
      {error && data.length > 0 ? <Alert>{error}</Alert> : null}
      <Table variant="striped" data-testid={dataTestId}>
        <Thead>
          {getHeaderGroups().map(headerGroup => (
            <Tr key={`tr-header-${headerGroup.id}`}>
              {headerGroup.headers.map(header => {
                let label: React.ReactNode | null = null;

                if (!header.isPlaceholder) {
                  const text = flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  );

                  const tooltip =
                    header.getContext().column.columnDef.meta?.tooltip;

                  label = tooltip ? (
                    <Tooltip label={tooltip}>
                      <Text as="span" borderBottom="1px dotted">
                        {text}
                      </Text>
                    </Tooltip>
                  ) : (
                    text
                  );
                }

                return (
                  <Th
                    cursor={header.column.getCanSort() ? 'pointer' : 'default'}
                    key={`th-${header.id}`}
                    onClick={header.column.getToggleSortingHandler()}
                    textAlign="left"
                    width={header.getSize()}
                  >
                    {label}
                    <DataTableSortIcon
                      direction={
                        header.column.getCanSort()
                          ? header.column.getIsSorted()
                          : undefined
                      }
                    />
                  </Th>
                );
              })}
              {rowActions && rowActions.length > 0 && <Th width={10} />}
            </Tr>
          ))}
        </Thead>

        <Tbody>
          {isLoading && !error ? (
            <DataTableLoading cellCount={columnCount} />
          ) : null}

          {!isLoading && data.length > 0 && total ? (
            <DataTableRow
              isTotal
              key={`tr-total-${total.id}`}
              onClick={
                onClick || onSelect
                  ? () => {
                      if (onSelect && totalRow) {
                        toggleSelected(totalRow);
                        onSelect(totalRow.original);
                      }

                      onClick?.(totalRow?.original);
                    }
                  : undefined
              }
            >
              {totalRow?.getVisibleCells().map((cell, cellIndex) => (
                <DataTableCell
                  key={`td-total-${cell.id}`}
                  width={cell.column.getSize()}
                >
                  {cellIndex === 0 ? (
                    <Flex alignItems="center">
                      {onSelect ? (
                        <DataTableSelectControl
                          selected={totalRow.getIsSelected()}
                        />
                      ) : null}
                      <Flex paddingLeft={hasHierarchy ? '20px' : undefined}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Flex>
                    </Flex>
                  ) : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                  )}
                </DataTableCell>
              ))}
              {rowActions && rowActions.length > 0 ? <DataTableCell /> : null}
            </DataTableRow>
          ) : null}

          {!isLoading && data.length > 0
            ? getRowModel()
                .rows.filter(r => r.original.id !== total?.id)
                .map(row => {
                  const { id } = row;
                  return (
                    <React.Fragment key={`tr-${id}`}>
                      <DataTableRow
                        onClick={
                          onClick || onSelect
                            ? () => {
                                if (onSelect) {
                                  toggleSelected(row);
                                  onSelect(row.original);
                                }

                                onClick?.(row.original);
                              }
                            : undefined
                        }
                      >
                        {row.getVisibleCells().map((cell, cellIndex) => (
                          <DataTableCell
                            key={`td-${cell.id}`}
                            width={cell.column.getSize()}
                          >
                            {cellIndex === 0 ? (
                              <Flex>
                                {onSelect ? (
                                  <DataTableSelectControl
                                    selected={row.getIsSelected()}
                                  />
                                ) : null}
                                <Flex
                                  alignItems="center"
                                  paddingLeft={
                                    hasHierarchy
                                      ? `${row.depth * 20 + 20}px`
                                      : undefined
                                  }
                                >
                                  <DataTableExpandIcon
                                    isExpanded={
                                      loadingSubRows[row.id] ||
                                      row.getIsExpanded()
                                    }
                                    canExpand={
                                      row.original.hasUnresolvedSubRows ||
                                      row.getCanExpand()
                                    }
                                    onClick={() => toggleRowExpansion(row)}
                                  />
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Flex>
                              </Flex>
                            ) : (
                              flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )
                            )}
                          </DataTableCell>
                        ))}
                        {rowActions && rowActions.length > 0 ? (
                          <DataTableCell>
                            <Box textAlign="right">
                              <ButtonGroup size="sm" variant="outline">
                                {rowActions.map(action => {
                                  if (
                                    action.visible &&
                                    !action.visible(row.original)
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <IconButton
                                      key={`row-action-${row.original.id}${action.label}`}
                                      aria-label={action.label}
                                      icon={<Icon glyph={action.icon} />}
                                      onClick={e => {
                                        e.stopPropagation();
                                        action.onClick(row.original);
                                      }}
                                      title={action.label}
                                    />
                                  );
                                })}
                              </ButtonGroup>
                            </Box>
                          </DataTableCell>
                        ) : null}
                      </DataTableRow>

                      {loadingSubRows[row.id] ? (
                        <DataTableLoading
                          rowCount={1}
                          cellCount={columnCount}
                        />
                      ) : null}

                      {errorSubRows[row.id] ? (
                        <DataTableSubRowError
                          cellCount={columnCount}
                          retry={() => toggleRowExpansion(row)}
                        />
                      ) : null}
                    </React.Fragment>
                  );
                })
            : null}
        </Tbody>
      </Table>

      {!isLoading && error && data.length === 0 ? (
        <Text m={4} fontSize="xl" textAlign="center" color={errorTextColor}>
          {error}
        </Text>
      ) : null}

      {!isLoading && !error && data.length === 0 ? (
        <Text m={4} fontSize="xl" textAlign="center">
          {emptyStateMessage ?? 'No results found.'}
        </Text>
      ) : null}

      {paginated && data.length > getState().pagination.pageSize ? (
        <HStack justify="space-between" marginTop="2">
          <Text color="gray.500" fontSize="sm">
            Showing {getState().pagination.pageSize} of{' '}
            {data.length.toLocaleString()}{' '}
            {data.length === 1 ? 'result' : 'results'}.
          </Text>
          <ButtonGroup variant="outline">
            <Button onClick={previousPage} disabled={!getCanPreviousPage()}>
              Previous
            </Button>
            <Button onClick={nextPage} disabled={!getCanNextPage()}>
              Next
            </Button>
          </ButtonGroup>
        </HStack>
      ) : null}
    </>
  );
}
