import React from 'react';
import { ButtonProps, Link as ChakraLink } from '@chakra-ui/react';
import { Button, Link } from '..';
import Icon from '../Icon';

interface Props extends ButtonProps {
  to: string;
}

export default function ButtonLink({ to, ...props }: Props): JSX.Element {
  if (to.startsWith('https')) {
    return (
      <ChakraLink
        isExternal
        href={to}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Button {...props} rightIcon={<Icon glyph="external-link" />} />
      </ChakraLink>
    );
  }

  return (
    <Link
      to={to}
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Button {...props} />
    </Link>
  );
}
