import lifestyleResults from '../../../server/static/lifestyleResults.json';
import biologicalResults from '../../../server/static/biologicalResults.json';
import allResults from '../../../server/static/results.json';

import {
  SESSION_USER_EMULATE_PURCHASE,
  SESSION_USER_LOGGED_IN,
  SESSION_USER_LOGGED_OUT,
  SESSION_USER_TOKEN_SET_STATE,
  SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED,
  SESSION_QUIZ_SET_ID,
  SESSION_QUIZ_SET_CURRENT_QUESTION,
  SESSION_QUIZ_SET_NEXT_QUESTION,
  SESSION_QUIZ_SET_QUIZ_FINISHED,
  STAGES_STAGE_1_ACHIEVED,
  STAGES_STAGE_1_SET_STATE,
  STAGES_STAGE_2_ACHIEVED,
  STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB,
  STAGES_STAGE_2_EMULATE_SET_REJECTED_BY_LAB,
  STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT,
  STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT,
  STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB,
  STAGES_STAGE_2_SET_STATE,
  STAGES_STAGE_3_ACHIEVED,
  STAGES_STAGE_3_SET_STATE,
  STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB,
  STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB,
  STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB,
  STAGES_STAGE_4_ACHIEVED,
  STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED,
  STAGES_STAGE_5_ACHIEVED,
  STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION,
  SESSION_CUSTOMER_FETCH_START,
  SESSION_CUSTOMER_SET_STATE,
  STAGES_STAGE_4_SET_RESULTS,
  SESSION_QUIZ_SET_QUIZ_SUBMITTED,
  SESSION_QUIZ_SET_QUIZ_UNSUBMITTED,
} from '../actions/actionTypes';

const initialState = {
  quiz: {
    activeQuestionNumber: 0,
    currentQuizQuestion: {},
    isQuizFinished: false,
    results: {},
    // answerState: null,
  },
  stages: {
    stage1: {},
    stage2: {},
    stage3: {},
    stage4: {},
    stage5: {},
  },
  user: {},
  customer: {},
};

export default function sessionReducer(
  state = initialState,
  action: {
    [x: string]: any;
  }
) {
  switch (action.type) {
    case SESSION_CUSTOMER_FETCH_START:
      return {
        ...state,
        customer: action.customer,
      };
    case SESSION_CUSTOMER_SET_STATE:
      return {
        ...state,
        customer: action.customer,
      };
    case SESSION_USER_TOKEN_SET_STATE:
      return {
        ...state,
        user: {
          ...state.user,
          token: action.token,
        },
      };
    case SESSION_USER_LOGGED_IN:
      // @ts-ignore
      const token = state.user.token;
      const result = {
        ...state,
        user: {
          ...action.user,
          token,
        },
      };
      return result;
    case SESSION_USER_LOGGED_OUT:
      return initialState;
    case SESSION_QUIZ_SET_ID:
      return {
        ...state,
        quiz: action.quiz,
      };
    case SESSION_QUIZ_SET_CURRENT_QUESTION:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          activeQuestionNumber: 0,
          // answerState: null,
          currentQuizQuestion: action.question,
          results: {},
        },
      };
    case SESSION_QUIZ_SET_NEXT_QUESTION:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          activeQuestionNumber: action.activeQuestionNumber,
          // answerState: null,
          currentQuizQuestion: action.currentQuizQuestion,
        },
      };
    case SESSION_QUIZ_SET_QUIZ_FINISHED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          isQuizFinished: true,
        },
      };
    case SESSION_QUIZ_SET_QUIZ_SUBMITTED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          quizSubmitted: true,
        },
      };
    case SESSION_QUIZ_SET_QUIZ_UNSUBMITTED:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          quizSubmitted: false,
        },
      };
    case SESSION_QUIZ_EMULATE_SET_QUIZ_FINISHED:
      const results = {
        '6': 'Morning',
        '7': 'Comlpete concentration',
        '8': 'Yes',
        '9': 'Yes',
        '10': 'Yes',
        '11': 'Yes',
        '12': 'Low',
        '13': 'I tend to miss them regularly',
        '14': '<30',
        '15': 'Yes',
        '16': 'Yes',
        '17': 'A',
        '18': 'Looks',
        '19': '1-2 per week',
        '20': 'Yes',
        '21': 'Rarely',
        '22': 'Insecure',
        '23': 'Nervous',
        '24': 'I often stumble my words',
        '25': 'Never',
        '26': 'Exhausted',
        '27': 'First thing in the morning',
        '28': 'Often',
        '29': 'None',
        '30': 'Low intensity',
        '31': 'Never',
        '32': '<20',
        '33': '<1',
        '34': 'Yes',
        '35': 'Never',
        '36': 'Once a month',
        '37': 'Never',
        '38': 'Never',
        '39': 'Never',
        '40': 'Never',
        '41': 'Yes',
        '42': 'Yes',
        '43': 'Yes',
        '44': 'Always',
        '45': '1-3',
        '46': '<5',
        '47': 'Yes',
        '48': 'Yes',
        '49': 'Very consistent',
        '50': 'Fresh and well-rested',
        '51': 'Yes',
        '52': 'Yes',
        '53': 'Yes',
        '54': 'Always',
        '55': '1-3',
        '56': '0-10',
        '57': 'Yes',
        '58': 'Yes',
        '59': 'Yes',
        '60': 'Yes',
        '61': 'When I wake up',
        '62': 'I stay calm',
        '63': 'Paleo',
        '64': 'Yes',
        '65': 'Yes',
      };

      return {
        ...state,
        quiz: {
          ...state.quiz,
          isQuizFinished: true,
          results,
        },
        stages: {
          ...state.stages,
          stage1: {
            ...state.stages.stage1,
            isQuizFinished: true,
            results,
            ...lifestyleResults
          },
        },
      };
    case STAGES_STAGE_1_ACHIEVED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage1: {
            ...state.stages.stage1,
            achieved: true,
          },
        },
      };
    case STAGES_STAGE_1_SET_STATE:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage1: { ...action.results, ...state.stages.stage1 },
        },
      };
    case STAGES_STAGE_2_ACHIEVED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage2,
            achieved: true,
          },
        },
      };
    case STAGES_STAGE_2_EMULATE_SET_RECEIVED_BY_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage2,
            kit_status: 'Received By Lab',
          },
          stage3: {
            ...state.stages.stage3,
            kit_status: 'Received By Lab',
          },
        },
      };
    case STAGES_STAGE_2_EMULATE_SET_REJECTED_BY_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage2,
            results: {
              kitStatus: 'Rejected By Lab',
            },
          },
        },
      };
    case STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_PATIENT:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Shipped To Patient',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: 'Tracking_number_easy_post',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
        },
      };
    case STAGES_STAGE_2_EMULATE_SET_DELIVERED_TO_PATIENT:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Delivered To Patient',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: 'Tracking_number_easy_post',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
        },
      };
    case STAGES_STAGE_2_EMULATE_SET_SHIPPED_TO_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Shipped To Lab',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
          stage3: {
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Shipped To Lab',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
        },
      };
    case STAGES_STAGE_2_SET_STATE:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: action.results,
        },
      };
    case STAGES_STAGE_3_ACHIEVED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage3: {
            ...state.stages.stage3,
            achieved: true,
          },
        },
      };
    case STAGES_STAGE_3_EMULATE_SET_DELIVERED_TO_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            achieved:true,
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Delivered To Lab',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
          stage3: {
            id: '1467b567-d85f-4ff1-9e2c-34a140199053',
            customer_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            test_date: '2023-10-23T13:20:50+00:00',
            results: {
              status: 'awaiting results',
            },
            imaware_careplan_id: '830a326b-e6ac-4538-9204-0048f185313d',
            patient_id: '1e8c0898-2336-4bd4-aed1-71d28f79a0c3',
            kit_status: 'Delivered To Lab',
            tracking_status: null,
            to_lab_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            diagnostic_report: null,
            to_patient_shipping: {
              carrier: 'UPS',
              trackingNumber: '1Z12345E1512345676',
            },
            kit_id: 'IAMYB6A4E5',
          },
        },
      };
    case STAGES_STAGE_3_EMULATE_SET_PARTIALLY_RESULTED_BY_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage2,
            achieved:true,
            kit_status: 'Partially Resulted By Lab',
          },
          stage3: {
            ...state.stages.stage3,
            kit_status: 'Partially Resulted By Lab',
          },
        },
      };
    case STAGES_STAGE_3_EMULATE_SET_FULLY_RESULTED_BY_LAB:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage2,
            achieved:true,
            kit_status: 'Fully Resulted By Lab',
          },
          stage3: {
            ...state.stages.stage3,
            achieved:true,
            kit_status: 'Fully Resulted By Lab',
          },
          stage4:{
            ...allResults
          }
        },
      };
    case STAGES_STAGE_3_SET_STATE:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage3: action.results,
        },
      };
    case STAGES_STAGE_4_ACHIEVED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage4: {
            ...state.stages.stage4,
            achieved: true,
          },
        },
      };
    case STAGES_STAGE_4_EMULATE_SET_PLAN_DEVELOPED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage4: {
            ...state.stages.stage4,
            results: action.results,
          },
        },
      };
    case STAGES_STAGE_4_SET_RESULTS:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage4: {
            ...state.stages.stage4,
            ...action.results,
          },
        },
      };
    case STAGES_STAGE_5_ACHIEVED:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage5: {
            ...state.stages.stage5,
            achieved: true,
          },
        },
      };
    case STAGES_STAGE_5_EMULATE_SET_SUPPLEMENTS_IN_FORMULATION:
      return {
        ...state,
        stages: {
          ...state.stages,
          stage2: {
            ...state.stages.stage1,
            results: {
              'SUPPLEMENTS STATUS': 'IN FORMULATION',
            },
          },
        },
      };
    case SESSION_USER_EMULATE_PURCHASE:
      return {
        ...state,
        user: {
          ...state.user,
          purchase: {
            id: '98765432',
            orderDate: new Date().toISOString(),
          },
        },
      };
    default:
      return state;
  }
}