import axios from 'axios';
import { useMediaQuery } from '@chakra-ui/react';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPool from '../../../server/cognito';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  Spinner,
  Text,
} from '../../ui';
import PageBoundary from '../../ui/ErrorBoundary';
import ProductLogo from '../../ui/Shell/components/ProductLogo';
import {
  stageAchieved,
  updateSessionQuiz,
  updateSessionUser,
  updateSessionUserToken,
} from '../../store/actions/sessionActions';
import ChangePassword from '../../components/Login/ChangePassword';
import XButton from '../../components/XButton';
import Copyright from '../../components/Copyright';

const mock = process.env.MOCK_SOURCES === 'true' || false;
const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';

const Login = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
  const [changePasswordRequired, setChangePasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(
    undefined
  );
  const [userAttributes, setUserAttributes] = useState<CognitoUser | undefined>(
    undefined
  );

  const getSessionQuiz = async (jwt: string) => {
    try {
      const { data } = await axios.get(`${baseURL}plan${mock ? '.json' : ''}`, {
        headers: { Authorization: jwt },
      });
      return data[0].survey_response;
    } catch (e) {
      console.error(e);
    }
  };

  const getUserCallback = (
    err?: Error,
    userResult?: CognitoUserAttribute[]
  ) => {
    if (err) {
      console.log('err', err);
      return;
    }

    const purchase = { id: '9876543' }; // TODO: a new endpoint to capture the purchase id is needed, to be called when Shopify redirects to the App after the product is purchased.

    dispatch(
      updateSessionUser({
        email: userResult![5].Value,
        id: userResult![0].Value,
        lastName: userResult![3].Value,
        name: userResult![2].Value,
        purchase,
      })
    );

    searchParams.set('tab', 'Scores');
    navigate({ pathname: '/dashboard', search: searchParams.toString() });
  };

  const validateForm = () => {
    const emailFieldEmpty = email === '';

    if (emailFieldEmpty) {
      setEmailIsInvalid(true);
      return false;
    }

    const emailFieldWrongFormat: boolean =
      (!String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) as unknown as boolean) && email !== undefined;

    if (emailFieldWrongFormat) {
      setEmailIsInvalid(true);
      return false;
    }

    const passwordFieldEmpty = password === '';

    if (passwordFieldEmpty) {
      setPasswordIsInvalid(true);
      return false;
    }

    return true;
  };

  const handleAuthSuccess = async (
    user: CognitoUser,
    authenticateResult: CognitoUserSession
  ) => {
    const sessionQuiz = await getSessionQuiz(
      // @ts-ignore
      authenticateResult.idToken.jwtToken
    );

    dispatch(
      updateSessionUserToken(
        // @ts-ignore
        authenticateResult.idToken.jwtToken
      )
    );
    dispatch(
      updateSessionQuiz({
        id: sessionQuiz.id,
        answers: sessionQuiz.answers,
        finished: sessionQuiz.finished,
        survey_id: sessionQuiz.survey_id,
      })
    );

    if (sessionQuiz.finished) {
      dispatch(stageAchieved(1));
    }

    user.getUserAttributes(getUserCallback);
  };

  const onSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const myUser = new CognitoUser({
      Username: email || '',
      Pool: UserPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email || '',
      Password: password,
    });

    myUser.authenticateUser(authenticationDetails, {
      onSuccess: async authenticateResult => {
        await handleAuthSuccess(myUser, authenticateResult);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setCognitoUser(myUser);
        setUserAttributes(userAttributes);
        setChangePasswordRequired(true);
      },
      onFailure: err => {
        console.log('login failed', err);
        setLoading(false);

        toast.error(err.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      },
    });
  };

  const emailFieldEmpty = email === '';

  return (
    <PageBoundary>
      <>
        <Flex as="form" direction="row" h="100vh" overflowY="hidden">
          {!isMobile && (
            <Box
              bgImage="/static/ads/earth.png"
              bgRepeat="no-repeat"
              bgSize="cover"
              bgColor="current"
              flex="1"
              width="100%"
            />
          )}
          <Flex
            flex="1"
            bg="#141414"
            alignItems="center"
            justifyContent="space-between"
            direction="column"
            pt="6rem"
            style={{ position: 'relative' }}
          >
            <XButton style={{ position: 'absolute', top: '2%', right: '2%' }} />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              direction="column"
            >
              <Link to="/">
                <ProductLogo
                  boxSize="104px"
                  display="block"
                  name="MNLY"
                  position="relative"
                  top="-1.05rem"
                />
              </Link>

              <Text
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="32px"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="150%"
                mt="3rem"
              >
                MNLY Portal Log In
              </Text>
              {!changePasswordRequired ? (
                <>
                  <FormControl isInvalid={emailIsInvalid} textAlign="center">
                    <Input
                      _active={{
                        border: '2px solid #7D7C7A',
                      }}
                      _selected={{
                        border: '2px solid #7D7C7A',
                      }}
                      _focus={{
                        border: '2px solid #7D7C7A',
                      }}
                      _placeholder={{ color: '#7D7C7A' }}
                      borderRadius="0"
                      border="2px solid #7D7C7A"
                      color="#7D7C7A"
                      errorBorderColor="#7D7C7A"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="138%"
                      mt=".6rem"
                      pl="1rem"
                      top="8px"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={e => {
                        if (emailIsInvalid) {
                          setEmailIsInvalid(false);
                        }

                        setEmail(e.currentTarget.value);
                      }}
                      width="21.25rem"
                    />
                    {!emailIsInvalid ? (
                      <FormHelperText />
                    ) : emailFieldEmpty ? (
                      <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                        Email is required.
                      </FormErrorMessage>
                    ) : (
                      <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                        Email format is not valid.
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={passwordIsInvalid} textAlign="center">
                    <Input
                      _active={{
                        border: '2px solid #7D7C7A',
                      }}
                      _selected={{
                        border: '2px solid #7D7C7A',
                      }}
                      _focus={{
                        border: '2px solid #7D7C7A',
                      }}
                      _placeholder={{ color: '#7D7C7A' }}
                      borderRadius="0"
                      border="2px solid #7D7C7A"
                      color="#7D7C7A"
                      errorBorderColor="#7D7C7A"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="138%"
                      mt=".45rem"
                      onChange={e => {
                        if (passwordIsInvalid) {
                          setPasswordIsInvalid(false);
                        }
                        setPassword(e.currentTarget.value);
                      }}
                      pl="1rem"
                      position="relative"
                      top="8px"
                      type="password"
                      placeholder="Password"
                      value={password}
                      width="339px"
                    />
                    {!passwordIsInvalid ? (
                      <FormHelperText />
                    ) : (
                      <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                        Password is required.
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <Link to="../ForgotPassword">Forgot my Password?</Link>
                  <Box
                    _hover={{ cursor: 'pointer' }}
                    alignItems="center"
                    bg="#685848"
                    display="flex"
                    height="40px"
                    flexDirection="column"
                    gap="40px"
                    justifyContent="center"
                    mt="1rem"
                    onClick={e => {
                      setLoading(true);
                      onSubmit(e);
                    }}
                    padding="20px 20px"
                    width="339px"
                  >
                    <Text
                      color="#E3DBD3"
                      textAlign="center"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="normal"
                      textTransform="uppercase"
                    >
                      {loading ? (
                        <Box textAlign="center" pt=".4rem">
                          <Spinner color="gray.500" emptyColor="gray.200" />
                        </Box>
                      ) : (
                        <>LOGIN</>
                      )}
                    </Text>
                  </Box>
                </>
              ) : (
                <ChangePassword
                  cognitoUser={cognitoUser!}
                  userAttributes={userAttributes}
                  handleAuthSuccess={handleAuthSuccess}
                />
              )}
            </Flex>
            <Copyright />
          </Flex>
        </Flex>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </>
    </PageBoundary>
  );
};

export default Login;
