import React from 'react';
import { Td, TableCellProps } from '@chakra-ui/react';

export default function DataTableCell({
  children,
  width,
  ...rest
}: TableCellProps): JSX.Element {
  return (
    <Td
      width={width ?? 1}
      style={{
        background: 'none',
      }}
      {...rest}
    >
      {children}
    </Td>
  );
}
