import { Box } from '../../../../ui';
import React from 'react';
import HoverListItem from './HoverListItem';
import { bioFactors } from '../../../../constants';

type Props = {
  setHovered: (value: boolean) => void;
  setFactorCategory: (value: string) => void;
};
const BiologicalAssessmentItems = ({
  setHovered,
  setFactorCategory,
}: Props) => {
  return (
    <Box>
      {Object.keys(bioFactors).map(category => (
        <HoverListItem
          key={category}
          factors={bioFactors}
          factorCategory={category}
          setHovered={setHovered}
          setFactorCategory={setFactorCategory}
        />
      ))}
    </Box>
  );
};

export default BiologicalAssessmentItems;
