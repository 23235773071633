import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Redirect {
  from: RegExp;
  to: string;
}

interface UseRedirectsOptions {
  /** If true, will not redirect even if a match is found. */
  skip?: boolean;
}

/** A prioritized list of redirects. Paths match the start of the pathname. */
const redirects: Redirect[] = [
  // Page level redirects.
  { from: /^\/quality\/bugs/, to: '/operate/bugs' },
  { from: /^\/quality\/reviews/, to: '/monitor/reviews' },
  { from: /^\/quality\/incidents/, to: '/monitor/incidents' },
  // Top level redirects.
  { from: /^\/analytics/, to: '/build' },
  { from: /^\/operations/, to: '/operate' },
  { from: /^\/quality/, to: '/test' },
  { from: /^\/releases/, to: '/release' },
  { from: /^\/repos\//, to: '/repositories/' },
  { from: /^\/repos$/, to: '/repositories' },
];

/** On navigate, check if the new route matches known redirect patterns. */
export default function useRedirects(options: UseRedirectsOptions = {}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (options.skip !== true) {
      redirects.forEach(redirect => {
        if (redirect.from.test(pathname)) {
          navigate(pathname.replace(redirect.from, redirect.to), {
            replace: true,
          });
        }
      });
    }
  }, [pathname]);
}
