import { Box, Flex, Heading, Spacer } from '@chakra-ui/react';
import React from 'react';
import Link from '../Link';
import { Tooltip, Text } from '..';

export interface Props {
  action?: React.ReactNode;
  title?: React.ReactNode;
  to?: string;
  tooltip?: React.ReactNode;
}

export default function CardHeader({
  action,
  title,
  to,
  tooltip,
}: Props): JSX.Element {
  return (
    <Flex align="center" justify="space-between" p={2} borderBottomWidth="1px">
      <Heading as="h2" fontSize="lg" p={2}>
        {to ? <Link to={to}>{title}</Link> : title}
      </Heading>

      {tooltip ? <Tooltip label={tooltip} /> : null}

      <Spacer />

      <Box>
        {typeof action === 'string' ? (
          <Text color="gray.500" fontSize="md" px={2}>
            {action}
          </Text>
        ) : (
          action
        )}
      </Box>
    </Flex>
  );
}
