import {Text} from "../ui";
import React from "react";

const RightArrow = () => (
    <Text
        style={{
            backgroundImage:
                "url('" +
                'static/illustrations/arrow-right-thin.svg' +
                "')",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '24px',
            width: '24px',
        }}
    />
)

export default RightArrow;
