import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { defaultVerticalGap } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { InstructionTitle } from './InstructionCommonComponents';
import {ActionButton} from "../../../components/Buttons";

const Step8 = () => {
  const navigate = useNavigate();
  return (
    <Flex
      id="step-8"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <InstructionTitle>
        Congratulations! <br />
        <br />
        You have completed all steps.
      </InstructionTitle>

      <ActionButton onClick={() => navigate('/dashboard?tab=Scores')}>
        Go to Dashboard
      </ActionButton>
    </Flex>
  );
};

export default Step8;
