import { Flex } from '@chakra-ui/react';
import React from 'react';
import { InstructionStageProps } from './Step0';
import {ActionButton} from "../../../components/Buttons";

const BackAndNextBtns = ({
  activeStage,
  setActiveStage,
}: InstructionStageProps) => {
  return (
    <Flex gap="2rem">
      <ActionButton
        bg="#141414"
        border="2px solid #7D7C7A"
        onClick={() => setActiveStage((Number(activeStage) - 1).toString())}
      >
        Back
      </ActionButton>
      <ActionButton
        onClick={() => setActiveStage((Number(activeStage) + 1).toString())}
      >
        Next
      </ActionButton>
    </Flex>
  );
};

export default BackAndNextBtns;
