import React, { useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PageBoundary from '../../ui/ErrorBoundary';
import {
    Box,
    Flex,
    Text,
    Button
  } from '../../ui';
import ProductLogo from '../../ui/Shell/components/ProductLogo';

const ManageSubscriptions = () => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();
    const { user } = useSelector(state => {
        //@ts-ignore
        return state.session;
      });
  

    useEffect(() => {
        if (!user?.id) {
          navigate({ pathname: '/login'});
        }
      }, []);

    return(
        <PageBoundary>
        <>
         <Flex direction="row" h="100vh" overflowY="hidden">
           {!isMobile && (
             <Box
               bgImage={'/static/ads/earth.png'}
               bgRepeat="no-repeat"
               bgSize="cover"
               bgColor="current"
               flex="1"
               width="100%"
             />
           )}
           <Flex
             flex="1"
             bg="#141414"
             alignItems="center"
             justifyContent="space-between"
             direction="column"
             pt="6rem"
             style={{ position: 'relative' }}
           >
            <Box
            style={{ cursor: 'pointer' }}
            _hover={{ opacity: '75%' }}
            onClick={() => navigate('/')}
            position='absolute'
            top='2%'
            right='2%'
            >
            <img
                alt="MNLY Precision Men's Health"
                src={`/static/icons/close-icon.png`}
                width="100%"
                onClick={() => navigate(-1)}
            />
            </Box>
             <Flex
               alignItems="center"
               justifyContent="space-between"
               direction="column"
             >
               <Link to="/">
                 <ProductLogo
                   boxSize="104px"
                   display="block"
                   name="MNLY"
                   position="relative"
                   top="-1.05rem"
                 />
               </Link>
               <Box
               width='75%'>
                    <Text
                    color="#7D7C7A"
                    fontFamily="DIN-Regular"
                    fontSize="18px"
                    fontWeight="5"
                    lineHeight="150%"
                    >
                        To manage your subscriptions, please click the link below. You will be prompted to provide your email address, which will send you a 4-digit access code via email and SMS if you have a registered phone number. Use this access code to enter the customer portal. You will then be redirected to Recharge where you can cancel or renew your subscription, as well as view order history and update any shipping addresses you would like to use. 
                    </Text>
                </Box>
                <Box 
                  mt='5'>
                    <Button
                        bg='#685848'
                        color='#e3dbd3'
                        fontStyle='normal'
                        textDecoration='none'
                        _hover={{
                            bg:'#A0856A'
                        }}
                        onClick={() => window.open('https://mnly-store.myshopify.com/tools/recurring/get-subscription-access')}
                    >
                        Manage Subscription
                    </Button>
                </Box>
               </Flex>
          </Flex>
       </Flex>
        </>
       </PageBoundary>
    )
}
  export default ManageSubscriptions;