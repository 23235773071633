import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  image: string;
  title: string;
  text: string;
};
const AthleteHighlight = (props: Props) => {
  return (
    <Flex direction="column"  gap="1rem">
      <Box>
        <img alt="MNLY Precision Men's Health" src={props.image} />
      </Box>
      <Text
        color="#E3DBD3"
        fontFamily="DIN-Bold"
        fontSize={{sm: "12px", md: "16px"}}
        fontWeight={400}
        textAlign="left"
      >
        {props.title}
      </Text>
      <Text
        color="#7D7C7A"
        fontFamily="DIN-Regular"
        fontSize="14px"
        fontWeight={400}
        textAlign="left"
      >
        {props.text}
      </Text>
    </Flex>
  );
};

export default AthleteHighlight;
