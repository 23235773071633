import React from 'react';
import { Icon } from '@chakra-ui/react';

export type StatusTypes = 'ok' | 'error' | 'unknown';

interface Props {
  className?: string;
  size?: number;
  title?: string | null | undefined;
  value?: StatusTypes;
}

/**
 * A colored dot indicating a status. Ideally, we should include different
 * shapes in addition to color to support color blind users.
 */
export default function Status({
  className,
  size = 4,
  title,
  value = 'unknown',
}: Props): JSX.Element {
  return (
    <Icon
      className={className}
      data-testid="status-icon-test"
      display="inline-block"
      fill={`status.${value}`}
      height={size}
      mx={1}
      viewBox="0 0 20 20"
      width={size}
    >
      <title>{title ?? value}</title>
      <circle r={10} cx={10} cy={10} />
    </Icon>
  );
}
