import React from 'react';
import { Box } from '@chakra-ui/react';
import Icon from '../Icon';

interface Props {
  size?: string;
  value: number;
}

export default function Stars({ size = '16px', value }: Props): JSX.Element {
  const ratio = value / 5;

  return (
    <Box data-testid="star-box" position="relative" display="inline-block">
      <Box color="ratings.three" display="flex">
        <Icon glyph="star-empty" boxSize={size} />
        <Icon glyph="star-empty" boxSize={size} />
        <Icon glyph="star-empty" boxSize={size} />
        <Icon glyph="star-empty" boxSize={size} />
        <Icon glyph="star-empty" boxSize={size} />
      </Box>
      <Box
        overflow="hidden"
        position="absolute"
        top={0}
        transition="width 500ms"
        width={`${ratio * 100}%`}
      >
        <Box color="ratings.three" display="flex">
          <Icon glyph="star-filled" boxSize={size} />
          <Icon glyph="star-filled" boxSize={size} />
          <Icon glyph="star-filled" boxSize={size} />
          <Icon glyph="star-filled" boxSize={size} />
          <Icon glyph="star-filled" boxSize={size} />
        </Box>
      </Box>
    </Box>
  );
}
