import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Flex, Text, VStack } from '../../ui';
import PageBoundary from '../../ui/ErrorBoundary';
import Summary from './components/Summary';

import BiologicalHealth from './components/PopOverImage/BiologicalHealth';
import Supplements from './components/Supplements';
import PersonalizedHealthPlan from './components/PersonalizedHealthPlan';
import OurMethodology from './components/OurMethodology';
import {
  defaultVerticalGap,
  globalHorizontalMobilePadding,
  globalHorizontalPadding,
} from '../../constants';
import LifeStyleHealth from './components/PopOverImage/LifeStyleHealth';

const Product = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant', // instant works, but typescript doesn't like it, "auto" wasn't always working
    });
  }, [pathname]);

  return (
    <PageBoundary>
      <Flex
        id="HowItWorks"
        flexDirection="column"
        width="100%"
        gap={defaultVerticalGap}
        padding={{
          base: `${defaultVerticalGap} ${globalHorizontalPadding} ${defaultVerticalGap} ${globalHorizontalPadding}`,
          md: `${defaultVerticalGap} ${globalHorizontalPadding} ${defaultVerticalGap} ${globalHorizontalPadding}`,
          sm: `${defaultVerticalGap} ${globalHorizontalMobilePadding} ${defaultVerticalGap} ${globalHorizontalMobilePadding}`,
        }}
      >
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Bold"
          fontSize="32px"
          fontStyle="normal"
          fontWeight=""
          lineHeight="130%"
          maxWidth="26.4799rem"
        >
          WholeMan
        </Text>
        <VStack spacing="5rem">
          <Summary />
          <LifeStyleHealth />
          <BiologicalHealth />
          <Supplements />
          <PersonalizedHealthPlan />
          <OurMethodology />
          {/*<Testimonials />*/}
        </VStack>
      </Flex>
    </PageBoundary>
  );
};

function mapStateToProps(state: { session: any }) {
  return {
    user: state.session.user,
  };
}

export default connect(mapStateToProps, {})(Product);
