import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { Text } from '../../../ui';

interface SupplementProps {
  name: string;
  philosophy: string;
  timeOfDay: 'AFTERNOON' | 'MORNING' | 'NIGHT';
  weightInMilligrams: string;
}

export default function SupplementCard({
  name,
  timeOfDay,
  philosophy,
  weightInMilligrams,
}: SupplementProps): JSX.Element {
  const [supplementIsExpanded, setSupplementIsExpanded] = React.useState(false);

  return (
    <Box
      flex="25%"
      display="flex"
      flexDir="column"
      w="22.8rem"
      maxW="21.0rem"
      border="solid .2rem #7D7C7A"
      color="#E3DBD3"
      h={supplementIsExpanded ? 'auto' : '57px'}
    >
      <Box display="inline-flex" color="#E3DBD3" h="57px">
        <Box
          minW="51px"
          style={{
            backgroundImage: `url('/static/ads/supplements/${name}.png')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '51px',
            width: '51px',
          }}
        />
        <Box>
          <Text
            w="188px"
            pl={2}
            pt={1.5}
            color="#E3DBD3"
            fontFamily="DIN-Medium"
            fontSize="18px"
            fontStyle="normal"
            lineHeight="normal"
          >
            {name}
          </Text>
          <Text
            pl={2}
            pt={0.5}
            color="#7D7C7A"
            fontFamily="DIN-Regular"
            fontSize="10px"
            fontStyle="normal"
            lineHeight="normal"
          >
            {timeOfDay}
          </Text>
        </Box>
        <Box
          bg="#7D7C7A"
          display="inline-flex"
          padding="6px"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          h="1.4rem"
          w="3.2rem"
          mt=".5rem"
          ml=".5rem"
        >
          <Text
            color="#141414"
            fontFamily="DIN-Bold"
            fontSize="12px"
            fontStyle="normal"
            lineHeight="normal"
            textTransform="uppercase"
          >
            {weightInMilligrams}
          </Text>
        </Box>
        <Flex
          w="100%"
          justifyContent="flex-end"
          pt={4}
          pr={3}
          textAlign="right"
          onClick={() => {
            setSupplementIsExpanded(!supplementIsExpanded);
          }}
        >
          {supplementIsExpanded ? (
            <svg
              style={{ position: 'relative', top: '.5rem' }}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="2"
              viewBox="0 0 18 2"
              fill="none"
            >
              <line
                x1="17.4189"
                y1="1"
                x2="0.913738"
                y2="0.999999"
                stroke="#E3DBD3"
                stroke-width="2"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                fill="#E3DBD3"
              />
            </svg>
          )}
        </Flex>
      </Box>

      <Box
        mt="0rem"
        mr="4em"
        ml="1rem"
        display={supplementIsExpanded ? 'block' : 'none'}
      >
        <Text
          pt="1rem"
          pb="2rem"
          color="#E3DBD3"
          fontFamily="DIN-Regular"
          fontSize="14px"
          fontStyle="normal"
          lineHeight="150%"
        >
          {philosophy}
        </Text>
      </Box>
    </Box>
  );
}
