import React from 'react';
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import Icon from '../Icon';

type Props = ChakraLinkProps & {
  hideIcon?: boolean;
};

export default function ExternalLink({
  children,
  hideIcon = false,
  ...otherProps
}: Props): JSX.Element {
  return (
    <ChakraLink {...otherProps} isExternal>
      {children}
      {!hideIcon ? (
        <Icon
          glyph="external-link"
          color="gray.400"
          style={{
            display: 'inline-block',
            marginLeft: '0.25em',
            verticalAlign: 'sub',
          }}
        />
      ) : null}
    </ChakraLink>
  );
}
