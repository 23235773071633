import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ManageSubscriptions from './ManageSubscriptions';

export default function ManageSubscriptionsPage(): JSX.Element {
  return (
    <Routes>
      <Route index element={<ManageSubscriptions />} />
    </Routes>
  );
}