import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

type Props = {
  stage: string;
  onClick: () => void;
  achieved: boolean;
  lastStage?: boolean;
};

// This component has very specific styling to accommodate alignment of text under each step
// Unfortunately, you cannot reuse the Instructions stepper here
const StageStep = ({ stage, onClick, achieved, lastStage = false }: Props) => {
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        bg={achieved ? '#434B30' : ''}
        border={achieved ? 'solid .1rem transparent' : 'solid .1rem #E3DBD3'}
        color="#E3DBD3"
        onClick={onClick}
        width={{ base: '40px', sm: '30px', md: '40px' }}
        height={{ base: '40px', sm: '30px', md: '40px' }}
      >
        {achieved ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2rem"
            height="2rem"
            viewBox="0 0 40 40"
            fill="none"
          >
            <rect
              x="1"
              y="1"
              width="38"
              height="38"
              fill="#434B30"
              stroke="#434B30"
              stroke-width="2"
            />
            <mask id="mask0_1976_5602" maskUnits="userSpaceOnUse" x="8" y="8">
              <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1976_5602)">
              <path
                d="M17.5497 26.4523L11.3975 20.3001L13.2888 18.4088L17.5497 22.6697L26.7105 13.5088L28.6019 15.4001L17.5497 26.4523Z"
                fill="#E3DBD3"
              />
            </g>
          </svg>
        ) : (
          <p>{stage}</p>
        )}
      </Flex>
      {!lastStage && (
        <Box
          position="relative"
          top="1.3rem"
          mx="1rem"
          my={{ base: '-.3rem', sm: '-.3rem', md: '-.2rem' }}
          h=".05rem"
          bg="#E3DBD3"
          w={{
            base: '8rem',
            sm: '0.75rem',
            md: '4rem',
            lg: '8rem',
          }}
        >
          &nbsp;
        </Box>
      )}
    </>
  );
};

export default StageStep;
