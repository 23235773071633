import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Company from './Company';

export default function CompanyPage(): JSX.Element {
  return (
    <Routes>
      <Route index element={<Company />} />
    </Routes>
  );
}
