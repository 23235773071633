import {forwardRef, InputProps, TextProps} from "@chakra-ui/react";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {Box, Flex, FormControl, FormErrorMessage, FormHelperText, Input, Spinner, Text} from "../../ui";
import {CognitoUser, CognitoUserSession} from "amazon-cognito-identity-js";


const passwordValidationRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");

const FormText = forwardRef<TextProps, 'div'>((props, ref) => (
    <Text _hover={{cursor: 'pointer'}}
          color="#E3DBD3"
          textAlign="center"
          fontFamily="DIN-Regular"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="5"
          lineHeight="normal"
          pt="1.0rem"
          ref={ref}
          {...props} />
))

// Requires manual override of value and onChange props
const PasswordInput = forwardRef<InputProps, 'div'>((props, ref) => (
    <Input
        _active={{
            border: '2px solid #7D7C7A',
        }}
        _selected={{
            border: '2px solid #7D7C7A',
        }}
        _focus={{
            border: '2px solid #7D7C7A',
        }}
        _placeholder={{color: '#7D7C7A'}}
        borderRadius="0"
        border="2px solid #7D7C7A"
        color="#7D7C7A"
        errorBorderColor="#7D7C7A"
        fontFamily="DIN-Medium"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="550"
        lineHeight="138%"
        mt=".45rem"
        pl="1rem"
        position="relative"
        top="8px"
        type="password"
        placeholder="Password"
        width="339px"
        ref={ref}
        {...props}
    />
))

type Props = {
    cognitoUser: CognitoUser;
    userAttributes: any;
    handleAuthSuccess: (user: CognitoUser, authenticateResult: CognitoUserSession) => Promise<void>;
}
const ChangePassword = ({cognitoUser, userAttributes, handleAuthSuccess}: Props) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const validatePassword = (pw: string) => {
        if (!passwordValidationRegex.test(pw)) {
            setPasswordIsInvalid(true);
        } else {
            setPasswordIsInvalid(false);
        }
    }

    const passwordMatchError = password !== confirmPassword;

    const onSubmit = (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setSubmitted(true);
        validatePassword(password);

        if (!passwordIsInvalid && !passwordMatchError) { // change password
            console.log(userAttributes)
            // Cannot overwrite these
            delete userAttributes.email_verified;
            delete userAttributes.phone_number_verified;
            delete userAttributes.email;


            cognitoUser.completeNewPasswordChallenge(password, userAttributes, {
                onSuccess: async (userSession) => {
                    await handleAuthSuccess(cognitoUser, userSession);
                },
                onFailure: err => {
                    setLoading(false);
                    toast.error(err.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                }
            });

        } else {
            setLoading(false)
        }
    }

    return (
        <Flex as="form" direction="column" h="100vh" overflowY="hidden">
            <FormText fontSize="18px">
                Welcome!
            </FormText>
            <FormText>
                A password reset is required after first login.
            </FormText>
            <FormControl isInvalid={submitted && passwordIsInvalid} textAlign="center">
                <PasswordInput value={password} onChange={e => {
                    validatePassword(e.currentTarget.value);
                    setPassword(e.currentTarget.value);
                }}/>


                {!passwordIsInvalid ? (
                    <FormHelperText/>
                ) : (
                    <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                        Password must be 8 to 20 characters, contain at least one numeric digit, one uppercase and
                        one
                        lowercase
                        letter, and one special character
                    </FormErrorMessage>
                )}
            </FormControl>
            <FormControl isInvalid={submitted && passwordMatchError} textAlign="center">
                <PasswordInput value={confirmPassword} placeholder={'Confirm Password'} onChange={e => {
                    setConfirmPassword(e.currentTarget.value);
                }}/>
                {!passwordMatchError ? (
                    <FormHelperText/>
                ) : (
                    <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                        Passwords do not match
                    </FormErrorMessage>
                )}
            </FormControl>

            <Box
                _hover={{cursor: 'pointer'}}
                alignItems="center"
                bg="#685848"
                display="flex"
                height="40px"
                flexDirection="column"
                gap="40px"
                justifyContent="center"
                mt="1rem"
                onClick={e => {
                    setLoading(true);
                    onSubmit(e);

                }}
                padding="20px 20px"
                width="339px"
            >
                <Text
                    color="#E3DBD3"
                    textAlign="center"
                    fontFamily="DIN-Medium"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                    textTransform="uppercase"
                >
                    {loading ? (
                        <Box textAlign="center" pt=".4rem">
                            <Spinner color="gray.500" emptyColor="gray.200"/>
                        </Box>
                    ) : (
                        <>RESET PASSWORD</>
                    )}
                </Text>
            </Box>
        </Flex>
    )
}

export default ChangePassword;
