import React from 'react';
import {
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  usePopoverContext,
} from '..';
import {
  FilterActionButtons,
  FilterActionButtonsProps,
} from './FilterActionButtons';

type FilterPopoverContentProps = FilterActionButtonsProps & {
  children?: React.ReactNode;
  header?: string;
};

export const FilterPopoverContent = (props: FilterPopoverContentProps) => {
  const { header, children, onClickCancel, onClickApply, isCancelDisabled } =
    props;
  const { onClose } = usePopoverContext();
  return (
    <PopoverContent
      _focus={{ outline: 0, shadow: 'none' }}
      _focusVisible={{ shadow: 'outline' }}
    >
      {header && <PopoverHeader srOnly>{header}</PopoverHeader>}
      <PopoverBody padding="6">{children}</PopoverBody>
      <PopoverFooter>
        <FilterActionButtons
          onClickCancel={() => {
            onClickCancel?.();
            onClose();
          }}
          isCancelDisabled={isCancelDisabled}
          onClickApply={() => {
            onClickApply?.();
            onClose();
          }}
        />
      </PopoverFooter>
    </PopoverContent>
  );
};

export default FilterPopoverContent;
