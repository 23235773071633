import React, {useState} from 'react';
import AnswerItem from '../AnswerItem/AnswerItem';
import { Grid } from '@chakra-ui/react';
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
    Box
  } from '@chakra-ui/react';
import NextButton from '../NextButton/NextButton';



const AnswerRange = (props: {
  answers: any;
  onAnswerClick: () => void;
  onSaveClick: () => void;
  answerState?: { [x: string]: any };
  result: any;
}) => {
    const [sliderValue, setSliderValue] = useState(props.result ? props.result : props.answers.min)

  const labelStyles = {
    mt: '2',
    ml: '-2.5',
    fontSize: 'sm',
  }

    return (
<Grid gap="1rem" templateColumns="1fr" gridAutoRows="1fr 1fr">
        <Box pt={6} pb={2}>
      <Slider aria-label='slider-ex-6'
          

        onChange={(val) => setSliderValue(val)}
        min={props.answers.min}
        max={props.answers.max}
        defaultValue={sliderValue}
        >
        <SliderMark color="white" opacity="0.5" value={((props.answers.max - props.answers.min)*0.25 + props.answers.min)} {...labelStyles}>
        {parseInt((props.answers.max - props.answers.min)*0.25 + props.answers.min)}
        </SliderMark>
        <SliderMark color="white" opacity="0.5" value={((props.answers.max - props.answers.min)*0.5 + props.answers.min)} {...labelStyles}>
        {parseInt((props.answers.max - props.answers.min)*0.5 + props.answers.min)}
        </SliderMark>
        <SliderMark color="white" opacity="0.5" value={((props.answers.max - props.answers.min)*0.75 + props.answers.min)} {...labelStyles}>
        {parseInt((props.answers.max - props.answers.min)*0.75 + props.answers.min)}
        </SliderMark>
        <SliderMark
          value={sliderValue}
          textAlign='center'
          fontFamily="DIN-Medium"
          bg='#434B30'
          color='white'
          mt='-10'
          ml='-5'
          w='12'
        >
          {sliderValue}
        </SliderMark>
        <SliderTrack >
          <SliderFilledTrack bg='#434B30'/>
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </Box>
   {/* <AnswerItem
        answer={{id: sliderValue, text: sliderValue}}
        onAnswerClick={props.onAnswerClick}
    /> */}
    <NextButton
        answer={{id: sliderValue, text: "Next >", value: sliderValue}}
        onAnswerClick={props.onAnswerClick}
        onSaveClick={props.onSaveClick}
    />

    </Grid>
    
);
};

export default AnswerRange;
