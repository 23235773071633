import React from 'react';
import Icon from '../Icon';

export type Sentiment = 'positive' | 'negative' | 'neutral' | 'caution';

export const calculateSentiment = (
  coverage: number,
  threshold = 80
): Sentiment => {
  return coverage > threshold ? 'positive' : 'negative';
};

export const getColorBySentiment = (sentiment: Sentiment) => {
  switch (sentiment) {
    case 'positive':
      return 'status.ok';
    case 'negative':
      return 'status.error';
    case 'neutral':
      return 'status.aborted';
    case 'caution':
      return 'status.warning';
    default:
      return 'status.aborted';
  }
};

export const getIconBySentiment = (sentiment: Sentiment) => {
  switch (sentiment) {
    case 'positive':
      return <Icon glyph="check-filled" />;
    case 'negative':
      return <Icon glyph="alert" />;
    case 'neutral':
      return null;
    case 'caution':
      return <Icon glyph="alert" />;
    default:
      return null;
  }
};
