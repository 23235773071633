import React from 'react';
import { Box } from '@chakra-ui/react';
import Icon from '../Icon';
import Tooltip from '../Tooltip';

export type StatusType = 'pass' | 'fail' | 'warning' | 'unknown';

interface StatusSymbolProps {
  status: StatusType;
  tooltip?: string;
}

function getColor(status: StatusType): string {
  switch (status) {
    case 'pass':
      return 'green.400';
    case 'fail':
      return 'red.400';
    case 'warning':
      return 'yellow.400';
    default:
      return 'gray.400';
  }
}

function getIcon(status: StatusType): JSX.Element {
  switch (status) {
    case 'pass':
      return <Icon glyph="check-filled" />;
    case 'fail':
    case 'warning':
      return <Icon glyph="alert-filled" />;
    default:
      return <Icon glyph="question-filled" />;
  }
}

export default function StatusSymbol({
  status,
  tooltip,
}: StatusSymbolProps): JSX.Element {
  return (
    <Tooltip label={tooltip} isDisabled={tooltip === undefined}>
      <Box color={getColor(status)} fontSize="20px">
        {getIcon(status)}
      </Box>
    </Tooltip>
  );
}
