import { XAxisPlotBandsOptions } from 'highcharts';

export default function getWeekendBands(
  startDate: Date,
  endDate?: Date
): XAxisPlotBandsOptions[] {
  const weekends: XAxisPlotBandsOptions[] = [];

  const current = new Date(endDate || new Date());

  // Rewind to the previous midnight on Monday morning.
  current.setDate(current.getDate() - current.getDay() + 1);
  current.setHours(0);
  current.setMinutes(0);
  current.setSeconds(0);

  while (current > startDate) {
    const sunday = new Date(current);

    current.setDate(current.getDate() - 2);

    const saturday = new Date(current);

    weekends.push({
      color: 'rgba(0, 0, 0, 0.05)',
      from: saturday.getTime(),
      to: sunday.getTime(),
    });

    current.setDate(current.getDate() - 5);
  }

  return weekends;
}
