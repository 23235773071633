/* eslint-disable no-console */

type Attributes = { [key: string]: string };

function fetchAttributes(): Attributes {
  if (!document) {
    return {};
  }

  const meta = document
    .querySelector('meta[name="config"]')
    ?.getAttribute('content');

  if (!meta) {
    return {};
  }

  try {
    return JSON.parse(meta);
  } catch (ex) {
    console.error(ex);
    return {};
  }
}

let cache: Attributes;

export default function useClientConfig(): Attributes {
  if (!cache) {
    cache = fetchAttributes();
  }

  return cache;
}
