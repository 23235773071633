import React from 'react';
import { Icon } from '..';
import { Sentiment } from '../util/sentiment';

interface Props {
  data: number[];
  height?: number;
  invertSentiment?: boolean;
  stepped?: boolean;
  width?: number;
}

const margin = 5;

// Converts the literal value scale into a ratio based on the chart height
function parseData(data: number[], height: number) {
  let workingData = [...data];

  // Show a flat line if we have no data
  if (data.length === 0) {
    workingData = [1, 1];
  }

  const min = Math.min(...workingData);
  const max = Math.max(...workingData);
  const area = height - 2 * margin;

  return workingData.map(point => {
    if (min === max) {
      return 0.5 * area;
    }

    return area - ((point - min) / (max - min)) * area;
  });
}

// Coverts the number array into an SVG path
function plot(data: number[], width: number, stepped: boolean) {
  const spacing = (width - 2 * margin) / (data.length - 1);

  const path: string[] = [];

  data.forEach((point, i) => {
    if (stepped) {
      if (i !== data.length - 1) {
        path.push(
          `${i === 0 ? 'M' : 'L'} ${i * spacing + margin} ${point + margin}`
        );
        path.push(`L ${(i + 1) * spacing + margin} ${point + margin}`);
      }
    } else {
      path.push(
        `${i === 0 ? 'M' : 'L'} ${i * spacing + margin} ${point + margin}`
      );
    }
  });

  return path.join(' ');
}

function getSentiment(data: number[] | undefined, invert = false): Sentiment {
  if (!data || data.length === 0) {
    return 'neutral';
  }

  const firstItem = data[0];
  const lastItem = data[data.length - 1];

  if (firstItem === lastItem) {
    return 'neutral';
  }

  if (firstItem > lastItem) {
    return invert ? 'positive' : 'negative';
  }

  return invert ? 'negative' : 'positive';
}

function getLineColor(sentiment: Sentiment) {
  switch (sentiment) {
    case 'positive':
      return 'status.ok';
    case 'negative':
      return 'status.error';
    default:
      return 'gray.400';
  }
}

export default function SparkChart({
  data,
  invertSentiment = false,
  height = 30,
  stepped = false,
  width = 100,
}: Props): JSX.Element {
  const normalizedData = parseData(data, height);
  const path = plot(normalizedData, width, stepped);
  const sentiment = getSentiment(data, invertSentiment);

  return (
    <Icon
      fill="none"
      stroke={getLineColor(sentiment)}
      strokeWidth="2px"
      strokeLinecap="round"
      strokeLinejoin="round"
      h={`${height}px`}
      w={`${width}px`}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d={path} />
    </Icon>
  );
}
