import businessTime from 'dayjs-business-time';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localized from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utcTime from 'dayjs/plugin/utc';

dayjs.extend(businessTime);
dayjs.extend(duration);
dayjs.extend(localized);
dayjs.extend(relativeTime);
dayjs.extend(utcTime);

/*
 * If we want to exclude holidays, we can add them to this array.
 * dayjs.setHolidays(['2021-12-24']);
 */

/**
 * A wrapped version of dayjs that includes the common plugins. Webpack does
 * not recognize the extended dayjs by default when creating async bundles.
 */
export default dayjs;
