import React, { useState } from 'react';
import { Flex, Grid, Text } from '../../../../ui';
import { GridItem, useMediaQuery } from '@chakra-ui/react';
import EnvironmentalAssesmentItems from './EnvironmentalAssesmentItems';
import ImageWithPopover from './ImageWithPopover';
import { environmentalFactorsCopy } from '../../../../constants/copy';
import {
  defaultHorizontalGap,
  defaultVerticalGap,
  enviroFactors,
} from '../../../../constants';

const title = (
  <Text color="#E3DBD3" fontFamily="DIN-Bold" fontSize="2xl" lineHeight="130%">
    Environmental Health Assessment
  </Text>
);

const description = (
  <Text
    color="#7D7C7A"
    fontFamily="DIN-Regular"
    fontSize="18px"
    fontWeight="5"
    lineHeight="150%"
  >
    The MNLY online Environmental analysis provides valuable insights into the
    external factors that impact your health and well-being. It involves
    examining lifestyle choices, dietary patterns, physical activity levels,
    stress levels, sleep quality, focus ability, libido performance, social
    behavior & recovery factors that influence overall health.
  </Text>
);
const LifeStyleHealth = () => {
  const [isCompact] = useMediaQuery('(max-width: 992px)');
  const [hovered, setHovered] = useState(false);
  const [factorCategory, setFactorCategory] = useState('');

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      width="100%"
      gap={defaultHorizontalGap}
    >
      {isCompact ? (
        <GridItem colSpan={{ base: 2 }}>
          <Flex flexDirection="column" gap={defaultVerticalGap}>
            {title}
            <ImageWithPopover
              hovered={hovered}
              factorCategory={factorCategory}
              imagePath={'/static/ads/lifestyle-results-homepage.png'}
              factors={enviroFactors}
              factorsCopy={environmentalFactorsCopy}
            />
            {description}
            <EnvironmentalAssesmentItems
              setHovered={setHovered}
              setFactorCategory={setFactorCategory}
            />
          </Flex>
        </GridItem>
      ) : (
        <>
          <GridItem
            colSpan={{ base: 1, sm: 2, md: 1 }}
            order={{ sm: 3, md: 2 }}
          >
            <Flex flexDirection="column" gap={defaultVerticalGap}>
              {title}
              {description}
              <EnvironmentalAssesmentItems
                setHovered={setHovered}
                setFactorCategory={setFactorCategory}
              />
            </Flex>
          </GridItem>
          <GridItem
            colSpan={{ base: 1, sm: 2, md: 1 }}
            order={{ sm: 2, md: 3 }}
          >
            <ImageWithPopover
              hovered={hovered}
              factorCategory={factorCategory}
              imagePath={'/static/ads/lifestyle-results-homepage.png'}
              factors={enviroFactors}
              factorsCopy={environmentalFactorsCopy}
            />
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default LifeStyleHealth;
