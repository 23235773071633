import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  category: string;
  description: string;
  score: number;
  color: string;
};
const ScoreCategoryAndPercentage = ({
  category,
  description,
  score,
  color,
}: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Flex flexDirection="column" onClick={() => setExpanded(!expanded)}>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        style={{
          borderTop: '2px solid #3E362E',
          height: '3rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        }}
        _hover={{
          bg: '#685848',
        }}
        transition="background-color 200ms linear"
      >
        <Flex flexDirection="row" gap="1rem">
          <Box width="20px" height="20px" bg={color} borderColor="#3E362E" />
          <Box
            color="#E3DBD3"
            fontFamily="DIN-Regular"
            fontWeight="semibold"
            fontSize="14px"
          >
            {`${category}  ${Math.round(score)}%`}
          </Box>
        </Flex>
        <Box>
          {expanded ? (
            <img
              alt="MNLY Precision Men's Health"
              src="/static/icons/minus.png"
              width="100%"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
                fill="#E3DBD3"
              />
            </svg>
          )}
        </Box>
      </Flex>
      {expanded && (
        <Flex p="2%">
          <Text
            color="#E3DBD3"
            fontFamily="DIN-Regular"
            fontSize="14px"
            lineHeight="130%"
            width="300px"
          >
            {description}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ScoreCategoryAndPercentage;
