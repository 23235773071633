/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { Box, Card, ChakraLink, HStack, Link, Text } from '..';
import Icon from '../Icon';
import type { LinkEntry, NavigationEntry } from './PageContext';

interface NavigationProps {
  links: LinkEntry[];
  navigation: NavigationEntry[];
}

/**
 * The expanded navigation menu rendered by the Page component.
 */
export function Navigation({ navigation, links }: NavigationProps) {
  if (navigation.length === 0) {
    return null;
  }

  return (
    <>
      <Card noContentPadding>
        <Box pt={4} pb={2}>
          {navigation.map(({ enabled = true, icon, label, to }) => {
            if (!enabled) {
              return null;
            }

            return (
              <Box role="group" key={to}>
                <Link
                  to={to}
                  display="block"
                  borderLeft="3px solid transparent"
                  px={4}
                  py={3}
                  _activeLink={{
                    background: 'blue.50',
                    borderLeftColor: 'blue.400',
                    color: 'blue.400',
                  }}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  transition="background 150ms, border 150ms"
                  end
                >
                  <HStack spacing="2">
                    <Icon glyph={icon} boxSize="4" transition="color 150ms" />
                    <Text
                      color="gray.700"
                      transition="color 150ms"
                      _groupHover={{ color: 'blue.500' }}
                    >
                      {label}
                    </Text>
                  </HStack>
                </Link>
              </Box>
            );
          })}
        </Box>
      </Card>

      <Box mt={2}>
        {links.map(({ enabled = true, icon, label, href }) => {
          if (!enabled) {
            return null;
          }

          return (
            <ChakraLink
              href={href}
              key={href}
              aria-disabled="true"
              color="gray.500"
              _hover={{
                color: 'blue.600',
                textDecoration: 'none',
              }}
              display="block"
              px={4}
              py={3}
              borderLeft="3px solid transparent"
            >
              <HStack spacing="2">
                <Icon glyph={icon} boxSize="4" />
                <Text>{label}</Text>
                <Icon glyph="external-link" boxSize="4" />
              </HStack>
            </ChakraLink>
          );
        })}
      </Box>
    </>
  );
}

export default Navigation;
