import React from 'react';
import { Input, InputGroup, InputRightElement } from '..';
import { useSearchState } from './useSearchState';
import Icon from '../Icon';

export interface SearchFilterProps {
  clearParamsOnSubmit?: string[];
  onSubmit?: (values: string) => void;
  placeholder?: string;
  queryParam?: string;
}

export const SearchFilter = ({
  clearParamsOnSubmit,
  placeholder = 'Search',
  onSubmit,
  queryParam = 'search',
}: SearchFilterProps) => {
  const state = useSearchState({
    clearParamsOnSubmit,
    defaultValue: '',
    onSubmit,
    queryParam,
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    state.onSubmit();
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup size="sm">
        <Input
          rounded="md"
          placeholder={placeholder}
          value={state.value}
          onChange={e => {
            state.onChange(e.currentTarget.value);
          }}
          onBlur={state.onSubmit}
        />

        <InputRightElement>
          <Icon glyph="search" color="gray.400" />
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default SearchFilter;
