import React from 'react';
import TopMenu from './components/TopMenu';
import { Box, Flex } from '@chakra-ui/react';
import Footer from '../../pages/Products/components/Footer';

/** Shape of a link within a specific lifecycle phase. */
export interface AnchorLink {
  enabled?: boolean;
  group: number;
  href?: string;
  index: number;
  isButton?: boolean;
  label: string;
  subLabel?: string;
}

export interface ShellProps {
  children: React.ReactNode;
  links?: AnchorLink[];
  name?: string;
}

/**
 * The shell is the wrapper around the application, including the primary
 * navigation and other common elements like user profile, notifiications,
 * time selectors, etc.
 */
export default function Shell({
  children,
  links = [],
  name,
}: ShellProps): JSX.Element {
  // Filter out disabled links.
  const enabledLinks = links.filter(link => link.enabled !== false);

  return (
    <Box id="shell" bg="#141414" h="calc(100vh)">
      <Flex flexDirection="column">
        <TopMenu name={name} links={enabledLinks} />
        <Flex flexDirection="column" flexGrow={1}>
          {children}
          <Footer />
        </Flex>
      </Flex>
    </Box>
  );
}
