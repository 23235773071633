import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Athletes from "./Athletes";
const AthletesPage = () => {

  return(
    <Routes>
      <Route index element={<Athletes /> } />
    </Routes>
  )
}

export default AthletesPage;
