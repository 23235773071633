import { Box, Flex, Heading, HStack, Spacer } from '@chakra-ui/react';
import React from 'react';
import Link from '../Link';
import { Tooltip } from '..';

export interface Props {
  action?: React.ReactNode;
  footnote?: React.ReactNode;
  footnoteRight?: React.ReactNode;
  subtitle?: string;
  title?: string;
  to?: string;
  tooltip?: React.ReactNode;
}

export default function CardHeader({
  action,
  footnote,
  footnoteRight,
  subtitle,
  title,
  to,
  tooltip,
}: Props): JSX.Element {
  return (
    <Flex align="center" justify="space-between" p={2} borderBottomWidth="1px">
      <Heading as="h2" fontSize="lg" p={2}>
        {to ? <Link to={to}>{title}</Link> : title}
        {subtitle ? (
          <Box
            color="#7f8fa4"
            display="inline"
            fontStyle="normal"
            paddingLeft="4px"
          >
            {subtitle}
          </Box>
        ) : null}
      </Heading>

      {tooltip ? <Tooltip label={tooltip} /> : null}

      <Spacer />

      <Box>{action}</Box>
      {footnote || footnoteRight ? (
        <HStack fontSize="sm" color="gray.600">
          <Box>{footnote}</Box>
          <Box textAlign="right">{footnoteRight}</Box>
        </HStack>
      ) : null}
    </Flex>
  );
}
