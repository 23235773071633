import React from 'react';
import { Box, Flex, Spinner, Text } from '..';

interface Props {
  children?: React.ReactNode;
  enabled?: boolean;
  height?: number;
  label?: string;
}

export default function LoadingBox({
  children,
  enabled = true,
  height,
  label = 'Loading',
}: Props): JSX.Element {
  if (enabled) {
    return (
      <Flex align="center" justify="center" minHeight={height}>
        <Box textAlign="center">
          <Spinner color="gray.500" emptyColor="gray.200" />
          <Text fontSize="16px">{label}</Text>
        </Box>
      </Flex>
    );
  }

  return <> {children} </>;
}
