import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Unit = 'percent';

interface Props {
  negativePrefix?: string;
  negativeValue?: number;
  positivePrefix?: string;
  positiveValue?: number;
  scale?: number;
  valueUnits?: Unit;
}

function Rate({
  barRatio,
  prefix,
  status,
  unit,
  value,
}: {
  barRatio: number;
  prefix: string;
  status: 'favorable' | 'unfavorable';
  unit?: Unit;
  value: number;
}): JSX.Element {
  let color: string;

  if (value === 0) {
    color = 'gray.400';
  } else if (status === 'favorable') {
    color = 'sentiment.great';
  } else {
    color = 'sentiment.poor';
  }

  const bar = (
    <Box
      flex="0 0 auto"
      height={2}
      width={`${Math.floor(barRatio * 40)}px`}
      backgroundColor={color}
    />
  );

  return (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent={status === 'unfavorable' ? 'flex-end' : 'flex-start'}
    >
      {status === 'favorable' ? bar : null}
      <Text as="span" fontSize="xs" color={color} px={1}>
        {prefix}
        {value.toLocaleString()}
        {unit === 'percent' ? '%' : ''}
      </Text>
      {status === 'unfavorable' ? bar : null}
    </Flex>
  );
}

export default function RateIndicator({
  negativePrefix = '-',
  negativeValue = 0,
  positivePrefix = '+',
  positiveValue = 0,
  scale,
  valueUnits,
}: Props): JSX.Element {
  const max = Math.max(scale || Math.max(positiveValue, negativeValue), 1);

  return (
    <Flex>
      <Rate
        status="unfavorable"
        prefix={negativePrefix}
        value={negativeValue}
        unit={valueUnits}
        barRatio={negativeValue / max}
      />
      <Box height={2} width="1px" />
      <Rate
        status="favorable"
        prefix={positivePrefix}
        value={positiveValue}
        unit={valueUnits}
        barRatio={positiveValue / max}
      />
    </Flex>
  );
}
