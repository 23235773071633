import React from 'react';
import {
  HStack,
  Icon as ChakraIcon,
  PopoverTrigger,
  Text,
  useColorModeValue as mode,
} from '..';
import Icon from '../Icon';

export type FilterPopoverButtonProps = {
  icon?: React.ElementType;
  label: string;
  selected?: boolean;
  value?: string;
};

export const FilterPopoverButton = ({
  label,
  icon,
  selected,
  value,
}: FilterPopoverButtonProps) => {
  return (
    <PopoverTrigger>
      <HStack
        justify="space-between"
        position="relative"
        as="button"
        fontSize="sm"
        borderWidth="1px"
        zIndex="11"
        rounded="lg"
        paddingStart="3"
        paddingEnd="2"
        paddingY="1.5"
        spacing="1"
        data-selected={selected || undefined}
        _expanded={{ bg: mode('gray.100', 'gray.700') }}
        _selected={{ bg: 'blue.50', borderColor: 'blue.500' }}
      >
        {icon && <ChakraIcon as={icon} boxSize="2" />}
        <Text fontWeight="medium" whiteSpace="nowrap">
          {value ? `${label}: ${value}` : label}
        </Text>
        <Icon glyph="chevron-down" fontSize="xl" color="gray.400" />
      </HStack>
    </PopoverTrigger>
  );
};
