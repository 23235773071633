import React from 'react';
import {
  Box,
  Button,
  ExternalLink,
  Flex,
  Heading,
  HStack,
  Text,
  VStack,
} from '..';

export interface EmptyStateProps {
  description?: React.ReactNode;
  documentation?: string;
  icon: React.ReactNode;
  label?: string;
  onClick?: () => void;
  title: React.ReactNode;
}

export default function EmptyState({
  description,
  documentation,
  icon,
  label,
  onClick,
  title,
}: EmptyStateProps): JSX.Element {
  return (
    <VStack spacing={8} my={8}>
      <Box border="2px" borderColor="#00CF92" borderRadius="full" p={1}>
        <Flex
          align="center"
          bgGradient="linear(to-br, #00CF92, #009CDE)"
          borderRadius="full"
          color="white"
          fontSize="6xl"
          height={28}
          justify="center"
          width={28}
        >
          {icon}
        </Flex>
      </Box>
      <Box textAlign="center" maxWidth="800px">
        <Heading fontSize="3xl" pb={2}>
          {title}
        </Heading>
        {description ? (
          <Text fontSize="lg" color="gray.600">
            {description}
          </Text>
        ) : null}
      </Box>
      <HStack>
        {onClick && label ? (
          <Button colorScheme="blue" size="lg" onClick={onClick}>
            {label}
          </Button>
        ) : null}
        {documentation ? (
          <ExternalLink hideIcon href={documentation}>
            <Button colorScheme="blue" variant="ghost" size="lg">
              Learn more
            </Button>
          </ExternalLink>
        ) : null}
      </HStack>
    </VStack>
  );
}
