import { Flex, Grid, Text } from '../../../../ui';
import React, { useState } from 'react';
import { GridItem, useMediaQuery } from '@chakra-ui/react';
import BiologicalAssessmentItems from './BiologicalAssessmentItems';
import ImageWithPopover from './ImageWithPopover';
import { biologicalFactorsCopy } from '../../../../constants/copy';
import {
  bioFactors,
  defaultHorizontalGap,
  defaultVerticalGap,
} from '../../../../constants';

const title = (
  <Text
    color="#E3DBD3"
    fontFamily="DIN-Bold"
    fontSize="2xl"
    fontStyle="normal"
    lineHeight="130%"
  >
    Biological Health Assessment
  </Text>
);

const description = (
  <Text
    color="#7D7C7A"
    fontFamily="DIN-Regular"
    fontSize="18px"
    fontStyle="normal"
    fontWeight="5"
    lineHeight="150%"
  >
    MNLY at home lab kits offer an effortless biological analysis providing
    essential insights into your distinct physiology and predispositions,
    enabling a comprehensive understanding of 19 biomarkers to gauge
    cardiometabolic, Immune, cognitive, Hormonal, longevity, Adrenal and
    gastrointestinal health.
  </Text>
);

const BiologicalHealth = () => {
  const [isCompact] = useMediaQuery('(max-width: 992px)');

  const [factorCategory, setFactorCategory] = useState('');

  const [hovered, setHovered] = useState(false);

  return (
    <Grid
      templateColumns="repeat(2, 1fr)"
      width="100%"
      gap={defaultHorizontalGap}
    >
      {isCompact ? (
        <GridItem colSpan={{ base: 2 }}>
          <Flex flexDirection="column" gap={defaultVerticalGap}>
            {title}
            <ImageWithPopover
              hovered={hovered}
              factorCategory={factorCategory}
              imagePath={'/static/ads/mnly-box.png'}
              factors={bioFactors}
              factorsCopy={biologicalFactorsCopy}
            />

            {description}
            <BiologicalAssessmentItems
              setHovered={setHovered}
              setFactorCategory={setFactorCategory}
            />
          </Flex>
        </GridItem>
      ) : (
        <>
          <GridItem colSpan={{ base: 1, sm: 2, lg: 1 }}>
            <ImageWithPopover
              hovered={hovered}
              factorCategory={factorCategory}
              imagePath={'/static/ads/mnly-box.png'}
              factors={bioFactors}
              factorsCopy={biologicalFactorsCopy}
            />
          </GridItem>
          <GridItem colSpan={{ base: 1, sm: 2, lg: 1 }}>
            <Flex flexDirection="column" gap={defaultVerticalGap}>
              {title}
              {description}
              <BiologicalAssessmentItems
                setHovered={setHovered}
                setFactorCategory={setFactorCategory}
              />
            </Flex>
          </GridItem>
        </>
      )}
    </Grid>
  );
};

export default BiologicalHealth;
