import React, { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NotFoundPage from './pages/404';
import QuizPage from './pages/Quiz';
import ProductPage from './pages/Products';
import DashboardPage from './pages/Dashboard';
import InfoPage from './pages/Dashboard';
import CompanyPage from './pages/Company';
import Shell from './ui/Shell';
import { LoadingBox } from './ui';
import LogInPage from './pages/LogIn';
import ForgotPasswordPage from './pages/ForgotPassword';
import LogInInstructionsPage from './pages/LogIn/LogInInstructions';
import AthleteApplicationPage from './pages/Athletes';
import ManageSubscriptionsPage from './pages/ManageSubscriptions';

// Removes the focus outlines that appeared when tabs/other elements were clicked,
// while still keeping the outlines when doing keyboard navigation.
// https://github.com/chakra-ui/chakra-ui/issues/2016#issuecomment-691674038
import 'focus-visible/dist/focus-visible';
import useRedirects from './hooks/useRedirects';
import InstructionsPage from './pages/Instructions';
import WaitlistPage from './pages/Waitlist';

// Workaround for fast refresh sometimes not working due to the lazy imports.
// In dev mode we don't really need the lazy imports, so just disable it (by loading
// everything in the beginning) so that fast refresh isn't buggy.
if (process.env.NODE_ENV !== 'production') {
  import('./pages/Home/index');
}

const HomePage = React.lazy(() => import('./pages/Home/index'));

const App = (): React.ReactElement => {
  useRedirects();

  const location = useLocation();

  const links = [
    {
      enabled: true,
      group: 1,
      href: '/#HowItWorks',
      index: 0,
      label: 'HOW IT WORKS',
    },
    {
      enabled: true,
      group: 1,
      href: '/#OurScienceTeam',
      index: 1,
      label: 'OUR SCIENCE TEAM',
    },
    {
      enabled: true,
      group: 1,
      href: '/athletes',
      index: 1,
      label: 'ATHLETE APPLICATION',
    },
    {
      enabled: true,
      group: 2,
      href: '/product',
      index: 0,
      isButton: true,
      label: 'GET STARTED',
    },
    {
      enabled: true,
      group: 2,
      href: '/dashboard',
      index: 1,
      label: 'MY ACCOUNT',
    },
  ];

  let routes;
  if (
    location.pathname.toUpperCase() === '/LOGIN' ||
    location.pathname.toUpperCase() === '/LOGININSTRUCTIONS' ||
    // location.pathname.toUpperCase() === '/SIGNUP' ||
    location.pathname.toUpperCase() === '/FORGOTPASSWORD'
    // location.pathname.toUpperCase() === '/SETTINGS'
  ) {
    routes = (
      <Suspense fallback={<LoadingBox height={400} />}>
        <Routes location={location} key={location.pathname}>
          <Route path="login/*" element={<LogInPage />} />
          <Route path="ForgotPassword/*" element={<ForgotPasswordPage />} />
          <Route path="logInInstructions" element={<LogInInstructionsPage />} />
          {/* <Route path="settings" element={<SettingsPage />} /> */}
        </Routes>
      </Suspense>
    );
  } else {
    routes = (
      <Suspense fallback={<LoadingBox height={400} />}>
        <Shell links={links}>
          <Routes location={location} key={location.pathname}>
            <Route index element={<HomePage />} />
            <Route path="quiz/*" element={<QuizPage />} />
            <Route path="product/*" element={<ProductPage />} />
            <Route path="company/*" element={<CompanyPage />} />
            <Route path="dashboard/*" element={<DashboardPage />} />
            <Route path="instructions/*" element={<InstructionsPage />} />
            <Route path="info/*" element={<InfoPage />} />
            <Route path="waitlist/*" element={<WaitlistPage />} />
            <Route path="athletes/*" element={<AthleteApplicationPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="manageSubscriptions/*" element={<ManageSubscriptionsPage />} />
          </Routes>
        </Shell>
      </Suspense>
    );
  }

  return routes;
};

export default App;
