import { createGlobalStyle } from 'styled-components';
import theme from './theme';

type Theme = typeof theme;

export default createGlobalStyle<{ theme: Theme }>`

  html {
    background-color: #141414;
  }
  body {
    color: ${props => props.theme.colors.base.dark};
    margin: 0;
    ${props => props.theme.typography.bodyRegular}
  }

  h1 {
    ${props => props.theme.typography.header1}
  }

  h2 {
    ${props => props.theme.typography.header2}
  }

  h3 {
    ${props => props.theme.typography.header3}
  }

  h4 {
    ${props => props.theme.typography.bodyLargeBold}
  }

  a {
    color: ${props => props.theme.colors.primary.normal};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.colors.primary.hover};
      text-decoration: underline;
    }
  }
  @font-face {
    font-family: DIN-Medium;
    src: url("/static/fonts/DIN-Medium.otf") format("opentype"), url("/static/fonts/DIN-Medium.otf") format('truetype');
  }
  @font-face {
      font-family: DIN-Regular;
      src: url("/static/fonts/DIN-Regular.otf") format("opentype"), url("/static/fonts/DIN-Regular.otf") format('truetype');
  }
  @font-face {
    font-family: DIN-Bold;
    src: url("/static/fonts/DIN-Bold.otf") format("opentype"), url("/static/fonts/DIN-Bold.otf") format('truetype');
  }
  @font-face {
    font-family: DIN-Light;
    src: url("/static/fonts/DIN-Light.otf") format("opentype"), url("/static/fonts/DIN-Light.otf") format('truetype');
  }
  html { scroll-behavior: smooth; }
  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes our-methodology-frame1 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.8;
    }
    20% {
      opacity: 0.8;
    }
    30% {
      opacity: 0.8;
    }
    40% {
      opacity: 0.8;
    }
    50% {
      opacity: 0.8;
    }
    60% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.8;
    }
    80% {
      opacity: 0.8;
    }
    90% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.8;
    }
  }
  @keyframes our-methodology-frame2-0 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes our-methodology-frame2-3 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes our-methodology-frame2-6 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes our-methodology-frame2-9 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes our-methodology-frame2-center {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;
