import React from 'react';
import { Box, Flex, Heading, Text, VStack } from '..';
import Icon from '../Icon';

interface Props {
  description?: React.ReactNode;
  title: React.ReactNode;
}

export default function ErrorState({ description, title }: Props): JSX.Element {
  return (
    <VStack spacing={8} my={8}>
      <Box border="2px" borderColor="#FF9600" borderRadius="full" p={1}>
        <Flex
          align="center"
          bgGradient="linear(to-br, #FF9600, #DE0063)"
          borderRadius="full"
          color="white"
          fontSize="6xl"
          height={28}
          justify="center"
          width={28}
        >
          <Icon glyph="x" />
        </Flex>
      </Box>
      <Box textAlign="center" maxWidth="800px">
        <Heading fontSize="3xl" pb={2}>
          {title}
        </Heading>
        {description ? (
          <Text fontSize="lg" color="gray.600">
            {description}
          </Text>
        ) : null}
      </Box>
    </VStack>
  );
}
