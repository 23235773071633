import PageBoundary from '../../ui/ErrorBoundary';
import InstructionsStepper from './components/InstructionsStepper';
import React, { useEffect, useState } from 'react';
import { defaultVerticalGap } from '../../constants';
import { Flex } from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

export const instructionStages = ['0', '1', '2', '3', '4', '5', '6', '7', '8'];

// TODO - detect if kit is not already registered (would only work if we implemented user sessions, currently they are forced to log
//  in if they manually type in this url, so there is no way to get here unless the app sends you here)
const Instructions = () => {
  const navigate = useNavigate();
  const [searchParams, updateSearchParams] = useSearchParams();
  const [activeStage, setActiveStage] = useState<string>(instructionStages[0]);

  const { user } = useSelector(state => {
    // @ts-ignore
    return state.session;
  });

  useEffect(() => {
    if (!user?.id) {
      navigate({ pathname: '/login', search: searchParams.toString() });
    }
  }, []);

  return (
    <PageBoundary>
      <Flex
        flexDirection="column"
        gap={defaultVerticalGap}
        justifyContent="space-between"
        height="100%"
      >
        <InstructionsStepper
          activeStage={activeStage}
          setActiveStage={setActiveStage}
        />
      </Flex>
    </PageBoundary>
  );
};

function mapStateToProps(state: { session: any }) {
  return {
    user: state.session.user,
  };
}
export default connect(mapStateToProps, {})(Instructions);
