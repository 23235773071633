/**
 * Divides the 'value' by the 'total' and formats the result as a percent
 * string clamped between 0% and 100%.
 */
export default function toPercent(
  value: number,
  total: number,
  digits = 1
): string {
  const ratio = value / total;

  if (ratio >= 1) {
    return '100%';
  }

  if (ratio <= 0) {
    return `${(0).toFixed(digits)}%`;
  }

  return `${(ratio * 100).toFixed(digits)}%`;
}
