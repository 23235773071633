import React from 'react';
import { Box } from '@chakra-ui/react';
import { SortDirection } from '@tanstack/react-table';
import Icon from '../Icon';

interface Props {
  direction?: false | SortDirection;
}

export default function SortIcon({ direction }: Props): JSX.Element | null {
  return direction !== undefined ? (
    <Box display="inline-grid" height="6">
      <Icon
        boxSize="16px"
        color={direction === 'desc' ? 'gray.600' : 'gray.300'}
        glyph="sort-up"
        position="relative"
        top="1px"
      />
      <Icon
        boxSize="16px"
        color={direction === 'asc' ? 'gray.600' : 'gray.300'}
        glyph="sort-down"
        position="relative"
        top="-10px"
      />
    </Box>
  ) : null;
}
