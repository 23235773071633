import React from 'react';
import { Button, Flex, Text, Box } from '@chakra-ui/react';
import { defaultVerticalGap } from '../../../constants';
import {
  InstructionSubtext,
  InstructionTitle,
} from './InstructionCommonComponents';
import {ActionButton} from "../../../components/Buttons";
import './InstructionVideo.css'

export type InstructionStageProps = {
  activeStage: string | undefined;
  setActiveStage: (stage: string) => void;
};

const Step0 = ({ setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-0"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
    >
      <InstructionTitle>Your blood sample kit is registered.</InstructionTitle>
      <InstructionSubtext>
        Please follow these steps to take your sample and return it to our lab.
      </InstructionSubtext>
      <Box className='responsive-video'>
      <iframe className='responsive-iframe' src="https://drive.google.com/file/d/1lnSOQCcZ3fnSN6IzIohq1_acpMzAH7C8/preview" allow="fullscreen" allowFullScreen='true'></iframe>
      </Box>
      <ActionButton onClick={() => setActiveStage('1')}>
        Continue Activation
      </ActionButton>
    </Flex>
  );
};

export default Step0;
