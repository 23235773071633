/* eslint-disable no-console */

import React from 'react';

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { props, state } = this;

    if (state.hasError) {
      return props.fallback ?? <h1>Something went wrong.</h1>;
    }

    return props.children;
  }
}
