import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UseFilterStateProps<TValue> = {
  defaultValue: TValue;
  onSubmit?: (value: TValue) => void;
  parseValue?: (value: string) => TValue;
  queryParam: string;
  serializeValue?: (value: TValue) => string;
};

function getSearchQuery<TValue>(
  searchParams: URLSearchParams,
  param: string,
  parser: (value: string) => TValue
): TValue | undefined {
  const value = searchParams.get(param);

  if (!value) {
    return undefined;
  }

  return parser(value);
}

export function useFilterState<TValue = string>({
  defaultValue,
  onSubmit,
  queryParam,
  serializeValue = value => JSON.stringify(value),
  parseValue = value => JSON.parse(value),
}: UseFilterStateProps<TValue>) {
  const [searchParams, updateSearchParams] = useSearchParams();

  const [state, setState] = useState<TValue>(
    getSearchQuery(searchParams, queryParam, parseValue) ?? defaultValue
  );

  const [submittedValue, setSubmittedValue] = useState<TValue>(
    getSearchQuery(searchParams, queryParam, parseValue) ?? defaultValue
  );

  return {
    canCancel: defaultValue !== state,
    onChange: setState,
    onReset() {
      setState(defaultValue);
    },
    onSubmit() {
      const serialized = serializeValue(state);

      if (serialized === '') {
        searchParams.delete(queryParam);
      } else {
        searchParams.set(queryParam, serialized);
      }

      setSubmittedValue(state);
      updateSearchParams(searchParams);
      onSubmit?.(state);
    },
    submittedValue,
    value: state,
  };
}
