import React from 'react';
import { Box, Image, Text, useTheme } from '@chakra-ui/react';

interface Props {
  description?: string | React.ReactNode;
  src?: string;
  title?: string;
}

export default function Placeholder({
  description,
  src,
  title,
}: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      alignItems="center"
      data-testid="placeholder-illustration-test"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      pb={20}
      height="80vh"
    >
      {src ? (
        <Image
          alt=""
          height="200px"
          src={src}
          width="auto"
          style={{
            filter: 'invert(100%)',
          }}
        />
      ) : null}
      {title ? (
        <Text
          color={theme.colors.black}
          fontFamily="DIN-Bold"
          fontSize="5xl"
          fontWeight="bold"
          margin="20px 0 10px"
          style={{
            filter: 'invert(100%)',
          }}
        >
          {title}
        </Text>
      ) : null}
      {description ? (
        <Text
          color={theme.colors.darkgray}
          fontFamily="DIN-Regular"
          fontSize="2xl"
          lineHeight={1.3}
          margin={2}
          style={{
            filter: 'invert(100%)',
          }}
          maxWidth="600px"
        >
          {description}
        </Text>
      ) : null}
    </Box>
  );
}
