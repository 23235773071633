import React from 'react';
import {
  Box,
  Card,
  Grid,
  GridItem,
  HStack,
  Link,
  Score,
  Text,
  Tooltip,
} from '..';

export interface ScoreCriteria {
  label: string;
  score: number;
  to?: string;
  tooltip?: React.ReactNode;
  weight?: number;
}

interface Props {
  criteria?: ScoreCriteria[];
  error?: string;
  loading?: boolean;
  title: string;
  tooltip?: React.ReactNode;
}

/**
 * The Scorecard presents the user with an overall score based on a list
 * of individual criteria. Each criteria has its own score which contributes
 * to the overall score with an optional weighting.
 */
export default function Scorecard({
  criteria = [],
  error,
  loading = false,
  title,
  tooltip,
}: Props): JSX.Element {
  let totalPoints = 0;
  let totalWeight = 0;

  criteria.forEach(({ score, weight = 1 }) => {
    totalPoints += score * weight;
    totalWeight += weight;
  });

  const score = Math.round(totalPoints / totalWeight);

  return (
    <Card title={title} tooltip={tooltip} loading={loading} error={error}>
      <Grid
        templateColumns="auto 1fr"
        gap={0}
        fontFamily="body"
        fontSize="16px"
      >
        <GridItem>
          <Box>
            <Score value={score} size="huge" ml={4} mr={8} />
          </Box>
        </GridItem>
        <GridItem>
          <Grid templateColumns="auto 1fr" gap={2} alignItems="center">
            {criteria.map(criterion => {
              return (
                <React.Fragment key={criterion.label}>
                  <GridItem>
                    <Score value={criterion.score} size="tiny" />
                  </GridItem>
                  <GridItem>
                    <HStack spacing={2}>
                      {criterion.to ? (
                        <Link to={criterion.to}>{criterion.label}</Link>
                      ) : (
                        <Text>{criterion.label}</Text>
                      )}
                      {criterion.tooltip ? (
                        <Tooltip label={criterion.tooltip} />
                      ) : null}
                    </HStack>
                  </GridItem>
                </React.Fragment>
              );
            })}
          </Grid>
        </GridItem>
      </Grid>
    </Card>
  );
}
