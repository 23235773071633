import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UseFilterStateProps = {
  clearParamsOnSubmit?: string[];
  debounceInterval?: number;
  defaultValue?: string;
  onSubmit?: (value: string) => void;
  queryParam: string;
};

export function useSearchState({
  clearParamsOnSubmit = [],
  debounceInterval = 500,
  defaultValue = '',
  onSubmit,
  queryParam,
}: UseFilterStateProps) {
  const [searchParams, updateSearchParams] = useSearchParams();

  // Store the current, not yet submitted, value.
  const [state, setState] = useState<string>(
    searchParams.get(queryParam) ?? defaultValue
  );

  // Store the last known state so we can skip the query if nothing changed.
  const [lastState, setLastState] = useState<string>(
    searchParams.get(queryParam) ?? defaultValue
  );

  const handleSubmit = () => {
    if (state !== lastState) {
      clearParamsOnSubmit.forEach(param => {
        searchParams.delete(param);
      });

      if (state === '') {
        searchParams.delete(queryParam);
      } else {
        searchParams.set(queryParam, state);
      }

      updateSearchParams(searchParams);
      onSubmit?.(state);
      setLastState(state);
    }
  };

  useEffect(() => {
    const debouncer = setTimeout(() => {
      handleSubmit();
    }, debounceInterval);

    return () => clearTimeout(debouncer);
  }, [state, debounceInterval]);

  return {
    onChange: setState,
    onReset() {
      setState(defaultValue);
    },
    onSubmit: handleSubmit,
    value: state,
  };
}
