import { Box, Flex, Text } from '../../../../ui';
import BiomarkerCard from '../BiomarkerCard';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getBiologicalHealthOptions } from './ChartOptions';
import ScoreCategoryAndPercentage from './ScoreCategoryAndPercentage';
import {bioFactors, defaultLargeVerticalGap, smallGap} from '../../../../constants';
import { useMediaQuery } from '@chakra-ui/react';

type Props = {
  chartComponent: React.MutableRefObject<HighchartsReact.RefObject | null>;
};

const BiologicalHealthResults = ({ chartComponent }: Props) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)');
  const { stage4 } = useSelector(state => {
    //@ts-ignore
    return state.session.stages;
  });
  const [results, setResults] = useState(null);
  const [prevResults, setPrevResults] = useState(null);
  const [origResults, setOrigResults] = useState(null);
  const [labTests, setLabTests] = useState(null);
  const [prevLabTests, setPrevLabTests] = useState(null);
  const [origLabTests, setOrigLabTests] = useState(null);

  const chartOptions = useMemo(
    () => getBiologicalHealthOptions(results, prevResults, origResults),
    [results, prevResults, origResults]
  );

  /**
   * Fix bug where sometimes the chart width is messed up. On first render, wait
   * 10 milliseconds then reflow the chart.
   */
  useEffect(() => {
    setTimeout(() => {
      chartComponent.current?.chart.reflow();
    }, 10);
  }, [chartOptions]);

  const mock = process.env.MOCK_SOURCES === 'true' || false;
  const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';

  const { user } = useSelector(state => {
    //@ts-ignore
    return state.session;
  })
  useEffect(() => {
    // Define the async function inside useEffect
    const fetchLabTest = async () => {
      try {
        const { data } = await axios.get(`${baseURL}plan${mock ? '.json' : ''}`, {
          headers: { Authorization: user?.token },
        });
        // Set state with results from data
        setResults(data[0]?.results ?? null);
        setPrevResults(data[1]?.results ?? null);
        setOrigResults(null);
        if (data.length > 2) {
          setOrigResults(data[data.length - 1]?.results ?? null);
        }
        setLabTests(data[0]?.lab_test.results ?? null);
        setPrevLabTests(data[1]?.lab_test.results ?? null);
        setOrigLabTests(null);
        if (data.length > 2) {
          setOrigLabTests(data[data.length - 1]?.lab_test.results ?? null);
        }
      } catch (error) {
        console.error('Error fetching lifestyle results:', error);
      }
    };

      // Call the async function
      fetchLabTest();
    }, []);

    const getLabTestValue = (labTests, marker) => {
      const test = labTests?.find(test => test.marker === marker);
      return test ? test.value : null;
    };
    const getLabTestUnit = (labTests, marker) => {
      const test = labTests?.find(test => test.marker === marker);
      return test ? test.unit : null;
    };

    const filterUndefined = (values) => values.filter(value => value !== undefined);

    const getLabTestDifference = (currentTests, prevTests, marker) => {
  const currentValue = currentTests?.find(test => test.marker === marker)?.value;
  const prevValue = prevTests?.find(test => test.marker === marker)?.value;
  if (currentValue !== undefined && prevValue !== undefined) {
    const percentageChange = ((currentValue - prevValue) / prevValue) * 100;
    return `${percentageChange.toFixed(2)} %`;
  }
  return 'N/A';
};

  return (
    <Flex flexDirection="column" gap={defaultLargeVerticalGap}>
      <Flex
        id={'environmental-health-results'}
        flexDirection="column"
        gap={smallGap}
      >
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontSize="24px"
          textTransform="capitalize"
        >
          Biological Health Results
        </Text>
        <Flex
          gap={3}
          overflow="hidden"
          flexWrap="wrap"
          justifyContent={{
            base: 'flex-start',
            sm: 'center',
            lg: 'flex-start',
          }}
        >
          <BiomarkerCard
            factor="Vitamin D"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.vitamin_d
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.vitamin_d
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, '25-Hydroxyvitamin D (Vitamin D)')}
            unit={getLabTestUnit(labTests,'25-Hydroxyvitamin D (Vitamin D)' )}
            currentTest={getLabTestValue(labTests, '25-Hydroxyvitamin D (Vitamin D)')}
            prevTest={getLabTestValue(prevLabTests, '25-Hydroxyvitamin D (Vitamin D)')}
            // origTest={filterUndefined([getLabTestValue(origLabTests, '25-Hydroxyvitamin D (Vitamin D)')])}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.vitamin_d.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.vitamin_d.value
            }
          />
          <BiomarkerCard
            factor="B12"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.B12
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.B12
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'B12 - Vitamin B12 / Cobalamin')}
            unit={getLabTestUnit(labTests,'B12 - Vitamin B12 / Cobalamin' )}
            currentTest={getLabTestValue(labTests, 'B12 - Vitamin B12 / Cobalamin')}
            prevTest={getLabTestValue(prevLabTests, 'B12 - Vitamin B12 / Cobalamin')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.B12.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.B12.value}
          />
          <BiomarkerCard
            factor="Ferritin"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.ferritin
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.ferritin
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Ferritin')}
            unit={getLabTestUnit(labTests,'Ferritin' )}
            currentTest={getLabTestValue(labTests, 'Ferritin')}
            prevTest={getLabTestValue(prevLabTests, 'Ferritin')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.ferritin.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.ferritin.value
            }
          />
          <BiomarkerCard
            factor="ALT"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.ALT
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.ALT
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'ALT - Alanine transaminase')}
            unit={getLabTestUnit(labTests,'ALT - Alanine transaminase' )}
            currentTest={getLabTestValue(labTests, 'ALT - Alanine transaminase')}
            prevTest={getLabTestValue(prevLabTests, 'ALT - Alanine transaminase')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.ALT.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.ALT.value}
          />
          <BiomarkerCard
            factor="AST"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.AST
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.AST
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'AST - Aspartate aminotransferase')}
            unit={getLabTestUnit(labTests,'AST - Aspartate aminotransferase' )}
            currentTest={getLabTestValue(labTests, 'AST - Aspartate aminotransferase')}
            prevTest={getLabTestValue(prevLabTests, 'AST - Aspartate aminotransferase')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.AST.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.AST.value}
          />
          <BiomarkerCard
            factor="eGFR"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.eGFR
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.eGFR
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'eGFR')}
            unit={getLabTestUnit(labTests,'eGFR' )}
            currentTest={getLabTestValue(labTests, 'eGFR')}
            prevTest={getLabTestValue(prevLabTests, 'eGFR')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.eGFR.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.eGFR.value}
          />
          <BiomarkerCard
            factor="Free Testosterone"
            description={
              stage4.biomarker_output?.biomarkers_scores_output
                .free_testosterone.score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output
                .free_testosterone.score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Free Testosterone (Calc)')}
            unit={getLabTestUnit(labTests,'Free Testosterone (Calc)' )}
            currentTest={getLabTestValue(labTests, 'Free Testosterone (Calc)')}
            prevTest={getLabTestValue(prevLabTests, 'Free Testosterone (Calc)')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.free_testosterone.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output
                .free_testosterone.value
            }
          />
          <BiomarkerCard
            factor="Total Testosterone"
            description={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_testosterone.score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_testosterone.score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Testosterone, Total')}
            unit={getLabTestUnit(labTests,'Testosterone, Total' )}
            currentTest={getLabTestValue(labTests, 'Testosterone, Total')}
            prevTest={getLabTestValue(prevLabTests, 'Testosterone, Total')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.total_testosterone.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_testosterone.value
            }
          />
          <BiomarkerCard
            factor="SHBG"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.SHBG
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.SHBG
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'SHBG - Sex hormone binding globulin')}
            unit={getLabTestUnit(labTests,'SHBG - Sex hormone binding globulin' )}
            currentTest={getLabTestValue(labTests, 'SHBG - Sex hormone binding globulin')}
            prevTest={getLabTestValue(prevLabTests, 'SHBG - Sex hormone binding globulin')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.SHBG.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.SHBG.value}
          />
          <BiomarkerCard
            factor="Albumin"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.albumin
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.albumin
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'ALB - Albumin')}
            unit={getLabTestUnit(labTests,'ALB - Albumin' )}
            currentTest={getLabTestValue(labTests, 'ALB - Albumin')}
            prevTest={getLabTestValue(prevLabTests, 'ALB - Albumin')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.albumin.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.albumin.value
            }
          />
          <BiomarkerCard
            factor="Prolactin"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.prolactin
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.prolactin
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Prolactin (PRL)')}
            unit={getLabTestUnit(labTests,'Prolactin (PRL)' )}
            currentTest={getLabTestValue(labTests, 'Prolactin (PRL)')}
            prevTest={getLabTestValue(prevLabTests, 'Prolactin (PRL)')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.prolactin.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.prolactin.value
            }
          />
          <BiomarkerCard
            factor="TSH"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.TSH
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.TSH
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'TSH - Thyroid stimulating hormone')}
            unit={getLabTestUnit(labTests,'TSH - Thyroid stimulating hormone' )}
            currentTest={getLabTestValue(labTests, 'TSH - Thyroid stimulating hormone')}
            prevTest={getLabTestValue(prevLabTests, 'TSH - Thyroid stimulating hormone')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.TSH.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.TSH.value}
          />
          <BiomarkerCard
            factor="Free T4"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.T4
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.T4
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'T4, Free (Thyroxine) Free thyroxine')}
            unit={getLabTestUnit(labTests,'T4, Free (Thyroxine) Free thyroxine' )}
            currentTest={getLabTestValue(labTests, 'T4, Free (Thyroxine) Free thyroxine')}
            prevTest={getLabTestValue(prevLabTests, 'T4, Free (Thyroxine) Free thyroxine')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.T4.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.T4.value}
          />
          <BiomarkerCard
            factor="Free T3"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.T3
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.T3
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'T3, Free (triiodothyronine)')}
            unit={getLabTestUnit(labTests,'T3, Free (triiodothyronine)' )}
            currentTest={getLabTestValue(labTests, 'T3, Free (triiodothyronine)')}
            prevTest={getLabTestValue(prevLabTests, 'T3, Free (triiodothyronine)')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.T3.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.T3.value}
          />
          <BiomarkerCard
            factor="Total Cholesterol"
            description={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_cholesterol.score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_cholesterol.score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Cholesterol, Total')}
            unit={getLabTestUnit(labTests,'Cholesterol, Total' )}
            currentTest={getLabTestValue(labTests, 'Cholesterol, Total')}
            prevTest={getLabTestValue(prevLabTests, 'Cholesterol, Total')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.total_cholesterol.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output
                .total_cholesterol.value
            }
          />
          <BiomarkerCard
            factor="HDL"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.HDL
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.HDL
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'HDL-C - High-density lipoprotein Cholesterol')}
            unit={getLabTestUnit(labTests,'HDL-C - High-density lipoprotein Cholesterol' )}
            currentTest={getLabTestValue(labTests, 'HDL-C - High-density lipoprotein Cholesterol')}
            prevTest={getLabTestValue(prevLabTests, 'HDL-C - High-density lipoprotein Cholesterol')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.HDL.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.HDL.value}
          />
          <BiomarkerCard
            factor="LDL"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.LDL
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.LDL
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'LDL-C - Low-density lipoprotein cholesterol')}
            unit={getLabTestUnit(labTests,'LDL-C - Low-density lipoprotein cholesterol' )}
            currentTest={getLabTestValue(labTests, 'LDL-C - Low-density lipoprotein cholesterol')}
            prevTest={getLabTestValue(prevLabTests, 'LDL-C - Low-density lipoprotein cholesterol')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.LDL.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={stage4.biomarker_output?.biomarkers_scores_output.LDL.value}
          />
          <BiomarkerCard
            factor="Triglycerides"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.triglycerides
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.triglycerides
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'TRIG - Triglycerides')}
            unit={getLabTestUnit(labTests,'TRIG - Triglycerides' )}
            currentTest={getLabTestValue(labTests, 'TRIG - Triglycerides')}
            prevTest={getLabTestValue(prevLabTests, 'TRIG - Triglycerides')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.triglycerides.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.triglycerides
                .value
            }
          />
          <BiomarkerCard
            factor="Creatinine"
            description={
              stage4.biomarker_output?.biomarkers_scores_output.creatinine
                .score_description[0]
            }
            philosofy={
              stage4.biomarker_output?.biomarkers_scores_output.creatinine
                .score_description[1]
            }
            compare={getLabTestDifference(labTests, prevLabTests, 'Creatinine')}
            unit={getLabTestUnit(labTests,'Creatinine' )}
            currentTest={getLabTestValue(labTests, 'Creatinine')}
            prevTest={getLabTestValue(prevLabTests, 'Creatinine')}
            relatedCategoriesImgSrc={stage4.biomarker_output?.biomarkers_scores_output.creatinine.related_category.map(
              (category: string) => `/static/icons/${category}.png`
            )}
            score={
              stage4.biomarker_output?.biomarkers_scores_output.creatinine.value
            }
          />
        </Flex>
      </Flex>
      {/* Biological Health Scores */}
      <Flex flexDirection="column" gap={smallGap}>
        <Text
          color="#E3DBD3"
          display="inline"
          fontFamily="DIN-Medium"
          fontSize="24px"
          lineHeight="130%"
        >
          Biological Health Scores
        </Text>

        <Flex
          id="bio-health-scores-box"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="1rem"
          border={{
            base: '2px solid #7D7C7A',
            sm: 'none',
            md: '2px solid #7D7C7A',
          }}
          gap={smallGap}
        >
          <Flex
            id="bio-viz-box"
            justifyContent="center"
            flexDirection={{ base: 'column', xl: 'row' }}
            alignItems="center"
            width="100%"
          >
            {/* Chart */}
            <Flex id="bio-pie" alignItems="flex-start">
              <Flex alignItems="center" justifyContent="center">
                <img
                  style={{
                    height: '32px',
                    border: '1px solid #D7CBC0',
                    color: '#D7CBC0',
                    position: 'absolute',
                    zIndex: 1,
                  }}
                  src="/static/root/m.png"
                />

                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions}
                  containerProps={{
                    style: {
                      maxWidth: isMobile ? '325px' : null,
                    },
                  }}
                  ref={chartComponent}
                />
              </Flex>
            </Flex>

            <Box
              id="bio-percentage-viz"
              width={{ base: '33%', sm: '100%', md: '33%' }}
              maxWidth="325px"
            >
              {Object.keys(bioFactors).map(category => {
                const item = bioFactors[category];
                return (
                  <ScoreCategoryAndPercentage
                    key={item.title}
                    category={item.title}
                    description={stage4.biomarker_output?.biomarkers_categories_output[
                      category
                      ].text}
                    score={
                      stage4.biomarker_output?.biomarkers_categories_output[
                        category
                      ].value
                    }
                    color={item.color}
                  />
                );
              })}
            </Box>
          </Flex>

          <Text
            color="#7D7C7A"
            fontFamily="DIN-Regular"
            fontSize="14px"
            lineHeight="130%"
            textAlign="left"
            maxWidth={{ base: '75%', sm: '100%', md: '75%' }}
          >
            The biological health category scores are a function of the
            individual biomarker results. Biomarkers are assigned to the health
            categories they impact, with influential biomarkers like total
            testosterone assigned to multiple categories, and the individual
            biomarker scores are combined to compute the category score. The
            individual biomarker scores are calculated based on expert-defined
            ranges and fall between 0 (indicating not optimal levels) and 100
            (indicating optimal levels). Similarly, the category scores range
            from 0 (not optimal) to 100 (optimal).
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BiologicalHealthResults;
