import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import describeArc from '../helpers/describeArc';
import getColor from '../helpers/getColor';

interface Props extends BoxProps {
  negativeThreshold: number;
  positiveThreshold: number;
  value: number;
}

/**
 * Renders a representation of the score, where the background color
 * presents the sentiment (red = bad, green = good), a ring fills to match the
 * score (out of 100), and the value is shown as text in the center.
 */
export default function HugeScore({
  value,
  positiveThreshold,
  negativeThreshold,
  ...props
}: Props): JSX.Element {
  const color = getColor(value, positiveThreshold, negativeThreshold);

  return (
    <Box
      aria-label={`Score of ${value}%`}
      as="svg"
      role="img"
      viewBox="0 0 128 128"
      width="128px"
      height="128px"
      {...props}
    >
      {/* Render the background circle */}
      <Box as="circle" cx="64" cy="64" r="64" fill={color} />

      {/* Render a faded circle showing the path of the value ring. */}
      <Box
        as="circle"
        cx="64"
        cy="64"
        r="50"
        stroke="black"
        opacity={0.1}
        fill="none"
        strokeWidth="10"
        strokeLinecap="round"
      />

      {/* If the value is between 0 and 100 exclusively, render an arc. */}
      {value > 0 && value < 100 ? (
        <Box
          as="g"
          data-testid="score-value-arc"
          stroke="white"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
        >
          <path d={describeArc(64, 64, 50, 0, (value / 100) * 360)} />
        </Box>
      ) : null}

      {/* If the value is 100, render a full circle.  */}
      {value >= 100 ? (
        <Box
          as="circle"
          cx="64"
          cy="64"
          data-testid="score-value-circle"
          r="50"
          stroke="white"
          fill="none"
          strokeWidth="10"
          strokeLinecap="round"
        />
      ) : null}

      {/* Render the value as text in the center. */}
      <Box
        as="text"
        x="64"
        y="76"
        textAnchor="middle"
        fontSize="40px"
        fontWeight="bold"
        fill="white"
      >
        {value}
      </Box>
    </Box>
  );
}
