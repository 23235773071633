import { chakra, Text } from '@chakra-ui/react';

export const SectionHeader = chakra(Text, {
  baseStyle: {
    color: '#685848',
    fontFamily: 'DIN-Medium',
    fontSize: '14px',
    fontWeight: 550,
    textTransform: 'uppercase',
  },
});

export const SectionTitle = chakra(Text, {
  baseStyle: {
    color: '#E3DBD3',
    fontFamily: 'DIN-Medium',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '130%',
  },
});
