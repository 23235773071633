import React, { useState } from 'react';
import { Flex, IconButton, Text } from '..';
import Icon from '../Icon';

// https://a11y-style-guide.com/style-guide/section-navigation.html#kssref-navigation-pagination

interface Props {
  /**
   * (optional) If currentPage is not provided, this component will help keep track of the current page internally automatically.
   * You can provide the currentPage number if you need finer control over it. If you need to conditionally render this Pagination
   * component, it may be preferred to set this currentPage prop, otherwise the internal current page number could be reset upon every de-render.
   */
  currentPage?: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  /** (optional) If itemCount is provided, hovering over the Page # of # text will show the total item count */
  itemCount?: number;
  onFirstPageClick: () => void;
  onLastPageClick: () => void;
  onNextPageClick: () => void;
  onPreviousPageClick: () => void;
  /** If pageCount is undefined or null (in case data is loading), the Pagination control will be hidden */
  pageCount: number | undefined | null;
}

export default function Pagination({
  currentPage,
  pageCount,
  itemCount,
  hasNextPage,
  hasPreviousPage,
  onFirstPageClick,
  onLastPageClick,
  onNextPageClick,
  onPreviousPageClick,
}: Props): JSX.Element | null {
  const [internalCurrentPage, setInternalCurrentPage] = useState(1);

  if (!pageCount) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="center" aria-label="pagination">
      <IconButton
        aria-label="Navigate to first page."
        title="Navigate to first page."
        disabled={!hasPreviousPage}
        onClick={() => {
          onFirstPageClick();
          setInternalCurrentPage(1);
        }}
        icon={<Icon glyph="chevrons-left" />}
        size="sm"
        background="none"
      />
      <IconButton
        aria-label="Navigate to previous page."
        title="Navigate to previous page."
        disabled={!hasPreviousPage}
        onClick={() => {
          onPreviousPageClick();
          setInternalCurrentPage(page => page - 1);
        }}
        icon={<Icon glyph="chevron-left" />}
        size="sm"
        background="none"
      />
      <Text px={2} title={itemCount ? `${itemCount} total results` : undefined}>
        {`Page ${(
          currentPage ?? internalCurrentPage
        ).toLocaleString()} of ${pageCount.toLocaleString()}`}
      </Text>
      <IconButton
        aria-label="Navigate to next page."
        title="Navigate to next page."
        disabled={!hasNextPage}
        onClick={() => {
          onNextPageClick();
          setInternalCurrentPage(page => page + 1);
        }}
        icon={<Icon glyph="chevron-right" />}
        size="sm"
        background="none"
      />
      <IconButton
        aria-label="Navigate to last page."
        title="Navigate to last page."
        disabled={!hasNextPage}
        onClick={() => {
          onLastPageClick();
          setInternalCurrentPage(pageCount);
        }}
        icon={<Icon glyph="chevrons-right" />}
        size="sm"
        background="none"
      />
    </Flex>
  );
}
