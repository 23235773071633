import React from 'react';
import classes from './NextButton.module.css'
import { GridItem } from '../../../ui';
import { Answer } from '../types';

const NextButton = (props: {
  answer: Answer;
  answerState?: string | number;
  onAnswerClick: (anwser: Answer) => void;
  onSaveClick: () => void;
}) => {
    let classNames = [classes.NextButton];
  // For re-usability of this component, adding an optional value,
  // so "Text" can be displayed but something else (value) be submitted
  var valueToSubmit = props.answer;
  if(props.answer.value){
    valueToSubmit = {id: props.answer.id, text: props.answer.value}
  }
  // if (props.answerState) classNames.push(classes[props.answerState]);
  return (
    <GridItem
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames.join(' ')}
      onClick={() =>{
         if (props.onAnswerClick) {
        props.onAnswerClick(valueToSubmit);
      }
      props.onSaveClick();
    }}
    >
      {props.answer.text}
    </GridItem>
  );
};

export default NextButton;
