import React from 'react';
import { Box, Flex, Grid, VStack, Text } from '..';

interface Entry {
  children?: React.ReactNode;
  color?: string;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  title: React.ReactNode;
}

interface Props {
  entries: Entry[];
}

/**
 * A visual representation of events on a vertical timeline.
 */
export default function Timeline({ entries }: Props): JSX.Element {
  return (
    <Grid templateColumns="48px 1fr">
      {entries.map((entry, i) => {
        const isLast = i === entries.length - 1;
        return (
          <React.Fragment key={entry.title?.toString()}>
            <Flex
              align="center"
              alignSelf="center"
              bgColor="blue.500"
              borderRadius="full"
              color="white"
              fontSize="20px"
              height="40px"
              justify="center"
              justifySelf="center"
              mb={1}
              width="40px"
            >
              {entry.icon}
            </Flex>
            <VStack
              align="flex-start"
              justify="center"
              mb={1}
              ml={4}
              spacing={0}
            >
              <Text fontSize="16px" fontWeight="normal">
                {entry.title}
              </Text>
              <Text color="gray.500">{entry.description}</Text>
            </VStack>
            {!isLast || (isLast && entry.children) ? (
              <>
                <Flex align="center" justify="center" mb={1}>
                  {!isLast ? (
                    <Box
                      borderRightColor="gray.300"
                      borderRightWidth="1px"
                      height="100%"
                      width="1px"
                    />
                  ) : null}
                </Flex>
                <Box ml={4} mb={8}>
                  {entry.children}
                </Box>
              </>
            ) : null}
          </React.Fragment>
        );
      })}
    </Grid>
  );
}
