import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import getColor from '../helpers/getColor';

interface Props extends BoxProps {
  negativeThreshold: number;
  positiveThreshold: number;
  value: number;
}

/**
 * Renders a small sentiment indicator depending on the score, each with a
 * different color and shape.
 */
export default function NormalScore({
  value,
  positiveThreshold,
  negativeThreshold,
  ...props
}: Props): JSX.Element {
  const color = getColor(value, positiveThreshold, negativeThreshold);

  // If sentiment is netural, render an red circle.
  if (color === 'sentiment.poor') {
    return (
      <Box
        as="svg"
        display="inline-block"
        fill={color}
        height="16px"
        role="presentation"
        viewBox="0 0 16 16"
        width="16px"
        {...props}
      >
        <circle cx="8" cy="8" r="4" />
      </Box>
    );
  }

  // If sentiment is netural, render an orange diamond.
  if (color === 'sentiment.neutral') {
    return (
      <Box
        as="svg"
        fill={color}
        height="16px"
        role="presentation"
        viewBox="0 0 16 16"
        width="16px"
        {...props}
      >
        <path d="M 3 8 L 8 3 L 13 8 L 8 13 Z" />
      </Box>
    );
  }

  // Otherwise, render a green square.
  return (
    <Box
      as="svg"
      fill={color}
      height="16px"
      role="presentation"
      viewBox="0 0 16 16"
      width="16px"
      {...props}
    >
      <rect x={4} y={4} width={8} height={8} />
    </Box>
  );
}
