import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PageBoundary from '../../ui/ErrorBoundary';
import Tabs from '../../ui/Tabs';
import ScoresPage from './tabs/Scores';
import PlanPage from './tabs/Plan';
import PersonalizedPlanPage from './tabs/PersonalizedPlan'
import SuplementsPage from './tabs/Suplements';
import { Box } from '../../ui';

const Dashboard = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [searchParams, updateSearchParams] = useSearchParams();

  const { user, stages } = useSelector(state => {
    //@ts-ignore
    return state.session;
  });

  const tab = searchParams.get('tab') ?? 'all';

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: 'instant', // instant works, but typescript doesn't like it, "auto" wasn't always working
    });
  }, [pathname]);

  useEffect(() => {
    if (!user?.id) {
      navigate({ pathname: '/login', search: searchParams.toString() });
    }
  }, []);

  return (
    <PageBoundary>
      <Box bg="#141414" padding="2rem">
        <Tabs
          activeTab={tab}
          tabs={[
            {
              id: 'Scores',
              label: 'Health scores',
            },
            {
              disabled: !stages.stage4?.achieved,
              id: 'Plan',
              label: 'Health plan',
            },
            {
              disabled: !stages.stage5?.achieved,
              id: 'Supplements',
              label: 'Precision packs',
            },
          ]}
          onChange={newTab => {
            updateSearchParams({ tab: newTab });
          }}
        />

        {tab === 'Scores' ? (
          <Box mt="2rem">
            <ScoresPage />
          </Box>
        ) : tab === 'Plan' ? (
          <Box mt="2rem">
            <PersonalizedPlanPage />
          </Box>
        ) : (
          <Box mt="2rem">
            <SuplementsPage />
          </Box>
        )}
      </Box>
    </PageBoundary>
  );
};

function mapStateToProps(state: { stages: any; session: any }) {
  return {
    user: state.session.user,
    stages: state.stages,
    quiz: state.session.quiz,
  };
}

export default connect(mapStateToProps, {})(Dashboard);
