import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Grid,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Text } from '../../ui';
import PageBoundary from '../../ui/ErrorBoundary';
import Quiz from '../../components/Quiz';
import {
  fetchCustomer,
  updateCustomer,
} from '../../store/actions/sessionActions';

import { connect, useSelector, useDispatch } from 'react-redux';
import ProductLogo from '../../ui/Shell/components/ProductLogo';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Copyright from '../../components/Copyright';

const mock = process.env.MOCK_SOURCES === 'true' || false;
const baseURL = process.env.API_SOURCE_HOST || 'https://api.getmnly.com/';
const klaviyoFormId = 'VnVVvb';
const klaviyoApiKey = process.env.KLAVIYO_API_KEY || 'Srdwct';

const QuizForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const klaviyoRef = useRef<HTMLDivElement>(null);

  const [email, setEmail] = React.useState('');
  const [emailIsInvalid, setEmailIsInvalid] = useState(false);
  const [toastStatusIsError, setToastStatusIsError] = useState(false);

  const { customer, user, quiz } = useSelector(state => {
    return {
      //@ts-ignore
      customer: state.session.customer,
      //@ts-ignore
      user: state.session.user,
      //@ts-ignore
      quiz: state.session.quiz,
    };
  });

  const currentFactor =
    quiz?.currentQuizQuestion?.factorId?.toLowerCase() || '';
  const isQuizFinished = quiz?.isQuizFinished;

  const [subscriptionFormLoaded, setSubscriptionFormLoaded] =
    React.useState(false);

  useEffect(async () => {
    if (isQuizFinished) {
      navigate('/dashboard?tab=Scores');
    }

    // Commenting out for now is this is creating a lot of traffic (2 calls per question answer)
    // not sure why this needs to be called here and so often
    //const customer = await getCustomer(user.email, user.token);
    //dispatch(fetchCustomer(customer));
  }, [quiz]);

  useEffect(() => {
    if (klaviyoRef!.current!.innerHTML === '') {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoApiKey}`
      );

      for (let i = 0; i < 10; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `klaviyo-form-${klaviyoFormId}`);

        klaviyoRef!.current!.appendChild(script);
        klaviyoRef!.current!.appendChild(div);
      }
      setSubscriptionFormLoaded(true);

      if (klaviyoRef?.current) {
        klaviyoRef.current.style.display = 'none';
      }
    }
  }, [subscriptionFormLoaded]);

  const getCustomer = async (email: string, jwt: string) => {
    try {
      const { data } = await axios.get(
        `${baseURL}customer${mock ? '.json' : '/' + email}`,
        {
          headers: { Authorization: jwt },
        }
      );

      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const updateCustomer = async (customer: {}, email: string, jwt: string) => {
    try {
      await axios.put(
        `${baseURL}customer${mock ? '.json' : '/' + email}`,
        customer,
        {
          headers: { Authorization: jwt },
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  const onDateOfBirthInput = async (dob: string) => {
    let customerUpdated = { ...customer };
    customerUpdated.birthdate = dob;

    await updateCustomer(customerUpdated, user.email, user.token);

    dispatch(fetchCustomer(customerUpdated));
  };

  const validateForm = () => {
    const emailFieldWrongFormat: boolean =
      (!String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) as unknown as boolean) && email !== undefined;

    if (emailFieldWrongFormat) {
      setEmailIsInvalid(true);
      return false;
    }

    return true;
  };

  const onSubscribeClick = () => {
    if (!validateForm()) {
      return;
    }

    //invoke kavviyo action
    const emailInputField =
      klaviyoRef!.current!.querySelector('input[id^=email_]');
    const emailSubmitButton = document.querySelector('.klaviyo-form button');

    try {
      // @ts-ignore
      emailInputField.value = email;
      // @ts-ignore
      emailInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      emailSubmitButton!.click();

      //notify user
      setToastStatusIsError(false);
      toast.success(
        "Thanks for subscribing, you'll get the first email in a couple of minutes.",
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    } catch (err) {
      setToastStatusIsError(true);
      toast.error('Internal server error.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    //reset input for subsecuent subscription requests
    setEmail('');
  };

  const emailFieldEmpty = email === '';

  return (
    <PageBoundary>
      <Flex direction="column">
        <Flex
          flex="1"
          display={isQuizFinished ? 'none' : 'flex'}
          gap={4}
          justifyContent="center"
        >
          <Box
            bg={
              [
                'nutrition',
                'focus',
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              [
                'nutrition',
                'focus',
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/libido.png"
              width="100%"
            />
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Nutrition
            </Text>
          </Box>
          <Box
            bg={
              [
                'focus',
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              [
                'focus',
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/focus.png"
              width="100%"
            />
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Focus
            </Text>
          </Box>
          <Box
            bg={
              [
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              [
                'confidence',
                'stamina',
                'mood',
                'sleep',
                'libido',
                'recovery',
              ].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/confidence.png"
              width="100%"
            />{' '}
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Confidence
            </Text>
          </Box>
          <Box
            bg={
              ['stamina', 'mood', 'sleep', 'libido', 'recovery'].includes(
                currentFactor
              )
                ? '#434B30'
                : 'transparent'
            }
            border={
              ['stamina', 'mood', 'sleep', 'libido', 'recovery'].includes(
                currentFactor
              )
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/stamina.png"
              width="100%"
            />
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Stamina
            </Text>
          </Box>
          <Box
            bg={
              ['mood', 'sleep', 'libido', 'recovery'].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              ['mood', 'sleep', 'libido', 'recovery'].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/mood.png"
              width="100%"
            />{' '}
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Mood
            </Text>
          </Box>
          <Box
            bg={
              ['sleep', 'libido', 'recovery'].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              ['sleep', 'libido', 'recovery'].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/sleep.png"
              width="100%"
            />
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Sleep
            </Text>
          </Box>
          <Box
            bg={
              ['libido', 'recovery'].includes(currentFactor)
                ? '#434B30'
                : 'transparent'
            }
            border={
              ['libido', 'recovery'].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/libido.png"
              width="100%"
            />
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Libido
            </Text>
          </Box>
          <Box
            bg={
              ['recovery'].includes(currentFactor) ? '#434B30' : 'transparent'
            }
            border={
              ['recovery'].includes(currentFactor)
                ? 'none'
                : 'solid 2px #E3DBD3'
            }
            transition="background-color 1000ms linear"
            width="74.153px"
            height="74.153px"
          >
            <img
              style={{ padding: '16px', paddingTop: '12px' }}
              alt="MNLY Precision Men's Health"
              src="/static/icons/recovery.png"
              width="100%"
            />{' '}
            <Text
              position="relative"
              bottom="14px"
              right="1px"
              color="#FFF"
              textAlign="center"
              fontFamily="DIN-Medium"
              fontSize="10px"
              fontStyle="normal"
              lineHeight="normal"
            >
              Recovery
            </Text>
          </Box>
        </Flex>

        <Box mx="auto" pl=".9rem" w="46.0rem">
          <Quiz onDateOfBirthInput={onDateOfBirthInput} />
        </Box>
      </Flex>

      <Box pt="10rem">
        <hr
          style={{
            borderColor: '#7D7C7A',
            height: '.1px',
            opacity: 0.5,
          }}
        />
      </Box>
      {/* Footer */}
      <Box
        px={useBreakpointValue({
          '2xl': '0',
          xl: '50px',
          lg: '50px',
        })}
        mx={useBreakpointValue({
          '2xl': '10%',
          xl: '10%',
          lg: '10%',
          base: '1rem',
        })}
      >
        <ProductLogo
          boxSize="104px"
          display="block"
          name="MNLY"
          position="relative"
          top="-1.05rem"
        />
        <Flex height="100%">
          <Box
            color="#E3DBD3"
            flex="1"
            fontFamily="DIN-Medium"
            fontSize={useBreakpointValue({
              '2xl': '2.2858em',
              xl: '1.6em',
              lg: '1.4em',
            })}
            fontStyle="normal"
            lineHeight="130%"
            minW="26rem"
            width={useBreakpointValue({
              '2xl': '443px',
              xl: '272px',
              lg: '222px',
            })}
            ml={useBreakpointValue({
              '2xl': '0rem',
              xl: '0rem',
              lg: '0rem',
            })}
          >
            <Box
              maxH={useBreakpointValue({
                '2xl': '544px',
                xl: 'none',
                lg: 'none',
              })}
              display="flex"
            >
              <Text
                color="#E3DBD3"
                fontFamily="DIN-Medium"
                fontSize="18px"
                fontStyle="normal"
                lineHeight="normal"
                pt="3rem"
                width="17rem"
              >
                Subscribe and be the first to know about MNLY.
              </Text>
            </Box>
            <Box w="274px">
              <FormControl isInvalid={emailIsInvalid} textAlign="center">
                <Grid
                  pt="1rem"
                  templateColumns="258px 16px"
                  borderBottomColor="rgba(125,124,122,0.5)"
                  borderBottomWidth="1px"
                >
                  <Text
                    color="#7D7C7A"
                    display="inline"
                    fontFamily="DIN-Medium"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="550"
                    lineHeight="normal"
                    position="relative"
                    top="10px"
                  >
                    <Input
                      _active={{
                        border: '0',
                      }}
                      _selected={{
                        border: '0',
                      }}
                      _focus={{
                        border: '0',
                      }}
                      _placeholder={{ color: '#7D7C7A' }}
                      color="#7D7C7A"
                      fontFamily="DIN-Medium"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="550"
                      lineHeight="138%"
                      border="0"
                      pl="1rem"
                      position="relative"
                      top="-8px"
                      placeholder="Enter email"
                      value={email}
                      onChange={e => {
                        if (emailIsInvalid) {
                          setEmailIsInvalid(false);
                        }
                        setEmail(e.currentTarget.value);
                      }}
                    />
                  </Text>

                  <Button
                    top=".05rem"
                    textAlign="right"
                    width="116px"
                    _hover={{
                      textDecoration: 'none',
                      bg: '#E3DBD3',
                      // color: '#7D7C7A',
                    }}
                    bg="#7D7C7A"
                    borderRadius="0"
                    color="#141414"
                    onClick={onSubscribeClick}
                    fontFamily="DIN-Medium"
                    fontSize={useBreakpointValue({
                      md: '.85rem',
                      sm: '.65rem',
                      base: '.35rem',
                    })}
                    fontWeight="medium"
                    lineHeight="normal"
                    position="relative"
                    height={useBreakpointValue({
                      md: '2rem',
                      sm: '2.2rem',
                      base: '1.2rem',
                    })}
                    px="1.25rem"
                    py="1.25rem"
                    textTransform="uppercase"
                  >
                    SUBSCRIBE
                  </Button>
                </Grid>
                {!emailIsInvalid ? (
                  <FormHelperText />
                ) : emailFieldEmpty ? (
                  <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                    Email is required.
                  </FormErrorMessage>
                ) : (
                  <FormErrorMessage width="21.25rem" m="1rem auto" mb="0">
                    Email format is not valid.
                  </FormErrorMessage>
                )}
              </FormControl>
              <Text
                color="#7D7C7A"
                fontFamily="DIN-Regular"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="5"
                lineHeight="normal"
                pt="1rem"
                w="22.5rem"
              >
                By subscribing you agree to our Privacy Policy and provide
                consent to receive updates from our company.
              </Text>
            </Box>
          </Box>
        </Flex>
        <Box width="100%" mt="5rem">
          <hr
            color="#7D7C7A"
            style={{
              borderColor: '#7D7C7A',
              height: '.1px',
              opacity: 0.5,
            }}
          />
          <Flex justifyContent="space-between" pt="1rem">
            <Copyright />

            <Flex gap={6}>
              <Box
                _hover={{ cursor: 'pointer' }}
                color="#7D7C7A"
                fontFamily="Inter"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                onClick={() =>
                  window.open('https://www.shopify.com/legal/terms')
                }
              >
                Terms of Service
              </Box>
              <Box
                _hover={{ cursor: 'pointer' }}
                color="#7D7C7A"
                fontFamily="Inter"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                onClick={() =>
                  window.open('https://www.shopify.com/legal/privacy')
                }
              >
                Privacy Policy
              </Box>
              <Box
                _hover={{ cursor: 'pointer' }}
                color="#7D7C7A"
                fontFamily="Inter"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                onClick={() =>
                  window.open('https://www.shopify.com/legal/cookies')
                }
              >
                Cookie
              </Box>
              <Box
                _hover={{ cursor: 'pointer' }}
                color="#7D7C7A"
                fontFamily="Inter"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                onClick={() => window.open('https://www.shopify.com/legal/aup')}
              >
                Policy
              </Box>
              <Box
                _hover={{ cursor: 'pointer' }}
                color="#7D7C7A"
                fontFamily="Inter"
                fontSize="12px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                onClick={() =>
                  window.open('https://www.shopify.com/accessibility/policy')
                }
              >
                Accessibility
              </Box>
            </Flex>
          </Flex>
        </Box>
        <br />
        <br />
      </Box>

      <div
        ref={klaviyoRef}
        id={`klaviyo-form-${klaviyoFormId}-container`}
      ></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: toastStatusIsError ? 'initial' : '#303525',
        }}
        theme="dark"
      />
    </PageBoundary>
  );
};

function mapStateToProps(state: { session: { quiz: any; user: any } }) {
  return {
    currentFactor:
      state.session.quiz.currentQuizQuestion?.factorId?.toLowerCase() || '',
    customer: state.session.customer,
    isQuizFinished: state.session.quiz.isQuizFinished,
    quiz: state.session.quiz,
    user: state.session.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomer: customer => {
      return dispatch(fetchCustomer(customer));
    },
    updateCustomer: customer => dispatch(updateCustomer(customer)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuizForm);