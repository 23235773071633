import { useRef } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

interface ConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="black" color="white">
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="white">
            Important Information
          </AlertDialogHeader>

          <AlertDialogBody>
            Be sure to use all your previous information associated with your account (email, name, and address) so that your kit will be associated with your profile.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} colorScheme="whiteAlpha" color="white">
              Cancel
            </Button>
            <Button 
              bg="#A0856A" 
              color="white" 
              onClick={onConfirm} 
              ml={3}
              _hover={{ bg: '#7D7C7A' }} // Add hover style for the button
            >
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmModal;
