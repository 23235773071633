import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import {
  ApplicationPageActionButton,
} from './AthletesHero';
import {
  ApplicationPageLargeText,
  AthleteApplicationPageMobilePadding,
  AthleteApplicationPagePadding,
} from './Athletes';

type Props = {
  handleOpen: () => void;
}

const AthletesSection1 = ({handleOpen}: Props) => {
  return (
    <Flex
      direction={{ sm: 'column-reverse', lg: 'row' }}
      padding={{ sm: AthleteApplicationPageMobilePadding, lg: AthleteApplicationPagePadding }}
      gap={{ sm: '2rem', md: '0' }}
    >
      <Box
        width={{ sm: '75%', lg: '50%' }}
        paddingLeft={{ sm: '0', lg: '5.3rem' }}
        paddingRight={{ sm: '0', lg: '5.3rem' }}
      >
        <img
          alt="MNLY Precision Men's Health"
          src={`/static/ads/mnly_athletes.png`}
        />
      </Box>
      <Flex
        direction="column"
        alignItems="flex-start"
        gap="2rem"
        width={{ sm: '100%', lg: '50%' }}
      >
        <Text
          color="#E3DBD3"
          fontFamily="DIN-Medium"
          fontWeight={400}
          fontSize={{sm: "18px", md: "25px"}}
        >
          MNLY ATHLETES
        </Text>
        <ApplicationPageLargeText textAlign="left" as='h1'>
          Defining the Edge
          <br />
          of Excellence and Resilience
        </ApplicationPageLargeText>
        <Text color="#7D7C7A" fontSize="16px" fontWeight={400}>
          In the world of MNLY, our athletes are the epitome of raw, unbridled
          strength, matched with a relentless determination that knows no
          bounds. They are not just participants in their sport; they are the
          masters of their own arena. Defined by a tenacious pursuit of
          excellence, unyielding resilience in the face of challenge, and a
          steadfast commitment to exceeding every limit, they are the true
          warriors of our time.
          <br />
          <br />
          Our athletes embody a spirit that refuses to yield, a mindset that
          thrives on pushing the envelope. Every obstacle is a call to rise,
          every barrier a challenge to be overcome.
        </Text>
        <ApplicationPageActionButton width="125px" onClick={handleOpen}>
          APPLY HERE
        </ApplicationPageActionButton>
      </Flex>
    </Flex>
  );
};

export default AthletesSection1;
