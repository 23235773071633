import React, { useEffect } from 'react';

const KLAVIYO_COMPANY_ID = process.env.KLAVIYO_API_KEY || 'Srdwct'; 

const KlaviyoForm = () => {
  useEffect(() => {
    // Add the Klaviyo script only once
    const script = document.createElement('script');
    script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_COMPANY_ID}`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Clean up the script if needed (optional)
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="klaviyo-form-X2Xb6A"></div>
    </div>
  );
};

export default KlaviyoForm;
