import React from 'react';
import { Box, Button } from '@chakra-ui/react';

interface Props {
  color: string;
  disabled?: boolean;
  label: string;
  onClick?: () => void;
}

export default function Legend({
  color,
  disabled = false,
  label,
  onClick,
}: Props): JSX.Element {
  return (
    <Button
      leftIcon={
        <Box
          boxSize={2}
          borderRadius={4}
          borderWidth={1}
          borderColor={color}
          background={disabled ? 'transparent' : color}
        />
      }
      variant="ghost"
      onClick={onClick}
      size="xs"
      fontWeight="normal"
    >
      {label}
    </Button>
  );
}
