import React from 'react';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { InstructionStageProps } from './Step0';
import { defaultVerticalGap } from '../../../constants';
import BackAndNextBtns from './BackAndNextBtns';
import {
  InstructionListItem,
  InstructionTinySubtext,
  InstructionTitle,
  StepLabel,
} from './InstructionCommonComponents';

const Step6 = ({ activeStage, setActiveStage }: InstructionStageProps) => {
  return (
    <Flex
      id="step-6"
      flexDirection="column"
      alignItems="center"
      gap={defaultVerticalGap}
      maxWidth="615px"
    >
      <StepLabel>Step 6</StepLabel>
      <InstructionTitle>Applying the lancet.</InstructionTitle>

      <UnorderedList spacing="0.5rem">
        <InstructionListItem>
          Apply the lancet on either side of your finger.
        </InstructionListItem>
        <InstructionListItem>
          Press the lancet into your finger until you hear a click.
        </InstructionListItem>
        <InstructionListItem>
          Massage (dont squeeze) your finger to increase the blood flow.
        </InstructionListItem>
        <InstructionListItem>
          The blood goes in the left top and bottom squares of the collection device.
          Fill the device until the first line indicated on each strip turns
          red. Fill up all four panels on the two sample strips provided.
        </InstructionListItem>
      </UnorderedList>
      <Grid
        templateColumns={{
          base: 'repeat(2, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
        border="2px solid #7D7C7A"
        padding={'1.5rem'}
        columnGap={'3rem'}
        rowGap={'2rem'}
        maxWidth="615px"
      >
        <GridItem height="100%">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
          >
            <Box width={'158px'} height={'160px'}>
              <img
                alt="acceptable samples"
                src="/static/icons/acceptable_samples.png"
              />
              <img
                alt="acceptable samples 2"
                src="/static/icons/acceptable_samples_2.png"
              />
            </Box>
            <img src="static/icons/check_circle_filled.png" width="24px" />
            <InstructionTinySubtext fontFamily="DIN-Medium">
              Acceptable Samples
            </InstructionTinySubtext>
          </Flex>
        </GridItem>
        <GridItem height="100%">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="1rem"
          >
            <Box width="296px" height="160px">
              <img
                alt="acceptable samples"
                src="/static/icons/unacceptable_samples.png"
              />
            </Box>
            <img src="static/icons/cancel.png" width="24px" />
            <InstructionTinySubtext fontFamily="DIN-Medium">
              Unacceptable Samples
            </InstructionTinySubtext>
          </Flex>
        </GridItem>
      </Grid>
      <BackAndNextBtns
        activeStage={activeStage}
        setActiveStage={setActiveStage}
      />
    </Flex>
  );
};

export default Step6;