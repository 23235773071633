// https://react-icons.github.io/react-icons

import React from 'react';
import {
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import { AiOutlineBank } from '@react-icons/all-files/ai/AiOutlineBank';
import { BiBriefcase } from '@react-icons/all-files/bi/BiBriefcase';
import { BiExtension } from '@react-icons/all-files/bi/BiExtension';
import { BiPackage } from '@react-icons/all-files/bi/BiPackage';
import { BiRocket } from '@react-icons/all-files/bi/BiRocket';
import { BiStopwatch } from '@react-icons/all-files/bi/BiStopwatch';
import { BiWrench } from '@react-icons/all-files/bi/BiWrench';
import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import { FaExclamationCircle } from '@react-icons/all-files/fa/FaExclamationCircle';
import { FaQuestionCircle } from '@react-icons/all-files/fa/FaQuestionCircle';
import { FcApproval } from '@react-icons/all-files/fc/FcApproval';
import { FiActivity } from '@react-icons/all-files/fi/FiActivity';
import { FiArrowDown } from '@react-icons/all-files/fi/FiArrowDown';
import { FiArrowUp } from '@react-icons/all-files/fi/FiArrowUp';
import { FiAward } from '@react-icons/all-files/fi/FiAward';
import { FiBarChart2 } from '@react-icons/all-files/fi/FiBarChart2';
import { FiCalendar } from '@react-icons/all-files/fi/FiCalendar';
import { FiCheck } from '@react-icons/all-files/fi/FiCheck';
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown';
import { FiChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft';
import { FiChevronRight } from '@react-icons/all-files/fi/FiChevronRight';
import { FiChevronsLeft } from '@react-icons/all-files/fi/FiChevronsLeft';
import { FiChevronsRight } from '@react-icons/all-files/fi/FiChevronsRight';
import { FiChevronUp } from '@react-icons/all-files/fi/FiChevronUp';
import { FiClipboard } from '@react-icons/all-files/fi/FiClipboard';
import { FiClock } from '@react-icons/all-files/fi/FiClock';
import { FiCode } from '@react-icons/all-files/fi/FiCode';
import { FiCopy } from '@react-icons/all-files/fi/FiCopy';
import { FiEdit } from '@react-icons/all-files/fi/FiEdit';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';
import { FiGitBranch } from '@react-icons/all-files/fi/FiGitBranch';
import { FiGitCommit } from '@react-icons/all-files/fi/FiGitCommit';
import { FiGlobe } from '@react-icons/all-files/fi/FiGlobe';
import { FiGrid } from '@react-icons/all-files/fi/FiGrid';
import { FiHelpCircle } from '@react-icons/all-files/fi/FiHelpCircle';
import { FiHome } from '@react-icons/all-files/fi/FiHome';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiMenu } from '@react-icons/all-files/fi/FiMenu';
import { FiMessageSquare } from '@react-icons/all-files/fi/FiMessageSquare';
import { FiPieChart } from '@react-icons/all-files/fi/FiPieChart';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import { FiSearch } from '@react-icons/all-files/fi/FiSearch';
import { FiSettings } from '@react-icons/all-files/fi/FiSettings';
import { FiShield } from '@react-icons/all-files/fi/FiShield';
import { FiSliders } from '@react-icons/all-files/fi/FiSliders';
import { FiThumbsDown } from '@react-icons/all-files/fi/FiThumbsDown';
import { FiThumbsUp } from '@react-icons/all-files/fi/FiThumbsUp';
import { FiTrash } from '@react-icons/all-files/fi/FiTrash';
import { FiUploadCloud } from '@react-icons/all-files/fi/FiUploadCloud';
import { FiUser } from '@react-icons/all-files/fi/FiUser';
import { FiUsers } from '@react-icons/all-files/fi/FiUsers';
import { FiX } from '@react-icons/all-files/fi/FiX';
import { FiZoomIn } from '@react-icons/all-files/fi/FiZoomIn';
import { FiZoomOut } from '@react-icons/all-files/fi/FiZoomOut';
import { GiChart } from '@react-icons/all-files/gi/GiChart';
import { HiLockClosed } from '@react-icons/all-files/hi/HiLockClosed';
import { IoLogoAndroid } from '@react-icons/all-files/io5/IoLogoAndroid';
import { IoLogoApple } from '@react-icons/all-files/io5/IoLogoApple';
import { IoLogoGithub } from '@react-icons/all-files/io5/IoLogoGithub';
import { MdRefresh } from '@react-icons/all-files/md/MdRefresh';
import { RiAlertLine } from '@react-icons/all-files/ri/RiAlertLine';
import { RiBarChartHorizontalFill } from '@react-icons/all-files/ri/RiBarChartHorizontalFill';
import { RiHeartFill } from '@react-icons/all-files/ri/RiHeartFill';
import { RiHeartLine } from '@react-icons/all-files/ri/RiHeartLine';
import { RiArrowUpSFill } from '@react-icons/all-files/ri/RiArrowUpSFill';
import { RiArrowDownSFill } from '@react-icons/all-files/ri/RiArrowDownSFill';
import { RiStarFill } from '@react-icons/all-files/ri/RiStarFill';
import { RiStarLine } from '@react-icons/all-files/ri/RiStarLine';
import { TiArrowLoop } from '@react-icons/all-files/ti/TiArrowLoop';
import { VscBug } from '@react-icons/all-files/vsc/VscBug';
import { VscCircleFilled } from '@react-icons/all-files/vsc/VscCircleFilled';
import { VscCircleOutline } from '@react-icons/all-files/vsc/VscCircleOutline';
import { VscGitPullRequest } from '@react-icons/all-files/vsc/VscGitPullRequest';
import { VscListTree } from '@react-icons/all-files/vsc/VscListTree';
import { VscGithubAction } from '@react-icons/all-files/vsc/VscGithubAction';
import { VscRepo } from '@react-icons/all-files/vsc/VscRepo';
import PayPalIcon from './PayPal';
import SlackIcon from './Slack';
import VenmoIcon from './Venmo';

export const iconMap = {
  activity: FiActivity,
  alert: RiAlertLine,
  'alert-filled': FaExclamationCircle,
  android: IoLogoAndroid,
  apple: IoLogoApple,
  'arrow-down': FiArrowDown,
  'arrow-up': FiArrowUp,
  award: FiAward,
  backlog: VscListTree,
  bank: AiOutlineBank,
  branch: FiGitBranch,
  briefcase: BiBriefcase,
  bug: VscBug,
  calendar: FiCalendar,
  'chart-bar': RiBarChartHorizontalFill,
  'chart-column': FiBarChart2,
  'chart-line': GiChart,
  'chart-pie': FiPieChart,
  check: FiCheck,
  'check-filled': FaCheckCircle,
  'chevron-down': FiChevronDown,
  'chevron-left': FiChevronLeft,
  'chevron-right': FiChevronRight,
  'chevron-up': FiChevronUp,
  'chevrons-left': FiChevronsLeft,
  'chevrons-right': FiChevronsRight,
  'circle-empty': VscCircleOutline,
  'circle-filled': VscCircleFilled,
  clipboard: FiClipboard,
  clock: FiClock,
  code: FiCode,
  commit: FiGitCommit,
  copy: FiCopy,
  edit: FiEdit,
  extension: BiExtension,
  'external-link': FiExternalLink,
  filters: FiSliders,
  github: IoLogoGithub,
  globe: FiGlobe,
  grid: FiGrid,
  'heart-empty': RiHeartLine,
  'heart-filled': RiHeartFill,
  help: FiHelpCircle,
  home: FiHome,
  jobs: VscGithubAction,
  lock: HiLockClosed,
  mail: FiMail,
  menu: FiMenu,
  message: FiMessageSquare,
  package: BiPackage,
  paypal: PayPalIcon,
  plus: FiPlus,
  'pull-request': VscGitPullRequest,
  'question-filled': FaQuestionCircle,
  refresh: MdRefresh,
  repository: VscRepo,
  rocket: BiRocket,
  search: FiSearch,
  settings: FiSettings,
  shield: FiShield,
  slack: SlackIcon,
  'sort-down': RiArrowDownSFill,
  'sort-up': RiArrowUpSFill,
  sprint: TiArrowLoop,
  'star-empty': RiStarLine,
  'star-filled': RiStarFill,
  stopwatch: BiStopwatch,
  success: FcApproval,
  'thumbs-down': FiThumbsDown,
  'thumbs-up': FiThumbsUp,
  trash: FiTrash,
  upload: FiUploadCloud,
  user: FiUser,
  users: FiUsers,
  venmo: VenmoIcon,
  wrench: BiWrench,
  x: FiX,
  'zoom-in': FiZoomIn,
  'zoom-out': FiZoomOut,
} as const;

export type IconGlyph = keyof typeof iconMap;

interface IconProps extends ChakraIconProps {
  glyph: IconGlyph;
}

export default function Icon({ glyph, ...props }: IconProps) {
  return <ChakraIcon as={iconMap[glyph]} {...props} />;
}
