import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Box } from '../../../../ui';
import { Factor } from '../../../../constants';

type ListItemProps = {
  factors: Factor;
  factorCategory: string;
  setHovered: (value: boolean) => void;
  setFactorCategory: (name: string) => void;
};
const HoverListItem = ({
  factors,
  factorCategory,
  setHovered,
  setFactorCategory,
}: ListItemProps) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    onMouseEnter={() => {
      setHovered(true);
      setFactorCategory(factorCategory);
    }}
    onMouseLeave={() => setHovered(false)}
    _hover={{
      bg: '#685848',
    }}
    transition="background-color 200ms linear"
    borderTop="1px solid #3E362E"
    borderBottom="1px solid #3E362E"
    p="0.5rem"
  >
    <Flex flexDirection="row" alignItems="center">
      <Box>
        <img
          style={{ position: 'relative' }}
          alt="MNLY Precision Men's Health"
          src={`/static/icons/${factorCategory}.png`}
          width="100%"
        />
      </Box>
      <Box
        pl="1rem"
        color="#E3DBD3"
        fontFamily="DIN-Medium"
        fontSize="18px"
        fontWeight="550"
      >
        {factors[factorCategory].title}
      </Box>
    </Flex>
    <Box>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.03025 10.0001L8 10.9698L8 18H10V10.9095L10.9094 10.0001L18 10.0001V8.0001L10.9699 8.0001L10 7.03015V0H8L8 6.96985L6.96975 8.0001L0 8.0001V10.0001L7.03025 10.0001Z"
          fill="#E3DBD3"
        />
      </svg>
    </Box>
  </Flex>
);

export default HoverListItem;
