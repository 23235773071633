export default function getColor(
  score: number,
  positiveThreshold: number,
  negativeThreshold: number
): string {
  if (score < negativeThreshold) {
    return 'sentiment.poor';
  }

  if (score < positiveThreshold) {
    return 'sentiment.neutral';
  }

  return 'sentiment.great';
}
