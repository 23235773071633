import React from 'react';
import { Flex, Placeholder } from '../../ui';
import PageBoundary from '../../ui/ErrorBoundary';
const Company = () => {
  return (
    <PageBoundary>
      <Flex justifyContent="center" alignItems="center" bg= "linear-gradient(#141414, #000)">
        <Placeholder title="Coming Soon" description="We are working on it!" />
      </Flex>
    </PageBoundary>
  );
};

export default Company;
