import React, { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Flex, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import PageBoundary from '../../ui/ErrorBoundary';
import { ActionButton } from '../../components/Buttons';
import MnlyFormInputField from './MnlyFormInputField';
import Copyright from '../../components/Copyright';
import MnlyFormSelectField from './MnlyFormSelectField';
import ProductLogo from '../../ui/Shell/components/ProductLogo';
import { SmallAddIcon } from '@chakra-ui/icons';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { List, ListItem, ListIcon } from '@chakra-ui/react';
import { Placeholder } from '../../ui';

const KLAVIYO_FORM_ID = 'TvKtdg';
const KLAVIYO_COMPANY_ID = process.env.KLAVIYO_API_KEY || 'Srdwct';

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  referral?: string;
};

const schema = yup.object({
  email: yup
    .string()
    .email()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  firstName: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  lastName: yup
    .string()
    .max(255, 'Maximum of 255 characters')
    .required('Required'),
  referral: yup.string().max(255, 'Maximum of 255 characters'),
});

const WaitlistPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ resolver: yupResolver(schema) });
  const navigate = useNavigate();
  const [toastStatusIsError, setToastStatusIsError] = useState(false);

  const klaviyoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (klaviyoRef!.current!.innerHTML === '') {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${KLAVIYO_COMPANY_ID}`
      );

      for (let i = 0; i < 10; i++) {
        const div = document.createElement('div');
        div.setAttribute('class', `klaviyo-form-${KLAVIYO_FORM_ID}`);

        klaviyoRef!.current!.appendChild(script);
        klaviyoRef!.current!.appendChild(div);
      }

      if (klaviyoRef?.current) {
        klaviyoRef.current.style.display = 'none';
      }
    }
  }, []);

  const onSubmit: SubmitHandler<Inputs> = data => {
    try {
      // invoke kavviyo action
      const emailInputField =
        klaviyoRef!.current!.querySelector('input[id^=email_]');
      const firstNameInputField = klaviyoRef!.current!.querySelector(
        'input[id^=first_name_]'
      );
      const lastNameInputField = klaviyoRef!.current!.querySelector(
        'input[id^=last_name_]'
      );

      const referralInputField = klaviyoRef!.current!.querySelector(
        'input[id^=referral_]'
      );

      const submitBtn = document.querySelector('.klaviyo-form button');
      // @ts-ignore
      emailInputField.value = data.email;
      // @ts-ignore
      firstNameInputField.value = data.firstName;
      // @ts-ignore
      lastNameInputField.value = data.lastName;
      // @ts-ignore
      if (referralInputField) {
        referralInputField.value = data.referral;
      }
      // @ts-ignore
      emailInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      firstNameInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      lastNameInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      referralInputField.dispatchEvent(new Event('input'));
      // @ts-ignore
      setTimeout(function () {
        submitBtn!.click();
        setTimeout(function () {
          setToastStatusIsError(false);
          toast.success(
            'Congratulations you have been added to the waitlist. Please check your email for updates.',
            {
              autoClose: 5000,
              closeOnClick: true,
              draggable: true,
              hideProgressBar: false,
              pauseOnHover: true,
              position: 'top-right',
              progress: undefined,
              theme: 'dark',
            }
          );
          navigate('/');
        }, 200);
      }, 200);
    } catch (err) {
      console.error('Error joining waitlist:', err);
      setToastStatusIsError(true);
      toast.error(
        'Error joining waitlist, please try again or contact support.',
        {
          autoClose: 5000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-right',
          progress: undefined,
          theme: 'dark',
        }
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const referralOptions = ['HYROX', 'Golden Ticket'];

  const formFields = [
    {
      name: 'email',
      placeholder: 'Email',
      type: 'email',
      width: '350px',
    },
    {
      name: 'firstName',
      placeholder: 'First Name',
    },
    {
      name: 'lastName',
      placeholder: 'Last Name',
    },
    {
      name: 'referral',
      placeholder: 'Enter Referrer Code (optional)',
    },
  ];

  return (
    <PageBoundary>
      <Flex direction="row" h="100vh" overflowY="hidden">
        <Flex
          flex="1"
          bg="#141414"
          alignItems="center"
          gap={5}
          direction="column"
          pt="6rem"
        >
          <Heading as="h2" size="xl" mb={4} color="whiteAlpha.900">
            Join our Waitlist.
          </Heading>
          <Text fontSize="md" color="whiteAlpha.900" maxWidth="350px">
            Unlock Early Access and Special Pricing! Waitlist Subscribers gain
            access to:
          </Text>
          <VStack
            align="start"
            spacing={2}
            color="whiteAlpha.900"
            maxWidth="350px"
          >
            <List spacing={2}>
              <ListItem>
                <Flex alignItems="center">
                  <ListIcon as={SmallAddIcon} mr={2} />
                  <Text fontSize="md">
                    Exclusive early access to MNLY before the official launch.
                  </Text>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex alignItems="center">
                  <ListIcon as={SmallAddIcon} mr={2} />
                  <Text fontSize="md">
                    Special pricing reserved for the first 100 customers.
                  </Text>
                </Flex>
              </ListItem>
              <ListItem>
                <Flex alignItems="center">
                  <ListIcon as={SmallAddIcon} mr={2} />
                  <Text fontSize="md">Exclusive FREE bonus at checkout.</Text>
                </Flex>
              </ListItem>
            </List>
          </VStack>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex direction="column" gap={3}>
              {formFields.map(field => (
                <FormControl
                  key={field.name}
                  isInvalid={!!errors[field.name as keyof Inputs]}
                >
                  {field.type === 'select' ? (
                    <MnlyFormSelectField
                      id={field.name}
                      placeholder={field.placeholder}
                      {...register(field.name as keyof Inputs)}
                    >
                      {field.options?.map(option => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </MnlyFormSelectField>
                  ) : (
                    <MnlyFormInputField
                      id={field.name}
                      placeholder={field.placeholder}
                      type={field.type}
                      width={field.width}
                      {...register(field.name as keyof Inputs)}
                    />
                  )}
                  <FormErrorMessage>
                    {errors[field.name as keyof Inputs]?.message}
                  </FormErrorMessage>
                </FormControl>
              ))}
              <ActionButton type="submit">Join</ActionButton>
              <Flex justifyContent="center">
                <Copyright />
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Flex>
      <div ref={klaviyoRef} id={`klaviyo-form-${KLAVIYO_FORM_ID}-container`} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          backgroundColor: toastStatusIsError ? 'initial' : '#303525',
        }}
        theme="dark"
      />
    </PageBoundary>
  );
};

export default WaitlistPage;
